import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  ScrollView,
  StyleSheet,
  Image,
  View,
  TouchableOpacity,
} from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { PricingStackParamList } from '../navigators/PricingNavigator';
import Block from '../components/Block/Block';
import P from '../components/P/P';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartState, pricingCanOrderState } from '../store/state';
import Button from '../components/Button/Button';
import Stepper from '../components/Stepper/Stepper';
import { t } from '../helpers/translation';
import { formatPrice } from '../helpers/format';
import H3 from '../components/H3/H3';
import CheckBox from '../components/CheckBox/CheckBox';
import Wrapper from '../components/Wrapper/Wrapper';
import { useIsWideScreen } from '../hooks/responsive';

type Props = {
  navigation: StackNavigationProp<PricingStackParamList, 'Item'>;
  route: RouteProp<PricingStackParamList, 'Item'>;
};

const ItemScreen = (props: Props) => {
  const { item } = props.route.params;

  if (typeof item === 'string') {
    // If the user is getting to this page or tries to
    // share an item link, it won't work. Redirect to parent
    // instead.
    props.navigation.replace('Pricing', {
      pricingSlug: props.route.params.pricingSlug,
    });
  }

  const isWideScreen = useIsWideScreen();
  const [quantity, setQuantity] = useState(1);
  const [comment, setComment] = useState('');
  const [options, setOptions] = useState<{
    [optionCategoryId: string]: string[];
  }>(() => {
    if (!item.optionCategories) {
      return {};
    }

    return item.optionCategories
      .map((category) => {
        return {
          optionCategoryId: category.id,
          optionIds: category.options
            .filter((option) => {
              return option.isDefault;
            })
            .map((option) => option.id),
        };
      })
      .reduce(
        (acc, curr) => ({ ...acc, [curr.optionCategoryId]: curr.optionIds }),
        {}
      );
  });
  const [cart, setCart] = useRecoilState(cartState);
  const pricingCanOrder = useRecoilValue(pricingCanOrderState);
  const isItemInCart = useMemo(
    () => cart.find((cartItem) => cartItem.id === item.id),
    [cart, item.id]
  );

  useEffect(() => {
    if (isItemInCart) {
      const itemQuantity = cart.find((cartItem) => cartItem.id === item.id)!
        .quantity;
      setQuantity(itemQuantity);
    }
  }, [isItemInCart]);

  const handleSetQuantity = useCallback(
    (quantity: number) => {
      // TODO: if is in cart, should update cart item, on top of updating `quantity`
      setQuantity(quantity);
    },
    [isItemInCart]
  );

  const handleAddCartItem = useCallback(() => {
    setCart((cart) => [
      ...cart,
      {
        id: item.id!,
        quantity,
        comment: comment.length !== 0 ? comment : undefined,
        options: Object.entries(options)
          .filter(([_, optionIds]) => {
            return optionIds.length > 0;
          })
          .map(([optionCategoryId, optionIds]) => [
            optionCategoryId,
            optionIds,
          ]),
      },
    ]);

    props.navigation.pop();
  }, [item.id, comment, quantity, options]);

  const handleRemoveCartItem = useCallback(() => {
    setCart((cart) => cart.filter((cartItem) => cartItem.id !== item.id));

    props.navigation.pop();
  }, [item.id]);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Wrapper noPadding>
        {item.image && (
          <Image
            source={{ uri: item.image }}
            resizeMode="cover"
            style={[styles.image, isWideScreen && styles.imageWide]}
          />
        )}

        {item.description && (
          <Block
            horizontalMargins
            // @ts-ignore
            style={[
              styles.information,
              !!item.image && styles.informationWithImage,
            ]}
          >
            {item.description && <P>{item.description}</P>}
          </Block>
        )}

        {item.optionCategories &&
          item.optionCategories.length > 0 &&
          item.optionCategories.map((optionCategory) => (
            <View
              key={optionCategory.id}
              style={{ marginBottom: 20, marginHorizontal: 20 }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <View style={{ flex: 1 }}>
                  <H3>
                    {optionCategory.name +
                      ' (' +
                      t('screens.item.paragraphs.chooseUpTo') +
                      ' ' +
                      (optionCategory.tickCount === 1
                        ? t('screens.item.paragraphs.oneOption')
                        : t('screens.item.paragraphs.manyOptions', {
                            options: optionCategory.tickCount,
                          })) +
                      ')'}
                  </H3>

                  {optionCategory.description && (
                    <P>{optionCategory.description}</P>
                  )}
                </View>
              </View>

              <Block>
                {optionCategory.options.map((itemOption, index) => {
                  const optionValue = options[optionCategory.id]?.includes(
                    itemOption.id
                  );

                  const onPress = (isChecked: boolean) => {
                    setOptions((options) => {
                      if (isChecked) {
                        const categoryOptions = [...options[optionCategory.id]];

                        if (
                          categoryOptions.length === optionCategory.tickCount
                        ) {
                          categoryOptions.pop();
                        }

                        categoryOptions.push(itemOption.id);

                        return {
                          ...options,
                          [optionCategory.id]: categoryOptions,
                        };
                      } else {
                        return {
                          ...options,
                          [optionCategory.id]: options[
                            optionCategory.id
                          ].filter((optionId) => optionId !== itemOption.id),
                        };
                      }
                    });
                  };

                  return (
                    <TouchableOpacity
                      key={itemOption.id}
                      disabled={!pricingCanOrder}
                      onPress={() => {
                        onPress(!optionValue);
                      }}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingBottom:
                          index === optionCategory.options.length - 1 ? 0 : 10,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <P>{itemOption.name}</P>

                        {itemOption.description && (
                          <P>{itemOption.description}</P>
                        )}

                        {/* TODO format price */}
                        <P style={styles.optionPrice}>
                          {itemOption.price
                            ? `+${formatPrice(
                                'EUR',
                                'value',
                                itemOption.price
                              )}`
                            : t('common.free')}
                        </P>
                      </View>

                      {pricingCanOrder && (
                        <CheckBox value={optionValue} onValueChange={onPress} />
                      )}
                    </TouchableOpacity>
                  );
                })}
              </Block>
            </View>
          ))}

        {pricingCanOrder && (
          <Block horizontalMargins>
            <Stepper
              value={quantity}
              onChangeValue={handleSetQuantity}
              minValue={1}
              style={styles.stepperQuantity}
            />

            <Button
              type={isItemInCart ? 'danger' : 'default'}
              title={
                isItemInCart
                  ? t('screens.item.buttons.removeFromCart')
                  : t('screens.item.buttons.addToCart')
              }
              onPress={isItemInCart ? handleRemoveCartItem : handleAddCartItem}
            />
          </Block>
        )}
      </Wrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  image: {
    width: '100%',
    height: 200,
  },
  imageWide: {
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  information: {
    marginVertical: 20,
  },
  informationWithImage: {
    marginTop: -20,
  },
  stepperQuantity: {
    marginBottom: 20,
  },
  optionPrice: {
    fontStyle: 'italic',
  },
});

export default ItemScreen;
