import React from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import Icon from '../Icon/Icon';
import Table from '../Table/Table';
import Text from '../Text/Text';

export type FeaturesTableProps = {
  color: string;
  features: { icon: string; title: string; details?: string }[];
  style?: ViewProps['style'];
};

const FeaturesTable = (props: FeaturesTableProps) => {
  return (
    <Table
      hasLeadingColumn
      data={props.features.map((feature) => [
        <>
          <Icon
            name={feature.icon}
            color={props.color}
            style={styles.featureIcon}
          />

          <Text style={styles.featureTitle}>{feature.title}</Text>
        </>,
        feature.details ? (
          <Text>{feature.details}</Text>
        ) : (
          <Icon name="check" />
        ),
      ])}
      style={props.style}
    />
  );
};

const styles = StyleSheet.create({
  featureIcon: {
    marginRight: 10,
  },
  featureTitle: {
    fontWeight: 'bold',
  },
});

export default FeaturesTable;
