import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useRecoilValue } from 'recoil';
// @ts-ignore
import slugify from 'slug';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';
import { businessNameState } from '../../store/state';
import CurrencySelector from '../../components/CurrencySelector/CurrencySelector';
import Block from '../../components/Block/Block';
import H3 from '../../components/H3/H3';
import { t } from '../../helpers/translation';
import Hint from '../../components/Hint/Hint';
import P from '../../components/P/P';
import H2 from '../../components/H2/H2';

type Props = {
  submitting: boolean;
  onSubmit: (data: { slug: string; baseCurrency: string }) => void;
};

const CreatePricingForm = (props: Props) => {
  const businessName = useRecoilValue(businessNameState);
  const [slug, setSlug] = useState(() => slugify(businessName ?? ''));
  const [currency, setCurrency] = useState('EUR');

  const handleSubmit = useCallback(
    () => props.onSubmit({ slug, baseCurrency: currency }),
    []
  );

  return (
    <Block>
      <P>{t('forms.createPricing.slug.indications')}</P>

      <TextInput
        editable
        value={slug}
        onChangeText={setSlug}
        placeholder={t('forms.createPricing.slug.placeholder')}
        onBlur={() => setSlug(slugify(slug))}
        leftText="illicarte.com/"
        style={styles.inlineInput}
      />

      <Hint>{t('forms.createPricing.slug.hint')}</Hint>

      <P style={[styles.topMargin, styles.bottomMargin]}>
        {t('forms.createPricing.currency.indications')}
      </P>

      <CurrencySelector value={currency} onChangeValue={setCurrency} />

      <Button
        disabled={slug.length === 0 || currency.length !== 3}
        icon="arrow-right"
        title={t('common.continue')}
        onPress={handleSubmit}
        style={styles.topMargin}
        busy={props.submitting}
      />
    </Block>
  );
};

const styles = StyleSheet.create({
  inlineInput: {
    flexDirection: 'row',
    marginTop: 10,
  },
  topMargin: {
    marginTop: 20,
  },
  bottomMargin: {
    marginBottom: 10,
  },
});

export default CreatePricingForm;
