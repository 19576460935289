import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';
import Text from '../Text/Text';

export type BubbleProps = {
  children: any;
  sideWidth?: number;
  color?: string;
  style?: ViewProps['style'];
};

const Bubble = (props: BubbleProps) => {
  return (
    <View
      style={[
        styles.container,
        !!props.color && { backgroundColor: props.color },
        !!props.sideWidth && {
          height: props.sideWidth,
          width: props.sideWidth,
          borderRadius: props.sideWidth / 2,
        },
        props.style,
      ]}
    >
      {typeof props.children === 'string' ||
      typeof props.children === 'number' ? (
        <Text style={styles.text}>{props.children}</Text>
      ) : (
        props.children
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: DefaultTheme.colors.primary,
    borderRadius: 10,
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
});

export default Bubble;
