import React from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DefaultTheme, DarkTheme, RouteProp } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import { UserStackParamList } from '../navigators/UserNavigator';
import { t } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import OrderDetails from '../components/OrderDetails/OrderDetails';
import OrderStatusSelectors from '../components/OrderStatusSelectors/OrderStatusSelectors';

type BusinessOrderDetailsScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'BusinessOrderDetails'>;
  route: RouteProp<UserStackParamList, 'BusinessOrderDetails'>;
};

const BusinessOrderDetailsScreen = (props: BusinessOrderDetailsScreenProps) => {
  const scheme = useColorScheme();
  const { order } = props.route.params;

  useDocumentTitle(
    t('navigation.orderDetails', { reference: order.referenceNumber })
  );

  return (
    <>
      <View
        style={[
          styles.statusSelectors,
          scheme === 'dark' && styles.statusSelectorsDark,
        ]}
      >
        <OrderStatusSelectors
          statusOwner={order.statusOwner}
          statusUser={order.statusUser}
          referenceNumber={order.referenceNumber}
          referenceToken={order.referenceToken}
        />
      </View>

      <ScrollView contentContainerStyle={styles.currentBookingScrollView}>
        <OrderDetails order={order} />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  statusSelectors: {
    alignItems: 'flex-end',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  statusSelectorsDark: {
    borderBottomColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  currentBookingScrollView: {
    padding: 20,
  },
});

export default BusinessOrderDetailsScreen;
