import React from 'react';
import { useIsWideScreen } from '../../hooks/responsive';
import { View, ViewProps, StyleSheet } from 'react-native';

export type FeaturesProps = ViewProps & {
  children: any;
  noMargins?: boolean;
  style?: ViewProps['style'];
};

const Features = (props: FeaturesProps) => {
  const isWideScreen = useIsWideScreen();
  const { children, style, ...otherProps } = props;

  return (
    <View
      style={[
        styles.container,
        props.noMargins && styles.containerNoMargins,
        isWideScreen && styles.containerWide,
        style,
      ]}
      {...otherProps}
    >
      {React.Children.map(children, (child, index) => {
        if (index < React.Children.count(children) - 1) {
          return React.cloneElement(child, {
            ...child.props,
            style: [styles.feature, isWideScreen && styles.featureWide],
          });
        }

        return child;
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 70,
  },
  containerNoMargins: {
    marginTop: 0,
    marginBottom: 0,
  },
  containerWide: {
    flexDirection: 'row',
  },
  feature: {
    marginBottom: 20,
  },
  featureWide: {
    marginBottom: 0,
    marginRight: 20,
  },
});

export default Features;
