import React, { useState, useCallback } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { useRecoilValue } from 'recoil';
import TextInput from '../components/TextInput/TextInput';
import Button from '../components/Button/Button';
import API from '../helpers/api';
import { businessIdState } from '../store/state';
import DeleteButton from '../components/DeleteButton/DeleteButton';
import QueryCache from '../helpers/query-cache';
import Switch from '../components/Switch/Switch';
import { t } from '../helpers/translation';
import { useIsWideScreen } from '../hooks/responsive';

type RoomTableFormProps = {
  type: 'create' | 'update';
  roomId: string;
  id?: string;
  name?: string;
  seats?: number;
  minimumSeats?: number;
  available?: boolean;
  style?: ViewProps['style'];
};

const RoomTableForm = (props: RoomTableFormProps) => {
  const [name, setName] = useState(props.name ?? '');
  const [seats, setSeats] = useState(props.seats ?? 1);
  const [minimumSeats, setMinimumSeats] = useState(props.minimumSeats);
  const [available, setAvailable] = useState(props.available ?? true);
  const [isBusy, setIsBusy] = useState(false);
  const businessId = useRecoilValue(businessIdState);
  const isWideScreen = useIsWideScreen();

  const canSend =
    props.type === 'create'
      ? name.length > 0 && seats
      : name !== props.name ||
        seats !== props.seats ||
        minimumSeats !== props.minimumSeats ||
        available !== props.available;

  const handleSend = useCallback(async () => {
    setIsBusy(true);

    try {
      if (props.type === 'create') {
        const table = {
          name,
          seats,
          minimumSeats,
          available,
        };

        const tableId = await API.createTableForRoom(
          businessId,
          props.roomId,
          table
        );

        QueryCache.createRoomTable(businessId, props.roomId, {
          id: tableId,
          ...table,
        });

        setName('');
        setSeats(1);
        setMinimumSeats(undefined);
        setAvailable(true);
      } else {
        const table = {
          name,
          seats,
          minimumSeats,
          available,
        };

        await API.updateTableById(businessId, props.roomId, props.id!, table);
        QueryCache.updateRoomTable(businessId, props.roomId, props.id!, table);
      }
    } catch (error) {
      console.warn(error);
    }

    setIsBusy(false);
  }, [
    businessId,
    props.type,
    props.roomId,
    props.id,
    name,
    seats,
    minimumSeats,
    available,
  ]);

  const handleDelete = useCallback(async () => {
    setIsBusy(true);

    try {
      await API.deleteTableById(businessId, props.roomId, props.id!);
      QueryCache.deleteRoomTable(businessId, props.roomId, props.id!);
    } catch (error) {
      console.warn(error);
    }

    setIsBusy(false);
  }, [businessId, props.roomId, props.id]);

  return (
    <View
      style={[
        isWideScreen && { flexDirection: 'row', alignItems: 'center' },
        props.style,
      ]}
    >
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <TextInput
          inline
          editable
          required
          type="text"
          value={name}
          onChangeText={setName}
          label={t('forms.roomTable.inputs.name.label')}
          style={{ marginRight: 10, flex: 1 }}
        />

        <TextInput
          inline
          editable
          required
          type="number"
          value={String(seats)}
          onChangeText={(seats) => setSeats(parseInt(seats, 10))}
          label={t('forms.roomTable.inputs.seats.label')}
          style={{ flex: 1 }}
        />
      </View>

      {props.type === 'update' && (
        <Switch
          value={available}
          onValueChange={setAvailable}
          required
          label={t('forms.roomTable.inputs.available.label')}
          style={[
            { marginTop: 10 },
            isWideScreen && { marginTop: 0, marginLeft: 10 },
          ]}
        />
      )}

      <View
        style={[
          { flexDirection: 'row', alignItems: 'center', marginTop: 10 },
          isWideScreen && { marginLeft: 10 },
          isWideScreen && props.type === 'create' && { alignSelf: 'flex-end' },
        ]}
      >
        <Button
          size="small"
          inline
          title={props.type === 'create' ? t('common.add') : t('common.update')}
          onPress={handleSend}
          icon={props.type === 'create' ? 'plus' : 'edit-3'}
          busy={isBusy}
          disabled={!canSend}
          style={{ flex: 1 }}
        />

        {props.type === 'update' && (
          <DeleteButton onDelete={handleDelete} style={{ marginLeft: 10 }} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({});

export default RoomTableForm;
