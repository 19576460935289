import React, { useState, useCallback } from 'react';
import Picker from '../Picker/Picker';

export type CurrencySelectorProps = {
  value: string;
  onChangeValue: (value: string) => void;
};

const CurrencySelector = (props: CurrencySelectorProps) => {
  const [currencyCodes] = useState(() => {
    return require('currency-codes/data');
  });

  const handleChange = useCallback(
    (value: string) => {
      props.onChangeValue(value);
    },
    [props.onChangeValue]
  );

  return (
    <Picker selectedValue={props.value} onValueChange={handleChange}>
      {currencyCodes.map((currency: { code: string; currency: string }) => (
        <Picker.Item
          key={currency.code}
          value={currency.code}
          label={`${currency.code} - ${currency.currency}`}
        />
      ))}
    </Picker>
  );
};

export default CurrencySelector;
