import React from 'react';
import { ViewProps } from 'react-native';
import InlineSelect from '../InlineSelect/InlineSelect';
import { t } from '../../helpers/translation';

type Props = {
  value: string;
  onChangeValue: (value: string) => void;
  style?: ViewProps['style'];
};

const PriceTypeSelector = (props: Props) => {
  return (
    <InlineSelect
      value={props.value}
      onChangeValue={props.onChangeValue}
      options={[
        {
          label: t('components.priceTypeSelector.options.value'),
          value: 'value',
        },
        {
          label: t('components.priceTypeSelector.options.range'),
          value: 'range',
        },
        { label: t('components.priceTypeSelector.options.up'), value: 'up' },
        {
          label: t('components.priceTypeSelector.options.other'),
          value: 'other',
        },
      ]}
      style={props.style}
    />
  );
};

export default PriceTypeSelector;
