import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import * as Localization from 'expo-localization';
import TextInput from '../components/TextInput/TextInput';
import { t } from '../helpers/translation';
import PhoneNumberInput from '../components/PhoneNumberInput/PhoneNumberInput';
import Button from '../components/Button/Button';
import Stepper from '../components/Stepper/Stepper';
import Label from '../components/Label/Label';
import { businessIdState } from '../store/state';
import API from '../helpers/api';
import Hint from '../components/Hint/Hint';
import ActivityIndicator from '../components/ActivityIndicator/ActivityIndicator';
import P from '../components/P/P';
import Pill from '../components/Pill/Pill';
import H2 from '../components/H2/H2';
import { minutesToPercentage } from '../helpers/format';
import Theme from '../helpers/theme';

type CreateWalkInBookingFormProps = {
  sending: boolean;
  onSubmit: (values: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumberCountryCode?: string;
    phoneNumber?: string;
    comment?: string;
    date?: number;
    timeSlot?: number;
    quantity: number;
    tableId: string;
  }) => void;
};

const CreateWalkInBookingForm = (props: CreateWalkInBookingFormProps) => {
  const [isAddingInformation, setIsAddingInformation] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState(
    Localization.region || 'US'
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [comment, setComment] = useState('');
  const [guests, setGuests] = useState(1);
  const [tableId, setTableId] = useState<string>();
  const businessId = useRecoilValue(businessIdState);
  const { data: tables, status: tablesStatus } = useQuery(
    ['available-tables-now', businessId, guests],
    () => API.fetchAvailableTablesNow(businessId, guests),
    { cacheTime: 60000 }
  );

  return (
    <>
      <H2 style={{ marginBottom: 10 }}>
        {t('screens.liveDashboard.actions.addWalkIn')}
      </H2>

      <Label required>{t('forms.createWalkIn.inputs.guests.label')}</Label>

      <Stepper
        value={guests}
        onChangeValue={setGuests}
        minValue={1}
        style={styles.input}
      />

      <Label required>{t('forms.createWalkIn.inputs.pickTable.label')}</Label>

      <Hint style={styles.input}>
        {t('forms.createWalkIn.inputs.pickTable.hint')}
      </Hint>

      <View style={styles.input}>
        {tablesStatus === 'loading' && <ActivityIndicator />}

        {tables && (
          <>
            {tables.length === 0 && (
              <P>{t('forms.createWalkIn.paragraphs.noTables')}</P>
            )}

            {tables.length > 0 && (
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {tables
                  .sort(
                    ([_tableA, isARecommended], [_tableB, isBRecommended]) =>
                      isBRecommended - isARecommended
                  )
                  .map(([table, isRecommended]) => (
                    <Pill
                      key={table.id}
                      type={tableId === table.id ? 'primary' : undefined}
                      icon={isRecommended ? 'check-circle' : 'x'}
                      iconColor={!isRecommended ? Theme.RED : undefined}
                      title={t('forms.createWalkIn.buttons.tableSeats', {
                        name: table.name,
                        seats: table.seats,
                      })}
                      onPress={() => setTableId(table.id)}
                      inline
                      style={{
                        marginRight: 10,
                        marginBottom: 10,
                      }}
                    />
                  ))}
              </View>
            )}
          </>
        )}
      </View>

      {!isAddingInformation && (
        <Button
          size="small"
          inline
          icon="plus"
          title={t('forms.createWalkIn.actions.addCustomerInformation')}
          onPress={() => setIsAddingInformation(true)}
          style={styles.input}
        />
      )}

      {isAddingInformation && (
        <>
          <TextInput
            type="text"
            editable
            value={firstName}
            onChangeText={setFirstName}
            label={t('common.firstName')}
            style={styles.input}
          />

          <TextInput
            type="text"
            editable
            value={lastName}
            onChangeText={setLastName}
            label={t('common.lastName')}
            style={styles.input}
          />

          <TextInput
            type="email"
            editable
            value={email}
            onChangeText={setEmail}
            label={t('common.email')}
            placeholder="email@mail.com"
            style={styles.input}
          />

          <PhoneNumberInput
            label={t('screens.join.inputs.phoneNumber.label')}
            placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
            phoneNumber={phoneNumber}
            onChangePhoneNumber={setPhoneNumber}
            countryCode={phoneNumberCountryCode}
            onChangeCountryCode={setPhoneNumberCountryCode}
            style={styles.input}
          />

          <TextInput
            editable
            multiline
            numberOfLines={3}
            value={comment}
            onChangeText={setComment}
            label={t('forms.createBooking.inputs.comment.placeholder')}
            style={styles.input}
          />
        </>
      )}

      <Button
        title={t('forms.createBooking.buttons.book')}
        busy={props.sending}
        disabled={!guests || !tableId}
        onPress={() => {
          if (!tableId) {
            return;
          }

          const date = new Date();

          props.onSubmit({
            firstName,
            lastName,
            email,
            phoneNumberCountryCode,
            phoneNumber,
            comment,
            tableId,
            quantity: guests,
            date: date.getTime(),
            timeSlot: date.getHours() + minutesToPercentage(date.getMinutes()),
          });

          setFirstName('');
          setLastName('');
          setEmail('');
          setPhoneNumber('');
          setComment('');
          setTableId(undefined);
          setGuests(1);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
  },
});

export default CreateWalkInBookingForm;
