import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

type TableProps = {
  data: any[][];
  alternateBackgrounds?: boolean;
  hasLeadingColumn?: boolean;
  cellPadding?: number;
  style?: ViewProps['style'];
};

const Table = (props: TableProps) => {
  return (
    <View style={[styles.container, props.style]}>
      {props.data.map((row, index) => (
        <View
          key={Math.random()}
          style={[
            styles.row,
            props.alternateBackgrounds &&
              index % 2 !== 0 &&
              styles.rowAlternative,
            !!props.cellPadding && {
              paddingHorizontal: props.cellPadding,
            },
          ]}
        >
          {row.map((cell, index) => (
            <View
              key={Math.random()}
              style={[
                styles.cell,
                index === 0 && styles.firstCell,
                props.hasLeadingColumn &&
                  index === 0 &&
                  styles.firstLeadingCell,
              ]}
            >
              {cell}
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  rowAlternative: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  cell: {
    flex: 1,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  firstCell: {
    justifyContent: 'flex-start',
  },
  firstLeadingCell: {
    flex: 2,
  },
});

export default Table;
