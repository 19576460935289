import { useEffect, useState } from 'react';
import {
  useInfiniteQuery,
  InfiniteQueryKey,
  InfiniteQueryFunction,
} from 'react-query';
import API from '../helpers/api';
import { Booking, Order } from '../../types';

type LiveQueryOptions<T> = {
  enabled?: boolean;
  interval?: number;
  elementsKey: string;
  sortByKey?: string;
  mapElement?: (value: T, index: number, array: T[]) => T;
};

export function useLiveQuery<T>(
  queryKey: InfiniteQueryKey<string>,
  queryFn: InfiniteQueryFunction<T[], [string], number>,
  options: LiveQueryOptions<T> = {
    elementsKey: 'data',
    interval: 10000,
    mapElement: (element) => element,
  }
) {
  const [flattenData, setFlattenData] = useState<T[]>([]);
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  const {
    data,
    status,
    fetchMore,
    // @ts-ignore
  } = useInfiniteQuery(queryKey, queryFn, {
    ...options,
    getFetchMore: (lastGroup) => (lastGroup as any).nextCursor,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMore();
      setIsFirstRequest(false);
    }, options.interval);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (data) {
      const flatten = data.reduce(
        // @ts-ignore
        (acc: T[], group: { [key: string]: T[] }) => [
          ...acc,
          ...group[options.elementsKey].map(options.mapElement!),
        ],
        []
      );

      if (options.sortByKey) {
        setFlattenData(
          // @ts-ignore
          flatten.sort((a, b) => b[options.sortByKey] - a[options.sortByKey])
        );
      } else {
        // @ts-ignore
        setFlattenData(flatten);
      }
    } else {
      setFlattenData([]);
    }
  }, [data]);

  return { data: flattenData, status, isFirstRequest };
}

export const useLiveBookings = (
  businessId: string,
  options?: LiveQueryOptions<Booking>
) => {
  return useLiveQuery(
    ['bookings', businessId],
    (_key: string, businessId: string, lastQueryAt: number) => {
      const options: { from?: number } = {};
      if (lastQueryAt) {
        options.from = lastQueryAt;
      }

      return API.fetchBookings(businessId, options);
    },
    {
      elementsKey: 'bookings',
      sortByKey: 'date',
      mapElement: (element) => ({
        ...element,
        type: 'booking',
        date: new Date(element.startAt),
      }),
      interval: 15000,
      ...options,
    }
  );
};

export const useLiveOrders = (
  businessId: string,
  options?: LiveQueryOptions<Order>
) => {
  return useLiveQuery(
    ['orders', businessId],
    (_key: string, businessId: string, lastQueryAt: number) => {
      const options: { from?: number } = {};
      if (lastQueryAt) {
        options.from = lastQueryAt;
      }

      return API.fetchOrders(businessId, options);
    },
    {
      elementsKey: 'orders',
      sortByKey: 'date',
      mapElement: (element) => ({
        ...element,
        type: 'order',
        date: new Date(element.createdAt!),
      }),
      interval: 15000,
      ...options,
    }
  );
};
