import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useSetRecoilState } from 'recoil';
import {
  isLoggedInState,
  businessIdState,
  businessNameState,
} from '../store/state';
import API from '../helpers/api';
import Button from '../components/Button/Button';
import TextInput from '../components/TextInput/TextInput';
import { useDocumentTitle } from '../hooks/document';
import Block from '../components/Block/Block';
import Link from '../components/Link/Link';
import Text from '../components/Text/Text';
import { AuthStackParamList } from '../navigators/AuthNavigator';
import { t } from '../helpers/translation';
import Wrapper from '../components/Wrapper/Wrapper';

type Props = {
  navigation: StackNavigationProp<AuthStackParamList, 'SignIn'>;
};

const SignInScreen = (props: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  const setBusinessId = useSetRecoilState(businessIdState);
  const setBusinessName = useSetRecoilState(businessNameState);

  useDocumentTitle(t('navigation.signIn'));

  const handleSignIn = async () => {
    if (!email || !password) {
      return;
    }

    setHasError(false);
    setIsBusy(true);

    try {
      await API.signIn(email, password);

      const profile = await API.fetchUserProfile();
      if (profile.businesses.length !== 0) {
        const business = profile.businesses[0];
        setBusinessId(business.id);
        setBusinessName(business.name);
      }

      setIsBusy(false);
      setIsLoggedIn(true);
    } catch (error) {
      setHasError(true);
      setIsBusy(false);
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Wrapper noPadding>
        <Block>
          {hasError && (
            <Text style={styles.error}>{t('errors.basicMessage')}</Text>
          )}

          <TextInput
            editable
            autoFocus
            type="email"
            placeholder="email@mail.com"
            label={t('common.email')}
            value={email}
            onChangeText={setEmail}
            style={styles.input}
          />

          <TextInput
            editable
            type="password"
            label={t('common.password')}
            placeholder="******"
            value={password}
            onChangeText={setPassword}
            style={styles.input}
          />

          <Button
            title={t('navigation.signIn')}
            onPress={handleSignIn}
            busy={isBusy}
          />

          <View style={styles.joinUsContainer}>
            <Text>
              {t('screens.signIn.banners.createYourPricing')}{' '}
              <Link to="/join">{t('screens.signIn.banners.joinUs')}</Link>
            </Text>
          </View>
        </Block>
      </Wrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  error: {
    marginBottom: 10,
  },
  input: {
    marginBottom: 20,
  },
  joinUsContainer: {
    marginTop: 20,
    flexDirection: 'row',
  },
});

export default SignInScreen;
