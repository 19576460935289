import React from 'react';
import { View, ViewProps, StyleSheet } from 'react-native';
import { queryCache } from 'react-query';
import { useRecoilValue } from 'recoil';
import StatusSelector from '../StatusSelector/StatusSelector';
import API from '../../helpers/api';
import {
  OrderStatus,
  Order,
  OrderStatusOwner,
  OrderStatusUser,
} from '../../../types';
import { t } from '../../helpers/translation';
import { businessIdState } from '../../store/state';
import Icon from '../Icon/Icon';

type OrderStatusSelectorsProps = {
  referenceNumber: string;
  referenceToken: string;
  statusOwner: OrderStatusOwner;
  statusUser: OrderStatusUser;
  style?: ViewProps['style'];
};

const OrderStatusSelectors = (props: OrderStatusSelectorsProps) => {
  const businessId = useRecoilValue(businessIdState);

  return (
    <View style={[styles.container, props.style]}>
      <Icon name="check" size={16} style={styles.icon} />

      <StatusSelector
        status={props.statusOwner}
        onStatusChange={(status) => {
          (async () => {
            try {
              await API.updateOrderStatus(
                businessId,
                props.referenceNumber,
                props.referenceToken,
                'owner',
                status as OrderStatus
              );

              queryCache.setQueryData(
                ['orders', businessId],
                (oldOrders: any) =>
                  oldOrders.map((oldOrdersGroup: Order[]) =>
                    oldOrdersGroup.map((oldOrder) => {
                      if (oldOrder.referenceNumber !== props.referenceNumber) {
                        return oldOrder;
                      }

                      return {
                        ...oldOrder,
                        statusOwner: status,
                      };
                    })
                  )
              );
            } catch (error) {
              console.warn(error);
            }
          })();
        }}
        options={[
          [t('status.hold'), 'hold'],
          [t('status.waiting'), 'waiting'],
          [t('status.doing'), 'doing'],
          [t('status.done'), 'done'],
          [t('status.cancelled'), 'cancelled'],
        ]}
        style={styles.selector}
      />

      <Icon name="dollar-sign" size={16} style={styles.icon} />

      <StatusSelector
        status={props.statusUser}
        onStatusChange={(status) => {
          (async () => {
            try {
              await API.updateOrderStatus(
                businessId,
                props.referenceNumber,
                props.referenceToken,
                'user',
                status as OrderStatus
              );

              queryCache.setQueryData(
                ['orders', businessId],
                (oldOrders: any) =>
                  oldOrders.map((oldOrdersGroup: Order[]) =>
                    oldOrdersGroup.map((oldOrder) => {
                      if (oldOrder.referenceNumber !== props.referenceNumber) {
                        return oldOrder;
                      }

                      return {
                        ...oldOrder,
                        statusUser: status,
                      };
                    })
                  )
              );
            } catch (error) {
              console.warn(error);
            }
          })();
        }}
        options={[
          [t('status.unpaid'), 'unpaid'],
          [t('status.paid'), 'paid'],
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
  },
  selector: {
    marginRight: 10,
  },
});

export default OrderStatusSelectors;
