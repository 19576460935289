import React from 'react';
import {
  ActivityIndicator as RNActivityIndicator,
  ActivityIndicatorProps as RNActivityIndicatorProps,
  StyleSheet,
} from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import { DefaultTheme } from '@react-navigation/native';

export type ActivityIndicatorProps = RNActivityIndicatorProps;

const ActivityIndicator = (props: ActivityIndicatorProps) => {
  const scheme = useColorScheme();

  return (
    <RNActivityIndicator
      color={scheme === 'dark' ? 'white' : DefaultTheme.colors.primary}
      {...props}
      style={[styles.container, props.style]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
});

export default ActivityIndicator;
