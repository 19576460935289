import React from 'react';
import {
  Switch as RNSwitch,
  SwitchProps as RNSwitchProps,
  View,
} from 'react-native';
// @ts-ignore
import color from 'color';
import Theme from '../../helpers/theme';
import Label from '../Label/Label';
import Button from '../Button/Button';

export type SwitchProps = RNSwitchProps & {
  titleOn?: string;
  titleOff?: string;
  color?: string;
  required?: boolean;
  label?: string;
};

const Switch = ({
  color: switchColor,
  required,
  label,
  style,
  titleOn,
  titleOff,
  ...extraProps
}: SwitchProps) => {
  const activeColor = switchColor ?? Theme.PRIMARY;

  if (titleOn && titleOff) {
    return (
      <View style={style}>
        <Button
          inline
          size="small"
          icon={extraProps.value ? 'check' : undefined}
          type={extraProps.value ? 'success' : 'default'}
          title={extraProps.value ? titleOn : titleOff}
          onPress={() => extraProps.onValueChange!(!extraProps.value)}
        />
      </View>
    );
  }

  return (
    <View style={style}>
      {label && <Label required={required}>{label}</Label>}

      <RNSwitch
        // @ts-ignore
        // Web-only
        activeThumbColor={activeColor}
        activeTrackColor={color(activeColor).lighten(0.5)}
        {...extraProps}
      />
    </View>
  );
};

export default Switch;
