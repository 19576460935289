import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useFocusEffect } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { AppStackParamList } from '../navigators/AppNavigator';
import { isLoggedInState } from '../store/state';
import API from '../helpers/api';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';

type Props = {
  navigation: DrawerNavigationProp<AppStackParamList, 'SignOut'>;
};

const SignOutScreen = (props: Props) => {
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);

  useFocusEffect(() => {
    (async () => {
      try {
        await API.signOut();
      } catch (error) {
        console.error(error);
      }

      setIsLoggedIn(false);
    })();
  });

  return <LoaderContainer />;
};

export default SignOutScreen;
