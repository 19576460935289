import React, { useEffect, useRef } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { RouteProp, Link as RNLink } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useQuery } from 'react-query';
import API from '../helpers/api';
import Text from '../components/Text/Text';
import { useDocumentTitle } from '../hooks/document';
import { PricingStackParamList } from '../navigators/PricingNavigator';
import BusinessHeaderTitle from '../components/BusinessHeaderTitle/BusinessHeaderTitle';
import Category from '../components/Pricing/Category/Category';
import CategoryPane from '../components/Pricing/CategoryPane/CategoryPane';
import { t } from '../helpers/translation';
import { PricingItem } from '../../types';
import { useSetRecoilState, useResetRecoilState, useRecoilState } from 'recoil';
import {
  pricingItemsState,
  RawPricingItems,
  cartState,
  pricingPaymentMethodState,
  pricingCanOrderState,
  pricingBusinessIdState,
  pricingCanBookState,
  pricingBusinessNameState,
} from '../store/state';
import Cart from '../components/Cart/Cart';
import Link from '../components/Link/Link';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import Button from '../components/Button/Button';
import Wrapper from '../components/Wrapper/Wrapper';
import MakeBooking from '../components/MakeBooking/MakeBooking';

type Props = {
  navigation: StackNavigationProp<PricingStackParamList, 'Pricing'>;
  route: RouteProp<PricingStackParamList, 'Pricing'>;
};

type Category = {
  id: string;
  name: string;
  description?: string;
  items: PricingItem[];
};

const PricingScreen = (props: Props) => {
  const slug = props.route.params.pricingSlug;
  const scrollViewRef = useRef<ScrollView>();
  const setPricingItems = useSetRecoilState(pricingItemsState);
  const setPricingPaymentMethod = useSetRecoilState(pricingPaymentMethodState);
  const [pricingCanOrder, setPricingCanOrder] = useRecoilState(
    pricingCanOrderState
  );
  const [pricingCanBook, setPricingCanBook] = useRecoilState(
    pricingCanBookState
  );
  const setPricingBusinessId = useSetRecoilState(pricingBusinessIdState);
  const setPricingBusinessName = useSetRecoilState(pricingBusinessNameState);
  const resetCart = useResetRecoilState(cartState);

  const { data: pricing, status: pricingStatus } = useQuery(
    ['pricing', slug],
    () => API.fetchPricingBySlug(slug),
    { enabled: typeof slug === 'string' && slug.length > 0 }
  );

  useDocumentTitle(pricing?.business.name || t('navigation.pricing'));

  useEffect(() => {
    if (pricing && pricingStatus === 'success') {
      props.navigation.setOptions({
        headerTitle: () => (
          <BusinessHeaderTitle
            name={pricing.business.name}
            image={pricing.business.image}
          />
        ),
        headerRight: () =>
          pricing.business.address ? (
            <RNLink
              to={generateMapsURL()}
              target="_blank"
              style={styles.mapIcon}
            >
              <Button
                inline
                size="small"
                icon="map-pin"
                title={t('screens.pricing.actions.getDirections')}
              />
            </RNLink>
          ) : null,
      });

      setPricingItems(
        (pricing.card.categories as Category[])
          .map((category) => category.items)
          .reduce<RawPricingItems>((acc, items) => {
            const allItems = { ...acc };

            for (const item of items) {
              allItems[item.id!] = item;
            }

            return allItems;
          }, {})
      );

      setPricingBusinessId(pricing.business.id);
      setPricingBusinessName(pricing.business.name);
      setPricingCanOrder(pricing.card.canOrder);
      setPricingCanBook(pricing.card.canBook);

      if (pricing.card.payment_method) {
        setPricingPaymentMethod({
          type: pricing.card.payment_method.type,
          clientId: pricing.card.payment_method.client_id,
        });
      }

      resetCart();
    }
  }, [pricingStatus]);

  const generateMapsURL = () => {
    if (!pricing?.business.address) {
      return '';
    }

    const query = `${pricing?.business.name} ${pricing?.business.address}`;
    const mapURL = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      query
    )}`;

    return mapURL;
  };

  if (!slug) {
    return (
      <View style={styles.fullScreen}>
        <Text>{t('screens.pricing.banners.noSlug')}</Text>
      </View>
    );
  }

  if (pricingStatus === 'loading') {
    return <LoaderContainer />;
  }

  if (pricingStatus === 'error' || !pricing) {
    return (
      <View style={styles.fullScreen}>
        <Text>There was an error. Try again or get in touch with us.</Text>
      </View>
    );
  }

  return (
    <>
      <ScrollView
        // @ts-ignore
        ref={(s) => (scrollViewRef.current = s)}
        contentContainerStyle={styles.container}
      >
        <Wrapper noPadding>
          {pricingCanBook && <MakeBooking />}

          {/* {pricing.card.categories.length !== 0 && (
        <ScrollView horizontal contentContainerStyle={styles.categoryBar}>
          {pricing.card.categories.map((category: Category) => (
            <CategoryBarItem
              key={category.id}
              id={category.id}
              name={category.name}
              onPress={(scrollTo: number) => {
                console.log(scrollTo, scrollViewRef.current);

                // @ts-ignore
                scrollViewRef.current.scrollTo({
                  y: scrollTo - 20,
                });
              }}
            />
          ))}
        </ScrollView>
      )} */}

          {pricing.card.categories.map((category: Category) => (
            <CategoryPane
              key={category.id}
              category={category}
              onPressItem={(item: PricingItem) => {
                props.navigation.navigate('Item', {
                  pricingSlug: slug,
                  itemId: item.id!,
                  item,
                });
              }}
            />
          ))}

          <Link to="https://illicarte.com" target="_blank">
            {t('screens.pricing.links.createdWith')}
          </Link>
        </Wrapper>
      </ScrollView>

      {pricingCanOrder && <Cart businessId={pricing.business.id} />}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 70,
  },
  name: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  mapIcon: {
    marginRight: 20,
  },
  fullScreen: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default PricingScreen;
