import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  ViewProps,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
// @ts-ignore
import countryCodes from 'country-codes-list';
import Text from '../Text/Text';
import { CountryData } from '../../../types';

type Props = {
  countryCode: string;
  onPress: (event: GestureResponderEvent) => void;
  style?: ViewProps['style'];
};

const CountryIcon = (props: Props) => {
  const [countries] = useState<CountryData[]>(countryCodes.all());
  const [country, setCountry] = useState<CountryData>();

  useEffect(() => {
    const currentCountry = countries.find(
      (country) => country.countryCode === props.countryCode
    );

    if (currentCountry) {
      setCountry(currentCountry);
    } else {
      setCountry(countries.find((country) => country.countryCode === 'US'));
    }
  }, [props.countryCode]);

  if (!country) {
    return null;
  }

  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[styles.container, props.style]}
    >
      <Text style={styles.flag}>{country.flag}</Text>
      <Text>+{country.countryCallingCode}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  flag: {
    marginRight: 5,
  },
});

export default React.memo(CountryIcon);
