import React, { useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppStackParamList } from '../navigators/AppNavigator';
import { useDocumentTitle } from '../hooks/document';
import Wrapper from '../components/Wrapper/Wrapper';
import Hero from '../components/Hero/Hero';
import { t } from '../helpers/translation';
import Features from '../components/Features/Features';
import Feature from '../components/Feature/Feature';
import Theme from '../helpers/theme';
import BrandBar from '../components/BrandBar/BrandBar';
import Step from '../components/Step/Step';
import H1 from '../components/H1/H1';
import JoinBanner from '../components/JoinBanner/JoinBanner';
import Carousel from '../components/Carousel/Carousel';
import BulletFeatures from '../components/BulletFeatures/BulletFeatures';
import FeaturesJob from '../components/FeaturesJob/FeaturesJob';
import { RouteProp } from '@react-navigation/native';
import JobFeatures, { JobType } from '../constants/job-features';
import FeaturesTable from '../components/FeaturesTable/FeaturesTable';
import YoutubePlayer from '../components/YoutubePlayer/YoutubePlayer';
import Modal from '../components/Modal/Modal';

type Props = {
  navigation: StackNavigationProp<AppStackParamList, 'ProductOrders'>;
  route: RouteProp<AppStackParamList, 'ProductOrders'>;
};

const ProductOrdersScreen = (props: Props) => {
  const [watchVideo, setWatchVideo] = useState(false);

  useDocumentTitle(t('screens.home.products.orders.title'));

  const job: JobType =
    props.route.params && props.route.params.job
      ? props.route.params.job
      : 'all';

  return (
    <>
      <Modal visible={watchVideo} onClose={() => setWatchVideo(false)}>
        <YoutubePlayer videoKey="-lvr1W80pZ8" />
      </Modal>

      <ScrollView contentContainerStyle={styles.container}>
        <BrandBar />

        <Wrapper>
          <Hero
            image={require('../../assets/order_confirmed.svg')}
            title={t('screens.home.products.orders.title')}
            subtitle={t('screens.home.products.orders.subtitle')}
            action={t('screens.products.actions.watchVideo')}
            actionIcon="play-circle"
            onPressAction={(event) => {
              event.preventDefault();
              setWatchVideo(true);
            }}
          />

          <Features>
            <Feature
              title={t('screens.productOrders.features.notifications.title')}
              subtitle={t(
                'screens.productOrders.features.notifications.subtitle'
              )}
              image={require('../../assets/notified.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />

            <Feature
              title={t('screens.productOrders.features.payments.title')}
              subtitle={t('screens.productOrders.features.payments.subtitle')}
              image={require('../../assets/police.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />
          </Features>

          <Carousel
            color={Theme.ORANGE}
            items={[
              {
                image: require('../../assets/orders-shot-1.png'),
                title: t('screens.productOrders.steps.yourWebsite'),
              },
              {
                image: require('../../assets/orders-shot-2.png'),
                title: t('screens.productOrders.steps.clientsBuy'),
              },
              {
                image: require('../../assets/orders-shot-3.png'),
                title: t('screens.productOrders.steps.clientsPutOrder'),
              },
            ]}
            style={styles.carousel}
          />

          <BulletFeatures
            color={Theme.ORANGE}
            features={[
              {
                icon: 'users',
                title: t('screens.productOrders.features.middlemen.title'),
                text: t('screens.productOrders.features.middlemen.subtitle'),
              },
              {
                icon: 'shield',
                title: t('screens.productOrders.features.safety.title'),
                text: t('screens.productOrders.features.safety.subtitle'),
              },
              {
                icon: 'bell',
                title: t(
                  'screens.productOrders.features.ordersNotifications.title'
                ),
                text: t(
                  'screens.productOrders.features.ordersNotifications.subtitle'
                ),
              },
              {
                icon: 'smile',
                title: t('screens.productOrders.features.complexity.title'),
                text: t('screens.productOrders.features.complexity.subtitle'),
              },
            ]}
            style={styles.bulletFeatures}
          />

          {/* <FeaturesJob
          job={job}
          image={require(`../../assets/job-${job}.jpg`)}
          features={JobFeatures.orders[job]}
          style={{
            marginBottom: 70,
          }}
        /> */}

          <H1>{t('screens.productOrders.headers.faq')}</H1>

          <Step
            number={'Q'}
            title={t('screens.productOrders.faq.anotherService.question')}
            indications={t('screens.productOrders.faq.anotherService.answer')}
            numberColor={Theme.ORANGE}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t('screens.productOrders.faq.setUpOrders.question')}
            indications={t('screens.productOrders.faq.setUpOrders.answer')}
            numberColor={Theme.ORANGE}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t('screens.productOrders.faq.getMoney.question')}
            indications={t('screens.productOrders.faq.getMoney.answer')}
            numberColor={Theme.ORANGE}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t('screens.productOrders.faq.trust.question')}
            indications={t('screens.productOrders.faq.trust.answer')}
            numberColor={Theme.ORANGE}
            style={styles.step}
          />

          <H1 style={styles.whatYouGet}>
            {t('screens.products.headers.whatYouGet')}
          </H1>

          <FeaturesTable
            color={Theme.ORANGE}
            features={[
              {
                icon: 'layout',
                title: t('screens.products.included.customBranding'),
              },
              {
                icon: 'at-sign',
                title: t('screens.productOrders.included.shopSpace'),
              },
              {
                icon: 'mail',
                title: t('screens.productOrders.included.orderConfirmation'),
              },
              {
                icon: 'lock',
                title: t('screens.products.included.securePayments'),
              },
              {
                icon: 'help-circle',
                title: t('screens.products.included.onboardingSupport'),
              },
              {
                icon: 'instagram',
                title: t('screens.products.included.socialMediaMaterials'),
              },
              {
                icon: 'list',
                title: t('screens.products.included.mobileAppAccess'),
              },
              {
                icon: 'link-2',
                title: t('screens.products.included.customDomainName'),
                details: t('screens.products.included.onDemand'),
              },
              {
                icon: 'smartphone',
                title: t('screens.productOrders.included.customMobileApp'),
                details: t('screens.products.included.onDemand'),
              },
            ]}
            style={styles.featuresTable}
          />

          <JoinBanner backgroundColor={Theme.ORANGE} style={styles.join} />

          <H1>{t('screens.products.headers.youMightAlsoNeed')}</H1>

          <Features>
            <Feature
              href="/products/bookings"
              title={t('screens.home.products.bookings.title')}
              subtitle={t('screens.home.products.bookings.subtitle')}
              image={require('../../assets/restaurant.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />

            <Feature
              href="/products/memberships"
              title={t('screens.home.products.memberships.title')}
              subtitle={t('screens.home.products.memberships.subtitle')}
              image={require('../../assets/credit_card.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />
          </Features>
        </Wrapper>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  carousel: {
    marginBottom: 70,
  },
  bulletFeatures: {
    marginBottom: 30,
  },
  step: {
    marginTop: 30,
    marginBottom: 10,
  },
  featuresTable: {
    marginBottom: 70,
  },
  whatYouGet: {
    marginTop: 60,
    marginBottom: 20,
  },
  join: {
    marginBottom: 70,
  },
});

export default ProductOrdersScreen;
