import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppStackParamList } from '../navigators/AppNavigator';
import { useDocumentTitle } from '../hooks/document';
import { t } from '../helpers/translation';
import BrandBar from '../components/BrandBar/BrandBar';
import Wrapper from '../components/Wrapper/Wrapper';
import Hero from '../components/Hero/Hero';
import PricingPlans from '../components/PricingPlans/PricingPlans';
import Icons from '../constants/icons';

type Props = {
  navigation: StackNavigationProp<AppStackParamList, 'ProductPricing'>;
};

const ProductPricingScreen = (props: Props) => {
  useDocumentTitle(t('navigation.productPricing'));

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <BrandBar />

      <Wrapper>
        <Hero
          // TODO
          title="Pricing plans"
        />

        <PricingPlans
          plans={[
            {
              name: 'Independent',
              icon: 'user',
              description: '1 person',
              features: [
                {
                  name: t('screens.home.products.pricing.title'),
                  icon: Icons.PRICING,
                  price: 10,
                },
                {
                  name: t('screens.home.products.orders.title'),
                  icon: Icons.ORDERS,
                  price: 5,
                  isSub: true,
                },
                {
                  name: t('screens.home.products.bookings.title'),
                  icon: Icons.BOOKINGS,
                  price: 5,
                  isSub: true,
                },
                {
                  name: t('screens.home.products.memberships.title'),
                  icon: Icons.LOYALTY,
                  price: 10,
                },
              ],
            },
            {
              name: 'Retail',
              icon: 'users',
              description: '> 1 person',
              features: [
                {
                  name: t('screens.home.products.pricing.title'),
                  icon: Icons.PRICING,
                  price: 20,
                },
                {
                  name: t('screens.home.products.orders.title'),
                  icon: Icons.ORDERS,
                  price: 10,
                  isSub: true,
                },
                {
                  name: t('screens.home.products.bookings.title'),
                  icon: Icons.BOOKINGS,
                  price: 10,
                  isSub: true,
                },
                {
                  name: t('screens.home.products.memberships.title'),
                  icon: Icons.LOYALTY,
                  price: 20,
                },
              ],
            },
          ]}
        />
      </Wrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {},
  rowIcon: {
    marginRight: 10,
  },
  rowTitle: {
    fontWeight: 'bold',
  },
  titleCell: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleCellIcon: {
    marginBottom: 5,
  },
});

export default ProductPricingScreen;
