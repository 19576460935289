import React from 'react';
import {
  Link as RNLink,
  DefaultTheme,
  NavigationAction,
} from '@react-navigation/native';
import { StyleSheet, TextProps } from 'react-native';

export type LinkProps = {
  to: string;
  action?: NavigationAction;
  target?: string;
  underline?: boolean;
} & (TextProps & {
  children: React.ReactNode;
});

const Link = (props: LinkProps) => {
  return (
    // @ts-ignore
    <RNLink
      {...props}
      style={[
        styles.container,
        typeof props.underline === 'boolean' &&
          !props.underline &&
          styles.containerNoUnderline,
        props.style,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    color: DefaultTheme.colors.primary,
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  containerNoUnderline: {
    textDecorationLine: 'none',
  },
});

export default Link;
