import React from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';
import H2 from '../../H2/H2';
import H3 from '../../H3/H3';

type Props = {
  name: string;
  description?: string;
  style?: ViewProps['style'];
};

const Category = (props: Props) => {
  return (
    <View style={[styles.container, props.style]}>
      <H2>{props.name}</H2>

      {props.description && (
        <H3 style={styles.description}>{props.description}</H3>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  description: {
    marginTop: 3,
    fontWeight: 'normal',
  },
});

export default Category;
