import React from 'react';
import { ViewProps, View, StyleSheet } from 'react-native';
import { OrderStatus as OrderStatusType, BookingStatus } from '../../../types';
import Theme from '../../helpers/theme';
import Text from '../Text/Text';
import { t } from '../../helpers/translation';

type StatusProps = {
  status: OrderStatusType | BookingStatus;
  style?: ViewProps['style'];
};

const Status = (props: StatusProps) => {
  let text;
  let color;

  switch (props.status) {
    case 'cancelled':
      text = t('status.cancelled');
      color = Theme.RED;
      break;

    case 'doing':
      text = t('status.doing');
      color = Theme.ORANGE;
      break;

    case 'confirmed':
      text = t('status.confirmed');
      color = Theme.GREEN;
      break;

    case 'done':
      text = t('status.done');
      color = Theme.GREEN;
      break;

    case 'hold':
      text = t('status.hold');
      color = Theme.ORANGE;
      break;

    case 'waiting':
      text = t('status.waiting');
      color = Theme.ORANGE;
      break;

    case 'paid':
      text = t('status.paid');
      color = Theme.GREEN;
      break;

    case 'unpaid':
      text = t('status.unpaid');
      color = Theme.ORANGE;
      break;

    default:
      console.log(props.status);
      return null;
  }

  return (
    <View style={[styles.container, props.style]}>
      <View style={[styles.status, { backgroundColor: color }]} />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  status: {
    marginRight: 5,
    height: 10,
    width: 10,
    borderRadius: 5,
  },
  text: {
    fontWeight: 'bold',
  },
});

export default Status;
