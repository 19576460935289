import React from 'react';
import { ScrollView } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { useRecoilValue } from 'recoil';
import { UserStackParamList } from '../navigators/UserNavigator';
import { t } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import { membershipStagesState } from '../store/state';
import AddProgramStageForm from '../forms/AddProgramStageForm';
import Block from '../components/Block/Block';

type EditRewardScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'EditReward'>;
  route: RouteProp<UserStackParamList, 'EditReward'>;
};

const EditRewardScreen = (props: EditRewardScreenProps) => {
  useDocumentTitle(t('navigation.myLoyaltyProgram'));

  const membershipStages = useRecoilValue(membershipStagesState);

  const stage = membershipStages.find(
    (stage) => stage.id === props.route.params.rewardId
  );

  if (!stage) {
    return null;
  }

  return (
    <ScrollView>
      <Block horizontalMargins verticalMargins>
        <AddProgramStageForm
          id={props.route.params.rewardId}
          name={stage.rewardName}
          description={stage.rewardDescription}
          stampsCount={stage.stampsCount}
          type={stage.rewardType}
          isRecurrent={stage.isRecurrent}
          isActivated={stage.isActivated}
        />
      </Block>
    </ScrollView>
  );
};

export default EditRewardScreen;
