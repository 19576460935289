import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { useQuery } from 'react-query';
import { DefaultTheme } from '@react-navigation/native';
import Block from '../Block/Block';
import API from '../../helpers/api';
import {
  pricingBusinessIdState,
  pricingBusinessNameState,
} from '../../store/state';
import LoaderContainer from '../LoaderContainer/LoaderContainer';
import Pill from '../Pill/Pill';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import P from '../P/P';
import { formatTime, percentageToMinutes } from '../../helpers/format';
import Button from '../Button/Button';
import CreateBookingForm from '../../forms/CreateBookingForm';
import Theme from '../../helpers/theme';
import Text from '../Text/Text';
import { useIsWideScreen } from '../../hooks/responsive';
import Label from '../Label/Label';
import { getFormattedLocale, t } from '../../helpers/translation';
import Banner from '../Banner/Banner';
import H2 from '../H2/H2';

type MakeBookingProps = {};

const MakeBooking = (props: MakeBookingProps) => {
  const [step, setStep] = useState<
    'start' | 'pick-date' | 'contact-details' | 'confirmed'
  >('start');
  const [date, setDate] = useState(new Date());
  const [guests, setGuests] = useState<number>();
  const [timeSlot, setTimeSlot] = useState<number>();
  const [isSending, setIsSending] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const pricingBusinessId = useRecoilValue(pricingBusinessIdState);
  const pricingBusinessName = useRecoilValue(pricingBusinessNameState);
  const isWideScreen = useIsWideScreen();

  const { data: availableBookings, status } = useQuery(
    ['available-bookings', pricingBusinessId, date.getTime()],
    () => API.fetchAvailableBookingsForDate(pricingBusinessId, date),
    {
      enabled: step === 'pick-date',
    }
  );

  const isTimeAvailable = useCallback((date: Date, time: number) => {
    const hours = Math.floor(time);
    const minutes = percentageToMinutes(time - hours);
    return moment.utc(date).hour(hours).minute(minutes).isAfter(moment.utc());
  }, []);

  const filteredBookings =
    availableBookings &&
    guests &&
    availableBookings.timeSlots[guests.toString()]
      ? availableBookings.timeSlots[guests.toString()].filter((slot: number) =>
          isTimeAvailable(date, slot)
        )
      : [];

  useEffect(() => {
    setTimeSlot(undefined);

    if (
      availableBookings &&
      guests &&
      !availableBookings.possibleSeats.includes(guests)
    ) {
      setGuests(undefined);
    }
  }, [date]);

  const handleCreateBooking = useCallback(
    async (values: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumberCountryCode: string;
      phoneNumber: string;
      comment?: string;
    }) => {
      setIsSending(true);

      try {
        if (guests && timeSlot) {
          const { referenceNumber } = await API.createBookingForBusiness(
            pricingBusinessId,
            getFormattedLocale(),
            {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              phoneNumberCountryCode: values.phoneNumberCountryCode,
              comment: values.comment,
              quantity: guests!,
              date: date.getTime(),
              timeSlot: timeSlot!,
            }
          );

          setReferenceNumber(referenceNumber);
          setStep('confirmed');
        }
      } catch (error) {
        console.warn(error);
      }

      setIsSending(false);
    },
    [pricingBusinessId, date, guests, timeSlot]
  );

  if (step === 'start') {
    return (
      <Button
        icon="edit"
        title={t('components.makeBooking.actions.bookTable')}
        onPress={() => setStep('pick-date')}
        style={styles.bookTableButton}
      />
    );
  }

  return (
    <Block style={styles.container}>
      {step === 'confirmed' ? (
        <>
          <H2>
            {t('components.makeBooking.headers.bookingNumber', {
              reference: referenceNumber,
            })}
          </H2>

          <Banner rounded align="left" style={styles.bookingConfirmedBanner}>
            {t('components.makeBooking.paragraphs.bookingConfirmed')}
          </Banner>
        </>
      ) : (
        <>
          {status === 'loading' && <LoaderContainer />}

          {step === 'pick-date' && availableBookings && (
            <>
              <DateTimePicker
                value={date}
                onChange={setDate}
                minimumDate={new Date()}
                style={styles.datePicker}
              />

              {availableBookings.possibleSeats.length === 0 && (
                <P style={styles.closedDayParagraph}>
                  {t('components.makeBooking.paragraphs.noBookings')}
                </P>
              )}

              {availableBookings.possibleSeats.length > 0 && (
                <>
                  <Label required style={styles.guestsNumberLabel}>
                    {t('components.makeBooking.labels.guestsNumber')}
                  </Label>

                  <View style={styles.possibleSeatsContainer}>
                    {availableBookings.possibleSeats.map((seats) => (
                      <Pill
                        key={seats}
                        type={seats === guests ? 'primary' : undefined}
                        title={
                          seats === 1
                            ? t('components.makeBooking.labels.onePerson')
                            : t('components.makeBooking.labels.manyPeople', {
                                count: seats,
                              })
                        }
                        inline
                        style={styles.possibleSeats}
                        onPress={() => setGuests(seats)}
                      />
                    ))}
                  </View>

                  {guests && (
                    <>
                      {filteredBookings.length > 0 && (
                        <Label required style={styles.pickTimeLabel}>
                          {t('components.makeBooking.labels.pickTime')}
                        </Label>
                      )}

                      <View style={styles.possibleSeatsContainer}>
                        {filteredBookings.length === 0 && (
                          <P style={styles.noBookingsParagraph}>
                            {t('components.makeBooking.paragraphs.noBookings')}
                          </P>
                        )}

                        {filteredBookings.map((slot) => (
                          <Pill
                            key={slot}
                            type={slot === timeSlot ? 'primary' : undefined}
                            title={formatTime(slot)}
                            inline
                            style={styles.possibleSeats}
                            onPress={() => setTimeSlot(slot)}
                          />
                        ))}
                      </View>

                      <Button
                        disabled={!guests || !timeSlot}
                        title={t('common.next')}
                        onPress={() => setStep('contact-details')}
                        style={styles.nextButton}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}

          {step === 'contact-details' && timeSlot && date && (
            <>
              <View
                style={[
                  styles.bookingRecapContainer,
                  isWideScreen && styles.bookingRecapContainerWide,
                ]}
              >
                <View
                  style={[
                    styles.bookingRecapTopRow,
                    isWideScreen && styles.bookingRecapTopRowWide,
                  ]}
                >
                  {isWideScreen && (
                    <View style={styles.bookingRecapDateContainer}>
                      <Text style={styles.bookingRecapDate}>
                        {moment.utc(date).format('D')}
                      </Text>

                      <Text style={styles.bookingRecapMonth}>
                        {moment.utc(date).format('MMM')}
                      </Text>
                    </View>
                  )}

                  <View style={styles.bookingRecapBottomRow}>
                    <P>
                      {t('components.makeBooking.paragraphs.bookingWith', {
                        name: pricingBusinessName,
                      })}
                    </P>

                    <P style={styles.bookingRecapMetadata}>
                      {guests === 1
                        ? t('components.makeBooking.labels.onePerson')
                        : t('components.makeBooking.labels.manyPeople', {
                            count: guests,
                          })}{' '}
                      ·{' '}
                      {moment
                        .utc(date)
                        .hour(Math.floor(timeSlot))
                        .minute(
                          percentageToMinutes(timeSlot - Math.floor(timeSlot))
                        )
                        .calendar()}
                    </P>
                  </View>
                </View>

                <Button
                  size="small"
                  inline
                  title={t('common.change')}
                  onPress={() => setStep('pick-date')}
                  style={[isWideScreen && styles.changeButtonWide]}
                />
              </View>

              <CreateBookingForm
                sending={isSending}
                onSubmit={handleCreateBooking}
              />
            </>
          )}
        </>
      )}
    </Block>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    marginBottom: 30,
  },
  bookTableButton: {
    marginBottom: 30,
  },
  bookingConfirmedBanner: {
    marginTop: 10,
  },
  guestsNumberLabel: {
    marginBottom: 10,
  },
  possibleSeatsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  possibleSeats: {
    marginRight: 10,
    marginBottom: 10,
  },
  datePicker: {
    zIndex: 2,
    marginBottom: 20,
  },
  pickTimeLabel: {
    marginTop: 10,
  },
  noBookingsParagraph: {
    marginVertical: 10,
    fontWeight: 'bold',
  },
  closedDayParagraph: {
    fontWeight: 'bold',
  },
  nextButton: {
    marginTop: 10,
  },
  bookingRecapContainer: {
    marginBottom: 20,
    borderRadius: 6,
    padding: 10,
    backgroundColor: DefaultTheme.colors.background,
  },
  bookingRecapContainerWide: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bookingRecapTopRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    flex: 1,
  },
  bookingRecapTopRowWide: {
    marginBottom: 0,
  },
  bookingRecapDateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  bookingRecapDate: {
    fontSize: 18,
    color: Theme.PRIMARY,
    fontWeight: 'bold',
  },
  bookingRecapMonth: {
    fontSize: 16,
  },
  bookingRecapBottomRow: {
    flex: 1,
  },
  bookingRecapMetadata: {
    fontWeight: 'bold',
  },
  changeButtonWide: {
    marginLeft: 10,
  },
});

export default MakeBooking;
