import React, { useState, useCallback } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import moment from 'moment';
import Calendar from './Calendar';
import Time from './Time';
import Button from '../Button/Button';

export type DateTimePickerProps = {
  mode?: 'date' | 'time' | 'datetime';
  value: Date;
  onChange: (date: Date) => void;
  minimumDate?: Date;
  maximumDate?: Date;
  minStep?: number;
  hourStep?: number;
  style?: ViewProps['style'];
};

const DateTimePicker = (props: DateTimePickerProps) => {
  const [visible, setVisible] = useState(false);
  const [tab, setTab] = useState(0);
  const scheme = useColorScheme();

  const toggleVisibility = useCallback(() => {
    setVisible((isVisible) => !isVisible);
  }, []);

  const { value: m } = props;

  return (
    <View style={props.style}>
      <Button
        title={moment(m).format(
          props.mode === 'time' ? 'HH:mm' : 'Do MMMM YYYY'
        )}
        icon={props.mode === 'time' ? 'clock' : 'calendar'}
        onPress={toggleVisibility}
        inline
        size="small"
      />

      <View
        style={[
          styles.modal,
          scheme === 'dark' && styles.modalDark,
          visible && styles.modalVisible,
        ]}
      >
        {/* <View style={styles.options}>
        <Button
          icon="calendar"
          title="Date"
          onPress={() => setTab(0)}
          inline
          size="small"
          selected={tab === 0}
          style={styles.optionButton}
        />

        <Button
          icon="clock"
          title="Time"
          onPress={() => setTab(1)}
          inline
          size="small"
          selected={tab === 1}
          style={[styles.optionButton, styles.optionButtonLast]}
        />
      </View> */}

        {props.mode === 'time' ? (
          <Time
            time={m}
            minStep={props.minStep!}
            hourStep={props.hourStep!}
            onChange={props.onChange}
          />
        ) : (
          <Calendar
            date={m}
            onChange={props.onChange}
            onClose={toggleVisibility}
            minimumDate={props.minimumDate}
            maximumDate={props.maximumDate}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    zIndex: 1,
  },
  modal: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    top: 38, // 30 (Button) + 8 (margin)
    left: 0,
    right: 0,
    display: 'none',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  modalDark: {
    backgroundColor: DarkTheme.colors.card,
    borderColor: DarkTheme.colors.border,
  },
  modalVisible: {
    display: 'flex',
  },
  options: {
    paddingVertical: 10,
    flexDirection: 'row',
  },
  optionButton: {
    flex: 1,
    marginHorizontal: 10,
  },
  optionButtonLast: {
    marginLeft: 0,
  },
});

export default DateTimePicker;
