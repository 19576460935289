import { queryCache } from 'react-query';
import { Room, RoomTable } from '../../types';

const QueryCache = {
  // -- Room

  createRoom(businessId: string, room: Room) {
    queryCache.setQueryData(['rooms', businessId], (oldRooms: any) => [
      room,
      ...oldRooms,
    ]);
  },

  updateRoom(businessId: string, roomId: string, updatedRoom: Room) {
    queryCache.setQueryData(['rooms', businessId], (oldRooms: any) =>
      oldRooms.map((room: Room) => {
        if (room.id === roomId) {
          return {
            ...room,
            ...updatedRoom,
          };
        }

        return room;
      })
    );
  },

  deleteRoom(businessId: string, roomId: string) {
    queryCache.setQueryData(['rooms', businessId], (oldRooms: any) =>
      oldRooms.filter((room: Room) => room.id !== roomId)
    );
  },

  // -- Table room

  createRoomTable(businessId: string, roomId: string, tableRoom: RoomTable) {
    queryCache.setQueryData(['rooms', businessId], (oldRooms: any) =>
      oldRooms.map((room: Room) => {
        if (room.id === roomId) {
          return {
            ...room,
            room_tables: [...(room.room_tables || []), tableRoom],
          };
        }

        return room;
      })
    );
  },

  updateRoomTable(
    businessId: string,
    roomId: string,
    tableId: string,
    updatedRoomTable: RoomTable
  ) {
    queryCache.setQueryData(['rooms', businessId], (oldRooms: any) =>
      oldRooms.map((room: Room) => {
        if (room.id === roomId) {
          return {
            ...room,
            room_tables: (room.room_tables || []).map((roomTable) => {
              if (roomTable.id === tableId) {
                return {
                  ...roomTable,
                  ...updatedRoomTable,
                };
              }

              return roomTable;
            }),
          };
        }

        return room;
      })
    );
  },

  deleteRoomTable(businessId: string, roomId: string, tableId: string) {
    queryCache.setQueryData(['rooms', businessId], (oldRooms: any) =>
      oldRooms.map((room: Room) => {
        if (room.id === roomId) {
          return {
            ...room,
            room_tables: (room.room_tables || []).filter(
              (roomTable) => roomTable.id !== tableId
            ),
          };
        }

        return room;
      })
    );
  },
};

export default QueryCache;
