import React, { useState, useEffect, useCallback } from 'react';
import {
  StyleSheet,
  View,
  Image,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  ViewProps,
} from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import TextInput from '../TextInput/TextInput';
import DeleteButton from '../DeleteButton/DeleteButton';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import PriceTypeSelector from '../PriceTypeSelector/PriceTypeSelector';
import PriceInput from '../PriceInput/PriceInput';
import { useIsWideScreen } from '../../hooks/responsive';
import FileInput from '../FileInput/FileInput';
import PricingItem from '../Pricing/Item/Item';
import Button from '../Button/Button';
import CreateItemOptionCategoryForm from '../../forms/CreateItemOptionCategoryForm';
import { PriceType, PricingItemOptionCategory } from '../../../types';
import { t } from '../../helpers/translation';

type Props = {
  id: string;
  name: string;
  description?: string;
  image?: string;
  priceType?: PriceType;
  price?: string;
  order?: number;
  optionCategories?: PricingItemOptionCategory[];
  editable?: boolean;
  isUploadingImage?: boolean;
  onUpdateImage?: (file: File) => void;
  onEditName?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onEditPrice?: (price: string) => void;
  onEditPriceType?: (priceType: PriceType) => void;
  onEditDescription?: (
    event: NativeSyntheticEvent<TextInputFocusEventData>
  ) => void;
  onCreateItemOption?: (
    categoryId: string,
    values: {
      name: string;
      description: string;
      price: number;
      isDefault: boolean;
    }
  ) => void;
  onEditItemOption?: (
    categoryId: string,
    optionId: string,
    key: string,
    value: any
  ) => void;
  onDeleteItemOption?: (categoryId: string, itemId: string) => void;
  onEditItemOptionCategory?: (
    categoryId: string,
    key: string,
    value: any
  ) => void;
  onDeleteItemOptionCategory?: (categoryId: string) => void;
  onDelete?: () => void;
  style?: ViewProps['style'];
};

const Item = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [price, setPrice] = useState(props.price);
  const [priceType, setPriceType] = useState(props.priceType);
  const [showAddOptionCategory, setShowAddOptionCategory] = useState(false);
  const [showItemOptions, setShowItemOptions] = useState(false);
  const scheme = useColorScheme();
  const isWideScreen = useIsWideScreen();

  useEffect(() => setName(props.name), [props.name]);
  useEffect(() => setDescription(props.description), [props.description]);
  useEffect(() => setPrice(props.price), [props.price]);
  useEffect(() => setPriceType(props.priceType), [props.priceType]);

  const handleChangeImage = useCallback(
    (file: File) => {
      if (props.onUpdateImage) {
        props.onUpdateImage(file);
      }
    },
    [props.onUpdateImage]
  );

  const handleEditPrice = useCallback(
    (price: string) => {
      setPrice(price);

      if (props.onEditPrice) {
        props.onEditPrice(price);
      }
    },
    [props.onEditPrice]
  );

  const handleEditPriceType = useCallback(
    (priceType: string) => {
      setPriceType(priceType as PriceType);

      if (props.onEditPriceType) {
        props.onEditPriceType(priceType as PriceType);
      }
    },
    [props.onEditPrice]
  );

  const handleShowAddOptionCategory = useCallback(
    () =>
      setShowAddOptionCategory(
        (showAddOptionCategory) => !showAddOptionCategory
      ),
    []
  );

  const handleShowItemOptions = useCallback(
    () => setShowItemOptions((showItemOptions) => !showItemOptions),
    []
  );
  return (
    <View
      style={[
        styles.container,
        isWideScreen && styles.containerWide,
        props.style,
      ]}
    >
      {isEditing ? (
        <>
          <View
            style={[
              styles.imageContainer,
              isWideScreen && styles.imageContainerWide,
              scheme === 'dark' && styles.imageContainerDark,
            ]}
          >
            {props.image && (
              <Image
                source={{ uri: props.image }}
                resizeMode="cover"
                style={[
                  styles.image,
                  props.isUploadingImage && styles.blockIsUploading,
                ]}
              />
            )}

            {props.isUploadingImage && (
              <View style={[StyleSheet.absoluteFill, styles.isUploadingImage]}>
                <ActivityIndicator />
              </View>
            )}

            <View
              style={[
                StyleSheet.absoluteFill,
                styles.fileInput,
                { opacity: props.isUploadingImage ? 0 : 1 },
              ]}
            >
              <FileInput onChangeFile={handleChangeImage} />
            </View>
          </View>

          <View style={styles.inputs}>
            <TextInput
              required
              value={name}
              onChangeText={setName}
              label={t('components.item.placeholders.name')}
              editable
              onBlur={props.onEditName}
              style={styles.nameInput}
            />

            <TextInput
              value={description}
              onChangeText={setDescription}
              label={t('components.item.placeholders.description')}
              editable
              multiline={props.editable}
              numberOfLines={props.editable ? 3 : 1}
              onBlur={props.onEditDescription}
            />

            <View style={styles.priceInputs}>
              <PriceTypeSelector
                value={priceType || 'value'}
                onChangeValue={handleEditPriceType}
                style={styles.priceTypeSelector}
              />

              <PriceInput
                value={price}
                onChangeValue={handleEditPrice}
                priceType={priceType || 'value'}
              />
            </View>

            <View style={styles.priceInputs}>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {/* TODO: supprimer trad {t('components.item.headers.optionsExtras')} */}

                <Button
                  icon="list"
                  title={t('components.item.buttons.showOptions', {
                    count: props.optionCategories?.length || 0,
                  })}
                  onPress={handleShowItemOptions}
                  size="small"
                  inline
                  style={{ flex: 1, marginRight: 10 }}
                />

                <Button
                  title={t('components.item.buttons.addOptions')}
                  onPress={handleShowAddOptionCategory}
                  size="small"
                  icon="plus"
                  inline
                  style={{ flex: 1 }}
                />
              </View>

              {showAddOptionCategory && (
                <CreateItemOptionCategoryForm
                  itemId={props.id}
                  onCreate={() => setShowAddOptionCategory(false)}
                />
              )}

              {showItemOptions &&
                props.optionCategories?.map((optionCategory) => (
                  <CreateItemOptionCategoryForm
                    key={optionCategory.id}
                    categoryId={optionCategory.id}
                    itemId={props.id}
                    name={optionCategory.name}
                    description={optionCategory.description}
                    tickCount={optionCategory.tickCount}
                    categoryOptions={optionCategory.options}
                    onEdit={(key: string, value: any) => {
                      if (props.onEditItemOptionCategory) {
                        props.onEditItemOptionCategory(
                          optionCategory.id,
                          key,
                          value
                        );
                      }
                    }}
                    onEditOptionEnd={(itemOptionId, key, value) => {
                      if (props.onEditItemOption) {
                        props.onEditItemOption(
                          optionCategory.id,
                          itemOptionId,
                          key,
                          value
                        );
                      }
                    }}
                    onDeleteOption={(id) => {
                      if (props.onDeleteItemOption) {
                        props.onDeleteItemOption(optionCategory.id, id);
                      }
                    }}
                    onCreateOption={(values) => {
                      if (props.onCreateItemOption) {
                        props.onCreateItemOption(optionCategory.id, values);
                      }
                    }}
                    onDelete={props.onDeleteItemOptionCategory}
                  />
                ))}
            </View>

            {/* 
        Preview:
        
        // TODO: replace with baseCurrency
        // add state for this
        // and format EUR to €
        <Text>{formatPrice('€', props.priceType, props.price)}</Text> */}
          </View>
        </>
      ) : (
        <PricingItem
          name={props.name}
          description={props.description}
          image={props.image}
          priceType={props.priceType}
          price={props.price}
          onPress={() => setIsEditing(true)}
          style={{ flex: 1 }}
        />
      )}

      <Button
        size="small"
        inline
        title={!isWideScreen && t('common.update')}
        onPress={() => setIsEditing(!isEditing)}
        icon="edit-3"
        style={[styles.editButton, isWideScreen && styles.editButtonWide]}
      />

      {props.onDelete && (
        <DeleteButton
          title={isWideScreen ? '' : t('components.item.buttons.delete')}
          onDelete={props.onDelete}
          style={[styles.deleteButton, isWideScreen && styles.deleteButtonWide]}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  containerWide: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  imageContainer: {
    position: 'relative',
    width: 80,
    height: 80,
    borderRadius: 6,
    backgroundColor: DefaultTheme.colors.background,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginRight: 10,
    marginBottom: 10,
  },
  imageContainerDark: {
    backgroundColor: DarkTheme.colors.background,
  },
  imageContainerWide: {
    marginBottom: 0,
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 6,
  },
  inputs: {
    flex: 1,
  },
  nameInput: {
    marginBottom: 10,
  },
  blockIsUploading: {
    opacity: 0.5,
  },
  priceInputs: {
    marginTop: 10,
  },
  priceTypeSelector: {
    marginBottom: 10,
  },
  editButton: {
    marginTop: 10,
  },
  editButtonWide: {
    marginTop: 0,
    marginLeft: 20,
    alignSelf: 'flex-start',
  },
  deleteButton: {
    marginTop: 10,
  },
  deleteButtonWide: {
    marginTop: 0,
    marginLeft: 10,
    alignSelf: 'flex-start',
  },
  isUploadingImage: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileInput: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Item;
