import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Localization from 'expo-localization';
import { useRecoilValue } from 'recoil';
import { PricingStackParamList } from '../navigators/PricingNavigator';
import {
  cartState,
  pricingItemsState,
  pricingPaymentMethodState,
  pricingBusinessIdState,
} from '../store/state';
import Button from '../components/Button/Button';
import BuyButton from '../components/BuyButton/BuyButton';
import { formatPrice } from '../helpers/format';
import API from '../helpers/api';
import Block from '../components/Block/Block';
import CartContainer from '../containers/CartContainer';
import H2 from '../components/H2/H2';
import Text from '../components/Text/Text';
import P from '../components/P/P';
import TextInput from '../components/TextInput/TextInput';
import PhoneNumberInput from '../components/PhoneNumberInput/PhoneNumberInput';
import { t, getFormattedLocale } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import Link from '../components/Link/Link';
import ActivityIndicator from '../components/ActivityIndicator/ActivityIndicator';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';
import Wrapper from '../components/Wrapper/Wrapper';
import { computeItemOptionsPrice } from '../helpers/compute';

type Props = {
  navigation: StackNavigationProp<PricingStackParamList, 'Item'>;
  route: RouteProp<PricingStackParamList, 'Item'>;
};

const MakeOrderScreen = (props: Props) => {
  const pricingBusinessId = useRecoilValue(pricingBusinessIdState);
  const cart = useRecoilValue(cartState);
  const pricingItems = useRecoilValue(pricingItemsState);
  const paymentMethod = useRecoilValue(pricingPaymentMethodState);
  const [contactPhoneCountryCode, setContactPhoneCountryCode] = useState(
    Localization.region
  );
  const [contactPhone, setContactPhone] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [comment, setComment] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [referenceToken, setReferenceToken] = useState('');
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [payInCash, setPayInCash] = useState(true);

  if (cart.length === 0) {
    props.navigation.replace('Pricing', {
      pricingSlug: props.route.params.pricingSlug,
    });
  }

  const cartSum = cart.reduce((acc, item) => {
    const itemDetails = pricingItems[item.id];

    if (
      !itemDetails ||
      itemDetails.priceType !== 'value' ||
      !itemDetails.price
    ) {
      return acc;
    }

    const itemOptionsPrice = computeItemOptionsPrice(item.options, itemDetails);

    return acc + (Number(itemDetails.price) + itemOptionsPrice) * item.quantity;
  }, 0);

  const needToPay = cartSum !== 0;

  useDocumentTitle(t('components.cart.buttons.makeOrder'));

  const handleCreateOrder = async (orderId?: string) => {
    setIsCreatingOrder(true);

    try {
      const order = await API.createOrderForBusiness(
        pricingBusinessId,
        getFormattedLocale(),
        {
          contactFirstName,
          contactLastName,
          contactPhoneCountryCode,
          contactPhone,
          contactEmail,
          comment,
          items: cart,
          isClickAndCollect: payInCash,
        },
        orderId
      );

      setReferenceNumber(order.referenceNumber);
      setReferenceToken(order.referenceToken);
    } catch (error) {
      console.warn(error);
    }

    setIsCreatingOrder(false);
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Wrapper noPadding>
        {referenceNumber && referenceToken ? (
          <Block verticalMargins horizontalMargins>
            <H2>{t('screens.makeOrder.headers.orderSuccess')}</H2>

            <P style={{ marginTop: 10 }}>
              {t('screens.makeOrder.banners.orderSuccess')}
            </P>

            <H2 style={{ marginTop: 20 }}>
              {t('screens.makeOrder.headers.orderFollowUp')}
            </H2>

            <P style={{ marginTop: 10 }}>
              {t('screens.makeOrder.banners.orderFollowUp')}
            </P>

            <H2 style={{ marginTop: 20 }}>
              {t('screens.makeOrder.headers.referenceNumber')} {referenceNumber}{' '}
              (
              <Link
                to={`/order/${referenceNumber}/${referenceToken}`}
                target="_blank"
              >
                {t('common.seeDetails')}
              </Link>
              )
            </H2>
          </Block>
        ) : (
          <>
            <Block verticalMargins horizontalMargins>
              <H2>{t('components.cart.headers.yourOrder')}</H2>

              <CartContainer scrollViewProps={{ style: { marginTop: 20 } }} />

              <ItemSeparator style={styles.subTotalLine} />

              <P style={{ textAlign: 'right' }}>
                {t('screens.makeOrder.headers.orderTotal', {
                  total: formatPrice('EUR', 'value', cartSum),
                })}
              </P>
            </Block>

            <Block horizontalMargins style={{ marginBottom: 20 }}>
              <H2>{t('screens.makeOrder.headers.yourContactDetails')}</H2>

              <P style={{ marginTop: 10 }}>
                {t('screens.makeOrder.banners.yourContactDetails')}
              </P>

              <TextInput
                editable
                required
                label={t('common.lastName')}
                value={contactLastName}
                onChangeText={setContactLastName}
                inline
                style={{ marginTop: 20 }}
              />

              <TextInput
                editable
                required
                label={t('common.firstName')}
                value={contactFirstName}
                onChangeText={setContactFirstName}
                inline
                style={{ marginTop: 20 }}
              />

              <TextInput
                editable
                required
                type="email"
                label={t('common.email')}
                placeholder="email@mail.com"
                value={contactEmail}
                onChangeText={setContactEmail}
                style={{ marginTop: 20 }}
              />

              <PhoneNumberInput
                required
                label={t('screens.join.inputs.phoneNumber.label')}
                placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
                phoneNumber={contactPhone}
                onChangePhoneNumber={setContactPhone}
                countryCode={contactPhoneCountryCode}
                onChangeCountryCode={setContactPhoneCountryCode}
                style={{ marginTop: 20 }}
              />

              <TextInput
                editable
                multiline
                numberOfLines={3}
                value={comment}
                onChangeText={setComment}
                label={t('screens.makeOrder.inputs.comment.placeholder')}
                style={{ marginTop: 20 }}
              />
            </Block>

            {needToPay && (
              <>
                <Text style={styles.paymentTypeLabel}>
                  {t('screens.makeOrder.inputs.paymentType.label')}
                </Text>

                <View style={styles.paymentTypeContainer}>
                  <Button
                    // Disable it so it doesn't appear as clickable, as a choice
                    disabled={!paymentMethod.clientId}
                    icon="shopping-bag"
                    title={t('screens.makeOrder.inputs.paymentType.inShop')}
                    onPress={
                      !paymentMethod.clientId
                        ? undefined
                        : () => setPayInCash(true)
                    }
                    selected={payInCash}
                    inline
                    size="small"
                    style={styles.paymentTypeInStore}
                  />

                  {paymentMethod.clientId && (
                    <Button
                      icon="credit-card"
                      title={t('screens.makeOrder.inputs.paymentType.online')}
                      onPress={() => setPayInCash(false)}
                      selected={!payInCash}
                      inline
                      size="small"
                      style={styles.paymentTypeOnline}
                    />
                  )}
                </View>
              </>
            )}

            {needToPay && !payInCash && paymentMethod ? (
              <Block horizontalMargins style={styles.paymentBox}>
                {isCreatingOrder && <ActivityIndicator style={styles.loader} />}

                <BuyButton
                  amount={cartSum}
                  currency="EUR"
                  clientId={paymentMethod.clientId}
                  onPaymentSuccess={(orderId) => {
                    handleCreateOrder(orderId);
                  }}
                />
              </Block>
            ) : (
              <View style={styles.makeOrderButton}>
                <Button
                  title={t('components.cart.buttons.makeOrder')}
                  onPress={() => handleCreateOrder()}
                  busy={isCreatingOrder}
                  disabled={
                    !contactFirstName ||
                    !contactLastName ||
                    !contactEmail ||
                    !contactPhone ||
                    !contactPhoneCountryCode
                  }
                />
              </View>
            )}
          </>
        )}
      </Wrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {},
  loader: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  subTotalLine: {
    marginVertical: 20,
  },
  paymentTypeContainer: {
    flexDirection: 'row',
    marginBottom: 20,
    marginHorizontal: 20,
  },
  paymentTypeLabel: {
    marginBottom: 10,
    marginHorizontal: 20,
  },
  paymentTypeInStore: {
    flex: 1,
  },
  paymentTypeOnline: {
    marginLeft: 20,
    flex: 1,
  },
  makeOrderButton: {
    marginBottom: 20,
    marginHorizontal: 20,
  },
  paymentBox: {
    marginBottom: 20,
  },
});

export default MakeOrderScreen;
