import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import Text from '../Text/Text';

type Props = {
  name: string;
  image?: string;
};

const BusinessHeaderTitle = (props: Props) => {
  return (
    <View style={styles.container}>
      {props.image && (
        <Image source={{ uri: props.image }} style={styles.image} />
      )}

      <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
        {props.name}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    height: 25,
    width: 25,
    borderRadius: 25,
    marginRight: 8,
  },
  name: {
    fontSize: 18,
    fontWeight: '500',
  },
});

export default BusinessHeaderTitle;
