import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  ViewProps,
  GestureResponderEvent,
} from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import Text from '../Text/Text';

type Props = {
  flag: string;
  name: string;
  callingCode: string;
  onPress: (event: GestureResponderEvent) => void;
  style?: ViewProps['style'];
};

const CountryItem = (props: Props) => {
  const scheme = useColorScheme();

  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[styles.container, scheme === 'dark' && styles.containerDark]}
    >
      <Text style={styles.flag}>{props.flag}</Text>
      <Text>{props.name}</Text>
      <Text style={styles.callingCode}>+{props.callingCode}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: DefaultTheme.colors.border,
    borderBottomWidth: 1,
    paddingHorizontal: 10,
  },
  containerDark: {
    borderBottomColor: DarkTheme.colors.border,
  },
  flag: {
    marginRight: 8,
  },
  callingCode: {
    flex: 1,
    marginLeft: 8,
    opacity: 0.8,
    textAlign: 'right',
  },
});

export default React.memo(CountryItem);
