import React, { useCallback, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import API from '../helpers/api';
import {
  membershipsProgramIdState,
  membershipStagesState,
} from '../store/state';
import { RewardType } from '../../types';
import TextInput from '../components/TextInput/TextInput';
import Switch from '../components/Switch/Switch';
import P from '../components/P/P';
import Button from '../components/Button/Button';
import H2 from '../components/H2/H2';
import Hint from '../components/Hint/Hint';
import Picker from '../components/Picker/Picker';
import Label from '../components/Label/Label';
import { t } from '../helpers/translation';

type AddProgramStageFormProps = {
  id?: string;
  name?: string;
  description?: string;
  stampsCount?: number;
  isRecurrent?: boolean;
  isActivated?: boolean;
  type?: RewardType;
};

const AddProgramStageForm = (props: AddProgramStageFormProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const [name, setName] = useState(props.name ?? '');
  const [description, setDescription] = useState(props.description ?? '');
  const [stampsCount, setStampsCount] = useState(props.stampsCount ?? 5);
  const [isRecurrent, setIsRecurrent] = useState(props.isRecurrent ?? false);
  const [type, setType] = useState<RewardType>(props.type ?? 'item');
  const [isActivated, setIsActivated] = useState(props.isActivated ?? false);
  const membershipsProgramId = useRecoilValue(membershipsProgramIdState);
  const setMembershipStages = useSetRecoilState(membershipStagesState);
  const canCreate = name && description && stampsCount;
  const isEditing = !!props.id;

  const handleActionProgramStage = useCallback(async () => {
    setIsCreating(true);

    try {
      if (isEditing) {
        const updatedStage = {
          name,
          description,
          stampsCount,
          isRecurrent,
          type,
          isActivated,
        };

        await API.updateLoyaltyStageById(props.id!, updatedStage);

        setMembershipStages((stages) =>
          stages.map((stage) => {
            if (stage.id !== props.id) {
              return stage;
            }

            return {
              ...stage,
              rewardName: name,
              rewardDescription: description,
              stampsCount,
              isRecurrent,
              rewardType: type,
              isActivated,
            };
          })
        );
      } else {
        const reward = await API.createLoyaltyStageForProgram(
          membershipsProgramId,
          {
            name,
            description,
            stampsCount,
            isRecurrent,
            type,
          }
        );

        setMembershipStages((stages) =>
          [
            ...stages,
            {
              id: reward.id,
              rewardName: name,
              rewardDescription: description,
              stampsCount,
              isRecurrent,
              rewardType: type,
              isActivated: true,
            },
          ].sort((a, b) => a.stampsCount - b.stampsCount)
        );

        setName('');
        setDescription('');
        setStampsCount(5);
        setIsRecurrent(false);
        setType('item');
      }
    } catch (error) {
      console.warn(error);
    }

    setIsCreating(false);
  }, [
    isEditing,
    props.id,
    name,
    description,
    stampsCount,
    isRecurrent,
    isActivated,
    type,
  ]);

  return (
    <View>
      <H2 style={styles.title}>
        {isEditing
          ? t('forms.addProgramStage.headers.editProgramStage')
          : t('forms.addProgramStage.headers.createProgramStage')}
      </H2>

      <Label required>
        {t('forms.addProgramStage.inputs.rewardType.label')}
      </Label>

      <Picker selectedValue={type} onValueChange={setType} style={styles.input}>
        <Picker.Item
          label={t('forms.addProgramStage.inputs.rewardType.options.item')}
          value="item"
        />
        <Picker.Item
          label={t('forms.addProgramStage.inputs.rewardType.options.discount')}
          value="discount"
        />
      </Picker>

      <TextInput
        editable
        required
        type="text"
        value={name}
        onChangeText={setName}
        label={t('forms.addProgramStage.inputs.rewardName')}
        style={styles.input}
      />

      <TextInput
        editable
        required
        type="text"
        value={description}
        onChangeText={setDescription}
        label={t('forms.addProgramStage.inputs.rewardDescription')}
        style={styles.input}
      />

      <TextInput
        editable
        required
        type="number"
        value={stampsCount.toString()}
        onChangeText={(count) => setStampsCount(parseInt(count, 10))}
        label={t('forms.addProgramStage.inputs.stampsCount.label')}
        placeholder={t('forms.addProgramStage.inputs.stampsCount.placeholder')}
        style={styles.input}
      />

      <View style={styles.input}>
        <Switch
          required
          label={t('forms.addProgramStage.inputs.isRecurrent.label')}
          value={isRecurrent}
          onValueChange={setIsRecurrent}
        />

        {!isNaN(stampsCount) && (
          <Hint style={styles.hint}>
            {t('forms.addProgramStage.inputs.isRecurrent.hint', {
              points: stampsCount,
              doublePoints: stampsCount * 2,
              triplePoints: stampsCount * 3,
            })}
          </Hint>
        )}
      </View>

      {isEditing && (
        <View style={styles.input}>
          <Switch
            label={t('forms.addProgramStage.inputs.isActivated')}
            value={isActivated}
            onValueChange={setIsActivated}
          />

          {isActivated && (
            <Hint style={styles.hint}>
              {t('forms.addProgramStage.banners.deactivateRewardIndications')}
            </Hint>
          )}
        </View>
      )}

      <Button
        icon={isEditing ? 'edit-3' : 'plus'}
        title={
          isEditing
            ? t('forms.addProgramStage.buttons.editProgramStage')
            : t('forms.addProgramStage.buttons.addProgramStage')
        }
        onPress={handleActionProgramStage}
        busy={isCreating}
        disabled={!canCreate}
        style={styles.sendButton}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    marginBottom: 10,
  },
  input: {
    marginBottom: 20,
  },
  inputRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputRowLabel: {
    flex: 1,
    fontWeight: 'bold',
  },
  hint: {
    marginTop: 5,
  },
  sendButton: {
    marginTop: 10,
  },
});

export default AddProgramStageForm;
