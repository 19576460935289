import moment from 'moment';
import { CartItemOption, PricingItem, Booking } from '../../types';

export const computeItemOptionsPrice = (
  itemOptions: CartItemOption[],
  itemDetails: PricingItem
) => {
  let itemOptionsPrice = 0;

  for (const [optionCategoryId, optionIds] of itemOptions) {
    const optionsDetails = itemDetails
      .optionCategories!.find(
        (optionCategory) => optionCategory.id.toString() === optionCategoryId
      )!
      .options.filter((option) => optionIds.includes(option.id));

    for (const optionDetails of optionsDetails) {
      if (optionDetails.price) {
        itemOptionsPrice += optionDetails.price;
      }
    }
  }

  return itemOptionsPrice;
};

export const computeBookingsOverlap = (
  booking: Booking,
  otherBookings: Booking[]
) => {
  const startAt = moment.utc(booking.startAt).valueOf();
  const endAt = moment.utc(booking.endAt).valueOf();

  let overlaps = 0;
  for (const otherBooking of otherBookings) {
    const otherStartAt = moment.utc(otherBooking.startAt).valueOf();
    const otherEndAt = moment.utc(otherBooking.endAt).valueOf();

    if (startAt <= otherEndAt && endAt >= otherStartAt) {
      overlaps++;
    }
  }

  return overlaps;
};
