import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import Icon from '../Icon/Icon';
import P from '../P/P';

export type HintProps = {
  children: any;
  style?: ViewProps['style'];
};

const Hint = (props: HintProps) => {
  return (
    <View style={[styles.container, props.style]}>
      <Icon name="help-circle" size={14} style={styles.icon} />
      <P style={styles.text}>{props.children}</P>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  icon: {
    marginRight: 5,
  },
  text: {
    fontSize: 12,
    opacity: 0.8,
    textAlign: 'justify',
  },
});

export default Hint;
