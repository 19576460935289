import AsyncStorage from '@react-native-community/async-storage';

export function getUserSetLanguage() {
  return AsyncStorage.getItem('set-language');
}

export function setUserSetLanguage(language: string) {
  return AsyncStorage.setItem('set-language', language);
}

export function getUserId() {
  return AsyncStorage.getItem('user_id');
}

export function saveUserId(userId: string) {
  return AsyncStorage.setItem('user_id', String(userId));
}

export function deleteUserId() {
  return AsyncStorage.removeItem('user_id');
}

export function getUserRole() {
  return AsyncStorage.getItem('user_role');
}

export function saveUserRole(role: string) {
  return AsyncStorage.setItem('user_role', role);
}

export function deleteUserRole() {
  return AsyncStorage.removeItem('user_role');
}

export function getAccessToken() {
  return AsyncStorage.getItem('api_access_token');
}

export function saveAccessToken(accessToken: string) {
  return AsyncStorage.setItem('api_access_token', accessToken);
}

export function deleteAccessToken() {
  return AsyncStorage.removeItem('api_access_token');
}

export function getRefreshToken() {
  return AsyncStorage.getItem('api_refresh_token');
}

export function saveRefreshToken(refreshToken: string) {
  return AsyncStorage.setItem('api_refresh_token', refreshToken);
}

export function deleteRefreshToken() {
  return AsyncStorage.removeItem('api_refresh_token');
}

export async function getTokenExpiresAt() {
  const expiresAt = await AsyncStorage.getItem('api_token_expires_at');

  if (expiresAt) {
    return Number(expiresAt);
  }

  return expiresAt;
}

export function saveTokenExpiresAt(expiresAt: number) {
  return AsyncStorage.setItem('api_token_expires_at', String(expiresAt));
}

export function deleteTokenExpiresAt() {
  return AsyncStorage.removeItem('api_token_expires_at');
}
