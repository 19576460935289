import React, { useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRecoilValue } from 'recoil';
import { cartState } from '../../store/state';
import Button from '../Button/Button';
import H2 from '../H2/H2';
import Block from '../Block/Block';
import Icon from '../Icon/Icon';
import { PricingStackParamList } from '../../navigators/PricingNavigator';
import CartContainer from '../../containers/CartContainer';
import { t } from '../../helpers/translation';
import { webOnly } from '../../helpers/style';

type Props = {
  businessId: string;
};

const Cart = (props: Props) => {
  const navigation = useNavigation<
    StackNavigationProp<PricingStackParamList, 'Pricing'>
  >();
  const route = useRoute<RouteProp<PricingStackParamList, 'Pricing'>>();
  const [isOpened, setIsOpened] = useState(false);
  const cart = useRecoilValue(cartState);

  const handleMakeOrder = () => {
    navigation.navigate('MakeOrder', {
      pricingSlug: route.params.pricingSlug,
    });
  };

  if (cart.length === 0) {
    return null;
  }

  const maxHeight = Dimensions.get('screen').height * 0.7;

  return (
    <Block style={styles.container}>
      {isOpened && (
        <>
          <View style={styles.header}>
            <H2 style={styles.headerTitle}>
              {t('components.cart.headers.yourOrder')}
            </H2>

            <Icon name="chevron-down" onPress={() => setIsOpened(false)} />
          </View>

          <CartContainer
            scrollViewProps={{
              contentContainerStyle: { maxHeight },
              style: { marginTop: 20 },
            }}
          />

          <Button
            title={t('components.cart.buttons.makeOrder')}
            onPress={handleMakeOrder}
            style={styles.buttonMakeOrder}
          />
        </>
      )}

      {!isOpened && (
        <Button
          icon="shopping-cart"
          title={t('components.cart.buttons.viewArticles', {
            count: cart.length,
          })}
          onPress={() => setIsOpened(true)}
        />
      )}
    </Block>
  );
};

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    left: 0,
    right: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    ...webOnly({
      position: 'sticky',
    }),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitle: {
    flex: 1,
  },
  buttonMakeOrder: {
    marginTop: 20,
  },
});

export default Cart;
