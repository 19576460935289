import React from 'react';
import {
  CheckBox as RNCheckBox,
  CheckBoxProps as RNCheckBoxProps,
  View,
  StyleSheet,
} from 'react-native';
import { DefaultTheme } from '@react-navigation/native';
import P from '../P/P';

export type CheckBoxProps = RNCheckBoxProps & {
  label?: string;
};

const CheckBox = (props: CheckBoxProps) => {
  if (props.label) {
    const { style, ...otherProps } = props;

    return (
      <View style={[styles.containerWithLabel, style]}>
        <RNCheckBox
          {...otherProps}
          // @ts-ignore
          color={DefaultTheme.colors.primary}
        />

        <P style={styles.label}>{props.label}</P>
      </View>
    );
  }

  return (
    <RNCheckBox
      {...props}
      // @ts-ignore
      color={DefaultTheme.colors.primary}
    />
  );
};

const styles = StyleSheet.create({
  containerWithLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: 10,
  },
});

export default CheckBox;
