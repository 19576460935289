import React from 'react';
import { Booking } from '../../../types';
import { View, StyleSheet } from 'react-native';
import Block from '../Block/Block';
import H1 from '../H1/H1';
import H2 from '../H2/H2';
import P from '../P/P';
import Link from '../Link/Link';
import Status from '../Status/Status';
import Pill from '../Pill/Pill';
import { t } from '../../helpers/translation';
import H3 from '../H3/H3';

type BookingDetailsProps = {
  booking: Booking;
};

const BookingDetails = ({ booking }: BookingDetailsProps) => {
  return (
    <View style={styles.container}>
      <H1>
        {t('components.bookingDetails.headers.bookingNumber', {
          referenceNumber: booking.referenceNumber,
        })}
      </H1>

      <View style={styles.statuses}>
        <Status status={booking.statusOwner} style={styles.status} />
        <Status status={booking.statusUser} />
      </View>

      <Block verticalMargins>
        <View style={styles.customerHeaderContainer}>
          <H3 style={styles.customerHeader}>{t('common.customer')}</H3>

          {booking.walkIn && <Pill type="primary" title={t('common.walkIn')} />}
        </View>

        {(booking.contactFirstName !== 'XXX' ||
          booking.contactLastName !== 'XXX') && (
          <P>
            {booking.contactFirstName !== 'XXX' &&
              booking.contactFirstName + ' '}

            {booking.contactLastName !== 'XXX' && booking.contactLastName}
          </P>
        )}

        {booking.contactPhone && (
          <P>
            {booking.contactPhone.includes('*') ? (
              booking.contactPhone
            ) : (
              <Link to={`tel:${booking.contactPhone}`} target="_blank">
                {booking.contactPhone}
              </Link>
            )}
          </P>
        )}

        {booking.contactEmail && (
          <P>
            {booking.contactEmail.includes('*') ? (
              booking.contactEmail
            ) : (
              <Link to={`mailto:${booking.contactEmail}`} target="_blank">
                {booking.contactEmail}
              </Link>
            )}
          </P>
        )}
      </Block>

      <Block>
        <H3>{t('common.comment')}</H3>

        <P>
          {booking.comment
            ? `"${booking.comment}"`
            : t('screens.orderDetails.errors.noComment')}
        </P>
      </Block>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  statuses: {
    flexDirection: 'row',
    marginTop: 10,
  },
  status: {
    marginRight: 8,
  },
  customerHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  customerHeader: {
    flex: 1,
  },
});

export default BookingDetails;
