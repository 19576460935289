import React, { useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { setUserSetLanguage } from '../../helpers/storage';
import Theme from '../../helpers/theme';
import P from '../P/P';
import Flag from '../Flag/Flag';
import { getFormattedLocale } from '../../helpers/translation';

type LanguageSelectorPopOverProps = {};

const langs = [
  { code: 'en', lang: 'English' },
  { code: 'fr', lang: 'Français' },
];

const LanguageSelectorPopOver = (props: LanguageSelectorPopOverProps) => {
  const language = getFormattedLocale();
  const onChangeLanguage = useCallback((language: string) => {
    setUserSetLanguage(language);
    window.location.reload();
  }, []);

  return langs.map(({ lang, code }, index) => (
    <TouchableOpacity
      key={code}
      disabled={code === language}
      onPress={() => onChangeLanguage(code)}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: index < langs.length - 1 ? 10 : 0,
      }}
    >
      <Flag code={code} style={styles.flag} />

      <P style={[code === language && { color: Theme.PRIMARY }]}>{lang}</P>
    </TouchableOpacity>
  ));
};

const styles = StyleSheet.create({
  flag: {
    width: 24,
    height: 18,
    borderRadius: 4,
    marginRight: 8,
  },
});

export default LanguageSelectorPopOver;
