import { useFocusEffect } from '@react-navigation/native';
import { Platform } from 'react-native';

export const useDocumentTitle = (title: string) => {
  if (Platform.OS === 'web') {
    useFocusEffect(() => {
      document.title = `${title} — illicarte`;
    });
  }
};
