import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useIsWideScreen } from '../../hooks/responsive';
import Table from '../Table/Table';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import { formatPrice } from '../../helpers/format';
import Block from '../Block/Block';
import H3 from '../H3/H3';
import H2 from '../H2/H2';

type PricingPlanFeature = {
  name: string;
  icon: string;
  price: number;
  isSub?: boolean;
};

type PricingPlan = {
  name: string;
  icon: string;
  description: string;
  features: PricingPlanFeature[];
};

type PricingPlansProps = {
  plans: PricingPlan[];
};

const PricingPlans = (props: PricingPlansProps) => {
  const isWideScreen = useIsWideScreen();

  const renderFeatureName = useCallback(
    (feature) => (
      <>
        <Icon name={feature.icon} style={styles.rowIcon} />
        <Text style={styles.rowTitle}>{feature.name}</Text>
      </>
    ),
    []
  );

  const renderFeaturePriceFromPlan = useCallback(
    (plan, feature, index) => (
      <Text>
        {(feature.isSub ? '+' : '') +
          formatPrice('EUR', 'value', plan.features[index].price)}
        /mois
      </Text>
    ),
    []
  );

  if (props.plans.length === 0) {
    return null;
  }

  if (isWideScreen) {
    return (
      <Block>
        <Table
          alternateBackgrounds
          cellPadding={10}
          data={[
            [
              // TODO
              <Text style={styles.rowTitle}>Products</Text>,
              ...props.plans.map((plan) => (
                <View style={styles.titleCell}>
                  <Icon name={plan.icon} style={styles.titleCellIcon} />
                  <Text style={styles.rowTitle}>{plan.name}</Text>
                  <Text>{plan.description}</Text>
                </View>
              )),
            ],
            ...props.plans[0].features.map((feature, index) => [
              renderFeatureName(feature),
              ...props.plans.map((plan) =>
                renderFeaturePriceFromPlan(plan, feature, index)
              ),
            ]),
          ]}
        />
      </Block>
    );
  }

  return props.plans.map((plan) => (
    <Block key={plan.name} style={{ marginBottom: 20 }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 20,
        }}
      >
        <Icon name={plan.icon} style={{ marginRight: 10 }} />
        <H2 style={{ marginRight: 10 }}>{plan.name}</H2>
        <H3>{plan.description}</H3>
      </View>

      <Table
        alternateBackgrounds
        cellPadding={10}
        data={[
          ...[
            // header
          ],
          ...plan.features.map((feature, index) => [
            renderFeatureName(feature),
            renderFeaturePriceFromPlan(plan, feature, index),
          ]),
        ]}
      />
    </Block>
  ));
};

const styles = StyleSheet.create({
  rowIcon: {
    marginRight: 10,
  },
  rowTitle: {
    fontWeight: 'bold',
  },
  titleCell: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleCellIcon: {
    marginBottom: 5,
  },
});

export default PricingPlans;
