import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useRecoilState, useRecoilValue } from 'recoil';
import arrayMove from 'array-move';
import {
  categoryItemsState,
  pricingIdState,
  rawCategoryItemsState,
} from '../store/state';
import API from '../helpers/api';
import ItemContainer from './ItemContainer';
import Button from '../components/Button/Button';
import Block from '../components/Block/Block';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';
import { t } from '../helpers/translation';
import Modal from '../components/Modal/Modal';
import CreateItemForm from '../forms/CreateItemForm';

type Props = {
  categoryId: string;
  editable?: boolean;
};

const ItemsContainer = (props: Props) => {
  const [isSorting, setIsSorting] = useState(false);
  const [isSendingSorting, setIsSendingSorting] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [itemsByOrder, setItemsByOrder] = useState<string[]>([]);
  const [itemsBeforeSorting, setItemsBeforeSorting] = useState<string[]>([]);
  const categoryItems = useRecoilValue(categoryItemsState(props.categoryId));
  const pricingId = useRecoilValue(pricingIdState);
  const [rawCategoryItems, setRawCategoryItems] = useRecoilState(
    rawCategoryItemsState(props.categoryId)
  );
  const shouldHideAddItem = categoryItems.length > 0 && !showAddItem;

  // Get the list of items sorted by their order property
  useEffect(() => {
    const items = categoryItems.map((itemId) =>
      rawCategoryItems.find((item) => item.id === itemId)
    );

    setItemsByOrder(
      items
        .map((item) => [item!.id!, item!.order!])
        .sort((a, b) => (a[1] as number) - (b[1] as number))
        .map(([itemId]) => itemId as string)
    );
  }, [rawCategoryItems]);

  const handleSort = useCallback((direction: 'up' | 'down', index: number) => {
    setItemsByOrder((itemsByOrder) => {
      const nextIndex = direction === 'up' ? index - 1 : index + 1;
      return arrayMove(itemsByOrder, index, nextIndex);
    });
  }, []);

  const handleUpdateItemsSorting = useCallback(async () => {
    if (
      itemsByOrder.every(
        (itemId, index) => itemId === itemsBeforeSorting[index]
      )
    ) {
      return;
    }

    setIsSendingSorting(true);

    const itemsOrder = itemsByOrder.map((itemId, index) => [
      itemId,
      index + 1,
    ]) as [string, number][];

    try {
      await API.updatePricingCategoryItemsOrder(
        pricingId,
        props.categoryId,
        itemsOrder
      );
    } catch (error) {
      console.warn(error);
    }

    setRawCategoryItems((rawItems) => {
      return rawItems.map((item) => {
        const newItem = itemsOrder.find(([itemId]) => itemId === item.id);

        if (newItem) {
          return {
            ...item,
            order: newItem[1],
          };
        }

        return item;
      });
    });

    setIsSendingSorting(false);
  }, [itemsByOrder, itemsBeforeSorting]);

  const handleAddedItem = useCallback(() => setShowAddItem(false), []);

  return (
    <>
      {showAddItem && (
        <Modal visible onClose={handleAddedItem}>
          <Block>
            <CreateItemForm
              categoryId={props.categoryId}
              onSuccess={handleAddedItem}
            />
          </Block>
        </Modal>
      )}

      <Block verticalMargins list>
        <View style={styles.topRow}>
          {!isSorting && (
            <Button
              size="small"
              inline
              icon="plus"
              title={t('components.itemsContainer.buttons.addItem')}
              onPress={() => setShowAddItem(true)}
            />
          )}

          {itemsByOrder.length > 1 && (
            <>
              {!isSorting && (
                <Button
                  icon="repeat"
                  title={t('components.itemsContainer.buttons.sortItems')}
                  size="small"
                  inline
                  onPress={() => {
                    setIsSorting(true);
                    setItemsBeforeSorting([...itemsByOrder]);
                  }}
                  style={styles.sortItemsButton}
                />
              )}

              {isSorting && (
                <>
                  <Button
                    type="success"
                    title={t('components.itemsContainer.buttons.finishSorting')}
                    size="small"
                    inline
                    busy={isSendingSorting}
                    onPress={async () => {
                      await handleUpdateItemsSorting();
                      setIsSorting(false);
                    }}
                    style={styles.finishSortingButton}
                  />

                  <Button
                    type="danger"
                    title={t('common.cancel')}
                    size="small"
                    inline
                    onPress={() => {
                      setIsSorting(false);
                      setItemsByOrder([...itemsBeforeSorting]);
                    }}
                  />
                </>
              )}
            </>
          )}
        </View>

        {itemsByOrder.map((itemId, index, items) => (
          <React.Fragment key={itemId}>
            <View style={styles.itemRow}>
              {isSorting && items.length > 1 && (
                <View style={styles.orderButtons}>
                  <Button
                    disabled={index === 0}
                    icon="chevron-up"
                    size="small"
                    inline
                    onPress={() => handleSort('up', index)}
                    style={styles.orderUpButton}
                  />

                  <Button
                    disabled={index === items.length - 1}
                    icon="chevron-down"
                    size="small"
                    inline
                    onPress={() => handleSort('down', index)}
                    style={styles.orderDownButton}
                  />
                </View>
              )}

              <ItemContainer
                id={itemId}
                categoryId={props.categoryId}
                editable={props.editable}
                style={[styles.containerRightPadded, styles.itemContainer]}
              />
            </View>

            {index < categoryItems.length - 1 && <ItemSeparator />}
          </React.Fragment>
        ))}
      </Block>
    </>
  );
};

const styles = StyleSheet.create({
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 20,
    marginRight: 20,
  },
  containerRightPadded: {
    paddingRight: 20,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  itemContainer: {
    flex: 1,
  },
  addItemButton: {
    marginRight: 20,
  },
  sortItemsButton: {
    marginLeft: 10,
  },
  finishSortingButton: {
    marginRight: 10,
  },
  orderButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  orderDownButton: {
    marginRight: 10,
  },
  orderUpButton: {
    marginRight: 5,
  },
});

export default React.memo(ItemsContainer);
