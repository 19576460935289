import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  TextStyle,
  GestureResponderEvent,
  ViewProps,
  View,
} from 'react-native';
import { DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
// @ts-ignore
import color from 'color';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import Theme from '../../helpers/theme';
import { webOnly } from '../../helpers/style';

export type ButtonProps = {
  type?: 'danger' | 'default' | 'success';
  title?: string;
  disabled?: boolean;
  busy?: boolean;
  icon?: string;
  iconColor?: string;
  iconSize?: number;
  inline?: boolean;
  progress?: number;
  onPress?: (event: GestureResponderEvent) => void;
  selected?: boolean;
  size?: 'small' | 'default';
  style?: ViewProps['style'];
  textStyle?: TextStyle;
};

const Button = (props: ButtonProps) => {
  const scheme = useColorScheme();

  let iconColor: string;
  let loaderColor: string | undefined;
  switch (props.type) {
    case 'danger':
      iconColor = Theme.RED;
      loaderColor = Theme.RED;
      break;

    case 'success':
      iconColor = Theme.GREEN;
      loaderColor = Theme.GREEN;
      break;

    default:
      iconColor = props.iconColor || Theme.PRIMARY;
  }

  return (
    <TouchableOpacity
      activeOpacity={scheme === 'dark' ? 0.7 : 0.5}
      onPress={props.onPress}
      disabled={props.disabled || props.busy || !props.onPress}
      style={[
        styles.container,
        scheme === 'dark' && styles.containerDark,
        props.type === 'danger' && styles.containerDanger,
        props.type === 'success' && styles.containerSuccess,
        props.disabled && props.onPress && styles.containerDisabled,
        props.size === 'small' && styles.containerSmall,
        props.inline && styles.containerInline,
        props.selected && styles.containerSelected,
        props.style,
      ]}
    >
      {props.busy ? (
        <ActivityIndicator size={12} color={loaderColor} />
      ) : (
        <>
          {props.icon && (
            <Icon
              name={props.icon}
              size={props.iconSize ?? 17}
              color={iconColor}
              style={!!props.title && styles.iconWithTitle}
            />
          )}

          {props.title && (
            <Text
              style={[
                styles.title,
                props.type === 'danger' && styles.titleDanger,
                props.type === 'success' && styles.titleSuccess,
                props.textStyle,
              ]}
            >
              {props.title}
            </Text>
          )}
        </>
      )}

      {typeof props.progress === 'number' && (
        <View
          style={[
            styles.progress,
            {
              width: `${props.progress * 100}%`,
            },
          ]}
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 6,
    alignItems: 'center',
    backgroundColor: color(Theme.PRIMARY).alpha(0.12).string(),
    borderColor: Theme.PRIMARY,
    borderWidth: 2,
    height: 50,
    justifyContent: 'center',
    flexDirection: 'row',
    opacity: 1,
    overflow: 'hidden',
    ...webOnly({
      transition: 'all 250ms',
    }),
  },
  containerDark: {
    backgroundColor: DarkTheme.colors.card,
  },
  containerSmall: {
    height: 30,
    paddingVertical: 8,
    borderWidth: 0,
  },
  containerInline: {
    width: 'auto',
  },
  containerDisabled: {
    opacity: 0.5,
  },
  containerDanger: {
    backgroundColor: color(Theme.RED).alpha(0.12).string(),
    borderColor: Theme.RED,
  },
  containerSuccess: {
    backgroundColor: color(Theme.GREEN).alpha(0.12).string(),
    borderColor: Theme.GREEN,
  },
  containerSelected: {
    borderWidth: 2,
  },
  title: {
    color: Theme.PRIMARY,
    fontWeight: 'bold',
  },
  titleDanger: {
    color: Theme.RED,
  },
  titleSuccess: {
    color: Theme.BLACK,
  },
  iconWithTitle: {
    marginRight: 5,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    ...webOnly({
      transition: 'all 100ms',
    }),
  },
});

export default Button;
