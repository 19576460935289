import React from 'react';
import { View, StyleSheet } from 'react-native';
import moment from 'moment';
import { DefaultTheme } from '@react-navigation/native';
// @ts-ignore
import color from 'color';
import Icon from '../Icon/Icon';
import { Order } from '../../../types';
import Text from '../Text/Text';
import Theme from '../../helpers/theme';

type OrderRowItemProps = {
  order: Order;
  selected?: boolean;
};

const OrderRowItem = ({ order, selected }: OrderRowItemProps) => {
  let statusOwnerIconName;
  let statusOwnerIconColor;

  switch (order.statusOwner) {
    case 'cancelled':
      statusOwnerIconName = 'x';
      statusOwnerIconColor = Theme.RED;
      break;

    case 'doing':
      statusOwnerIconName = 'loader';
      statusOwnerIconColor = Theme.ORANGE;
      break;

    case 'hold':
      statusOwnerIconName = 'pause';
      statusOwnerIconColor = Theme.ORANGE;
      break;

    case 'waiting':
      statusOwnerIconName = 'clock';
      statusOwnerIconColor = Theme.ORANGE;
      break;

    case 'done':
      statusOwnerIconName = 'check';
      statusOwnerIconColor = Theme.GREEN;
      break;
  }

  return (
    <View style={[styles.container, selected && styles.containerSelected]}>
      <Text style={[styles.text, styles.date]}>
        {moment.utc(order.createdAt).format('DD/MM')}
      </Text>

      <View style={styles.identification}>
        <Text style={[styles.text, styles.referenceNumber]}>
          #{order.referenceNumber}
        </Text>

        <Text ellipsize style={styles.text}>
          {order.contactLastName} {order.contactFirstName}
        </Text>
      </View>

      <View style={styles.metadata}>
        <View style={styles.quantityContainer}>
          {/*
        TODO: once we can pick up at a certain time, should add the following
        <Icon name="clock" size={12} style={styles.icon} />

        <Text style={[styles.text, styles.time]}>
          {moment.utc(order.readyAt).format('HH:mm')}
        </Text> */}

          <Icon name="shopping-bag" size={12} style={styles.icon} />

          <Text style={[styles.text, styles.quantity]}>
            {order.order_items.reduce((acc, item) => acc + item.quantity, 0)}
          </Text>
        </View>

        <View style={styles.statuses}>
          {order.comment && (
            <View style={[styles.status, styles.statusComment]}>
              <Icon name="message-circle" color="white" size={12} />
            </View>
          )}

          <View
            style={[
              styles.status,
              {
                backgroundColor: statusOwnerIconColor,
              },
            ]}
          >
            <Icon name={statusOwnerIconName} color="black" size={12} />
          </View>

          <View
            style={[
              styles.status,
              {
                backgroundColor:
                  order.statusUser === 'paid' ? Theme.GREEN : Theme.ORANGE,
              },
            ]}
          >
            <Icon name="dollar-sign" color="black" size={12} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerSelected: {
    backgroundColor: color(DefaultTheme.colors.border).lighten(0.1),
  },
  text: {
    fontSize: 12,
  },
  date: {
    flex: 2,
    fontWeight: 'bold',
  },
  referenceNumber: {
    fontWeight: 'bold',
  },
  identification: {
    paddingRight: 20,
    flex: 4,
  },
  metadata: {
    flex: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 10,
  },
  quantityContainer: {
    marginRight: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 3,
  },
  quantity: {
    fontWeight: 'bold',
  },
  time: {
    fontWeight: 'bold',
  },
  statuses: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  status: {
    marginLeft: 5,
    width: 18,
    height: 18,
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusComment: {
    backgroundColor: Theme.PRIMARY,
  },
});

export default React.memo(OrderRowItem, (prevProps, nextProps) => {
  return (
    prevProps.selected === nextProps.selected &&
    prevProps.order.referenceNumber === nextProps.order.referenceNumber &&
    prevProps.order.createdAt === nextProps.order.createdAt &&
    prevProps.order.contactFirstName === nextProps.order.contactFirstName &&
    prevProps.order.contactLastName === nextProps.order.contactLastName &&
    prevProps.order.statusOwner === nextProps.order.statusOwner &&
    prevProps.order.statusUser === nextProps.order.statusUser &&
    prevProps.order.comment === nextProps.order.comment
  );
});
