import {
  getAccessToken,
  getRefreshToken,
  getTokenExpiresAt,
  getUserId,
  getUserRole,
  saveAccessToken,
  saveRefreshToken,
  saveTokenExpiresAt,
  saveUserId,
  saveUserRole,
  deleteAccessToken,
  deleteRefreshToken,
  deleteTokenExpiresAt,
  deleteUserId,
  deleteUserRole,
} from './storage';

import {
  Planning,
  Order,
  PriceType,
  PaymentType,
  Business,
  PricingCard,
  Cart,
  Program,
  OrderStatus,
  Room,
  RoomTable,
  Booking,
  BookingStatus,
} from '../../types';

const API = {
  _BASE_URL: 'https://erenapi.com',
  // _BASE_URL: 'http://192.168.0.122:3000',
  // _BASE_URL: 'http://192.168.1.10:3000',
  // _BASE_URL: 'http://192.168.1.9:3000',

  _ACCESS_TOKEN: '',
  _REFRESH_TOKEN: '',
  _TOKEN_EXPIRES_AT: 0,

  _USER_ID: '',
  _USER_ROLE: '',

  getUserId() {
    return this._USER_ID;
  },

  setUserId(userId: string) {
    this._USER_ID = userId;
  },

  getUserRole() {
    return this._USER_ROLE;
  },

  isOwner() {
    return this.getUserRole() === 'owner';
  },

  setUserRole(role: string) {
    this._USER_ROLE = role;
  },

  setAccessToken(accessToken: string) {
    this._ACCESS_TOKEN = accessToken;
  },

  setRefreshToken(refreshToken: string) {
    this._REFRESH_TOKEN = refreshToken;
  },

  setTokenExpiresAt(expiresAt: number | string) {
    if (typeof expiresAt === 'string') {
      expiresAt = parseInt(expiresAt, 10);
    }

    this._TOKEN_EXPIRES_AT = expiresAt;
  },

  _request(path: string, options: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(this._makeURL(path), options)
        .then((response) => response.json())
        .then(resolve)
        .catch((error) =>
          reject({
            networkError: error.message,
          })
        );
    });
  },

  async _requestWithAuth() {
    if (Date.now() > this._TOKEN_EXPIRES_AT) {
      const newTokens = await this._post('auth/token', {
        token: this._REFRESH_TOKEN,
      });

      this.setAccessToken(newTokens.accessToken);
      await saveAccessToken(newTokens.accessToken);

      this.setTokenExpiresAt(newTokens.expiresAt);
      await saveTokenExpiresAt(newTokens.expiresAt);
    }

    return {
      Authorization: 'Bearer ' + this._ACCESS_TOKEN,
    };
  },

  _post(
    path: string,
    body: Object,
    headers = {},
    options = { isFormData: false }
  ) {
    let requestHeaders: any = {};

    if (!options.isFormData) {
      requestHeaders['Content-Type'] = 'application/json';
    }

    requestHeaders = {
      ...requestHeaders,
      ...headers,
    };

    return this._request(path, {
      method: 'POST',
      body: options.isFormData ? body : JSON.stringify(body),
      headers: requestHeaders,
    });
  },

  async _postWithAuth(
    path: string,
    body: Object,
    headers = {},
    options = { isFormData: false }
  ) {
    const authHeaders = await this._requestWithAuth();
    return this._post(path, body, { ...headers, ...authHeaders }, options);
  },

  _get(path: string, headers = {}) {
    return this._request(path, { method: 'GET', headers });
  },

  async _getWithAuth(path: string, headers = {}) {
    const authHeaders = await this._requestWithAuth();
    return this._get(path, { ...headers, ...authHeaders });
  },

  _delete(path: string, headers = {}) {
    return this._request(path, { method: 'DELETE', headers });
  },

  async _deleteWithAuth(path: string, headers = {}) {
    const authHeaders = await this._requestWithAuth();
    return this._delete(path, { ...headers, ...authHeaders });
  },

  _put(path: string, body: Object, headers = {}) {
    return this._request(path, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', ...headers },
    });
  },

  async _putWithAuth(path: string, body: Object = {}, headers = {}) {
    const authHeaders = await this._requestWithAuth();
    return this._put(path, body, { ...headers, ...authHeaders });
  },

  _makeURL(path: string) {
    return encodeURI(this._BASE_URL + '/' + path);
  },

  locallyFetchTokens() {
    return new Promise(async (resolve, _) => {
      let accessToken: string | null;
      let refreshToken: string | null;
      let expiresAt: string | number | null;
      let userId: string | null;
      let userRole: string | null;

      try {
        accessToken = await getAccessToken();
        refreshToken = await getRefreshToken();
        expiresAt = await getTokenExpiresAt();
        userId = await getUserId();
        userRole = await getUserRole();

        if (expiresAt) {
          expiresAt = Number(expiresAt);
        }
      } catch (error) {
        console.warn(error);
      }

      // @ts-ignore
      if (accessToken && refreshToken && expiresAt && userId && userRole) {
        this.setAccessToken(accessToken);
        this.setRefreshToken(refreshToken);
        this.setTokenExpiresAt(expiresAt);
        this.setUserId(userId);
        this.setUserRole(userRole);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  },

  // -- Auth

  signIn(email: string, password: string): Promise<any> {
    // @ts-ignore
    return this._post('auth/login', { email, password }).then(
      async (response: {
        accessToken: string;
        refreshToken: string;
        expiresAt: number;
        userId: string;
        role: string;
      }) => {
        const { accessToken, refreshToken, expiresAt, userId, role } = response;

        this.setAccessToken(accessToken);
        await saveAccessToken(accessToken);

        this.setRefreshToken(refreshToken);
        await saveRefreshToken(refreshToken);

        this.setTokenExpiresAt(expiresAt);
        await saveTokenExpiresAt(expiresAt);

        this.setUserId(userId);
        await saveUserId(userId);

        this.setUserRole(role);
        await saveUserRole(role);
      }
    );
  },

  async signOut() {
    if (!this._REFRESH_TOKEN) {
      return;
    }

    return this._postWithAuth('auth/logout', {
      token: this._REFRESH_TOKEN,
    }).then(async () => {
      this.setAccessToken('');
      await deleteAccessToken();

      this.setRefreshToken('');
      await deleteRefreshToken();

      this.setTokenExpiresAt(0);
      await deleteTokenExpiresAt();

      this.setUserId('');
      await deleteUserId();

      this.setUserRole('');
      await deleteUserRole();
    });
  },

  checkEmailHasAccount(email: string) {
    return this._post('auth/check-email', { email }).then(
      (response: { user: Object }) => response.user
    );
  },

  signUpUser(user: {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    phoneNumber: string;
    gender: string;
  }) {
    return this._post('auth/signup/user', {
      user: {
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        gender: user.gender,
      },
    });
  },

  signUpOwner(data: {
    user: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      phoneNumberCountryCode: string;
    };
    business: {
      name: string;
      address: string;
      phoneNumber: string;
      phoneNumberCountryCode: string;
    };
    program?: {
      stampsCount: number;
      rewardType: string;
      rewardName: string;
      rewardDescription: string;
    };
  }) {
    const ownerData: any = {
      user: {
        email: data.user.email,
        password: data.user.password,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        phoneNumberCountryCode: data.user.phoneNumberCountryCode,
        phoneNumber: data.user.phoneNumber,
      },
      business: {
        name: data.business.name,
        address: data.business.address,
        phoneNumberCountryCode: data.business.phoneNumberCountryCode,
        phoneNumber: data.business.phoneNumber,
      },
    };

    if (data.program) {
      ownerData.program = {
        stampsCount: data.program.stampsCount,
        rewardType: data.program.rewardType,
        rewardName: data.program.rewardName,
        rewardDescription: data.program.rewardDescription,
      };
    }

    return this._post('auth/signup/owner', ownerData);
  },

  signUpEmployee(data: {
    businessId: string;
    user: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
    };
  }) {
    return this._postWithAuth('auth/signup/employee', {
      business: {
        id: data.businessId,
      },
      user: {
        email: data.user.email,
        password: data.user.password,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        phoneNumber: data.user.phoneNumber,
      },
    });
  },

  // -- Dashboard

  fetchAccountStatus(): Promise<{
    services: {
      website: {
        enabled: boolean;
        id?: string;
        slug: string;
      };
      bookings: {
        enabled: boolean;
        has_tables: boolean;
        has_planning: boolean;
      };
      orders: {
        enabled: boolean;
      };
      payment_method: {
        enabled: boolean;
        type?: 'paypal';
        client_id?: string;
      };
      contact_tracing: {
        enabled: boolean;
      };
    };
  }> {
    return this._getWithAuth('pricing/status');
  },

  // -- Business

  fetchBusinessById(businessId: string): Promise<Business> {
    return this._getWithAuth('businesses/' + businessId).then(
      (response) => response.business
    );
  },

  updateBusinessById(
    businessId: string,
    toUpdate: {
      name?: string;
      address?: string;
      primaryColor?: string;
      image?: string;
      phoneNumber?: string;
    }
  ) {
    return this._putWithAuth('businesses/' + businessId, {
      business: toUpdate,
    });
  },

  uploadBusinessImageForId(businessId: string, file: string | Blob) {
    const formData = new FormData();
    formData.append('image', file);
    return this._postWithAuth(
      `businesses/${businessId}/image`,
      formData,
      {},
      {
        isFormData: true,
      }
    ).then((res: { imageURL: string }) => res.imageURL);
  },

  fetchBusinessEmployees(businessId: string) {
    return this._getWithAuth(`businesses/${businessId}/employees`).then(
      (response: {
        employees: {
          id: string;
          firstName: string;
          lastName: string;
          email: string;
        }[];
      }) => response.employees
    );
  },

  deleteBusinessEmployee(businessId: string, employeeId: string) {
    return this._deleteWithAuth(
      `businesses/${businessId}/employees/${employeeId}`
    );
  },

  fetchBusinessProgramRewards(
    businessId: string
  ): Promise<Program | undefined> {
    return new Promise((resolve) => {
      this._getWithAuth(`businesses/${businessId}/program`)
        .then((response: { program: Program }) => resolve(response.program))
        .catch(() => resolve(undefined));
    });
  },

  // -- Reward

  fetchUserRewardsByBusinessId(businessId: string) {
    return this._getWithAuth('rewards/' + businessId).then(
      (response: { rewards: Object[] }) => response.rewards
    );
  },

  markRewardAsUsedById(rewardId: string) {
    return this._putWithAuth('rewards/' + rewardId + '/used');
  },

  // -- Program Stages

  createProgram(): Promise<string> {
    return this._postWithAuth(`programs`, {}).then(
      (response) => response.programId
    );
  },

  deleteProgramById(programId: string) {
    return this._deleteWithAuth(`programs/${programId}`);
  },

  deactivateReward(rewardId: string) {
    return this._getWithAuth('program-stages/' + rewardId + '/deactivate');
  },

  activateReward(rewardId: string) {
    return this._getWithAuth('program-stages/' + rewardId + '/activate');
  },

  createLoyaltyStageForProgram(programId: string, stage: Object) {
    return this._postWithAuth('program-stages/' + programId, { stage });
  },

  updateLoyaltyStageById(rewardId: string, stage: Object) {
    return this._putWithAuth('program-stages/' + rewardId, { stage });
  },

  // -- User

  fetchUserProfile() {
    return this._getWithAuth('user/me').then(
      (response: {
        user: {
          email: string;
          firstName: string;
          lastName: string;
          phoneNumber: string;
          businesses: {
            id: string;
            name: string;
            phoneNumber: string;
            activated: boolean;
          }[];
        };
      }) => response.user
    );
  },

  updateUserProfile(toUpdate: {
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    phoneNumber?: string;
  }) {
    return this._putWithAuth('user/me', toUpdate);
  },

  // -- User Card

  fetchUserCards() {
    return this._getWithAuth('cards/me').then(
      (response: { cards: Object[] }) => response.cards
    );
  },

  fetchUserCardById(cardId: string) {
    return this._getWithAuth('cards/' + cardId);
  },

  createCardForBusiness(businessId: string) {
    return this._postWithAuth('cards', { businessId });
  },

  deleteCardById(cardId: string) {
    return this._deleteWithAuth('cards/' + cardId);
  },

  // -- Stamp

  addStampsToCard(
    userId: string,
    cardId: string,
    stampsCount: number,
    shouldCreateRewards = true
  ) {
    return this._postWithAuth('stamps', {
      userId,
      cardId,
      stampsCount,
      shouldCreateRewards,
    });
  },

  stampsCountFromTimeForCard(
    userId: string,
    cardId: string,
    startingTime: number
  ) {
    return this._postWithAuth('stamps/count-from', {
      userId,
      cardId,
      startingTime,
    }).then((response) => response.count);
  },

  // -- Statistics

  fetchUserBusinessStatistics() {
    return this._getWithAuth('statistics/business').then(
      (response: { statistics: Object }) => response.statistics
    );
  },

  // -- Search

  searchBusinessesByQuery(query: string) {
    return this._getWithAuth('search?query=' + query).then(
      (response: { results: Object[] }) => response.results
    );
  },

  // -- Pricing

  fetchPricingBySlug(slug: string) {
    return this._get(`pricing/cards/${slug}`).then(
      (response: { card: PricingCard; business: Business }) => ({
        card: response.card,
        business: response.business,
      })
    );
  },

  fetchPricingByBusinessId(businessId: string) {
    return this._get(`pricing/business/${businessId}/cards`).then(
      (response: { card: PricingCard; business: Business }) => ({
        card: response.card,
        business: response.business,
      })
    );
  },

  createPricingForBusiness(
    businessId: string,
    slug: string,
    baseCurrency: string
  ) {
    return this._postWithAuth(`pricing/cards`, {
      slug,
      baseCurrency,
      businessId,
    }).then((response) => response.cardId);
  },

  updatePricingById(
    pricingId: string,
    toUpdate: {
      slug?: string;
      baseCurrency?: string;
      canBook?: boolean;
      canOrder?: boolean;
      paymentType?: PaymentType;
      paymentClientId?: string;
      paymentClientSecret?: string;
    }
  ) {
    return this._putWithAuth(`pricing/cards/${pricingId}`, toUpdate);
  },

  deletePricingPaymentMethod(pricingId: string) {
    return this._deleteWithAuth(`pricing/cards/${pricingId}/payment-method`);
  },

  checkBusinessHasPricing(businessId: string) {
    return this._getWithAuth(`pricing/business/${businessId}/check`).then(
      (response) => response.hasPricing
    );
  },

  createCategoryForPricing(
    pricingId: string,
    categoryName: string,
    categoryDescription?: string
  ) {
    const data: { name: string; description?: string } = { name: categoryName };

    if (typeof categoryDescription === 'string') {
      data.description = categoryDescription;
    }

    return this._postWithAuth(
      `pricing/cards/${pricingId}/categories`,
      data
    ).then((response) => String(response.categoryId));
  },

  deletePricingCategory(pricingId: string, categoryId: string) {
    return this._deleteWithAuth(
      `pricing/cards/${pricingId}/categories/${categoryId}`
    );
  },

  deletePricing(pricingId: string) {
    return this._deleteWithAuth(`pricing/cards/${pricingId}`);
  },

  updatePricingCategoryById(
    pricingId: string,
    categoryId: string,
    toUpdate: { name?: string; description?: string }
  ) {
    return this._putWithAuth(
      `pricing/cards/${pricingId}/categories/${categoryId}`,
      toUpdate
    );
  },

  createPricingCategoryItem(
    pricingId: string,
    categoryId: string,
    data: {
      name: string;
      description?: string;
      priceType?: PriceType;
      price?: string;
    }
  ) {
    return this._postWithAuth(
      `pricing/cards/${pricingId}/categories/${categoryId}/items`,
      data
    ).then((response) => ({ itemId: response.itemId, order: response.order }));
  },

  updatePricingItemById(
    pricingId: string,
    categoryId: string,
    itemId: string,
    data: {
      name?: string;
      description?: string;
      priceType?: PriceType;
      price?: string;
    }
  ) {
    return this._putWithAuth(
      `pricing/cards/${pricingId}/categories/${categoryId}/items/${itemId}`,
      data
    );
  },

  uploadPricingItemImage(
    pricingId: string,
    categoryId: string,
    itemId: string,
    file: File
  ) {
    const formData = new FormData();
    formData.append('image', file);
    return this._postWithAuth(
      `pricing/cards/${pricingId}/categories/${categoryId}/items/${itemId}/image`,
      formData,
      {},
      { isFormData: true }
    ).then((response) => response.imageURL);
  },

  updatePricingCategoryItemsOrder(
    pricingId: string,
    categoryId: string,
    order: [string, number][]
  ) {
    return this._putWithAuth(
      `pricing/cards/${pricingId}/categories/${categoryId}/items-order`,
      { order }
    );
  },

  deletePricingItem(pricingId: string, categoryId: string, itemId: string) {
    return this._deleteWithAuth(
      `pricing/cards/${pricingId}/categories/${categoryId}/items/${itemId}`
    );
  },

  getPricingQRCodeLink(slug: string, language: string) {
    return `${this._BASE_URL}/pricing/qrcode/${language}-${slug}.pdf`;
  },

  createPricingItemOptionCategory(
    pricingId: string,
    itemId: string,
    category: { name: string; description?: string; tickCount: number }
  ): Promise<{ categoryId: number }> {
    return this._postWithAuth(
      `pricing/cards/${pricingId}/items/${itemId}/options/category`,
      category
    );
  },

  updatePricingItemOptionCategoryById(
    pricingId: string,
    itemId: string,
    categoryId: string,
    toUpdate: { name?: string; description?: string; tickCount?: number }
  ) {
    return this._putWithAuth(
      `pricing/cards/${pricingId}/items/${itemId}/options/category/${categoryId}`,
      toUpdate
    );
  },

  deletePricingItemOptionCategory(
    pricingId: string,
    itemId: string,
    categoryId: string
  ) {
    return this._deleteWithAuth(
      `pricing/cards/${pricingId}/items/${itemId}/options/category/${categoryId}`
    );
  },

  createPricingItemOption(
    pricingId: string,
    itemId: string,
    categoryId: number,
    option: {
      name: string;
      description?: string;
      price?: number;
      isDefault: boolean;
    }
  ): Promise<{ optionId: number }> {
    return this._postWithAuth(
      `pricing/cards/${pricingId}/items/${itemId}/options/category/${categoryId}/options`,
      option
    );
  },

  updatePricingItemOptionById(
    pricingId: string,
    itemId: string,
    categoryId: string,
    optionId: string,
    toUpdate: {
      name?: string;
      description?: string;
      price?: number;
      isDefault?: boolean;
    }
  ) {
    return this._putWithAuth(
      `pricing/cards/${pricingId}/items/${itemId}/options/category/${categoryId}/options/${optionId}`,
      toUpdate
    );
  },

  deletePricingItemOption(
    pricingId: string,
    itemId: string,
    categoryId: string,
    optionId: string
  ) {
    return this._deleteWithAuth(
      `pricing/cards/${pricingId}/items/${itemId}/options/category/${categoryId}/options/${optionId}`
    );
  },

  // -- Planning

  fetchPlanningByBusinessId(businessId: string) {
    return this._get(`pricing/planning/business/${businessId}`).then(
      (response: { planning: { slots: Planning } }) => response.planning.slots
    );
  },

  createPlanningForBusiness(businessId: string, planning: Planning) {
    return this._postWithAuth('pricing/planning', { businessId, planning });
  },

  deletePlanningByBusinessId(businessId: string) {
    return this._deleteWithAuth(`pricing/planning/business/${businessId}`);
  },

  // -- Orders

  fetchOrderByBusinessId(businessId: string) {
    return this._getWithAuth(`pricing/orders/business/${businessId}`).then(
      (response: { orders: Order[] }) => response.orders
    );
  },

  fetchOrderByReference(
    referenceNumber: string,
    referenceToken: string,
    asOwner: boolean = false
  ) {
    return this[asOwner ? '_getWithAuth' : '_get'](
      `pricing/orders/${referenceNumber}/${referenceToken}`
    ).then((response: { order: Order }) => response.order);
  },

  createOrderForBusiness(
    businessId: string,
    language: string,
    order: {
      contactPhoneCountryCode: string;
      contactPhone: string;
      contactEmail: string;
      contactFirstName: string;
      contactLastName: string;
      comment?: string;
      items: Cart;
      isClickAndCollect: boolean;
    },
    orderId?: string
  ): Promise<{ referenceNumber: string; referenceToken: string }> {
    return this._post(`pricing/orders/business/${businessId}`, {
      order,
      orderId,
      lang: language,
    });
  },

  fetchOrders(
    businessId: string,
    options: { from?: number; to?: number } = {}
  ): Promise<{ orders: Order[]; nextCursor: number }> {
    let extraQuery = '';
    if (options.from || options.to) {
      const fromToOptions = [];

      if (options.from) {
        fromToOptions.push(`from=${options.from}`);
      }

      if (options.to) {
        fromToOptions.push(`to=${options.to}`);
      }

      extraQuery += `?${fromToOptions.join('&')}`;
    }

    return this._getWithAuth(
      `pricing/orders/business/${businessId}/${extraQuery}`
    );
  },

  updateOrderStatus(
    businessId: string,
    referenceNumber: string,
    referenceToken: string,
    type: 'owner' | 'user',
    status: OrderStatus
  ): Promise<{}> {
    return this._putWithAuth(
      `pricing/orders/${referenceNumber}/${referenceToken}/status/${type}`,
      {
        status,
        business: {
          id: businessId,
        },
      }
    );
  },

  // -- Rooms

  fetchBusinessRooms(businessId: string) {
    return this._getWithAuth(`pricing/${businessId}/rooms`).then(
      (response: { rooms: Room[] }) => response.rooms
    );
  },

  createRoomForBusiness(businessId: string, room: Room) {
    return this._postWithAuth(`pricing/${businessId}/rooms`, room).then(
      (response: { roomId: string }) => response.roomId
    );
  },

  updateRoomById(businessId: string, roomId: string, room: Room) {
    return this._putWithAuth(`pricing/${businessId}/rooms/${roomId}`, room);
  },

  deleteRoomById(businessId: string, roomId: string) {
    return this._deleteWithAuth(`pricing/${businessId}/rooms/${roomId}`);
  },

  createTableForRoom(businessId: string, roomId: string, table: RoomTable) {
    return this._postWithAuth(
      `pricing/${businessId}/rooms/${roomId}/table`,
      table
    ).then((response: { tableId: string }) => response.tableId);
  },

  updateTableById(
    businessId: string,
    roomId: string,
    tableId: string,
    table: RoomTable
  ) {
    return this._putWithAuth(
      `pricing/${businessId}/rooms/${roomId}/table/${tableId}`,
      table
    );
  },

  deleteTableById(businessId: string, roomId: string, tableId: string) {
    return this._deleteWithAuth(
      `pricing/${businessId}/rooms/${roomId}/table/${tableId}`
    );
  },

  // -- Bookings

  fetchBookings(
    businessId: string,
    options: { from?: number; to?: number } = {}
  ): Promise<{ bookings: Booking[]; nextCursor: number }> {
    let extraQuery = '';
    if (options.from || options.to) {
      const fromToOptions = [];

      if (options.from) {
        fromToOptions.push(`from=${options.from}`);
      }

      if (options.to) {
        fromToOptions.push(`to=${options.to}`);
      }

      extraQuery += `?${fromToOptions.join('&')}`;
    }

    return this._getWithAuth(`pricing/${businessId}/bookings${extraQuery}`);
  },

  fetchAvailableBookingsForDate(
    businessId: string,
    date: Date
  ): Promise<{
    possibleSeats: number[];
    timeSlots: { [seats: string]: number[] };
  }> {
    return this._get(`pricing/${businessId}/bookings/date?date=${date}`);
  },

  fetchAvailableTablesNow(
    businessId: string,
    guests: number
  ): Promise<[RoomTable, boolean][]> {
    return this._getWithAuth(
      `pricing/${businessId}/bookings/tables?guests=${guests}&from=${new Date()}`
    ).then((response: { tables: [RoomTable, boolean][] }) => response.tables);
  },

  createBookingForBusiness(
    businessId: string,
    language: string,
    booking: {
      firstName: string;
      lastName: string;
      email: string;
      phoneNumberCountryCode: string;
      phoneNumber: string;
      comment?: string;
      quantity: number;
      date: number;
      timeSlot: number;
    }
  ): Promise<{ referenceNumber: string; referenceToken: string }> {
    return this._postWithAuth(`pricing/${businessId}/bookings`, {
      booking,
      lang: language,
    });
  },

  createWalkInForBusiness(
    businessId: string,
    booking: {
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumberCountryCode?: string;
      phoneNumber?: string;
      comment?: string;
      quantity: number;
      date?: number;
      timeSlot?: number;
      tableId: string;
    }
  ): Promise<{ referenceNumber: string; referenceToken: string }> {
    return this._postWithAuth(`pricing/${businessId}/bookings/walkin`, {
      booking,
    });
  },

  updateBookingTable(
    businessId: string,
    referenceNumber: string,
    tableId: string
  ): Promise<{}> {
    return this._putWithAuth(
      `pricing/${businessId}/bookings/${referenceNumber}/table`,
      {
        tableId,
      }
    );
  },

  updateBookingStatus(
    businessId: string,
    referenceNumber: string,
    referenceToken: string,
    type: 'owner' | 'user',
    status: BookingStatus
  ): Promise<{}> {
    return this._putWithAuth(
      `pricing/${businessId}/bookings/${referenceNumber}/${referenceToken}/status/${type}`,
      { status }
    );
  },

  // -- Contact tracing

  createContactTracingSettings(businessId: string) {
    return this._postWithAuth(`contact-tracing/${businessId}/settings`, {});
  },

  fetchContactTracingSettings(
    businessId: string
  ): Promise<{ averageVisitDuration: number }> {
    return this._getWithAuth(`contact-tracing/${businessId}/settings`).then(
      (response) => response.settings
    );
  },

  fetchContactTracingStatistics(
    businessId: string
  ): Promise<{
    history: [string, number][];
  }> {
    return this._getWithAuth(`contact-tracing/${businessId}/statistics`);
  },

  updateContactTracingSettings(
    businessId: string,
    toUpdate: { averageVisitDuration?: number } = {}
  ) {
    return this._putWithAuth(
      `contact-tracing/${businessId}/settings`,
      toUpdate
    );
  },

  createContactInformation(
    businessId: string,
    contactInformation: {
      firstName: string;
      lastName: string;
      phoneNumber: string;
      phoneNumberCountryCode: string;
      time: string;
    }
  ) {
    return this._post(`contact-tracing/${businessId}`, contactInformation);
  },

  getContactTracingQRCodeLink(slug: string, language: string) {
    return `${this._BASE_URL}/pricing/qrcode/tracing/${language}-${slug}.pdf`;
  },
};

export default API;
