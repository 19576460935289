import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { ScrollView, StyleSheet } from 'react-native';
import Block from '../../components/Block/Block';
import { PricingStackParamList } from '../../navigators/PricingNavigator';
import AddContactInformationForm from './AddContactInformationForm';
import { useDocumentTitle } from '../../hooks/document';
import { t } from '../../helpers/translation';
import Link from '../../components/Link/Link';

type AddContactInformationScreenProps = {
  route: RouteProp<PricingStackParamList, 'ContactTracing'>;
};

const AddContactInformationScreen = (
  props: AddContactInformationScreenProps
) => {
  const slug = props.route.params.pricingSlug;

  if (!slug) {
    return null;
  }

  useDocumentTitle(t('screens.addContactInformation.title'));

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Block style={styles.form}>
        <AddContactInformationForm slug={slug} />
      </Block>

      <Link to="https://illicarte.com" target="_blank">
        {t('screens.pricing.links.createdWith')}
      </Link>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  form: {
    marginBottom: 20,
  },
});

export default AddContactInformationScreen;
