import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useRecoilState, useSetRecoilState } from 'recoil';
import HomeScreen from '../screens/HomeScreen';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import API from '../helpers/api';
import {
  isLoggedInState,
  businessNameState,
  businessIdState,
} from '../store/state';
import { t } from '../helpers/translation';
import OrderDetailsScreen from '../screens/OrderDetailsScreen';
import UserNavigator from './UserNavigator';
import SignOutScreen from '../screens/SignOutScreen';
import JoinNavigator from './JoinNavigator';
import AuthNavigator from './AuthNavigator';
import ProductOrdersScreen from '../screens/ProductOrdersScreen';
import PricingNavigator from './PricingNavigator';
import PrivacyPolicyScreen from '../screens/plus-one/PrivacyPolicyScreen';
import TermsConditionsScreen from '../screens/plus-one/TermsConditionsScreen';
import ProductMembershipsScreen from '../screens/ProductMembershipsScreen';
import { JobType } from '../constants/job-features';
import ProductBookingsScreen from '../screens/ProductBookingsScreen';
import ProductContactTracingScreen from '../screens/ProductContactTracingScreen';
import ProductPricingScreen from '../screens/ProductPricingScreen';

type Props = {};

export type AppStackParamList = {
  // Plus One
  PlusOnePrivacyPolicyScreen: undefined;
  PlusOneTermsConditionsScreen: undefined;

  // Illicarte
  Home: undefined;
  Join: undefined;
  OrderDetails: {
    referenceNumber: string;
    referenceToken: string;
  };
  Pricing: undefined;
  ProductBookings: {
    job?: JobType;
  };
  ProductContactTracing: {
    job?: JobType;
  };
  ProductMemberships: {
    job?: JobType;
  };
  ProductOrders: {
    job?: JobType;
  };
  ProductPricing: undefined;
  Settings: undefined;
  SignIn: undefined;
  SignedIn: undefined;
  SignOut: undefined;

  Toast: undefined;
};

const Drawer = createDrawerNavigator<AppStackParamList>();

const AppNavigator = (props: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const setBusinessId = useSetRecoilState(businessIdState);
  const setBusinessName = useSetRecoilState(businessNameState);

  useEffect(() => {
    API.locallyFetchTokens().then(async (hasTokens) => {
      if (hasTokens) {
        const profile: any = await API.fetchUserProfile();

        if (profile.businesses.length !== 0) {
          const business = profile.businesses[0];
          setBusinessId(business.id);
          setBusinessName(business.name);
        }

        setIsLoggedIn(true);
      }
    });
  }, []);

  return (
    <Drawer.Navigator
      drawerType="slide"
      drawerContent={(props) => <NavigationBar {...props} />}
      drawerStyle={styles.container}
    >
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
        options={{ drawerLabel: t('navigation.home') }}
      />

      <Drawer.Screen
        name="ProductPricing"
        component={ProductPricingScreen}
        options={{
          drawerLabel: t('navigation.productPricing'),
        }}
      />

      {isLoggedIn ? (
        <>
          <Drawer.Screen
            name="SignedIn"
            component={UserNavigator}
            options={{
              drawerLabel: t('navigation.dashboard'),
            }}
          />

          <Drawer.Screen
            name="SignOut"
            component={SignOutScreen}
            options={{
              drawerLabel: t('navigation.signOut'),
            }}
          />
        </>
      ) : (
        <>
          <Drawer.Screen
            name="Join"
            component={JoinNavigator}
            options={{ drawerLabel: t('navigation.join') }}
          />

          <Drawer.Screen
            name="SignIn"
            component={AuthNavigator}
            options={{
              drawerLabel: t('navigation.signIn'),
            }}
          />
        </>
      )}

      <Drawer.Screen name="OrderDetails" component={OrderDetailsScreen} />

      <Drawer.Screen name="ProductOrders" component={ProductOrdersScreen} />

      <Drawer.Screen name="ProductBookings" component={ProductBookingsScreen} />

      <Drawer.Screen
        name="ProductContactTracing"
        component={ProductContactTracingScreen}
      />

      <Drawer.Screen
        name="ProductMemberships"
        component={ProductMembershipsScreen}
      />

      <Drawer.Screen
        name="PlusOnePrivacyPolicyScreen"
        component={PrivacyPolicyScreen}
      />

      <Drawer.Screen
        name="PlusOneTermsConditionsScreen"
        component={TermsConditionsScreen}
      />

      <Drawer.Screen
        name="Pricing"
        component={PricingNavigator}
        options={{
          drawerLabel: t('navigation.pricing'),
        }}
      />
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 240,
  },
});

export default AppNavigator;
