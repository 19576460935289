import React from 'react';
import { GestureResponderEvent, ViewProps } from 'react-native';
import Pill from '../Pill/Pill';

export type DeleteButtonProps = {
  type?: 'trash' | 'minus';
  title?: string;
  onDelete: (event: GestureResponderEvent) => void;
  style?: ViewProps['style'];
};

const DeleteButton = (props: DeleteButtonProps) => {
  return (
    <Pill
      type="danger"
      icon={props.type === 'minus' ? 'minus' : 'trash-2'}
      title={props.title}
      onPress={props.onDelete}
      style={props.style}
    />
  );
};

export default DeleteButton;
