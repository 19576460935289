import React, { useState, useEffect } from 'react';
import TextInput from '../TextInput/TextInput';
import { View, StyleSheet, ViewProps } from 'react-native';
import { t } from '../../helpers/translation';
import { PriceType } from '../../../types';

type Props = {
  value?: string;
  onChangeValue: (price: string) => void;
  priceType: PriceType;
  style?: ViewProps['style'];
};

type PriceRangeInputProps = {
  value?: string;
  onChangeValue: (price: string) => void;
  style?: ViewProps['style'];
};

const PriceRangeInput = (props: PriceRangeInputProps) => {
  const value = (props.value ?? '').split('-');
  const [rangeFrom, setRangeFrom] = useState(value[0] ?? '');
  const [rangeTo, setRangeTo] = useState(value[1] ?? '');

  const onChangeRange = () => {
    props.onChangeValue(`${rangeFrom}-${rangeTo}`);
  };

  return (
    <View style={[styles.priceRange, props.style]}>
      <TextInput
        type="number"
        editable
        placeholder={t('components.priceRangeInput.placeholders.priceFrom')}
        value={rangeFrom}
        onChangeText={setRangeFrom}
        onBlur={onChangeRange}
        inline
        style={[styles.priceRangeInput, styles.priceFromInput]}
      />

      <TextInput
        type="number"
        editable
        placeholder={t('components.priceRangeInput.placeholders.priceUpTo')}
        value={rangeTo}
        onChangeText={setRangeTo}
        onBlur={onChangeRange}
        inline
        style={styles.priceRangeInput}
      />
    </View>
  );
};

const PriceInput = (props: Props) => {
  const [value, setValue] = useState(props.value ?? '');

  useEffect(() => setValue(props.value ?? ''), [props.value]);

  if (props.priceType === 'range') {
    return (
      <PriceRangeInput
        value={props.value}
        onChangeValue={props.onChangeValue}
        style={props.style}
      />
    );
  }

  return (
    <TextInput
      editable
      placeholder={
        props.priceType === 'other'
          ? t('components.priceInput.placeholders.typeText')
          : t('components.priceInput.placeholders.price')
      }
      value={value}
      onChangeText={setValue}
      onBlur={() => props.onChangeValue(value)}
      type={props.priceType === 'other' ? 'text' : 'number'}
      style={props.style}
    />
  );
};

const styles = StyleSheet.create({
  priceRange: {
    flexDirection: 'row',
  },
  priceRangeInput: {
    flex: 1,
  },
  priceFromInput: {
    marginRight: 10,
  },
});

export default PriceInput;
