import React from 'react';
import { StyleSheet, View } from 'react-native';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';

type LoaderContainerProps = {};

const LoaderContainer = (props: LoaderContainerProps) => {
  return (
    <View style={styles.container}>
      <ActivityIndicator />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default LoaderContainer;
