import React from 'react';
import {
  Picker as RNPicker,
  PickerProps as RNPickerProps,
  StyleSheet,
} from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import { DarkTheme, DefaultTheme } from '@react-navigation/native';
import { webOnly } from '../../helpers/style';

export type PickerProps = RNPickerProps & {
  children: any;
};

const Picker = ({ style, ...props }: PickerProps) => {
  const scheme = useColorScheme();

  return (
    <RNPicker
      {...props}
      style={[
        styles.container,
        scheme === 'dark' && styles.containerDark,
        style,
      ]}
    />
  );
};

Picker.Item = RNPicker.Item;

const styles = StyleSheet.create({
  container: {
    backgroundColor: DefaultTheme.colors.card,
    borderWidth: 2,
    borderColor: DefaultTheme.colors.border,
    padding: 8,
    color: DefaultTheme.colors.text,
    ...webOnly({
      cursor: 'pointer',
      appearance: 'none',
    }),
  },
  containerDark: {
    color: DarkTheme.colors.text,
    borderColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
});

export default Picker;
