import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { View, StyleSheet, Clipboard } from 'react-native';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';
// @ts-ignore
import color from 'color';
// @ts-ignore
import I18n from 'i18n-js';
import {
  categoriesOrderState,
  pricingIdState,
  categoriesState,
  businessIdState,
  pricingSlugState,
} from '../store/state';
import CategoryContainer from '../containers/CategoryContainer';
import TextInput from '../components/TextInput/TextInput';
import Button from '../components/Button/Button';
import ItemsContainer from '../containers/ItemsContainer';
import Block from '../components/Block/Block';
import Feature from '../components/Feature/Feature';
import API from '../helpers/api';
import { useIsWideScreen } from '../hooks/responsive';
import { formatLocale } from '../helpers/format';
import { t } from '../helpers/translation';
import Theme from '../helpers/theme';
import Hint from '../components/Hint/Hint';
import H2 from '../components/H2/H2';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';

type Props = {};

const spreadWordSVG = require('../../assets/spread_word.svg');

const EditPricingForm = (props: Props) => {
  const [canLoad, setCanLoad] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const isWideScreen = useIsWideScreen();

  const setCategory = useSetRecoilState(categoriesState(categoryId));
  const pricingId = useRecoilValue(pricingIdState);
  const pricingSlug = useRecoilValue(pricingSlugState);
  const resetPricingSlug = useResetRecoilState(pricingSlugState);
  const businessId = useRecoilValue(businessIdState);
  const [categoriesOrder, setCategoriesOrder] = useRecoilState(
    categoriesOrderState
  );

  useEffect(() => {
    if (categoryId) {
      setCategoriesOrder((categoriesOrder) => [...categoriesOrder, categoryId]);
      setCategory({ name: categoryName, description: categoryDescription });
      setCategoryName('');
      setCategoryDescription('');
    }
  }, [categoryId]);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => setCanLoad(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const handleAddCategory = useCallback(async () => {
    setIsCreating(true);

    try {
      const categoryId = await API.createCategoryForPricing(
        pricingId,
        categoryName,
        categoryDescription
      );

      setCategoryId(categoryId);
    } catch (e) {}

    setIsCreating(false);
  }, [pricingId, categoryName, categoryDescription]);

  // const handleDeletePricing = useCallback(async () => {
  //   if (confirm(t('forms.editPricing.alerts.deletePricing'))) {
  //     setIsDeleting(true);

  //     try {
  //       await API.deletePricing(pricingId);
  //     } catch (e) {}

  //     setIsDeleting(false);
  //     resetPricingSlug();
  //   }
  // }, [pricingId]);

  if (!canLoad) {
    return <LoaderContainer />;
  }

  return (
    <>
      {categoriesOrder.map((categoryId) => (
        <View key={categoryId}>
          <CategoryContainer id={categoryId} editable />
          <ItemsContainer categoryId={categoryId} editable />
        </View>
      ))}

      <Block verticalMargins style={styles.addCategoryContainer}>
        <H2>{t('forms.editPricing.buttons.addCategory')}</H2>

        <Hint style={styles.hint}>
          {t('forms.editPricing.buttons.addCategoryHint')}
        </Hint>

        <TextInput
          editable
          required
          value={categoryName}
          onChangeText={setCategoryName}
          label={t('components.category.placeholders.name')}
          style={styles.input}
        />

        <TextInput
          editable
          value={categoryDescription}
          onChangeText={setCategoryDescription}
          label={t('components.category.placeholders.description')}
          style={styles.input}
        />

        <Button
          icon="plus"
          disabled={!categoryName}
          title={t('components.category.buttons.create')}
          onPress={handleAddCategory}
          busy={isCreating}
          style={styles.button}
        />
      </Block>

      <Feature
        title={t('forms.editPricing.banners.share.title')}
        subtitle={t('forms.editPricing.banners.share.subtitle')}
        image={isWideScreen && spreadWordSVG}
        backgroundColor={color(Theme.ORANGE).lighten(0.2)}
        textColor="black"
        actions={[
          {
            label: t('forms.editPricing.banners.share.actions.copyLink'),
            onPress() {
              Clipboard.setString(`https://illicarte.com/${pricingSlug}`);
            },
          },
          {
            label: t('forms.editPricing.banners.share.actions.getQrCodePDF'),
            onPress() {
              window.open(
                API.getPricingQRCodeLink(
                  pricingSlug,
                  formatLocale(I18n.currentLocale())
                ),
                '_blank'
              );
            },
          },
        ]}
        style={[styles.bannerShare, isWideScreen && styles.bannerShareWide]}
      />
    </>
  );
};

const styles = StyleSheet.create({
  addCategoryContainer: {
    marginTop: 0,
  },
  input: {
    marginTop: 10,
  },
  button: {
    marginTop: 20,
  },
  inputLarge: {
    width: '100%',
  },
  hint: {
    marginTop: 5,
  },
  bannerShare: {
    paddingVertical: 20,
    marginBottom: 20,
  },
  bannerShareWide: {
    height: 200,
  },
});

export default EditPricingForm;
