import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import JoinScreen from '../screens/JoinScreen';
import MenuIcon from '../components/MenuIcon/MenuIcon';
import { t } from '../helpers/translation';

type Props = {};

export type JoinStackParamList = {
  Join: undefined;
};

const Stack = createStackNavigator<JoinStackParamList>();

const JoinNavigator = (props: Props) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Join"
        component={JoinScreen}
        options={{
          title: t('navigation.join'),
          headerLeft: () => <MenuIcon />,
        }}
      />
    </Stack.Navigator>
  );
};

export default JoinNavigator;
