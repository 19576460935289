import React from 'react';

export type YoutubePlayerProps = {
  videoKey: string;
};

const YoutubePlayer = (props: YoutubePlayerProps) => {
  return (
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${props.videoKey}?autoplay=1&modestbranding=1&cc_load_policy=1&cc_lang_pref=en`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{
        maxWidth: '100%',
      }}
    />
  );
};

export default YoutubePlayer;
