import React from 'react';
import { useColorScheme } from 'react-native-appearance';
import { View, StyleSheet, ViewProps } from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';

export type ItemSeparatorProps = {
  noMargins?: boolean;
  style?: ViewProps['style'];
};

const ItemSeparator = (props: ItemSeparatorProps) => {
  const scheme = useColorScheme();

  return (
    <View
      style={[
        styles.container,
        scheme === 'dark' && styles.containerDark,
        props.noMargins && styles.containerNoMargins,
        props.style,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
    marginVertical: 12,
  },
  containerNoMargins: {
    marginVertical: 0,
  },
  containerDark: {
    borderBottomColor: DarkTheme.colors.border,
  },
});

export default ItemSeparator;
