import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';
import moment from 'moment';
import { RoomTable, Booking } from '../../../types';
import Text from '../Text/Text';
import { t } from '../../helpers/translation';
import { computeBookingsOverlap } from '../../helpers/compute';

type TableBookingsProps = {
  table: RoomTable;
  bookings: Booking[];
  hourColumnWidth: number;
  tableRowHeight: number;
  onPressBooking: (booking: Booking) => void;
};

const TableBookings = (props: TableBookingsProps) => {
  let maxOverlap = 0;
  const tableBookings = props.bookings.map((booking, index, bookings) => {
    const otherBookingsOverlap = computeBookingsOverlap(
      booking,
      bookings.slice(0, index)
    );

    const bookingStart = moment.utc(booking.startAt);
    const bookingEnd = moment.utc(booking.endAt);

    const top = otherBookingsOverlap * props.tableRowHeight;
    const left =
      bookingStart.hours() * props.hourColumnWidth +
      (bookingStart.minutes() / 60) * props.hourColumnWidth;
    const leftEnd =
      bookingEnd.hours() * props.hourColumnWidth +
      (bookingEnd.minutes() / 60) * props.hourColumnWidth;
    const width = leftEnd - left;

    maxOverlap = Math.max(otherBookingsOverlap, maxOverlap);

    return (
      <TouchableOpacity
        key={booking.referenceNumber}
        onPress={() => props.onPressBooking(booking)}
        style={{
          position: 'absolute',
          opacity: booking.statusOwner === 'cancelled' ? 0.5 : 1,
          zIndex: 1,
          backgroundColor: DefaultTheme.colors.card,
          height: props.tableRowHeight,
          width,
          left,
          top,
          justifyContent: 'center',
          paddingHorizontal: 10,
          borderWidth: 1,
          borderColor: DefaultTheme.colors.border,
          borderTopWidth: 0,
          borderStyle: booking.statusOwner === 'cancelled' ? 'dashed' : 'solid',
        }}
      >
        <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
          {booking.walkIn
            ? t('common.walkIn')
            : `${booking.contactFirstName} ${booking.contactLastName}`}
        </Text>

        <Text style={{ fontSize: 12 }}>
          {`${moment.utc(booking.startAt).format('LT')}-${moment
            .utc(booking.endAt)
            .format('LT')} · `}

          {booking.quantity === 1
            ? t('components.makeBooking.labels.onePerson')
            : t('components.makeBooking.labels.manyPeople', {
                count: booking.quantity,
              })}
        </Text>
      </TouchableOpacity>
    );
  });

  return (
    <View
      style={{
        position: 'relative',
        backgroundColor: DefaultTheme.colors.background,
        width: props.hourColumnWidth * 24,
        height: (maxOverlap + 1) * props.tableRowHeight,
      }}
    >
      {tableBookings}

      {Array(maxOverlap + 1)
        .fill(1)
        .map((_value, index) => (
          <View key={index} style={{ flexDirection: 'row' }}>
            {Array(24)
              .fill(1)
              .map((_value, index) => (
                <View
                  key={index}
                  style={{
                    flex: 1,
                    borderWidth: 1,
                    borderColor: DefaultTheme.colors.border,
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    height: props.tableRowHeight,
                  }}
                />
              ))}
          </View>
        ))}
    </View>
  );
};

const styles = StyleSheet.create({});

export default React.memo(TableBookings, (prevProps, nextProps) => {
  return (
    prevProps.tableRowHeight === nextProps.tableRowHeight &&
    prevProps.hourColumnWidth === nextProps.hourColumnWidth &&
    prevProps.table.id === nextProps.table.id &&
    prevProps.bookings
      .map(
        (booking) =>
          booking.referenceNumber + booking.statusUser + booking.statusOwner
      )
      .join('') ===
      nextProps.bookings
        .map(
          (booking) =>
            booking.referenceNumber + booking.statusUser + booking.statusOwner
        )
        .join('')
  );
});
