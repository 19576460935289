import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
// @ts-ignore
import { useFonts, Trochut_700Bold } from '@expo-google-fonts/trochut';
import Text from '../Text/Text';

export type LogoProps = {
  style?: ViewProps['style'];
};

const Logo = (props: LogoProps) => {
  const [fontsLoaded] = useFonts({ Trochut_700Bold });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View style={[styles.container, props.style]}>
      <Text style={styles.text}>illicarte</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Trochut_700Bold',
    fontWeight: 'bold',
    fontSize: 24,
  },
});

export default Logo;
