import React from 'react';
import { ScrollView, View, ScrollViewProps, StyleSheet } from 'react-native';
import { useRecoilValue } from 'recoil';
import P from '../P/P';
import { formatPrice } from '../../helpers/format';
import Stepper from '../Stepper/Stepper';
import { Cart } from '../../../types';
import { pricingItemsState } from '../../store/state';
import { t } from '../../helpers/translation';
import { computeItemOptionsPrice } from '../../helpers/compute';
import Text from '../Text/Text';
import Theme from '../../helpers/theme';

export type Props = {
  cart: Cart;
  onUpdateItemQuantity?: (itemId: string, quantity: number) => void;
  onRemoveItem?: (itemId: string) => void;
  scrollViewProps?: ScrollViewProps;
};

const CartItems = (props: Props) => {
  const pricingItems = useRecoilValue(pricingItemsState);

  return (
    <ScrollView {...props.scrollViewProps}>
      {props.cart.map((item, index) => (
        <React.Fragment key={item.id}>
          <View style={styles.rowItem}>
            {props.onUpdateItemQuantity && props.onRemoveItem && (
              <Stepper
                value={item.quantity}
                onChangeValue={(quantity) => {
                  if (quantity === 0) {
                    props.onRemoveItem!(item.id);
                  } else {
                    props.onUpdateItemQuantity!(item.id, quantity);
                  }
                }}
                minValue={0}
                style={styles.stepperQuantity}
              />
            )}

            {pricingItems[item.id] && (
              <>
                <View style={styles.rowItemName}>
                  <P>{pricingItems[item.id].name}</P>

                  {item.options && (
                    <View>
                      {item.options
                        .flatMap(([optionCategoryId, optionIds]) =>
                          pricingItems[item.id].optionCategories
                            ?.find(
                              (category) =>
                                category.id.toString() === optionCategoryId
                            )
                            ?.options.filter((option) =>
                              optionIds.includes(option.id)
                            )
                            .map((option) => option.name)
                        )
                        .map((option) => (
                          <Text style={styles.optionName}>{option}</Text>
                        ))}
                    </View>
                  )}
                </View>

                {pricingItems[item.id].priceType &&
                  pricingItems[item.id].price && (
                    <P>
                      {formatPrice(
                        'EUR',
                        pricingItems[item.id].priceType!,
                        pricingItems[item.id].priceType === 'value'
                          ? String(
                              (Number(pricingItems[item.id].price!) +
                                computeItemOptionsPrice(
                                  item.options,
                                  pricingItems[item.id]
                                )) *
                                item.quantity
                            )
                          : pricingItems[item.id].price!
                      )}

                      {pricingItems[item.id].priceType !== 'value' && (
                        <P style={{ fontWeight: 'bold' }}>
                          {t('components.cartItems.misc.payInShop')}
                        </P>
                      )}
                    </P>
                  )}
              </>
            )}
          </View>

          {index !== props.cart.length - 1 && (
            <View style={styles.itemSeparator} />
          )}
        </React.Fragment>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  rowItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowItemName: {
    flex: 1,
    marginRight: 10,
    flexDirection: 'column',
  },
  stepperQuantity: {
    marginRight: 10,
  },
  optionName: {
    fontSize: 12,
    color: Theme.PRIMARY,
  },
  itemSeparator: {
    flex: 1,
    height: 20,
  },
});

export default React.memo(CartItems);
