import React, { useCallback } from 'react';
import {
  StyleSheet,
  ImageBackground,
  ImageSourcePropType,
  ViewProps,
} from 'react-native';
import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import BulletFeatures, {
  BulletFeaturesProps,
} from '../BulletFeatures/BulletFeatures';
import Pill from '../Pill/Pill';
import Theme from '../../helpers/theme';
import { useIsWideScreen } from '../../hooks/responsive';
import { JobType } from '../../constants/job-features';
import { t } from '../../helpers/translation';
import H1 from '../H1/H1';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import H2 from '../H2/H2';

type FeaturesJobProps = {
  job?: JobType;
  image: ImageSourcePropType;
  features: BulletFeaturesProps['features'];
  title?: string;
  subtitle?: string;
  href?: string;
  style?: ViewProps['style'];
};

const FeaturesJob = (props: FeaturesJobProps) => {
  // TODO: add jobs content
  const isWideScreen = useIsWideScreen();
  const gradientProps: LinearGradientProps = {
    colors: ['transparent', '#000'],
  };

  if (!isWideScreen) {
    gradientProps.locations = [0, 0.6];
    gradientProps.colors = ['transparent', 'rgba(0, 0, 0, 0.8)'];
  }

  const Wrapper = useCallback(
    (otherProps: any) => {
      if (props.href) {
        return <Link to={props.href} underline={false} {...otherProps} />;
      }

      return <React.Fragment />;
    },
    [props.href]
  );

  return (
    <Wrapper>
      <ImageBackground
        source={props.image}
        style={[
          styles.container,
          isWideScreen && styles.containerWide,
          props.style,
        ]}
      >
        <LinearGradient {...gradientProps} style={styles.gradient} />

        {props.title && (
          <H1 dark style={styles.title}>
            {props.title}

            {props.href && (
              <Icon
                name="arrow-right"
                color={Theme.PRIMARY}
                size={18}
                style={styles.titleLinkArrow}
              />
            )}
          </H1>
        )}

        {props.subtitle && (
          <H2 dark style={styles.subtitle}>
            {props.subtitle}
          </H2>
        )}

        {props.job && (
          <Pill
            type="black"
            title={`${t('components.featuresJob.headers.tailoredFor')} ${
              props.job === 'all'
                ? t('components.featuresJob.headers.everyBusiness')
                : t(`components.featuresJob.headers.jobs.${props.job}`)
            }`}
            icon="check-circle"
            inline
            style={styles.tailoredFor}
          />
        )}

        <BulletFeatures
          orientation="row"
          itemsPerRow={isWideScreen ? 2 : 1}
          color="white"
          textColor="white"
          features={props.features}
          style={styles.advantages}
        />
      </ImageBackground>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    minHeight: 400,
    borderRadius: 6,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    paddingTop: 200,
  },
  containerWide: {
    paddingTop: 0,
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  },
  title: {
    zIndex: 1,
    marginHorizontal: 20,
  },
  subtitle: {
    zIndex: 1,
    marginHorizontal: 20,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 'normal',
  },
  titleLinkArrow: {
    marginLeft: 10,
  },
  tailoredFor: {
    margin: 20,
    marginBottom: 0,
    backgroundColor: Theme.BLACK,
    alignSelf: 'flex-start',
  },
  advantages: {
    padding: 20,
  },
});

export default FeaturesJob;
