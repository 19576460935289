import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useIsWideScreen } from '../../hooks/responsive';
import Button from '../Button/Button';
import H2 from '../H2/H2';
import Theme from '../../helpers/theme';
import { t } from '../../helpers/translation';

type Props = {
  forceWhite?: boolean;
  backgroundColor?: string;
  style?: ViewProps['style'];
};

const JoinBanner = (props: Props) => {
  const isWideScreen = useIsWideScreen();
  const navigation = useNavigation();

  return (
    <View
      style={[
        styles.container,
        isWideScreen && styles.containerWide,
        !!props.backgroundColor && { backgroundColor: props.backgroundColor },
        props.style,
      ]}
    >
      <H2
        light={!props.forceWhite}
        dark={props.forceWhite}
        style={[styles.title, isWideScreen && styles.titleWide]}
      >
        {t('components.joinBanner.headers.title')}
      </H2>

      <Button
        title={t('common.getStarted')}
        // @ts-ignore
        textStyle={[
          styles.buttonText,
          props.forceWhite && styles.buttonTextDefault,
        ]}
        onPress={() => navigation.navigate('Join')}
        style={[styles.button, props.forceWhite && styles.buttonDefault]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 6,
    justifyContent: 'center',
    backgroundColor: Theme.PRIMARY,
  },
  containerWide: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    flex: 1,
    marginBottom: 20,
    textAlign: 'center',
  },
  titleWide: {
    marginBottom: 0,
    textAlign: 'left',
  },
  button: {
    flex: 1,
    width: 'auto',
    borderColor: Theme.BLACK,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
  buttonDefault: {
    borderColor: 'white',
  },
  buttonText: {
    color: Theme.BLACK,
  },
  buttonTextDefault: {
    color: 'white',
  },
});

export default JoinBanner;
