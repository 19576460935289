import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { isLoggedInState } from '../../store/state';
import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';
import Pill from '../Pill/Pill';
import { t, getFormattedLocale } from '../../helpers/translation';
import { AppStackParamList } from '../../navigators/AppNavigator';
import Link from '../Link/Link';
import { useIsWideScreen } from '../../hooks/responsive';
import PopOver from '../PopOver/PopOver';
import LanguageSelectorPopOver from '../LanguageSelectorPopOver/LanguageSelectorPopOver';
import Flag from '../Flag/Flag';

type Props = {};

const BrandBar = (props: Props) => {
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const isWideScreen = useIsWideScreen();
  const navigation = useNavigation<
    DrawerNavigationProp<AppStackParamList, 'Home'>
  >();

  return (
    <View style={styles.container}>
      <View style={styles.logo}>
        <Icon
          name="menu"
          onPress={() => navigation.toggleDrawer()}
          style={styles.menuIcon}
        />

        <Logo />
      </View>

      {isLoggedIn ? (
        <Pill
          type="primary"
          icon="user"
          title={t('navigation.dashboard')}
          onPress={() => navigation.navigate('SignedIn')}
          style={styles.actionButton}
        />
      ) : (
        <>
          {isWideScreen && <Link to="/signin">{t('navigation.signIn')}</Link>}

          <Pill
            type="primary"
            icon="user"
            title={t('navigation.join')}
            onPress={() => navigation.navigate('Join')}
            style={styles.actionButton}
          />
        </>
      )}

      {isWideScreen && (
        <PopOver
          position="bottom-right"
          // @ts-ignore
          content={<LanguageSelectorPopOver />}
          style={styles.popOver}
        >
          <Flag
            code={getFormattedLocale()}
            style={{ width: 24, height: 18, borderRadius: 4, opacity: 0.9 }}
          />
        </PopOver>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  logo: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuIcon: {
    marginHorizontal: 20,
  },
  actionButton: {
    marginHorizontal: 20,
  },
  popOver: {
    marginRight: 20,
  },
});

export default BrandBar;
