import React from 'react';
import { View, StyleSheet } from 'react-native';
import moment, { Moment } from 'moment';
import Slider from '@react-native-community/slider';
import Theme from '../../helpers/theme';
import Text from '../Text/Text';

type TimePickerProps = {
  time: Date;
  minStep: number;
  hourStep: number;
  onChange: (date: Date) => void;
};

const TimePicker = (props: TimePickerProps) => {
  const changeHours = (hours: number) => {
    const m = moment(props.time);
    m.hours(hours);
    props.onChange(m.toDate());
  };

  const changeMinutes = (minutes: number) => {
    const m = moment(props.time);
    m.minutes(minutes);
    props.onChange(m.toDate());
  };

  const m = props.time;

  return (
    <View style={styles.container}>
      <View style={[styles.timeRow, styles.timeRowFirst]}>
        {/* TODO: remplacer hours avec textinput */}
        <Text style={styles.label}>Hours</Text>

        <Slider
          value={moment(m).hour()}
          minimumValue={0}
          maximumValue={23}
          step={props.hourStep}
          onValueChange={changeHours}
          minimumTrackTintColor={Theme.PRIMARY}
          thumbTintColor={Theme.PRIMARY}
          style={styles.slider}
        />
      </View>

      <View style={styles.timeRow}>
        <Text style={styles.label}>Minutes</Text>

        <Slider
          value={moment(m).minute()}
          minimumValue={0}
          maximumValue={59}
          step={props.minStep}
          onValueChange={changeMinutes}
          minimumTrackTintColor={Theme.PRIMARY}
          thumbTintColor={Theme.PRIMARY}
          style={styles.slider}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  timeRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  timeRowFirst: {
    marginBottom: 10,
  },
  label: {
    flex: 1,
  },
  slider: {
    flex: 4,
  },
});

export default TimePicker;
