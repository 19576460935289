import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import Text from '../Text/Text';
import Bubble from '../Bubble/Bubble';

export type StepProps = {
  number: number | string;
  numberColor?: string;
  title: string;
  indications: string;
  style?: ViewProps['style'];
};

const Step = (props: StepProps) => {
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.header}>
        <Bubble color={props.numberColor} style={styles.number}>
          {props.number.toString()}
        </Bubble>

        <Text style={styles.title}>{props.title}</Text>
      </View>

      <Text style={styles.explanation}>{props.indications}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingRight: 10,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  number: {
    marginRight: 5,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  explanation: {
    fontSize: 16,
    textAlign: 'justify',
  },
});

export default Step;
