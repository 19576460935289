import React, { useCallback } from 'react';
import CartItems, {
  Props as CartItemsProps,
} from '../components/CartItems/CartItems';
import { useRecoilState } from 'recoil';
import { cartState } from '../store/state';

type Props = {
  scrollViewProps?: CartItemsProps['scrollViewProps'];
};

const CartContainer = (props: Props) => {
  const [cart, setCart] = useRecoilState(cartState);

  const handleRemoveCartItem = useCallback((itemId: string) => {
    setCart((cart) => cart.filter((item) => item.id !== itemId));
  }, []);

  const handleUpdateCartItemQuantity = useCallback(
    (itemId: string, quantity: number) => {
      setCart((cart) =>
        cart.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              quantity,
            };
          }

          return item;
        })
      );
    },
    []
  );

  return (
    <CartItems
      cart={cart}
      onRemoveItem={handleRemoveCartItem}
      onUpdateItemQuantity={handleUpdateCartItemQuantity}
      {...props}
    />
  );
};

export default CartContainer;
