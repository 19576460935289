import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PriceType } from '../../types';
import Button from '../components/Button/Button';
import H2 from '../components/H2/H2';
import PriceInput from '../components/PriceInput/PriceInput';
import PriceTypeSelector from '../components/PriceTypeSelector/PriceTypeSelector';
import TextInput from '../components/TextInput/TextInput';
import API from '../helpers/api';
import { t } from '../helpers/translation';
import {
  categoryItemsState,
  pricingIdState,
  rawCategoryItemsState,
} from '../store/state';

type CreateItemFormProps = {
  categoryId: string;
  onSuccess: () => void;
};

const CreateItemForm = (props: CreateItemFormProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [priceType, setPriceType] = useState<PriceType>('value');
  const [price, setPrice] = useState('');
  const pricingId = useRecoilValue(pricingIdState);
  const setCategoryItems = useSetRecoilState(
    categoryItemsState(props.categoryId)
  );
  const setRawCategoryItems = useSetRecoilState(
    rawCategoryItemsState(props.categoryId)
  );

  const handleAddItem = useCallback(async () => {
    if (name.trim().length === 0) {
      return;
    }

    setIsCreating(true);

    try {
      const { itemId, order } = await API.createPricingCategoryItem(
        pricingId,
        props.categoryId,
        {
          name,
          description,
          price,

          // Only set price type if price is defined
          // (as price type equals to "value" by default)
          priceType: price && priceType ? priceType : undefined,
        }
      );

      setCategoryItems((categoryItems) => [...categoryItems, itemId]);
      setRawCategoryItems((rawCategoryItems) => [
        ...rawCategoryItems,
        { id: itemId, name, description, priceType, price, order },
      ]);

      setName('');
      setDescription('');
      setPriceType('value');
      setPrice('');

      props.onSuccess();
    } catch (error) {
      console.warn(error);
    }

    setIsCreating(false);
  }, [pricingId, props.categoryId, name, description, priceType, price]);

  return (
    <>
      <H2>{t('components.itemsContainer.buttons.addItem')}</H2>

      <TextInput
        editable
        required
        value={name}
        onChangeText={setName}
        label={t('common.name')}
        style={styles.input}
      />

      <TextInput
        editable
        multiline
        numberOfLines={2}
        value={description}
        onChangeText={setDescription}
        label={t('common.description')}
        style={styles.input}
      />

      <PriceTypeSelector
        value={priceType}
        // @ts-ignore
        onChangeValue={setPriceType}
        style={styles.input}
      />

      <PriceInput
        value={price}
        onChangeValue={setPrice}
        priceType={priceType}
        style={styles.input}
      />

      <Button
        disabled={name.trim().length === 0}
        icon="plus"
        title={t('components.itemsContainer.buttons.createItem')}
        onPress={handleAddItem}
        busy={isCreating}
        style={styles.button}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginTop: 10,
  },
  button: {
    marginTop: 20,
  },
});

export default CreateItemForm;
