import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import Block from '../../components/Block/Block';
import { t } from '../../helpers/translation';
import { useDocumentTitle } from '../../hooks/document';
import EditContactTracingSettingsForm from './EditContactTracingSettingsForm';

type EditContactTracingSettingsScreenProps = {};

const EditContactTracingSettingsScreen = (
  props: EditContactTracingSettingsScreenProps
) => {
  useDocumentTitle(t('screens.editContactTracingSettings.title'));

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Block>
        <EditContactTracingSettingsForm />
      </Block>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
});

export default EditContactTracingSettingsScreen;
