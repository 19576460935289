import React from 'react';
import { Text as RNText, TextProps, StyleSheet } from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import { DefaultTheme } from '@react-navigation/native';

export type Props = TextProps & {
  type?: 'link' | 'primary';
  align?: 'left' | 'center' | 'right' | 'justify';
  children: any;
  dark?: boolean;
  light?: boolean;
  ellipsize?: boolean;
};

const Text = (props: Props) => {
  const scheme = useColorScheme();

  return (
    <RNText
      {...props}
      style={[
        styles.container,
        !props.light &&
          (scheme === 'dark' || props.dark) &&
          styles.containerDark,
        props.type === 'link' && styles.containerLink,
        props.type === 'primary' && styles.containerPrimary,
        props.ellipsize && styles.containerEllipsize,
        props.align === 'left' && styles.containerAlignLeft,
        props.align === 'center' && styles.containerAlignCenter,
        props.align === 'right' && styles.containerAlignRight,
        props.align === 'justify' && styles.containerAlignJustify,
        props.style,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    color: 'black',
  },
  containerDark: {
    color: 'white',
  },
  containerLink: {
    color: DefaultTheme.colors.primary,
    textDecorationLine: 'underline',
  },
  containerPrimary: {
    color: DefaultTheme.colors.primary,
  },
  containerEllipsize: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  containerAlignLeft: {
    textAlign: 'left',
  },
  containerAlignCenter: {
    textAlign: 'center',
  },
  containerAlignRight: {
    textAlign: 'right',
  },
  containerAlignJustify: {
    textAlign: 'justify',
  },
});

export default Text;
