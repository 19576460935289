import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import SignInScreen from '../screens/SignInScreen';
import MenuIcon from '../components/MenuIcon/MenuIcon';
import { t } from '../helpers/translation';

type Props = {};

export type AuthStackParamList = {
  SignIn: undefined;
};

const Stack = createStackNavigator<AuthStackParamList>();

const AuthNavigator = (props: Props) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SignIn"
        component={SignInScreen}
        options={{
          title: t('navigation.signIn'),
          headerLeft: () => <MenuIcon />,
        }}
      />
    </Stack.Navigator>
  );
};

export default AuthNavigator;
