import React from 'react';
import { GestureResponderEvent, StyleSheet, ViewProps } from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import Pill from '../../components/Pill/Pill';
import Theme from '../../helpers/theme';

type ServiceBlockProps = {
  icon: string;
  color?: string;
  title: string;
  onPress?: (event: GestureResponderEvent) => void;
  selected?: boolean;
  style?: ViewProps['style'];
};

const ServiceBlock = (props: ServiceBlockProps) => {
  const color = props.color ?? Theme.PRIMARY;
  const scheme = useColorScheme();

  return (
    <Pill
      icon={props.icon}
      iconColor={color}
      title={props.title}
      color="transparent"
      inline
      // @ts-ignore
      textStyle={[
        styles.text,
        scheme === 'dark' && styles.textDark,
        props.selected && { color },
      ]}
      onPress={props.onPress}
      style={[styles.container, props.style]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  text: {
    fontSize: 16,
    color: Theme.BLACK,
  },
  textDark: {
    color: 'white',
  },
});

export default ServiceBlock;
