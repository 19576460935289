import React from 'react';
import { StyleSheet } from 'react-native';
import Text, { Props as TextProps } from '../Text/Text';

const H1 = (props: TextProps) => {
  return <Text {...props} style={[styles.container, props.style]} />;
};

const styles = StyleSheet.create({
  container: {
    fontWeight: 'bold',
    fontSize: 24,
  },
});

export default H1;
