import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList } from '../navigators/AppNavigator';
import { useDocumentTitle } from '../hooks/document';
import Wrapper from '../components/Wrapper/Wrapper';
import Hero from '../components/Hero/Hero';
import { getFormattedLocale, t } from '../helpers/translation';
import Features from '../components/Features/Features';
import Feature from '../components/Feature/Feature';
import Theme from '../helpers/theme';
import BrandBar from '../components/BrandBar/BrandBar';
import Step from '../components/Step/Step';
import H1 from '../components/H1/H1';
import JoinBanner from '../components/JoinBanner/JoinBanner';
import Carousel from '../components/Carousel/Carousel';
import BulletFeatures from '../components/BulletFeatures/BulletFeatures';
import FeaturesTable from '../components/FeaturesTable/FeaturesTable';
import YoutubePlayer from '../components/YoutubePlayer/YoutubePlayer';
import Modal from '../components/Modal/Modal';

type Props = {
  navigation: StackNavigationProp<AppStackParamList, 'ProductOrders'>;
  route: RouteProp<AppStackParamList, 'ProductOrders'>;
};

const ProductOrdersScreen = (props: Props) => {
  const [watchVideo, setWatchVideo] = useState(false);
  const lang = getFormattedLocale();

  useDocumentTitle(t('screens.home.products.contactTracing.title'));

  return (
    <>
      <Modal visible={watchVideo} onClose={() => setWatchVideo(false)}>
        <YoutubePlayer videoKey="tNm7w4R4lU0" />
      </Modal>

      <ScrollView contentContainerStyle={styles.container}>
        <BrandBar />

        <Wrapper>
          <Hero
            image={require('../../assets/contact-tracking.svg')}
            // subtitle="Follow contact tracing guidelines using a confidential and hassle-free system."
            title={t('screens.home.products.contactTracing.title')}
            subtitle={t('screens.home.products.contactTracing.subtitle')}
            action={t('screens.products.actions.watchVideo')}
            actionIcon="play-circle"
            onPressAction={(event) => {
              event.preventDefault();
              setWatchVideo(true);
            }}
          />

          <Features>
            <Feature
              title={t(
                'screens.productContactTracing.features.dataSafety.title'
              )}
              subtitle={t(
                'screens.productContactTracing.features.dataSafety.subtitle'
              )}
              image={require('../../assets/tracing_safe.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />

            <Feature
              title={t(
                'screens.productContactTracing.features.simplifyLife.title'
              )}
              subtitle={t(
                'screens.productContactTracing.features.simplifyLife.subtitle'
              )}
              image={require('../../assets/tracing_simple.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />
          </Features>

          <Carousel
            color={Theme.INDIGO}
            items={[
              {
                image:
                  lang === 'fr'
                    ? require('../../assets/fr-tracing-shot-1.png')
                    : require('../../assets/en-tracing-shot-1.png'),
                title: t('screens.productContactTracing.steps.getFormLink'),
              },
              {
                image:
                  lang === 'fr'
                    ? require('../../assets/fr-tracing-shot-2.png')
                    : require('../../assets/en-tracing-shot-2.png'),
                title: t('screens.productContactTracing.steps.clientsFillForm'),
              },
              {
                image:
                  lang === 'fr'
                    ? require('../../assets/fr-tracing-shot-3.png')
                    : require('../../assets/en-tracing-shot-3.png'),
                title: t(
                  'screens.productContactTracing.steps.weTakeCareOfTheRest'
                ),
              },
            ]}
            style={styles.carousel}
          />

          <BulletFeatures
            color={Theme.INDIGO}
            features={[
              {
                icon: 'zap',
                title: t(
                  'screens.productContactTracing.features.quickSetup.title'
                ),
                text: t(
                  'screens.productContactTracing.features.quickSetup.subtitle'
                ),
              },
              {
                icon: 'edit-3',
                title: t(
                  'screens.productContactTracing.features.autonomousClients.title'
                ),
                text: t(
                  'screens.productContactTracing.features.autonomousClients.subtitle'
                ),
              },
              {
                icon: 'smile',
                title: t(
                  'screens.productContactTracing.features.focusOnWhatMatters.title'
                ),
                text: t(
                  'screens.productContactTracing.features.focusOnWhatMatters.subtitle'
                ),
              },
              {
                icon: 'shield',
                title: t(
                  'screens.productContactTracing.features.confidential.title'
                ),
                text: t(
                  'screens.productContactTracing.features.confidential.subtitle'
                ),
              },
            ]}
            style={styles.bulletFeatures}
          />

          <H1>{t('screens.productOrders.headers.faq')}</H1>

          <Step
            number={'Q'}
            title={t('screens.productContactTracing.faq.safety.question')}
            indications={t('screens.productContactTracing.faq.safety.answer')}
            numberColor={Theme.INDIGO}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t(
              'screens.productContactTracing.faq.timeManagement.question'
            )}
            indications={t(
              'screens.productContactTracing.faq.timeManagement.answer'
            )}
            numberColor={Theme.INDIGO}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t('screens.productContactTracing.faq.setUpTracing.question')}
            indications={t(
              'screens.productContactTracing.faq.setUpTracing.answer'
            )}
            numberColor={Theme.INDIGO}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t('screens.productOrders.faq.trust.question')}
            indications={t('screens.productOrders.faq.trust.answer')}
            numberColor={Theme.INDIGO}
            style={styles.step}
          />

          {/* <H1 style={styles.whatYouGet}>
          {t('screens.products.headers.whatYouGet')}
        </H1>

        <FeaturesTable
          color={Theme.ORANGE}
          features={[
            {
              icon: 'layout',
              title: t('screens.products.included.customBranding'),
            },
            {
              icon: 'at-sign',
              title: t('screens.productContactTracing.included.shopSpace'),
            },
            {
              icon: 'mail',
              title: t(
                'screens.productContactTracing.included.orderConfirmation'
              ),
            },
            {
              icon: 'lock',
              title: t('screens.products.included.securePayments'),
            },
            {
              icon: 'help-circle',
              title: t('screens.products.included.onboardingSupport'),
            },
            {
              icon: 'instagram',
              title: t('screens.products.included.socialMediaMaterials'),
            },
            {
              icon: 'list',
              title: t('screens.products.included.mobileAppAccess'),
            },
            {
              icon: 'link-2',
              title: t('screens.products.included.customDomainName'),
              details: t('screens.products.included.onDemand'),
            },
            {
              icon: 'smartphone',
              title: t(
                'screens.productContactTracing.included.customMobileApp'
              ),
              details: t('screens.products.included.onDemand'),
            },
          ]}
          style={styles.featuresTable}
        /> */}

          <View style={styles.featuresTable} />

          <JoinBanner backgroundColor={Theme.INDIGO} style={styles.join} />

          <H1>{t('screens.products.headers.youMightAlsoNeed')}</H1>

          <Features>
            <Feature
              href="/products/orders"
              title={t('screens.home.products.orders.title')}
              subtitle={t('screens.home.products.orders.subtitle')}
              image={require('../../assets/order_confirmed.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />

            <Feature
              href="/products/bookings"
              title={t('screens.home.products.bookings.title')}
              subtitle={t('screens.home.products.bookings.subtitle')}
              image={require('../../assets/restaurant.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />
          </Features>
        </Wrapper>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  carousel: {
    marginBottom: 70,
  },
  bulletFeatures: {
    marginBottom: 30,
  },
  step: {
    marginTop: 30,
    marginBottom: 10,
  },
  featuresTable: {
    marginBottom: 70,
  },
  whatYouGet: {
    marginTop: 60,
    marginBottom: 20,
  },
  join: {
    marginBottom: 70,
  },
});

export default ProductOrdersScreen;
