import React from 'react';
import { Feather } from '@expo/vector-icons';
import { useColorScheme } from 'react-native-appearance';
import { ViewProps } from 'react-native';

export type IconProps = {
  name: string;
  size?: number;
  color?: string;
  onPress?: () => void;
  style?: ViewProps['style'];
};

const Icon = (props: IconProps) => {
  const scheme = useColorScheme();

  return (
    <Feather
      color={scheme === 'dark' ? 'white' : 'black'}
      size={24}
      {...props}
    />
  );
};

export default Icon;
