import React from 'react';
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
import { DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import H3 from '../../H3/H3';
import P from '../../P/P';
import Text from '../../Text/Text';
import { formatPrice } from '../../../helpers/format';
import { PriceType } from '../../../../types';
import { transition } from '../../../helpers/style';

type Props = {
  name: string;
  description?: string;
  image?: string;
  priceType?: PriceType;
  price?: string;
  quantity?: number;
  onPress?: () => void;
  style?: ViewProps['style'];
};

const Item = (props: Props) => {
  const scheme = useColorScheme();

  return (
    <View style={[styles.container, props.style]}>
      <TouchableOpacity
        disabled={!props.onPress}
        onPress={props.onPress}
        style={[styles.container, { flex: 1 }]}
      >
        {props.quantity && (
          <Text type="primary" style={styles.quantity}>
            x{props.quantity}
          </Text>
        )}

        <View
          style={[
            styles.quantityMargin,
            typeof props.quantity !== 'undefined' &&
              props.quantity !== 0 && {
                marginLeft: 10,
              },
          ]}
        />

        {props.image && (
          <View
            style={[
              styles.imageContainer,
              scheme === 'dark' && styles.imageContainerDark,
            ]}
          >
            <Image
              source={{ uri: props.image }}
              resizeMode="cover"
              // @ts-ignore
              style={styles.image}
            />
          </View>
        )}

        <View style={styles.name}>
          {props.name && <H3>{props.name}</H3>}

          {props.description && (
            <P numberOfLines={2} ellipsizeMode="tail">
              {props.description}
            </P>
          )}
        </View>
      </TouchableOpacity>

      {props.price && props.priceType && (
        <View style={styles.price}>
          {/* TODO */}
          <P>{formatPrice('EUR', props.priceType, props.price)}</P>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  quantityMargin: {
    // @ts-ignore
    ...transition(),
  },
  quantity: {
    fontWeight: 'bold',
    alignSelf: 'center',
    width: 30,
    textAlign: 'center',
  },
  imageContainer: {
    position: 'relative',
    width: 60,
    height: 60,
    borderRadius: 6,
    alignSelf: 'flex-start',
    marginRight: 10,
  },
  imageContainerDark: {
    backgroundColor: DarkTheme.colors.background,
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 6,
  },
  name: {
    flex: 1,
    flexDirection: 'column',
  },
  price: {
    marginLeft: 10,
    flexDirection: 'column',
  },
});

export default Item;
