import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import Icon from '../Icon/Icon';
import H2 from '../H2/H2';
import P from '../P/P';
import { useIsWideScreen } from '../../hooks/responsive';

export type BulletFeaturesProps = {
  orientation?: 'column' | 'row';
  itemsPerRow?: number;
  color: string;
  textColor?: string;
  features: {
    icon: string;
    title: string;
    text: string;
  }[];
  style?: ViewProps['style'];
};

const BulletFeatures = (props: BulletFeaturesProps) => {
  const isWideScreen = useIsWideScreen();
  const isRowLayout = isWideScreen || props.orientation === 'row';

  return (
    <View
      style={[
        styles.container,
        isRowLayout && styles.containerWide,
        props.style,
      ]}
    >
      {props.features.map((feature) => (
        <View
          key={feature.title}
          style={[
            styles.featureContainer,
            isRowLayout && styles.featureContainerWide,
            isRowLayout && {
              width: props.itemsPerRow
                ? `${100 / props.itemsPerRow - 5 * (props.itemsPerRow - 1)}%`
                : '45%',
            },
          ]}
        >
          <Icon name={feature.icon} color={props.color} />

          <View
            style={[
              styles.featureTextContainer,
              isRowLayout && styles.featureTextContainerWide,
            ]}
          >
            <H2
              style={[
                styles.featureText,
                isRowLayout && styles.featureTextWide,
                !!props.textColor && { color: props.textColor },
              ]}
            >
              {feature.title}
            </H2>

            <P
              style={[
                styles.featureText,
                isRowLayout && styles.featureTextWide,
                !!props.textColor && { color: props.textColor },
              ]}
            >
              {feature.text}
            </P>
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  containerWide: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  featureContainer: {
    flexDirection: 'column',
    marginBottom: 20,
    alignItems: 'center',
  },
  featureContainerWide: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  featureTextContainer: {
    marginTop: 10,
    flexShrink: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  featureTextContainerWide: {
    marginLeft: 20,
    marginTop: 0,
    alignItems: 'flex-start',
  },
  featureText: {
    textAlign: 'center',
  },
  featureTextWide: {
    textAlign: 'left',
  },
});

export default BulletFeatures;
