import * as Localization from 'expo-localization';
// @ts-ignore
import I18n from 'i18n-js';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/fr-ch';
import 'moment/locale/en-sg';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-il';
import 'moment/locale/en-nz';
import en from '../langs/en';
import fr from '../langs/fr';
import { getUserSetLanguage } from './storage';
import { formatLocale } from './format';

export async function initTranslations() {
  I18n.translations = {
    en,
    fr,
  };

  I18n.locale = (await getUserSetLanguage()) || Localization.locale;
  I18n.fallbacks = true;

  moment.locale(getLocale());

  return I18n.locale;
}

export function t(...args: any[]) {
  return I18n.t(...args);
}

export function getLocale() {
  return I18n.locale;
}

export function getFormattedLocale() {
  return formatLocale(getLocale());
}
