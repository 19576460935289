import React, { useState, useCallback } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import Button from '../components/Button/Button';
import { t } from '../helpers/translation';
import TextInput from '../components/TextInput/TextInput';
import CheckBox from '../components/CheckBox/CheckBox';
import PriceInput from '../components/PriceInput/PriceInput';
import DeleteButton from '../components/DeleteButton/DeleteButton';

type Props = {
  id?: string;
  name?: string;
  description?: string;
  price?: number;
  isDefault?: boolean;
  onCreate?: (values: {
    name: string;
    description: string;
    price: number;
    isDefault: boolean;
  }) => void;
  onEdit?: (key: string, value: any) => void;
  onDelete?: (id: string) => void;

  // Fired on every input blur event
  onEditEnd?: (key: string, value: any) => void;
  style?: ViewProps['style'];
};

const CreateItemOptionForm = (props: Props) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState<number>();
  const [isDefault, setIsDefault] = useState(false);

  const handleAddOption = useCallback(() => {
    if (props.onCreate) {
      props.onCreate({
        name,
        description,
        price: price ?? 0,
        isDefault,
      });

      setName('');
      setDescription('');
      setPrice(0);
      setIsDefault(false);
    }
  }, [name, description, price, isDefault, props.onCreate]);

  const handleEditName = useCallback((name: string) => {
    if (props.onEdit) {
      props.onEdit('name', name);
    } else {
      setName(name);
    }
  }, []);

  const handleEditDescription = useCallback((description: string) => {
    if (props.onEdit) {
      props.onEdit('description', description);
    } else {
      setDescription(description);
    }
  }, []);

  const handleEditPrice = useCallback((price: string) => {
    // Some browsers will replace "0.50" to "0,50" automatically.
    // `parseFloat` does not parse strings with commas
    const parsedPrice = parseFloat(price.replace(/,/g, '.'));

    if (props.onEdit) {
      props.onEdit('price', parsedPrice);
    } else {
      setPrice(parsedPrice);
    }

    if (props.onEditEnd) {
      props.onEditEnd('price', parsedPrice);
    }
  }, []);

  const handleEditIsDefault = useCallback((isDefault: boolean) => {
    if (props.onEdit) {
      props.onEdit('isDefault', isDefault);
    } else {
      setIsDefault(isDefault);
    }

    if (props.onEditEnd) {
      props.onEditEnd('isDefault', isDefault);
    }
  }, []);

  return (
    <View style={[styles.container, props.style]}>
      {props.onDelete && props.id && (
        <DeleteButton
          type="minus"
          onDelete={() => props.onDelete!(props.id!)}
          style={styles.buttonDelete}
        />
      )}

      <View style={styles.inputs}>
        <View style={[styles.row, { alignItems: 'flex-end' }]}>
          <TextInput
            editable
            required
            type="text"
            label={t('forms.createItemOption.inputs.optionName')}
            value={props.name ?? name}
            onChangeText={handleEditName}
            // @ts-ignore
            onBlur={
              props.onEditEnd
                ? () => props.onEditEnd!('name', props.name)
                : undefined
            }
            inline
            style={{ flex: 1 }}
          />

          <PriceInput
            value={
              props.price
                ? props.price.toString()
                : price
                ? price.toString()
                : ''
            }
            onChangeValue={handleEditPrice}
            priceType="value"
            style={{ marginLeft: 10, flex: 1 }}
          />
        </View>

        <TextInput
          type="text"
          editable
          multiline
          numberOfLines={2}
          value={props.description ?? description}
          onChangeText={handleEditDescription}
          onBlur={
            props.onEditEnd
              ? () => props.onEditEnd!('description', props.description)
              : undefined
          }
          label={t('forms.createItemOption.inputs.optionDescription')}
          style={{ marginTop: 10 }}
        />

        <CheckBox
          label={t('forms.createItemOption.inputs.isDefault')}
          value={props.isDefault ?? isDefault}
          onValueChange={handleEditIsDefault}
          style={{ marginTop: 10 }}
        />

        {props.onCreate && (
          <Button
            title={t('forms.createItemOption.buttons.addOption')}
            onPress={handleAddOption}
            disabled={!name}
            size="small"
            icon="plus"
            style={styles.buttonAddOption}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputs: {
    flex: 1,
  },
  buttonAddOption: {
    marginTop: 10,
  },
  buttonDelete: {
    marginRight: 10,
    alignSelf: 'center',
  },
});

export default CreateItemOptionForm;
