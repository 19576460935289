import React from 'react';
import { StyleSheet, GestureResponderEvent, ViewProps } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';
import Button, { ButtonProps } from '../Button/Button';
import Theme from '../../helpers/theme';

export type PillProps = {
  type?: 'primary' | 'danger' | 'black';
  color?: string;
  icon?: string;
  iconColor?: string;
  iconSize?: number;
  title?: string;
  onPress?: (event: GestureResponderEvent) => void;
  inline?: ButtonProps['inline'];
  progress?: number;
  textStyle?: ButtonProps['textStyle'];
  style?: ViewProps['style'];
};

const Pill = (props: PillProps) => {
  return (
    <Button
      disabled={!props.onPress}
      icon={props.icon}
      iconColor={props.iconColor ?? (!!props.type ? 'white' : undefined)}
      iconSize={props.iconSize}
      inline={props.inline}
      title={props.title}
      progress={props.progress}
      onPress={props.onPress}
      style={[
        styles.container,
        props.type === 'danger' && styles.containerDanger,
        props.type === 'primary' && styles.containerPrimary,
        props.type === 'black' && styles.containerBlack,
        !!props.color && { backgroundColor: props.color },
        props.style,
      ]}
      // @ts-ignore
      textStyle={[
        styles.text,
        props.type && styles.textNonDefault,
        props.textStyle,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    height: 30,
    width: 'auto',
    borderRadius: 15,
    backgroundColor: DefaultTheme.colors.background,
    paddingVertical: 0,
    borderWidth: 0,
  },
  containerDanger: {
    backgroundColor: 'tomato',
  },
  containerPrimary: {
    backgroundColor: Theme.PRIMARY,
  },
  containerBlack: {
    backgroundColor: Theme.BLACK,
  },
  text: {
    color: Theme.BLACK,
    fontSize: 14,
    fontWeight: 'bold',
  },
  textNonDefault: {
    color: 'white',
  },
});

export default Pill;
