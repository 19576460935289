import React from 'react';
import { View, ViewProps, StyleSheet } from 'react-native';
import { queryCache } from 'react-query';
import { useRecoilValue } from 'recoil';
import StatusSelector from '../StatusSelector/StatusSelector';
import API from '../../helpers/api';
import {
  OrderStatus,
  BookingStatusOwner,
  BookingStatusUser,
  Booking,
  BookingStatus,
} from '../../../types';
import { t } from '../../helpers/translation';
import { businessIdState } from '../../store/state';
import Icon from '../Icon/Icon';

type BookingStatusSelectorsProps = {
  referenceNumber: string;
  referenceToken: string;
  statusOwner: BookingStatusOwner;
  statusUser: BookingStatusUser;
  style?: ViewProps['style'];
};

// TODO: API

const BookingStatusSelectors = (props: BookingStatusSelectorsProps) => {
  const businessId = useRecoilValue(businessIdState);

  return (
    <View style={[styles.container, props.style]}>
      <Icon name="check" size={16} style={styles.icon} />

      <StatusSelector
        status={props.statusOwner}
        onStatusChange={(status) => {
          (async () => {
            try {
              await API.updateBookingStatus(
                businessId,
                props.referenceNumber,
                props.referenceToken,
                'owner',
                status as BookingStatus
              );

              queryCache.setQueryData(
                ['bookings', businessId],
                (oldBookings: any) =>
                  oldBookings.map((oldBookingsGroup: Booking[]) =>
                    oldBookingsGroup.map((oldBooking) => {
                      if (
                        oldBooking.referenceNumber !== props.referenceNumber
                      ) {
                        return oldBooking;
                      }

                      return {
                        ...oldBooking,
                        statusOwner: status,
                      };
                    })
                  )
              );
            } catch (error) {
              console.warn(error);
            }
          })();
        }}
        options={[
          [t('status.confirmed'), 'confirmed'],
          [t('status.cancelled'), 'cancelled'],
        ]}
        style={styles.selector}
      />

      <Icon name="dollar-sign" size={16} style={styles.icon} />

      <StatusSelector
        status={props.statusUser}
        onStatusChange={(status) => {
          (async () => {
            try {
              await API.updateBookingStatus(
                businessId,
                props.referenceNumber,
                props.referenceToken,
                'user',
                status as BookingStatus
              );

              queryCache.setQueryData(
                ['bookings', businessId],
                (oldBookings: any) =>
                  oldBookings.map((oldBookingsGroup: Booking[]) =>
                    oldBookingsGroup.map((oldBooking) => {
                      if (
                        oldBooking.referenceNumber !== props.referenceNumber
                      ) {
                        return oldBooking;
                      }

                      return {
                        ...oldBooking,
                        statusUser: status,
                      };
                    })
                  )
              );
            } catch (error) {
              console.warn(error);
            }
          })();
        }}
        options={[
          [t('status.unpaid'), 'unpaid'],
          [t('status.paid'), 'paid'],
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
  },
  selector: {
    marginRight: 10,
  },
});

export default BookingStatusSelectors;
