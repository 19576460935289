import React, { useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { View, StyleSheet } from 'react-native';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';

type Props = {
  clientId: string;
  currency: string;
  amount: number;
  onPaymentSuccess: (orderId: string) => void;
};

const BuyButton = (props: Props) => {
  const [isReady, setIsReady] = useState(false);

  return (
    <View style={styles.container}>
      {!isReady && <ActivityIndicator style={styles.loader} />}

      <PayPalButton
        amount={props.amount}
        onButtonReady={() => setIsReady(true)}
        onSuccess={(_details: any, data: any) => {
          props.onPaymentSuccess(data.orderID);
        }}
        options={{
          currency: props.currency,
          clientId: props.clientId,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    padding: 20,
  },
  loader: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default BuyButton;
