import React, { useState } from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  TouchableOpacity,
  ViewProps,
} from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import { DarkTheme, DefaultTheme } from '@react-navigation/native';
import H3 from '../../components/H3/H3';
import P from '../../components/P/P';
import Modal from '../../components/Modal/Modal';
import YoutubePlayer from '../../components/YoutubePlayer/YoutubePlayer';

type TutorialBlockProps = {
  image: ImageSourcePropType;
  title: string;
  subtitle: string;
  videoKey: string;
  style?: ViewProps['style'];
};

const TutorialBlock = (props: TutorialBlockProps) => {
  const [watchVideo, setWatchVideo] = useState(false);
  const scheme = useColorScheme();

  return (
    <>
      <Modal visible={watchVideo} onClose={() => setWatchVideo(false)}>
        <YoutubePlayer videoKey={props.videoKey} />
      </Modal>

      <TouchableOpacity
        onPress={() => setWatchVideo(true)}
        style={[
          styles.container,
          scheme === 'dark' && styles.containerDark,
          props.style,
        ]}
      >
        <Image source={props.image} resizeMode="contain" style={styles.image} />
        <H3>{props.title}</H3>
        <P>{props.subtitle}</P>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: DefaultTheme.colors.border,
    padding: 20,
    borderRadius: 6,
  },
  containerDark: {
    borderColor: DarkTheme.colors.border,
  },
  image: {
    width: 150,
    height: 150,
    marginBottom: 10,
  },
});

export default TutorialBlock;
