import React from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DefaultTheme, DarkTheme, RouteProp } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import { UserStackParamList } from '../navigators/UserNavigator';
import { t } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import BookingDetails from '../components/BookingDetails/BookingDetails';
import BookingStatusSelectors from '../components/BookingStatusSelectors/BookingStatusSelectors';

type BusinessBookingDetailsScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'BusinessBookingDetails'>;
  route: RouteProp<UserStackParamList, 'BusinessBookingDetails'>;
};

const BusinessBookingsScreen = (props: BusinessBookingDetailsScreenProps) => {
  const scheme = useColorScheme();
  const { booking } = props.route.params;

  useDocumentTitle(
    t('navigation.bookingDetails', { reference: booking.referenceNumber })
  );

  return (
    <>
      <View
        style={[
          styles.statusSelectors,
          scheme === 'dark' && styles.statusSelectorsDark,
        ]}
      >
        <BookingStatusSelectors
          statusOwner={booking.statusOwner}
          statusUser={booking.statusUser}
          referenceNumber={booking.referenceNumber}
          referenceToken={booking.referenceToken}
        />
      </View>

      <ScrollView contentContainerStyle={styles.currentBookingScrollView}>
        <BookingDetails booking={booking} />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  statusSelectors: {
    alignItems: 'flex-end',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  statusSelectorsDark: {
    borderBottomColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  currentBookingScrollView: {
    padding: 20,
  },
});

export default BusinessBookingsScreen;
