import React, { useState } from 'react';
import * as Localization from 'expo-localization';
import { StyleSheet } from 'react-native';
import Button from '../../components/Button/Button';
import H2 from '../../components/H2/H2';
import PhoneNumberInput from '../../components/PhoneNumberInput/PhoneNumberInput';
import TextInput from '../../components/TextInput/TextInput';
import API from '../../helpers/api';
import { t } from '../../helpers/translation';
import P from '../../components/P/P';
import Link from '../../components/Link/Link';

type AddContactInformationFormProps = {
  slug: string;
};

const AddContactInformationForm = (props: AddContactInformationFormProps) => {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState(
    Localization.region || 'FR'
  );

  const handleAddingContactInformation = async () => {
    setIsSending(true);

    try {
      await API.createContactInformation(props.slug, {
        firstName,
        lastName,
        phoneNumber,
        phoneNumberCountryCode,
        time: new Date().toISOString(),
      });

      setIsSent(true);
    } catch (error) {
      console.warn(error);
    }

    setIsSending(false);
  };

  if (isSent) {
    return (
      <>
        <H2>
          {t('forms.addContactInformation.headers.informationSent', {
            name: firstName,
          })}
        </H2>

        <P style={styles.paragraph}>
          {t('forms.addContactInformation.paragraphs.goodToGo')}
        </P>

        <P style={styles.paragraph}>
          {t('forms.addContactInformation.paragraphs.itIsScary')}
        </P>

        <P style={styles.paragraph}>
          <Link to="/">{t('navigation.contact')}</Link>
        </P>
      </>
    );
  }

  return (
    <>
      <H2>{t('forms.addContactInformation.headers.yourInformation')}</H2>

      <TextInput
        editable
        required
        label={t('common.firstName')}
        value={firstName}
        onChangeText={setFirstName}
        style={styles.input}
      />

      <TextInput
        editable
        required
        label={t('common.lastName')}
        value={lastName}
        onChangeText={setLastName}
        style={styles.input}
      />

      <PhoneNumberInput
        required
        label={t('screens.join.inputs.phoneNumber.label')}
        placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
        phoneNumber={phoneNumber}
        onChangePhoneNumber={setPhoneNumber}
        countryCode={phoneNumberCountryCode}
        onChangeCountryCode={setPhoneNumberCountryCode}
        style={styles.input}
      />

      <Button
        disabled={
          !firstName || !lastName || !phoneNumberCountryCode || !phoneNumber
        }
        icon="edit-3"
        title={t('forms.addContactInformation.actions.addMyInformation')}
        busy={isSending}
        onPress={handleAddingContactInformation}
        style={styles.button}
      />

      <P style={styles.legal}>
        {t('forms.addContactInformation.paragraphs.legal')}
      </P>
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginVertical: 10,
  },
  button: {
    marginVertical: 10,
  },
  paragraph: {
    marginTop: 10,
  },
  legal: {
    marginTop: 10,
    fontStyle: 'italic',
  },
});

export default AddContactInformationForm;
