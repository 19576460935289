import React from 'react';
import { StyleSheet, View } from 'react-native';
import Block from '../../Block/Block';
import ItemSeparator from '../../ItemSeparator/ItemSeparator';
import Category from '../Category/Category';
import Item from '../Item/Item';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  categoryScrollTopPositionState,
  cartState,
} from '../../../store/state';
import { PricingItem } from '../../../../types';

type CategoryType = {
  id: string;
  name: string;
  description?: string;
  items: PricingItem[];
};

type Props = {
  category: CategoryType;
  onPressItem: (item: PricingItem) => void;
};

const CategoryPane = (props: Props) => {
  const { category } = props;

  const cart = useRecoilValue(cartState);
  const setCategoryScrollTopPosition = useSetRecoilState(
    categoryScrollTopPositionState(category.id)
  );

  if (category.items.length === 0) {
    return null;
  }

  return (
    <View
      key={category.id}
      onLayout={(event) =>
        setCategoryScrollTopPosition(event.nativeEvent.layout.y)
      }
      style={styles.container}
    >
      <Category
        name={category.name}
        description={category.description}
        style={styles.category}
      />

      <Block verticalMargins list style={styles.block}>
        {category.items.map((item, index) => {
          let quantity;

          const cartItem = cart.find((cartItem) => cartItem.id === item.id);
          if (cartItem) {
            quantity = cartItem.quantity;
          }

          return (
            <React.Fragment key={item.id}>
              <Item
                image={item.image}
                name={item.name}
                description={item.description}
                price={item.price}
                priceType={item.priceType}
                quantity={quantity}
                onPress={() => props.onPressItem(item)}
                style={styles.item}
              />

              {index < category.items.length - 1 && <ItemSeparator />}
            </React.Fragment>
          );
        })}
      </Block>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  category: {
    paddingRight: 20,
  },
  item: {
    paddingRight: 20,
  },
  block: {
    marginTop: 10,
  },
});

export default CategoryPane;
