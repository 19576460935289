import React from 'react';
import { StyleSheet, ViewProps, View } from 'react-native';
import { useIsWideScreen } from '../../hooks/responsive';

export type WrapperProps = ViewProps & {
  children: any;
  noPadding?: boolean;
};

const Wrapper = (props: WrapperProps) => {
  const isWideScreen = useIsWideScreen();

  return (
    <View
      {...props}
      style={[
        styles.container,
        isWideScreen && styles.containerWide,
        props.noPadding && styles.containerNoPadding,
        isWideScreen && props.noPadding && styles.containerNoPaddingWide,
        props.style,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 1000,
    marginHorizontal: 'auto',
    paddingHorizontal: 20,
  },
  containerWide: {
    paddingTop: 50,
  },
  containerNoPadding: {
    paddingHorizontal: 0,
    paddingTop: 0,
    margin: 0,
  },
  containerNoPaddingWide: {
    marginHorizontal: 'auto',
  },
});

export default Wrapper;
