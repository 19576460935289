import React, { useState } from 'react';
import * as Localization from 'expo-localization';
import TextInput from '../components/TextInput/TextInput';
import { t } from '../helpers/translation';
import { StyleSheet } from 'react-native';
import PhoneNumberInput from '../components/PhoneNumberInput/PhoneNumberInput';
import Button from '../components/Button/Button';

type CreateBookingFormProps = {
  sending: boolean;
  onSubmit: (values: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumberCountryCode: string;
    phoneNumber: string;
    comment?: string;
  }) => void;
};

const CreateBookingForm = (props: CreateBookingFormProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState(
    Localization.region
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [comment, setComment] = useState('');

  return (
    <>
      <TextInput
        type="text"
        editable
        required
        value={firstName}
        onChangeText={setFirstName}
        label={t('common.firstName')}
        style={styles.input}
      />

      <TextInput
        type="text"
        editable
        required
        value={lastName}
        onChangeText={setLastName}
        label={t('common.lastName')}
        style={styles.input}
      />

      <TextInput
        type="email"
        editable
        required
        value={email}
        onChangeText={setEmail}
        label={t('common.email')}
        placeholder="email@mail.com"
        style={styles.input}
      />

      <PhoneNumberInput
        required
        label={t('screens.join.inputs.phoneNumber.label')}
        placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
        phoneNumber={phoneNumber}
        onChangePhoneNumber={setPhoneNumber}
        countryCode={phoneNumberCountryCode}
        onChangeCountryCode={setPhoneNumberCountryCode}
        style={styles.input}
      />

      <TextInput
        editable
        multiline
        numberOfLines={3}
        value={comment}
        onChangeText={setComment}
        label={t('forms.createBooking.inputs.comment.placeholder')}
        style={styles.input}
      />

      <Button
        title={t('forms.createBooking.buttons.book')}
        busy={props.sending}
        onPress={() => {
          props.onSubmit({
            firstName,
            lastName,
            email,
            phoneNumberCountryCode,
            phoneNumber,
            comment,
          });
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginBottom: 20,
  },
});

export default CreateBookingForm;
