export default {
  navigation: {
    account: 'Account',
    bookingDetails: 'Booking %{reference}',
    contact: 'Contact',
    home: 'Home',
    dashboard: 'Dashboard',
    help: 'Help',
    join: 'Join',
    myPricing: 'Configure services/products',
    myOrders: 'My orders',
    myBookings: 'My bookings',
    myLoyaltyProgram: 'Configure loyalty program',
    orderDetails: 'Order %{reference}',
    paymentSettings: 'Configure payments',
    planning: 'Configure business hours',
    pricing: 'Shops',
    productPricing: 'Pricing',
    roomTables: 'Manage tables',
    settings: 'Settings',
    signIn: 'Sign in',
    signOut: 'Sign out',
    welcome: 'Create your website',
  },
  common: {
    activate: 'Activate',
    activated: 'Activated',
    add: 'Add',
    address: 'Address',
    business: 'Business',
    cancel: 'Cancel',
    change: 'Change',
    close: 'Close',
    comment: 'Comment',
    configure: 'Configure',
    continue: 'Continue',
    customer: 'Customer',
    deactivated: 'deactivated',
    description: 'Description',
    edit: 'Edit',
    email: 'Email address',
    filters: 'Filtres',
    firstName: 'First name',
    free: 'Free',
    getStarted: 'Get started',
    go: 'Go',
    lastName: 'Last name',
    learnMore: 'Learn more',
    name: 'Name',
    new: 'New',
    next: 'Next',
    open: 'Open',
    optional: 'optional',
    order: 'Order',
    password: 'Password',
    pause: 'Pause',
    payment: 'Payment',
    previous: 'Previous',
    seeDetails: 'see details',
    soon: 'Soon',
    status: 'Status',
    today: 'Today',
    update: 'Update',
    view: 'View',
    walkIn: 'Walk-in customer',
  },
  errors: {
    basicMessage: 'There was an error. Try again or get in touch with us.',
  },
  status: {
    cancelled: 'Cancelled',
    confirmed: 'Confirmed',
    doing: 'Doing',
    done: 'Ready',
    hold: 'On hold',
    paid: 'Paid',
    unpaid: 'Unpaid',
    waiting: 'Waiting',
  },
  components: {
    bookingDetails: {
      headers: {
        bookingNumber: 'Booking %{referenceNumber}',
      },
    },
    carousel: {
      buttons: {
        tapAnywhere: 'Tap anywhere',
      },
    },
    cart: {
      headers: {
        yourOrder: 'Your order',
      },
      buttons: {
        makeOrder: 'Make order',
        viewArticles: 'See my basket (%{count})',
      },
    },
    cartItems: {
      misc: {
        payInShop: ' - To pay in shop',
      },
    },
    category: {
      placeholders: {
        name: 'Category name',
        description: 'Category description',
      },
      buttons: {
        create: 'Create category',
        delete: 'Delete category',
      },
    },
    categoryContainer: {
      alerts: {
        deleteCategory:
          'Are you sure you want to delete this category and its items? This cannot be undone.',
      },
    },
    countryPicker: {
      placeholders: {
        searchCountry: 'Find your country',
      },
    },
    downloadAppBanner: {
      headers: {
        download: 'Download the app for free',
      },
    },
    featuresJob: {
      headers: {
        tailoredFor: 'Tailored for',
        everyBusiness: 'every business',
        jobs: {
          hairdressers: 'hairdressers',
          restaurants: 'restaurants',
        },
      },
    },
    joinBanner: {
      headers: {
        title: 'Great business is only a few steps away',
      },
    },
    item: {
      placeholders: {
        name: 'Item name',
        description: 'Item description',
      },
      buttons: {
        addOptions: 'Add an option (toppings, size)',
        showOptions: 'Show item options (%{count})',
        delete: 'Delete item',
      },
      headers: {
        optionsExtras: 'Options & extras',
      },
    },
    makeBooking: {
      actions: {
        bookTable: 'Book a table',
      },
      headers: {
        bookingNumber: 'Booking #%{reference}',
      },
      paragraphs: {
        bookingConfirmed:
          'Your booking has been confirmed. You will receive a recap by email shortly.',
        bookingWith: 'Your booking with %{name}',
        noBookings: 'There is no available booking for this day.',
      },
      labels: {
        guestsNumber: 'How many people?',
        onePerson: '1 person',
        manyPeople: '%{count} people',
        pickTime: 'Pick a time',
      },
    },
    planning: {
      alerts: {
        redoPlanning:
          'Are you sure you want to delete and redo your planning? This cannot be undone.',
      },
      banners: {
        dayOff: 'Closed',
        ifClosed:
          "Your business will be considered closed on %{day} if you don't provide opening hours for this day.",
      },
    },
    previewPricingButton: {
      preview: 'Preview',
    },
    priceRangeInput: {
      placeholders: {
        priceFrom: 'Price from',
        priceUpTo: 'Price up to',
      },
    },
    priceInput: {
      placeholders: {
        typeText: 'Type text',
        price: 'Set price if not free',
      },
    },
    priceTypeSelector: {
      options: {
        value: 'Value: €X',
        range: 'Range: €X-€Y',
        up: 'Up: €X up',
        other: 'Custom text',
      },
    },
    itemsContainer: {
      buttons: {
        addItem: 'Add item',
        createItem: 'Create item',
        sortItems: 'Sort items',
        finishSorting: 'Finish sorting',
      },
    },
    itemContainer: {
      alerts: {
        deleteItem:
          'Are you sure you want to delete this item? This cannot be undone.',
      },
    },
  },
  forms: {
    addContactInformation: {
      headers: {
        yourInformation: 'Your information',
        informationSent: 'Good to go %{name}',
      },
      actions: {
        addMyInformation: 'Add my contact information',
      },
      paragraphs: {
        legal:
          'In accordance with the General Data Protection Regulation, your information is kept confidential and on our services for 14 days. Neither this business or anyone else has the right to get access to your information. They can only be shared with local authorities in the event of a local outbreak and following a legal request.',
        goodToGo:
          'You are good to go! Keep in mind that this information is solely kept for 14 days only. All of it has been encrypted and can only be shared with national services for pandemic-related outbreaks.',
        itIsScary:
          'We know it is scary to give away your information but you can trust us on this (we are people just like you and care about privacy!). If you have any further question about our services, feel free to get in touch via our front page.',
      },
    },
    createBooking: {
      buttons: {
        book: 'Book',
      },
      inputs: {
        comment: {
          placeholder: 'Anything you would like us to know about your booking?',
        },
      },
    },
    createItemOption: {
      buttons: {
        addOption: 'Add option',
      },
      inputs: {
        optionName: 'Option name',
        optionDescription: 'Option description',
        isDefault: 'Default option',
      },
    },
    createItemOptionCategory: {
      buttons: {
        createOptions: 'Create option',
        deleteCategory: 'Delete option',
      },
      inputs: {
        tickCount: {
          leftText: 'Maximum of options that can be selected',
          hint:
            'How many options can be selected? e.g. how many toppings? A unique size?',
        },
      },
    },
    createPricing: {
      slug: {
        title: 'Website',
        indications:
          'To create your website, please choose a simple sharing name. This link is unique and will give your customers access to your online services.',
        placeholder: 'my-business',
        hint:
          'Your link cannot contain any uppercased characters. Only lowercase and dashes are allowed.',
      },
      currency: {
        title: 'Currency',
        indications:
          "Choose the base currency in use for your optional shop. The prices will be converted accordingly to a user's currency.",
      },
    },
    createWalkIn: {
      actions: {
        addCustomerInformation: 'Add customer information (optional)',
      },
      inputs: {
        guests: {
          label: 'Guests',
        },
        pickTable: {
          label: 'Pick a table',
          hint:
            'These are tables recommandations based on your current bookings. You can still pick one that is supposely taken.',
        },
      },
      paragraphs: {
        noTables: 'Add tables from your dashboard to create bookings.',
      },
      buttons: {
        tableSeats: '%{name} (%{seats} seats)',
      },
    },
    editContactTracingSettings: {
      headers: {
        visits: 'Visits',
      },
      inputs: {
        averageVisitDuration: {
          label: 'Average visit duration',
          hint: 'Average time in minutes for each visit in your business.',
        },
      },
    },
    editPricing: {
      alerts: {
        deletePricing:
          'Are you sure you want to delete your pricing? This cannot be undone.',
      },
      buttons: {
        addCategory: 'Add category',
        addCategoryHint: `A category corresponds to a sub-category of your pricing. For example: "Salads", "Coffees", "Man haircuts"...are all valid categories. You then can associate products/services to it.`,
        deletePricing: 'Delete Pricing',
      },
      banners: {
        share: {
          title: 'Spread the word',
          subtitle:
            'Let your customers see your pricing! Use a sharable link or print the QR code for them to scan it straight from your business.',
          actions: {
            copyLink: 'Copy website link',
            getQrCodePDF: 'Get QR code PDF',
          },
        },
        configurePayment: {
          canOrder: 'Can customers order?',
          systemIndications: 'PayPal is used as our payment system.',
          createBusinessAccount:
            'You will need a business account to get started (available for free).',
          createApplication:
            'Once your account is created, you need to declare your application via PayPal which will give you both your client ID and secret that you just need to paste below in order to get paid.',
          followSteps: 'Follow these 4 steps to get your credentials,',
          pasteCredentials:
            ' then paste the live client ID and secret, not the ones from sandbox.',
          inputs: {
            clientID: {
              label: 'Client ID',
              placeholder: 'e.g. TRKO2HILPsHEo...',
            },
            clientSecret: {
              label: 'Client secret',
              hint:
                'Your client secret is safely encrypted on our side to protect anyone from stealing it. It will only be used to validate your customers payments.',
              placeholder: 'e.g. TRKO2HILPsHEo...',
            },
          },
          actions: {
            updatePaymentMethod: 'Update payment method',
          },
        },
      },
    },
    addProgramStage: {
      banners: {
        deactivateRewardIndications:
          'You cannot delete a reward as some of your customers might still possess it. However, you can deactivate it so no one can ever get this reward again until you reactive it.',
      },
      buttons: {
        addProgramStage: 'Add program stage',
        editProgramStage: 'Edit program stage',
      },
      headers: {
        createProgramStage: 'Create a program stage',
        editProgramStage: 'Edit a program stage',
      },
      inputs: {
        rewardType: {
          label: 'Reward type',
          options: {
            item: 'Item',
            discount: 'Discount',
          },
        },
        rewardName: 'Reward name',
        rewardDescription: 'Reward description',
        stampsCount: {
          label: 'How many points to reach this reward?',
          placeholder: 'Reward points',
        },
        isRecurrent: {
          label: 'Is recurrent?',
          hint: `If a %{points} points reward is set as recurrent, a customer will obtain it after %{points}, %{doublePoints}, %{triplePoints}...points.`,
        },
        isActivated: 'Is activated?',
      },
    },
    room: {
      titles: {
        createRoom: 'Add a room',
      },
      inputs: {
        name: {
          label: 'Room name',
          hint: 'Ground floor, F1, Garden...',
        },
      },
      actions: {
        addRoom: 'Add this room',
        updateRoom: "Update this room's name",
      },
    },
    roomTable: {
      inputs: {
        name: {
          label: 'Table name',
        },
        seats: {
          label: 'Nb. seats',
        },
        available: {
          label: 'Availability',
        },
      },
    },
  },
  screens: {
    addContactInformation: {
      title: 'Contact tracing form',
    },
    businessBookings: {
      paragraphs: {
        noBooking: 'You have no bookings yet.',
        pickBooking: 'Pick a booking to see its details.',
      },
      inputs: {
        searchBookings: {
          placeholder: 'Search bookings by name, email, number...',
        },
      },
    },
    businessOrders: {
      paragraphs: {
        noOrder: 'You have no orders yet.',
        pickOrder: 'Pick an order to see its details.',
      },
      inputs: {
        searchOrders: {
          placeholder: 'Search orders by name, email, number...',
        },
      },
    },
    createPlanning: {
      actions: {
        redoPlanning: 'Redo planning',
        confirmPlanning: 'Confirm my planning',
        addSlot: 'Add more hours',
        deleteSlot: 'Remove hours',
        goNextDay: 'Go to next day',
        sameDayBusinessHours: 'My hours for %{day} are the same as yesterday',
        dontWorkOnThatDay: 'I do not work on %{day}',
      },
      banners: {
        allSet:
          'Congratulations, your planning can now be seen by your customers. There is no extra step, you are now all set!',
        slotFrom: 'Slot from',
        slotTo: 'to',
        iStartAt: 'I start at',
      },
      inputs: {
        capacity: {
          label: 'Maximum nb. of customers per slot',
          placeholder: 'Capacity',
          hint: 'Leave to 0 if there is no maximum limit',
        },
      },
    },
    dashboard: {
      banners: {
        paymentsNotConfigured:
          'Configure payments if you want to activate online payments.',
        seeLiveDashboard: 'See your orders and bookings LIVE',
        instructionsToSeeLiveDashboard:
          'Activate orders and/or bookings to see them LIVE',
      },
      actions: {
        previewMyWebsite: 'Preview my website',
        openContactTracingQRCodePDF: 'Get QR code PDF',
        copyContactTracingFormLink: 'Copy contact tracing form link',
        openContactTracingForm: 'Open contact tracing form',
      },
    },
    editContactTracingSettings: {
      title: 'Configure contact tracing',
    },
    home: {
      title: 'Your online business services',
      banners: {
        covid:
          'Give us some feedback and get all our services for free for a month.',
        tellAFriend: {
          title: 'Tell a friend, get it for free',
          subtitle:
            "We believe in word-of-mouth. Save from €20 a month by getting a friend onboard! It's dangerous to go alone!",
        },
      },
      headers: {
        title: 'The all-in-one toolbox you need to share your services online.',
        // title: 'Share your services online without the scary bits.',
        trustedBy: 'Trusted by 1000+ businesses,',
        whyNotYou: 'why not you?',
      },
      steps: {
        addBusiness: {
          title: 'Add your business',
          text:
            "Fill in your information (name, address, logo) and you're ready to go.",
        },
        createPricing: {
          title: 'Choose your services',
          text:
            'Create your online shop, a loyalty program using our smart and easy tools.',
        },
        shareLink: {
          title: 'Share your website',
          text:
            'You will be given a sharable link of your choice and a QR code to share with your customers!',
        },
      },
      features: {
        saveMoney: {
          title: 'Increase your revenue',
          text:
            'Save time and money for what really matters and expand your customer base!',
        },
        improveVisibility: {
          title: 'Improve your online visibility',
          text:
            'One who can check your business online is a potential customer!',
        },
      },
      products: {
        conciergerie: {
          title: 'Try The Conciergerie',
          subtitle:
            'Let us take care of setting up your account and services for you.',
          features: {
            simplicity: {
              title: 'Simplicity',
              text:
                'We will call you back and set up all you need for you: menu, orders, bookings and more.',
            },
            fast: {
              title: 'Fast',
              text:
                'It takes only 2 days top to have it all setup. Why not giving it a try?',
            },
          },
        },
        pricing: {
          title: 'Website',
        },
        orders: {
          title: 'Orders',
          subtitle:
            'Making orders should be simple. Let your customers put orders using safe payments.',
        },
        bookings: {
          title: 'Bookings',
          subtitle:
            'Your customers need to make bookings. Do this safely for all your services.',
        },
        contactTracing: {
          title: 'Contact tracing',
          subtitle:
            "A simple and confidential tool to keep track of your customers' information.",
        },
        memberships: {
          title: 'Loyalty Cards',
          subtitle:
            'Digital loyalty cards allow you to understand and reward your most loyal customers better!',
        },
      },
      contactUs: {
        makeYourLifeEasier:
          'We strive to make your life easier. If there is anything else we can do better,',
        contactByEmail: 'contact us by email',
      },
    },
    item: {
      buttons: {
        removeFromCart: 'Remove from cart',
        addToCart: 'Add to cart',
      },
      paragraphs: {
        chooseUpTo: 'choose up to',
        oneOption: 'one option',
        manyOptions: '%{options} options',
      },
    },
    join: {
      headers: {
        thanksForJoining:
          "We are very happy to see you joining us! Let's start with filling in all the information below.",
        yourInformation: 'Your information',
        yourBusinessInformation: 'Your business information',
      },
      inputs: {
        phoneNumber: {
          placeholder: 'e.g. 0102030405',
          label: 'Phone number',
          hint:
            'This will not be shared with third-parties. It can only be used for authorization purpose.',
        },
        businessName: {
          placeholder: 'Business name',
        },
        businessAddress: {
          hint:
            'Indicate your full address (country included), e.g. 7 Main Street, 12345 Paris, France. We will then find it automatically using Google services.',
        },
        businessPhoneNumber: {
          label: 'Business phone number',
          hint:
            'This phone number should be valid for customers to contact you if necessary.',
        },
      },
      banners: {
        covid: {
          title: 'We are there for you',
          text:
            'In order to help you as much as we can during this pandemic, all our services are available for free. Our pricing will get back to normal in September, starting from €9.99/mo so it gives you time to try illicarte.',
        },
      },
    },
    liveDashboard: {
      actions: {
        addWalkIn: 'Add walk-in',
      },
      inputs: {
        search: {
          placeholder: 'Search by name, email, number...',
        },
      },
    },
    makeOrder: {
      headers: {
        orderSuccess: 'Thank you! Your order is on the way.',
        orderFollowUp: 'Order follow-up',
        referenceNumber: 'Reference number:',
        orderTotal: 'Your order total is %{total}.',
        yourContactDetails: 'Your contact details',
      },
      banners: {
        orderSuccess:
          'You can now collect your order once it is ready. Do not hesitate to contact the business owner on how you should collect your order.',
        orderFollowUp:
          'In order to identify your order, we gave you a reference number to both you and the business owner in order to confirm you put this order. As this information is very important, we made sure of sending you a copy of your order by email so you can access your details anytime.',
        yourContactDetails:
          'In order for the business to get back to you and identify your order, please provide your contact details below.',
      },
      inputs: {
        comment: {
          placeholder: 'Anything you would like us to know about your order?',
        },
        paymentType: {
          label: 'How would you like to pay for your order?',
          inShop: 'In shop',
          online: 'Online',
        },
      },
    },
    memberships: {
      banners: {
        message:
          'Your loyalty program can be viewed on your Plus One dashboard (an illicarte application) using your illicarte login credentials (same email/password).',
        noRewards: 'You have no rewards in your program yet. Create one!',
      },
      actions: {
        createProgram: 'Create program',
        downloadIOSApp: 'Download Plus One on iOS',
        downloadAndroidApp: 'Download Plus One on Android',
        deleteProgram: 'Delete loyalty program',
      },
      alerts: {
        deleteMembership:
          'Are you sure you want to delete this loyalty program? Your customers will lose all their rewards too. This cannot be undone.',
      },
      paragraphs: {
        everyXPoints: `Every %{points} points`,
        XPoints: '%{points} points',
        indications: {
          whatIsAMembership:
            'A loyalty program allows your customers to collect points in your shop and give you a better overview of who is your customer base using our statistics.',
          onceCreated:
            'After you create your program, it will be available to your customers from the Plus One mobile application which you can download for free. Your statistics can also be seen on the application.',
          connectionWithApp:
            'All you need to do is to login using your illicarte login on Plus One (same email/password). You can edit your loyalty program either from here or the app.',
        },
      },
    },
    orderDetails: {
      title: `Order %{referenceNumber} details`,
      errors: {
        orderNotFound:
          'Your order could not be found. If you are certain your reference number is correct (here: %{referenceNumber}), please get in touch with us.',
        noComment: 'No comment was provided.',
      },
      headers: {
        orderNumber: 'Order %{referenceNumber}',
      },
      banners: {
        paymentInformation:
          'The order payment information can be found on PayPal dashboard or through the email you received after making this order.',
      },
    },
    paymentSettings: {
      banners: {
        onlyClickCollect:
          'If you only need a click and collect solution, you do not need to set up payments. Your customers will pay in store rather than online.',
      },
      actions: {
        deactivateOnlinePayments: 'Deactivate online payments',
      },
    },
    pricing: {
      banners: {
        noSlug:
          'We will soon showcase the best pricings from our customers to inspire you. Stay tuned.',
      },
      actions: {
        getDirections: 'Directions',
      },
      price: {
        rangeFromTo: 'From %{from} to %{to}',
        up: '%{price} up',
      },
      links: {
        createdWith: 'Created with illicarte',
      },
    },
    products: {
      headers: {
        whatYouGet: 'What you get',
        youMightAlsoNeed: 'You might also need...',
      },
      included: {
        onDemand: 'On demand',
        onboardingSupport: 'Onboarding support',
        socialMediaMaterials: 'Social media marketing materials',
        customBranding: 'Custom branding (logo, name)',
        securePayments: 'Secure payments',
        mobileAppAccess: 'Custom access to illicarte mobile application',
        customDomainName: 'Custom domain name',
      },
      actions: {
        watchVideo: 'Watch how it works',
      },
    },
    productBookings: {
      features: {
        fullHouse: {
          title: 'Play to a full house',
          subtitle:
            'Make it simple for your customers to book appointments online and increase your revenue.',
        },
        organize: {
          title: 'Make your organization even better',
          subtitle:
            'A great booking system lets you better plan your quantities and schedule.',
        },
        wastingTime: {
          title: 'Stop wasting your time on the phone',
          subtitle: 'Your customers book entirely online.',
        },
        scheduleUpToDate: {
          title: 'Your schedule up-to-date',
          subtitle:
            'Your customers check your schedule and availability in real time.',
        },
        missedBookings: {
          title: 'Say goodbye to no-shows',
          subtitle: 'Your customers pay in advance online.',
        },
      },
      included: {
        personalSpace: 'Personal space for your services',
        bookingConfirmation: 'Mail booking confirmations',
      },
      faq: {
        setUpBookings: {
          question: 'Does it take long to set up bookings?',
        },
      },
    },
    productContactTracing: {
      features: {
        dataSafety: {
          title: 'Your data is safe',
          subtitle:
            'Only strictly necessary data is collected, put in a safe place then deleted after 14 days.',
        },
        simplifyLife: {
          title: 'Keep things simple',
          subtitle:
            'Our services adapt to the rapidly changing safety mesures to keep your life simple in these difficult times.',
        },
        quickSetup: {
          title: 'Set it up in minutes',
          subtitle: 'Create your online contact tracing form in minutes.',
        },
        focusOnWhatMatters: {
          title: 'Focus on what matters',
          subtitle:
            "We take care of sharing information with authorities when needed so you don't have to.",
        },
        autonomousClients: {
          title: 'Your customers are in charge',
          subtitle:
            'They now can fill in their information through the online form.',
        },
        confidential: {
          title: 'Your data is kept confidential',
          subtitle: "Your customers' confidential information is kept safe.",
        },
      },
      steps: {
        getFormLink: 'Get a form link to share with your customers',
        clientsFillForm: 'Your customers fill it in and send it',
        weTakeCareOfTheRest: 'We take care of everything else!',
      },
      faq: {
        safety: {
          question: 'Is it really safe?',
          answer:
            "We are living just like you big changes in our daily lives and are keeping at heart your data safety. Your customers' information is only kept for 14 days according to the General Data Protection Regulation, is encrypted and only competent authorities can have access to them when legally required.",
        },
        timeManagement: {
          question: 'Is it really a gain of time?',
          answer:
            "When you need to keep track of your customers' information, the responsability goes back on you and your employees. What we suggest to do and for free, is to take care of all of it for you. You will only need to ask your customers to send their information via your online website (which will be given to you once registered). If local authorities come to you, you simply need to redirect them to us for any request.",
        },
        setUpTracing: {
          question: 'Is it difficult to set things up?',
          answer:
            'Once your account is created, you can activate your contact tracing form in a click on your dashboard. A link for your form will then be given to you so your customers can fill it in before getting inside your business.',
        },
      },
    },
    productMemberships: {
      features: {
        understandCustomers: {
          title: 'Understand your customers',
          subtitle:
            'Learn from their habits using your personal dashboard and statistics.',
        },
        simplicity: {
          title: 'Simplicity for everyone',
          subtitle:
            'Switching to digital loyalty cards is a matter of minutes for you and your customers!',
        },
        adjustedRewards: {
          title: 'Adjusted rewards',
          subtitle: 'A discount after 5 points, a coffee every 10 points.',
        },
        useYourPhone: {
          title: 'Use your phone',
          subtitle: 'Scan your customers’ QR code and you are all set up!',
        },
        detailedStatistics: {
          title: 'Detailed statistics',
          subtitle: 'Understand how your customer base evolves.',
        },
        noPrinting: {
          title: 'No card printing anymore',
          subtitle: 'Reduce your costs and protect the environment.',
        },
      },
      steps: {
        createMembership: 'Clients create their card on Plus One app',
        scanCode: 'The client QR code is scanned by a business’ employee',
        getRewards: 'The client gets their points and rewards!',
      },
      included: {
        customBranding: 'Custom branding (logo, name, card)',
        loyaltyProgram: 'Loyalty program with unlimited reward stages',
        customerStatistics: 'Customers statistics',
        mobileAppAccess: 'Custom access to Plus One mobile application',
        businessPromotion: 'Promotion to all our application users',
      },
      faq: {
        anotherService: {
          question: 'Why our loyalty program service?',
          answer:
            "Most online loyalty program platforms will take much more money from you than they should. They know you don't know how much does it really cost and will take advantage of it. We understand that this is an essential service for every type of business and have created an offer accordingly to your needs without compromising quality.",
        },
        setUpMemberships: {
          question: 'Does it take long to set up loyalty cards?',
          answer:
            'All you really need is a smartphone. Customers coming in can create their loyalty card from the online website or mobile application. You then only need to scan their QR code to add as many points as you would like onto their card. If you already have an account with us for other services, you will have to edit your loyalty program which only takes a few minutes.',
        },
      },
    },
    productOrders: {
      features: {
        notifications: {
          title: 'Be notified',
          subtitle:
            'Get an email when a customer makes an order. Your customers will also get a copy straight into their inbox!',
        },
        payments: {
          title: 'Safe payments',
          subtitle:
            'When going online, you need peace of mind. PayPal takes care of customer payments in the safest way possible.',
        },
        middlemen: {
          title: 'Farewell middlemen',
          subtitle:
            'No more commissions. Pay a fixed cost, no matter how much you sell.',
        },
        safety: {
          title: 'Trade safely',
          subtitle:
            'Our systems guarantee the safety you need online (payments, website).',
        },
        ordersNotifications: {
          title: 'Your orders notified',
          subtitle:
            'You will be notified of every order so you do not miss out.',
        },
        complexity: {
          title: 'Your complexity simplified',
          subtitle:
            'Our platform will adapt to your needs to simplify your selling workflow.',
        },
      },
      headers: {
        faq: 'Questions worth asking yourself',
      },
      steps: {
        yourWebsite: 'A website to access all your products & services',
        clientsBuy: 'Clients decide what they want to buy',
        clientsPutOrder: 'Customers put their order, you get the details',
      },
      faq: {
        anotherService: {
          question: 'Why our online shop service?',
          answer:
            "Most shopping platforms will charge you more than they should. They are aware you don't know how much a website really costs and will take advantage of it. We do not take any money for your payments, only PayPal fees will apply so you can benefit from their 20+ years experience in safe payment system.",
        },
        setUpOrders: {
          question: 'Does it take long to set up orders?',
          answer:
            'Once your pricing is created (e.g. menu, list of services), you will create a free PayPal business account. You’ll be guided step by step to complete the registration process in a matter of minutes. Remember that we are here to assist in case you find any difficulties.',
        },
        getMoney: {
          question: 'How do I get my money?',
          answer:
            'After linking your free PayPal business account to our platform, we will then safely validate payments and check if the amount paid matches their order. All the money will then be available straight through your PayPal dashboard.',
        },
        trust: {
          question: 'Why should I trust you?',
          answer:
            'All we care about is helping you out. This platform is made for you to reach your customers from anywhere, the easiest way. We are a business, yes, yet a business that cares a lot about you.',
        },
      },
      included: {
        shopSpace: 'Personal space for your shop',
        orderConfirmation: 'Email order confirmations',
        customMobileApp: 'Custom mobile application',
      },
    },
    roomTables: {
      actions: {
        createRoom: 'Add a room and tables',
      },
      banners: {
        explanations:
          'By adding rooms/tables, you allow your customers to make bookings which will automatically be associated to the best matching tables.',
        noRooms:
          'There are no rooms and tables at the moment. Add some using the form.',
      },
    },
    settings: {
      inputs: {
        password: {
          hint: 'If set, this will override your current password.',
        },
      },
      buttons: {
        updateInformation: 'Update information',
        updateBusinessLogo: 'Update business logo',
      },
    },
    signIn: {
      banners: {
        createYourPricing: 'Want to create your pricing?',
        joinUs: 'Join us',
      },
    },
    welcome: {
      banners: {
        startWebsite: {
          title: 'Start with a website',
          subtitle:
            'In order for your customers to find you online, we need to setup your website.',
        },
        thankYouForJoining: {
          title: 'Thank you for joining us',
          subtitle:
            'If there is anything we can do, feel free to drop us a message so we can further assist you. We prepared a few tutorials for you below so you do not feel lost, take a look!',
        },
        website: {
          title: 'Products and services',
          subtitle: 'You want to set up a menu or a list of products/services.',
        },
        orders: {
          title: 'Orders',
          subtitle: 'You want your customers to order online.',
        },
        bookings: {
          title: 'Bookings',
          subtitle: 'You want your customers to book online.',
        },
        loyaltyProgram: {
          title: 'Loyalty Program',
          subtitle: 'You want to create a loyalty program for your customers.',
        },
        contactTracing: {
          title: 'Contact tracing',
          subtitle: 'You want to add contact tracing to your business.',
        },
      },
      headers: {
        whereToStart: 'Where should we start?',
        learnAboutOurServices:
          'Learn about our services through short tutorial videos.',
      },
      actions: {
        skip: 'Continue, I understand how it works',
      },
    },
  },
};
