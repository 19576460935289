import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { DarkTheme, DefaultTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';

export type BlockProps = ViewProps & {
  children: any;
  list?: boolean;
  horizontalMargins?: boolean;
  verticalMargins?: boolean;
};

const Block = (props: BlockProps) => {
  const scheme = useColorScheme();

  return (
    <View
      {...props}
      style={[
        styles.container,
        scheme === 'dark' && styles.containerDark,
        props.horizontalMargins && styles.containerHorizontalMargins,
        props.verticalMargins && styles.containerVerticalMargins,
        props.list && styles.containerList,
        props.style,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: DefaultTheme.colors.card,
    borderWidth: 1,
    borderColor: DefaultTheme.colors.border,
    borderRadius: 6,
  },
  containerDark: {
    backgroundColor: DarkTheme.colors.card,
    borderColor: DarkTheme.colors.border,
  },
  containerHorizontalMargins: {
    marginHorizontal: 20,
  },
  containerVerticalMargins: {
    marginVertical: 20,
  },
  containerList: {
    padding: 0,
    paddingLeft: 20,
    paddingVertical: 20,
  },
});

export default Block;
