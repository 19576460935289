import React from 'react';
import {
  Image,
  StyleSheet,
  View,
  ImageSourcePropType,
  GestureResponderEvent,
} from 'react-native';
import { useIsWideScreen } from '../../hooks/responsive';
import Link from '../Link/Link';
import Icon from '../Icon/Icon';
import Theme from '../../helpers/theme';
import Title from '../Title/Title';
import H1 from '../H1/H1';

export type HeroProps = {
  title: any;
  subtitle?: any;
  image?: ImageSourcePropType;
  action?: any;
  actionIcon?: string;
  onPressAction?: (event: GestureResponderEvent) => void;
};

const Hero = (props: HeroProps) => {
  const isWideScreen = useIsWideScreen();

  return (
    <View
      style={[
        styles.container,
        !props.image && styles.containerNoImage,
        isWideScreen && styles.containerWide,
      ]}
    >
      {props.image && (
        <Image source={props.image} style={styles.image} resizeMode="contain" />
      )}

      <View style={styles.titleContainer}>
        <Title style={[styles.title, isWideScreen && styles.titleWide]}>
          {props.title}
        </Title>

        {props.subtitle && (
          <H1 style={[styles.subtitle, isWideScreen && styles.subtitleWide]}>
            {props.subtitle}
          </H1>
        )}

        {props.action && props.onPressAction && (
          <View style={[styles.action, isWideScreen && styles.actionWide]}>
            {props.actionIcon && (
              <Icon
                name={props.actionIcon}
                color={Theme.PRIMARY}
                size={18}
                style={styles.actionIcon}
              />
            )}

            <Link
              onPress={props.onPressAction}
              to="/"
              style={styles.actionText}
            >
              {props.action}
            </Link>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingBottom: 30,
    paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerWide: {
    paddingTop: 0,
    flexDirection: 'row',
  },
  containerNoImage: {
    paddingTop: 50,
  },
  image: {
    height: 225,
    width: 300,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  titleWide: {
    textAlign: 'left',
  },
  subtitle: {
    marginTop: 10,
    textAlign: 'center',
  },
  subtitleWide: {
    textAlign: 'left',
  },
  action: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionWide: {
    justifyContent: 'flex-start',
  },
  actionText: {
    fontSize: 18,
  },
  actionIcon: {
    marginRight: 8,
  },
});

export default Hero;
