import React from 'react';
import { View, StyleSheet, TouchableOpacity, ViewProps } from 'react-native';
import range from 'lodash.range';
import chunk from 'lodash.chunk';
import moment, { Moment } from 'moment';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import Theme from '../../helpers/theme';

type DayProps = {
  i: number;
  w: number;
  d: number;
  onPress?: () => void;
  style?: ViewProps['style'];
};

const Day = (props: DayProps) => {
  const prevMonth = props.w === 0 && props.i > 7;
  const nextMonth = props.w >= 4 && props.i <= 14;

  return (
    <TouchableOpacity onPress={props.onPress} style={props.style}>
      <Text
        style={[
          styles.day,
          (prevMonth || nextMonth) && styles.dayOtherMonth,
          !prevMonth && !nextMonth && props.i === props.d && styles.dayCurrent,
        ]}
      >
        {props.i}
      </Text>
    </TouchableOpacity>
  );
};

type CalendarProps = {
  date: Date;
  minimumDate?: Date;
  maximumDate?: Date;
  onChange: (date: Date) => void;
  onClose: () => void;
};

const Calendar = (props: CalendarProps) => {
  const scheme = useColorScheme();

  const selectDate = (i: number, w: number) => {
    const prevMonth = w === 0 && i > 7;
    const nextMonth = w >= 4 && i <= 14;

    const nextDate = moment(props.date).clone();

    if (prevMonth) {
      nextDate.subtract(1, 'month');
    }

    if (nextMonth) {
      nextDate.add(1, 'month');
    }

    nextDate.date(i);

    if (props.minimumDate) {
      const minimumDate = moment(props.minimumDate);

      if (
        nextDate.get('month') === minimumDate.get('month') &&
        nextDate.get('date') < minimumDate.get('date')
      ) {
        return;
      }
    }

    if (props.maximumDate) {
      const maximumDate = moment(props.maximumDate);

      if (
        nextDate.get('month') === maximumDate.get('month') &&
        nextDate.get('day') > maximumDate.get('day')
      ) {
        return;
      }
    }

    props.onChange(nextDate.toDate());
    props.onClose();
  };

  const prevMonth = () => {
    props.onChange(moment(props.date).subtract(1, 'month').toDate());
  };

  const nextMonth = () => {
    props.onChange(moment(props.date).add(1, 'month').toDate());
  };

  const m = moment(props.date);
  const d = m.date();
  const d1 = m.clone().subtract(1, 'month').endOf('month').date();
  const d2 = m.clone().date(1).day();
  const d3 = m.clone().endOf('month').date();
  const days = [].concat(
    // @ts-ignore
    range(d1 - d2 + 1, d1 + 1),
    range(1, d3 + 1),
    range(1, 42 - d3 - d2 + 1)
  );
  const weeks = moment.weekdays();

  return (
    <View style={styles.container}>
      <View style={styles.toolbar}>
        {(!props.minimumDate ||
          (props.minimumDate &&
            // TODO: URGENT, will not work. Need to check
            // for same month same year or different mechanism
            m.get('month') > moment(props.minimumDate).get('month') &&
            m.get('year') >= moment(props.minimumDate).get('year'))) && (
          <Icon name="arrow-left" color={Theme.PRIMARY} onPress={prevMonth} />
        )}

        <Text style={styles.currentDate}>{m.format('MMMM YYYY')}</Text>

        {(!props.maximumDate ||
          (props.maximumDate &&
            m.get('month') < moment(props.maximumDate).get('month'))) && (
          <Icon name="arrow-right" color={Theme.PRIMARY} onPress={nextMonth} />
        )}
      </View>

      <View style={[styles.calendar, scheme === 'dark' && styles.calendarDark]}>
        <View
          style={[
            styles.calendarRow,
            scheme === 'dark' && styles.calendarRowDark,
          ]}
        >
          {weeks.map((w, i) => (
            <Text
              key={i}
              style={[
                styles.calendarCell,
                scheme === 'dark' && styles.calendarCellDark,
                styles.calendarHeaderCell,
              ]}
            >
              {w.substr(0, 3)}
            </Text>
          ))}
        </View>

        <View>
          {chunk(days, 7).map((row, w) => (
            <View
              key={w}
              style={[
                styles.calendarRow,
                scheme === 'dark' && styles.calendarRowDark,
              ]}
            >
              {row.map((i) => (
                <Day
                  key={i}
                  i={i}
                  d={d}
                  w={w}
                  onPress={() => selectDate(i, w)}
                  style={[
                    styles.calendarCell,
                    scheme === 'dark' && styles.calendarCellDark,
                  ]}
                />
              ))}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  toolbar: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  currentDate: {
    flex: 1,
    textTransform: 'capitalize',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  calendar: {
    marginTop: 10,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderColor: DefaultTheme.colors.border,
  },
  calendarDark: {
    borderColor: DarkTheme.colors.border,
  },
  calendarRow: {
    flexDirection: 'row',
    borderColor: DefaultTheme.colors.border,
    borderBottomWidth: 1,
  },
  calendarRowDark: {
    borderColor: DarkTheme.colors.border,
  },
  calendarHeaderCell: {
    textAlign: 'center',
    textTransform: 'capitalize',
    paddingVertical: 8,
    fontWeight: 'bold',
  },
  calendarCell: {
    flex: 1,
    borderColor: DefaultTheme.colors.border,
    borderRightWidth: 1,
  },
  calendarCellDark: {
    borderColor: DarkTheme.colors.border,
  },
  day: {
    textAlign: 'center',
    padding: 8,
  },
  dayOtherMonth: {
    opacity: 0.4,
  },
  dayCurrent: {
    color: Theme.PRIMARY,
    fontWeight: 'bold',
  },
});

export default Calendar;
