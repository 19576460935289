import React from 'react';
import {
  StyleSheet,
  View,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
} from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
// @ts-ignore
import color from 'color';
import Text from '../Text/Text';
import Hint from '../Hint/Hint';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';

export type TextInputProps = RNTextInputProps & {
  required?: boolean;
  label?: string;
  type?: 'number' | 'text' | 'email' | 'search' | 'phone' | 'password';
  leftText?: string;
  leftIcon?: string;
  hint?: string;
  forwardedRef?: any;
  inline?: boolean;
  inputStyle?: RNTextInputProps['style'];
};

const TextInput = (props: TextInputProps) => {
  const scheme = useColorScheme();

  const extraProps: TextInputProps = {};
  switch (props.type) {
    case 'email':
      extraProps.autoCapitalize = 'none';
      extraProps.keyboardType = 'email-address';
      break;

    case 'number':
      extraProps.keyboardType = 'numeric';
      break;

    case 'password':
      extraProps.autoCapitalize = 'none';
      extraProps.secureTextEntry = true;
      extraProps.spellCheck = false;
      break;

    case 'phone':
      extraProps.keyboardType = 'phone-pad';
      break;

    case 'search':
      extraProps.keyboardType = 'web-search';
      extraProps.returnKeyType = 'search';
      extraProps.autoCapitalize = 'none';
      extraProps.spellCheck = false;
      break;

    default:
      extraProps.keyboardType = 'default';
  }

  return (
    <View style={[styles.wrapper, props.style]}>
      {props.label && <Label required={props.required}>{props.label}</Label>}

      <View style={styles.input}>
        {props.leftIcon && (
          <View style={styles.leftText}>
            <Icon name={props.leftIcon} size={16} />
          </View>
        )}

        {props.leftText && (
          <View style={styles.leftText}>
            <Text style={styles.leftTextLabel}>{props.leftText}</Text>
          </View>
        )}

        <RNTextInput
          ref={props.forwardedRef}
          {...extraProps}
          {...props}
          style={[
            styles.container,
            scheme === 'dark' && styles.containerDark,
            props.editable && styles.containerEditable,
            props.editable && scheme === 'dark' && styles.containerEditableDark,
            props.inline && styles.containerInline,
            props.inputStyle,
          ]}
        />
      </View>

      {props.hint && <Hint style={styles.hint}>{props.hint}</Hint>}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    flexBasis: 'auto',
    position: 'relative',
  },
  input: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 0.8,
  },
  container: {
    flex: 1,
    color: 'black',
  },
  containerDark: {
    color: 'white',
  },
  containerEditable: {
    padding: 10,
    borderRadius: 6,
    backgroundColor: color(DefaultTheme.colors.card).darken(0.1).string(),
  },
  containerEditableDark: {
    backgroundColor: color(DarkTheme.colors.card).lighten(0.5).string(),
  },
  containerInline: {
    width: 0,
  },
  leftText: {
    marginRight: 5,
  },
  leftTextLabel: {
    fontWeight: 'bold',
  },
  hint: {
    marginTop: 5,
  },
});

export default React.forwardRef<any, Props>((props, ref) => (
  <TextInput forwardedRef={ref} {...props} />
));
