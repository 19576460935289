import React from 'react';
import { Image, ImageSourcePropType, ImageProps } from 'react-native';

type FlagProps = {
  code: keyof typeof flags;
  style?: ImageProps['style'];
};

const flags: { [lang: string]: ImageSourcePropType } = {
  fr: require(`../../../assets/fr.svg`),
  en: require(`../../../assets/en.svg`),
};

const Flag = (props: FlagProps) => {
  return <Image source={flags[props.code]} style={props.style} />;
};

export default Flag;
