import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { t } from '../helpers/translation';
import PreviewPricingButton from '../components/PreviewPricingButton/PreviewPricingButton';
import MenuIcon from '../components/MenuIcon/MenuIcon';
import EditScreen from '../screens/EditScreen';
import DashboardScreen from '../screens/DashboardScreen';
import PaymentSettingsScreen from '../screens/PaymentSettingsScreen';
import MembershipsScreen from '../screens/MembershipsScreen';
import EditRewardScreen from '../screens/EditRewardScreen';
import BusinessOrdersScreen from '../screens/BusinessOrdersScreen';
import CreatePlanningScreen from '../screens/CreatePlanningScreen';
import RoomTablesScreen from '../screens/RoomTablesScreen';
import BusinessBookingsScreen from '../screens/BusinessBookingsScreen';
import BusinessBookingDetailsScreen from '../screens/BusinessBookingDetailsScreen';
import LiveDashboardScreen from '../screens/LiveDashboardScreen';
import { Booking, Order } from '../../types';
import BusinessOrderDetailsScreen from '../screens/BusinessOrderDetailsScreen';
import WelcomeScreen from '../modules/welcome/WelcomeScreen';
import SettingsScreen from '../screens/SettingsScreen';
import EditContactTracingSettingsScreen from '../modules/contact-tracing/EditContactTracingSettingsScreen';

type Props = {};

export type UserStackParamList = {
  BusinessBookingDetails: {
    booking: Booking;
  };
  BusinessOrderDetails: {
    order: Order;
  };
  BusinessBookings: undefined;
  BusinessOrders: undefined;
  CreatePlanning: undefined;
  Dashboard: undefined;
  Edit: undefined;
  EditContactTracingSettings: undefined;
  EditReward: {
    rewardId: string;
  };
  LiveDashboard: undefined;
  Memberships: undefined;
  PaymentSettings: undefined;
  RoomTables: undefined;
  Welcome: undefined;
  Settings: undefined;
};

const Stack = createStackNavigator<UserStackParamList>();

const UserNavigator = (props: Props) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={{
          title: t('navigation.dashboard'),
          headerLeft: () => <MenuIcon />,
        }}
      />

      <Stack.Screen
        name="Edit"
        component={EditScreen}
        options={{
          title: t('navigation.myPricing'),
          headerRight: () => <PreviewPricingButton />,
        }}
      />

      <Stack.Screen
        name="BusinessOrders"
        component={BusinessOrdersScreen}
        options={{
          title: t('navigation.myOrders'),
        }}
      />

      <Stack.Screen
        name="BusinessOrderDetails"
        component={BusinessOrderDetailsScreen}
        options={({ route }) => ({
          title: t('navigation.orderDetails', {
            reference: route.params.order.referenceNumber,
          }),
        })}
      />

      <Stack.Screen
        name="BusinessBookings"
        component={BusinessBookingsScreen}
        options={{
          title: t('navigation.myBookings'),
        }}
      />

      <Stack.Screen
        name="BusinessBookingDetails"
        component={BusinessBookingDetailsScreen}
        options={({ route }) => ({
          title: t('navigation.bookingDetails', {
            reference: route.params.booking.referenceNumber,
          }),
        })}
      />

      <Stack.Screen
        name="Memberships"
        component={MembershipsScreen}
        options={{
          title: t('navigation.myLoyaltyProgram'),
        }}
      />

      <Stack.Screen
        name="EditReward"
        component={EditRewardScreen}
        options={{
          title: t('navigation.myLoyaltyProgram'),
        }}
      />

      <Stack.Screen
        name="PaymentSettings"
        component={PaymentSettingsScreen}
        options={{
          title: t('navigation.paymentSettings'),
        }}
      />

      <Stack.Screen
        name="CreatePlanning"
        component={CreatePlanningScreen}
        options={{
          title: t('navigation.planning'),
        }}
      />

      <Stack.Screen
        name="RoomTables"
        component={RoomTablesScreen}
        options={{
          title: t('navigation.roomTables'),
        }}
      />

      <Stack.Screen
        name="LiveDashboard"
        component={LiveDashboardScreen}
        options={{
          header: () => null,
        }}
      />

      <Stack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{
          title: t('navigation.welcome'),
          headerLeft: () => null,
        }}
      />

      <Stack.Screen
        name="EditContactTracingSettings"
        component={EditContactTracingSettingsScreen}
        options={{
          title: t('screens.editContactTracingSettings.title'),
        }}
      />

      <Stack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: t('navigation.settings'),
        }}
      />
    </Stack.Navigator>
  );
};

export default UserNavigator;
