import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { setUserSetLanguage } from '../../helpers/storage';
import Picker from '../Picker/Picker';
import { getFormattedLocale } from '../../helpers/translation';

type Props = {};

const LanguageSelector = (props: Props) => {
  const language = getFormattedLocale();
  const onChangeLanguage = useCallback((language: string) => {
    setUserSetLanguage(language);
    window.location.reload();
  }, []);

  return (
    <Picker
      selectedValue={language}
      onValueChange={onChangeLanguage}
      style={styles.container}
    >
      <Picker.Item label="🇫🇷 Je parle français" value="fr" />
      <Picker.Item label="I speak english" value="en" />
    </Picker>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
});

export default LanguageSelector;
