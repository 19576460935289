import React, { useState, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import TextInput from '../components/TextInput/TextInput';
import Button from '../components/Button/Button';
import { t } from '../helpers/translation';
import API from '../helpers/api';
import { pricingIdState, itemsState } from '../store/state';
import { PricingItemOption, PricingItemOptionCategory } from '../../types';
import CreateItemOptionForm from './CreateItemOptionForm';
import { useIsWideScreen } from '../hooks/responsive';
import DeleteButton from '../components/DeleteButton/DeleteButton';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';

type Props = {
  categoryId?: string;
  itemId: string;
  name?: string;
  description?: string;
  tickCount?: number;
  categoryOptions?: PricingItemOption[];
  onCreateOption?: (values: {
    name: string;
    description: string;
    price: number;
    isDefault: boolean;
  }) => void;
  onEditOptionEnd?: (itemOptionId: string, key: string, value: any) => void;
  onDeleteOption?: (id: string) => void;
  onCreate?: () => void;
  onEdit?: (key: string, value: any) => void;
  onDelete?: (categoryId: string) => void;
};

const CreateItemOptionCategoryForm = (props: Props) => {
  const [name, setName] = useState(props.name ?? '');
  const [description, setDescription] = useState(props.description ?? '');
  const [tickCount, setTickCount] = useState(props.tickCount ?? 1);
  const [categoryOptions, setCategoryOptions] = useState<PricingItemOption[]>(
    props.categoryOptions ?? []
  );
  const [categoryId, setCategoryId] = useState<number>();
  const [isCreating, setIsCreating] = useState(false);
  const pricingId = useRecoilValue(pricingIdState);
  const setItem = useSetRecoilState(itemsState(props.itemId));
  const isWideScreen = useIsWideScreen();

  const handleAddCategoryAndOptions = useCallback(async () => {
    if (!props.itemId || tickCount <= 0 || categoryOptions.length === 0) {
      return;
    }

    setIsCreating(true);

    try {
      const response = await API.createPricingItemOptionCategory(
        pricingId,
        props.itemId,
        {
          name,
          description,
          tickCount,
        }
      );

      setCategoryId(response.categoryId);

      const category: PricingItemOptionCategory = {
        id: response.categoryId.toString(),
        name,
        description,
        tickCount,
        options: [],
      };

      for (const itemOption of categoryOptions) {
        const optionResponse = await API.createPricingItemOption(
          pricingId,
          props.itemId,
          response.categoryId,
          itemOption
        );

        category.options.push({
          ...itemOption,
          id: optionResponse.optionId.toString(),
        });
      }

      setItem((item) => ({
        ...item,
        optionCategories: item.optionCategories
          ? [...item.optionCategories, category]
          : [category],
      }));

      setName('');
      setDescription('');
      setTickCount(1);
      setCategoryOptions([]);

      props.onCreate && props.onCreate();
    } catch (error) {
      console.warn(error);
    }

    setIsCreating(false);
  }, [name, description, tickCount, categoryOptions]);

  const handleChangeTickCount = useCallback((tickCount: string) => {
    const parsedTickCount = parseInt(tickCount, 10);

    setTickCount(parsedTickCount);

    if (props.onEdit && parsedTickCount !== props.tickCount) {
      props.onEdit('tickCount', parsedTickCount);
    }
  }, []);

  const handleAddOption = useCallback(
    (values: {
      name: string;
      description: string;
      price: number;
      isDefault: boolean;
    }) => {
      const option = {
        id: Date.now().toString(),
        ...values,
      };

      setCategoryOptions((categoryOptions) => [...categoryOptions, option]);

      props.onCreateOption && props.onCreateOption(option);
    },
    []
  );

  return (
    <>
      <ItemSeparator />

      <View style={[isWideScreen && { flexDirection: 'row' }]}>
        <View
          style={[{ flex: 1 }, isWideScreen && { alignSelf: 'flex-start' }]}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <TextInput
              editable
              required
              type="text"
              label={t('common.name')}
              value={name}
              onChangeText={setName}
              onBlur={() => {
                if (props.onEdit && name !== props.name) {
                  props.onEdit('name', name);
                }
              }}
              style={{ flex: 1 }}
            />

            {props.onDelete && props.categoryId && (
              <DeleteButton
                title={t(
                  'forms.createItemOptionCategory.buttons.deleteCategory'
                )}
                onDelete={() => {
                  props.onDelete!(props.categoryId!);
                }}
                style={{ marginLeft: 10 }}
              />
            )}
          </View>

          <TextInput
            editable
            type="text"
            label={t('common.description')}
            multiline
            numberOfLines={1}
            value={description}
            onChangeText={setDescription}
            onBlur={() => {
              if (props.onEdit && description !== props.description) {
                props.onEdit('description', description);
              }
            }}
            style={styles.input}
          />

          <TextInput
            editable
            required
            type="number"
            value={tickCount.toString()}
            onChangeText={handleChangeTickCount}
            // TODO change leftText to label
            label={t(
              'forms.createItemOptionCategory.inputs.tickCount.leftText'
            )}
            hint={t('forms.createItemOptionCategory.inputs.tickCount.hint')}
            placeholder="1"
            inline
            style={styles.input}
          />
        </View>

        <View
          style={[
            { marginTop: 10 },
            isWideScreen && {
              marginTop: 0,
              paddingLeft: 10,
              marginLeft: 10,
              borderLeftWidth: 1,
              borderLeftColor: DefaultTheme.colors.border,
              borderStyle: 'dashed',
              flex: 1,
            },
          ]}
        >
          {categoryOptions.map((option) => (
            <React.Fragment key={option.id}>
              <CreateItemOptionForm
                id={option.id}
                name={option.name}
                description={option.description}
                price={option.price}
                isDefault={option.isDefault}
                onEditEnd={
                  props.onEditOptionEnd
                    ? (key: string, value: any) => {
                        if (props.onEditOptionEnd) {
                          props.onEditOptionEnd(option.id, key, value);
                        }
                      }
                    : undefined
                }
                onEdit={(key: string, value: any) => {
                  setCategoryOptions((categoryOptions) =>
                    categoryOptions.map((categoryOption) => {
                      if (categoryOption.id === option.id) {
                        return {
                          ...categoryOption,
                          [key]: value,
                        };
                      } else {
                        return categoryOption;
                      }
                    })
                  );
                }}
                onDelete={(id: string) => {
                  setCategoryOptions((categoryOptions) =>
                    categoryOptions.filter((categoryOption) => {
                      return categoryOption.id !== id;
                    })
                  );

                  props.onDeleteOption && props.onDeleteOption(id);
                }}
              />

              <ItemSeparator />
            </React.Fragment>
          ))}

          <CreateItemOptionForm onCreate={handleAddOption} />
        </View>
      </View>

      {props.onCreate && (
        <Button
          icon="plus"
          title={t('forms.createItemOptionCategory.buttons.createOptions')}
          onPress={handleAddCategoryAndOptions}
          busy={isCreating}
          disabled={!name || !categoryOptions}
          style={styles.input}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginTop: 10,
  },
});

export default CreateItemOptionCategoryForm;
