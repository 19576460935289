import React, { useCallback, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { StackNavigationProp } from '@react-navigation/stack';
import { queryCache } from 'react-query';
import { UserStackParamList } from '../../navigators/UserNavigator';
import { businessIdState } from '../../store/state';
import API from '../../helpers/api';
import CreatePricingForm from './CreatePricingForm';
import { useDocumentTitle } from '../../hooks/document';
import { t } from '../../helpers/translation';
import Block from '../../components/Block/Block';
import P from '../../components/P/P';
import Button from '../../components/Button/Button';
import Feature from '../../components/Feature/Feature';
import H2 from '../../components/H2/H2';
import TutorialBlock from './TutorialBlock';
import { useIsWideScreen } from '../../hooks/responsive';

type Props = {
  navigation: StackNavigationProp<UserStackParamList, 'Welcome'>;
};

const WelcomeScreen = (props: Props) => {
  const [isCreatingPricing, setIsCreatingPricing] = useState(false);
  const [createdPricing, setCreatedPricing] = useState(false);
  const businessId = useRecoilValue(businessIdState);
  const isWideScreen = useIsWideScreen();

  useDocumentTitle(t('navigation.welcome'));

  const handleContinue = useCallback(
    async (data: { slug: string; baseCurrency: string }) => {
      if (!businessId || !data.slug || !data.baseCurrency) {
        return;
      }

      setIsCreatingPricing(true);

      try {
        await API.createPricingForBusiness(
          businessId,
          data.slug,
          data.baseCurrency
        );

        setCreatedPricing(true);

        queryCache.refetchQueries(['account-status', businessId]);
      } catch (error) {
        console.warn(error);
      }

      setIsCreatingPricing(false);
    },
    [businessId]
  );

  const handleSkip = useCallback(
    () => props.navigation.navigate('Dashboard'),
    []
  );

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {!createdPricing && (
        <>
          <Feature
            title={t('screens.welcome.banners.startWebsite.title')}
            subtitle={t('screens.welcome.banners.startWebsite.subtitle')}
            image={require('../../../assets/create_website.svg')}
            style={styles.topBlock}
          />

          <CreatePricingForm
            submitting={isCreatingPricing}
            onSubmit={handleContinue}
          />
        </>
      )}

      {createdPricing && (
        <>
          <Feature
            title={t('screens.welcome.banners.thankYouForJoining.title')}
            subtitle={t('screens.welcome.banners.thankYouForJoining.subtitle')}
            image={require('../../../assets/handshake.svg')}
            style={styles.topBlock}
          />

          <Block>
            <H2>{t('screens.welcome.headers.whereToStart')}</H2>

            <P>{t('screens.welcome.headers.learnAboutOurServices')}</P>

            <View
              style={[
                styles.tutorialBlocks,
                isWideScreen && styles.tutorialBlocksWide,
              ]}
            >
              <TutorialBlock
                image={require('../../../assets/online_shopping.svg')}
                title={t('screens.welcome.banners.website.title')}
                subtitle={t('screens.welcome.banners.website.subtitle')}
                // IDEA: OR USE TRANSLATION FILE .videoKey!
                // videoKey={getFormattedLocale() === 'fr' ? '45j8rtgxqUQ' : ''}
                videoKey="45j8rtgxqUQ"
                style={[
                  styles.tutorialBlock,
                  isWideScreen && styles.tutorialBlockWide,
                ]}
              />

              <TutorialBlock
                image={require('../../../assets/contact-tracking.svg')}
                title={t('screens.welcome.banners.contactTracing.title')}
                subtitle={t('screens.welcome.banners.contactTracing.subtitle')}
                videoKey="tNm7w4R4lU0"
                style={[
                  styles.tutorialBlock,
                  isWideScreen && styles.tutorialBlockWide,
                ]}
              />

              <TutorialBlock
                image={require('../../../assets/order_confirmed.svg')}
                title={t('screens.welcome.banners.orders.title')}
                subtitle={t('screens.welcome.banners.orders.subtitle')}
                videoKey="-lvr1W80pZ8"
                style={[
                  styles.tutorialBlock,
                  isWideScreen && styles.tutorialBlockWide,
                ]}
              />

              <TutorialBlock
                image={require('../../../assets/restaurant.svg')}
                title={t('screens.welcome.banners.bookings.title')}
                subtitle={t('screens.welcome.banners.bookings.subtitle')}
                videoKey="ft1PoLVToSw"
                style={[
                  styles.tutorialBlock,
                  isWideScreen && styles.tutorialBlockWide,
                ]}
              />

              <TutorialBlock
                image={require('../../../assets/credit_card.svg')}
                title={t('screens.welcome.banners.loyaltyProgram.title')}
                subtitle={t('screens.welcome.banners.loyaltyProgram.subtitle')}
                videoKey="l3UZIl1_6zQ"
                style={[
                  styles.tutorialBlock,
                  isWideScreen && styles.tutorialBlockWide,
                ]}
              />
            </View>

            <Button
              title={t('screens.welcome.actions.skip')}
              onPress={handleSkip}
            />
          </Block>
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  topBlock: {
    marginBottom: 20,
  },
  tutorialBlocks: {
    flexDirection: 'column',
    marginVertical: 20,
  },
  tutorialBlocksWide: {
    flexDirection: 'row',
  },
  tutorialBlock: {
    marginBottom: 20,
  },
  tutorialBlockWide: {
    marginBottom: 0,
    marginRight: 20,
  },
});

export default WelcomeScreen;
