import React, { ReactText } from 'react';
import { StyleSheet } from 'react-native';
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from 'recyclerlistview';
// @ts-ignore
import countryCodes from 'country-codes-list';
import CountryItem from './CountryItem';
import { CountryData } from '../../../types';

type Props = {
  height: number;
  query?: string;
  onChangeCountry: (countryCode: string) => void;
};

type State = {
  dataProvider: DataProvider;
};

class CountriesList extends React.Component<Props, State> {
  _layoutProvider = new LayoutProvider(
    () => 0,
    (_type, dim) => {
      dim.width = window.innerWidth;
      dim.height = 50;
    }
  );

  state = {
    dataProvider: new DataProvider((r1: CountryData, r2: CountryData) => {
      return r1.countryCode !== r2.countryCode;
    }).cloneWithRows(countryCodes.all()),
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.query !== this.props.query) {
      this.setState((state) => {
        const query = this.props.query!.toLowerCase();

        return {
          dataProvider: state.dataProvider.cloneWithRows(
            query
              ? countryCodes
                  .all()
                  .filter((data: CountryData) =>
                    data.countryNameEn.toLowerCase().includes(query)
                  )
              : countryCodes.all()
          ),
        };
      });
    }
  }

  _rowRenderer = (_type: ReactText, data: CountryData) => {
    return (
      <CountryItem
        flag={data.flag}
        name={data.countryNameEn}
        callingCode={data.countryCallingCode}
        onPress={() => this.props.onChangeCountry(data.countryCode)}
        style={styles.container}
      />
    );
  };

  render() {
    return (
      <RecyclerListView
        layoutProvider={this._layoutProvider}
        dataProvider={this.state.dataProvider}
        rowRenderer={this._rowRenderer}
        style={{ height: this.props.height }}
      />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
});

export default CountriesList;
