import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import Text from '../Text/Text';
import Theme from '../../helpers/theme';

export type InlineSelectProps = {
  value: string;
  options: { label: string; value: string }[];
  onChangeValue: (value: string) => void;
  style?: ViewProps['style'];
};

const InlineSelect = (props: InlineSelectProps) => {
  const scheme = useColorScheme();

  return (
    <View
      style={[
        styles.container,
        scheme === 'dark' && styles.containerDark,
        props.style,
      ]}
    >
      {props.options.map((option, index) => (
        <TouchableOpacity
          key={option.value}
          disabled={option.value === props.value}
          onPress={() => props.onChangeValue(option.value)}
          style={[
            styles.option,
            scheme === 'dark' && styles.optionDark,
            option.value === props.value && styles.optionSelected,
            index === 0 && styles.optionFirst,
            index === props.options.length - 1 && styles.optionLast,
          ]}
        >
          <Text
            style={[
              styles.optionLabel,
              option.value === props.value && styles.optionLabelSelected,
            ]}
          >
            {option.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    backgroundColor: DefaultTheme.colors.card,
    height: 50,
    flexDirection: 'row',
  },
  containerDark: {
    backgroundColor: DarkTheme.colors.card,
  },
  option: {
    flex: 1,
    paddingHorizontal: 8,
    borderWidth: 2,
    borderColor: DefaultTheme.colors.border,
    borderRightWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionDark: {
    borderColor: DarkTheme.colors.border,
  },
  optionFirst: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  optionSelected: {
    backgroundColor: Theme.PRIMARY,
  },
  optionLast: {
    borderRightWidth: 2,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  optionLabel: {
    textAlign: 'center',
  },
  optionLabelSelected: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default InlineSelect;
