import React, { useRef, useLayoutEffect, useState } from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';
import moment from 'moment';
import { Room, Booking } from '../../../types';
import TableBookings from './TableBookings';
import P from '../P/P';
import Text from '../Text/Text';
import Theme from '../../helpers/theme';
import Icon from '../Icon/Icon';
import { computeBookingsOverlap } from '../../helpers/compute';

type BookingsTimelineProps = {
  rooms: Room[];
  bookings: Booking[];
  onPressBooking: (booking: Booking) => void;
  style?: ViewProps['style'];
};

const BookingsTimeline = (props: BookingsTimelineProps) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const scrollViewRef = useRef<ScrollView>() as React.RefObject<ScrollView>;
  const HOUR_COLUMN_WIDTH = 200;
  const TABLE_ROW_HEIGHT = 35;

  useLayoutEffect(() => {
    const now = moment();

    scrollViewRef.current?.scrollTo({
      x: Math.max(
        0,
        now.hours() * HOUR_COLUMN_WIDTH +
          (now.minutes() / 60) * HOUR_COLUMN_WIDTH -
          HOUR_COLUMN_WIDTH
      ),
    });

    const timeInterval = setInterval(() => setCurrentTime(Date.now()), 60000);

    return () => clearInterval(timeInterval);
  }, []);

  return (
    <View style={[{ position: 'relative' }, props.style]}>
      <View
        style={{
          zIndex: 2,
          position: 'absolute',
          borderRightWidth: 1,
          borderColor: DefaultTheme.colors.border,
          backgroundColor: DefaultTheme.colors.card,
          width: HOUR_COLUMN_WIDTH / 2,
          top: TABLE_ROW_HEIGHT,
          bottom: 0,
        }}
      >
        {props.rooms.map((room) => (
          <React.Fragment key={room.id}>
            <View
              style={{
                height: TABLE_ROW_HEIGHT,
                borderBottomWidth: 1,
                borderColor: DefaultTheme.colors.border,
                paddingHorizontal: 10,
                justifyContent: 'center',
              }}
            >
              <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                {room.name}
              </Text>
            </View>

            {room.room_tables?.map((table) => {
              let maxOverlap = 0;

              props.bookings
                .filter((booking) => booking.room_table.id === table.id)
                .forEach((booking, index, bookings) => {
                  maxOverlap = Math.max(
                    computeBookingsOverlap(booking, bookings.slice(0, index)),
                    maxOverlap
                  );
                });

              return (
                <View
                  key={table.id}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingHorizontal: 10,
                    height: TABLE_ROW_HEIGHT * (maxOverlap + 1),
                    borderBottomWidth: 1,
                    borderColor: DefaultTheme.colors.border,
                  }}
                >
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {table.name}
                  </Text>

                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <svg
                      style={{ width: 12, height: 12, marginRight: 3 }}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill={Theme.BLACK}
                        d="M11,9H9V2H7V9H5V2H3V9C3,11.12 4.66,12.84 6.75,12.97V22H9.25V12.97C11.34,12.84 13,11.12 13,9V2H11V9M16,6V14H18.5V22H21V2C18.24,2 16,4.24 16,6Z"
                      />
                    </svg>

                    <Text style={{ fontSize: 12 }}>{table.seats}</Text>
                  </View>
                </View>
              );
            })}
          </React.Fragment>
        ))}
      </View>

      <ScrollView
        ref={scrollViewRef}
        horizontal
        contentContainerStyle={{ width: HOUR_COLUMN_WIDTH * 24 }}
        bounces={false}
      >
        <View
          style={{
            zIndex: 3,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left:
              moment().hours() * HOUR_COLUMN_WIDTH +
              (moment().minutes() / 60) * HOUR_COLUMN_WIDTH,
            width: 2,
            backgroundColor: Theme.PRIMARY,
          }}
        />

        <View
          style={{
            zIndex: 3,
            position: 'absolute',
            top: TABLE_ROW_HEIGHT + 4,
            height: TABLE_ROW_HEIGHT - 10,
            left:
              moment().hours() * HOUR_COLUMN_WIDTH +
              (moment().minutes() / 60) * HOUR_COLUMN_WIDTH -
              HOUR_COLUMN_WIDTH / 4,
            width: HOUR_COLUMN_WIDTH / 2,
            borderRadius: 6,
            backgroundColor: Theme.PRIMARY,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ color: 'white', fontWeight: 'bold' }}>
            {moment(currentTime).format('LT')}
          </Text>
        </View>

        <View style={{ flexDirection: 'column' }}>
          <View style={{ flexDirection: 'row' }}>
            {Array(24)
              .fill(1)
              .map((_value, index) => (
                <View
                  key={index}
                  style={{
                    width: HOUR_COLUMN_WIDTH,
                    borderBottomWidth: 1,
                    borderBottomColor: DefaultTheme.colors.border,
                    height: TABLE_ROW_HEIGHT,
                    justifyContent: 'center',
                  }}
                >
                  <P style={{ paddingHorizontal: 5 }}>
                    {moment().hours(index).minutes(0).format('LT')}
                  </P>
                </View>
              ))}
          </View>

          {props.rooms.map((room) => (
            <React.Fragment key={room.id}>
              <View
                style={{
                  height: TABLE_ROW_HEIGHT,
                  borderBottomWidth: 1,
                  borderColor: DefaultTheme.colors.border,
                  backgroundColor: DefaultTheme.colors.card,
                }}
              />

              {room.room_tables?.map((table) => (
                <TableBookings
                  key={table.id}
                  hourColumnWidth={HOUR_COLUMN_WIDTH}
                  tableRowHeight={TABLE_ROW_HEIGHT}
                  table={table}
                  bookings={props.bookings.filter(
                    (booking) => booking.room_table.id === table.id
                  )}
                  onPressBooking={props.onPressBooking}
                />
              ))}
            </React.Fragment>
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({});

export default BookingsTimeline;
