import React, { useState, useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import { transition } from '../../helpers/style';

export type PopOverProps = {
  children: any;
  content: any;
  position: 'bottom-right';
  style?: ViewProps['style'];
};

const PopOver = (props: PopOverProps) => {
  const [visible, setVisible] = useState(false);
  const scheme = useColorScheme();

  const toggleVisibility = useCallback(
    () => setVisible((isVisible) => !isVisible),
    []
  );

  return (
    <View style={[styles.container, props.style]}>
      <TouchableOpacity onPress={toggleVisibility}>
        {props.children}
      </TouchableOpacity>

      <View
        style={[
          styles.popOver,
          scheme === 'dark' && styles.popOverDark,
          visible && styles.popOverVisible,
          props.position === 'bottom-right' && styles.popOverBottomRight,
        ]}
      >
        {props.content}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  popOver: {
    ...transition(),
    display: 'none',
    position: 'absolute',
    borderWidth: 1,
    borderColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
    padding: 10,
    borderRadius: 6,
    zIndex: 1,
  },
  popOverDark: {
    borderColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  popOverVisible: {
    display: 'flex',
  },
  popOverBottomRight: {
    top: '100%',
    right: 0,
    marginTop: 10,
  },
});

export default PopOver;
