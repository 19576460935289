import React, { useEffect, useCallback } from 'react';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import Category from '../components/Category/Category';
import {
  categoriesState,
  rawCategoriesState,
  categoriesOrderState,
  pricingIdState,
  rawCategoryItemsState,
  categoryItemsState,
} from '../store/state';
import API from '../helpers/api';
import { t } from '../helpers/translation';

type Props = {
  id: string;
  editable?: boolean;
};

const CategoryContainer = (props: Props) => {
  const [category, setCategory] = useRecoilState(categoriesState(props.id));
  const pricingId = useRecoilValue(pricingIdState);
  const setCategoriesOrder = useSetRecoilState(categoriesOrderState);
  const rawCategories = useRecoilValue(rawCategoriesState);
  const setCategoryRawItems = useSetRecoilState(
    rawCategoryItemsState(props.id)
  );
  const setCategoryItems = useSetRecoilState(categoryItemsState(props.id));

  useEffect(() => {
    if (rawCategories.length > 0) {
      const category = rawCategories.find(
        (category) => category.id === props.id
      );

      if (category) {
        setCategory({ name: category.name, description: category.description });

        const items = (category as any).items;
        setCategoryRawItems(items);
        setCategoryItems(items.map((item: any) => item.id));
      }
    }
  }, [rawCategories]);

  const handleDelete = useCallback(async () => {
    if (!confirm(t('components.categoryContainer.alerts.deleteCategory'))) {
      return;
    }

    setCategoriesOrder((categoriesOrder) =>
      categoriesOrder.filter((category) => category !== props.id)
    );

    try {
      API.deletePricingCategory(pricingId, props.id);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const handleEditName = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      const value = (event.target as any).value;

      if (value.trim().length > 0 && value !== category.name) {
        setCategory((category) => ({ ...category, name: value }));
        try {
          API.updatePricingCategoryById(pricingId, props.id, {
            name: value,
          });
        } catch (e) {}
      }
    },
    [category.name, pricingId, props.id]
  );

  const handleEditDescription = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      const value = (event.target as any).value;
      if (value !== category.description) {
        setCategory((category) => ({ ...category, description: value }));

        try {
          API.updatePricingCategoryById(pricingId, props.id, {
            description: value,
          });
        } catch (e) {}
      }
    },
    [category.description, pricingId, props.id]
  );

  return (
    <Category
      {...category}
      editable={props.editable}
      onEditName={handleEditName}
      onEditDescription={handleEditDescription}
      onDelete={handleDelete}
    />
  );
};

export default React.memo(CategoryContainer);
