import React, { useCallback, useState } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DefaultTheme } from '@react-navigation/native';
import * as Localization from 'expo-localization';
// @ts-ignore
import color from 'color';
import { JoinStackParamList } from '../navigators/JoinNavigator';
import { useDocumentTitle } from '../hooks/document';
import Block from '../components/Block/Block';
import TextInput from '../components/TextInput/TextInput';
import Button from '../components/Button/Button';
import API from '../helpers/api';
import H2 from '../components/H2/H2';
import PhoneNumberInput from '../components/PhoneNumberInput/PhoneNumberInput';
import { isValidPhoneNumber } from '../helpers/format';
import { t } from '../helpers/translation';
import Banner from '../components/Banner/Banner';
import Theme from '../helpers/theme';
import Wrapper from '../components/Wrapper/Wrapper';
import Feature from '../components/Feature/Feature';

type Props = {
  navigation: StackNavigationProp<JoinStackParamList, 'Join'>;
};

const JoinScreen = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isBusinessPage, setIsBusinessPage] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState(
    Localization.region || 'FR'
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [
    businessPhoneNumberCountryCode,
    setBusinessPhoneNumberCountryCode,
  ] = useState(Localization.region || 'FR');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');

  useDocumentTitle(t('navigation.join'));

  const hasCompletedUserInformation = !!(
    email &&
    password &&
    firstName &&
    lastName &&
    phoneNumberCountryCode &&
    phoneNumber &&
    isValidPhoneNumber(phoneNumberCountryCode, phoneNumber)
  );

  const canSignUp = !!(
    hasCompletedUserInformation &&
    businessName &&
    businessAddress &&
    businessPhoneNumberCountryCode &&
    businessPhoneNumber &&
    isValidPhoneNumber(businessPhoneNumberCountryCode, businessPhoneNumber)
  );

  const handleChangeUserCountryCode = useCallback(
    (countryCode: string) => {
      setPhoneNumberCountryCode(countryCode);

      if (!businessPhoneNumber) {
        setBusinessPhoneNumberCountryCode(countryCode);
      }
    },
    [businessPhoneNumber]
  );

  const handleGoToBusinessPage = useCallback(() => setIsBusinessPage(true), []);

  const handleGoBackToUserPage = useCallback(
    () => setIsBusinessPage(false),
    []
  );

  const handleSignUp = async () => {
    setHasError(false);
    setIsCreating(true);

    try {
      await API.signUpOwner({
        user: {
          email,
          password,
          firstName,
          lastName,
          phoneNumber,
          phoneNumberCountryCode,
        },
        business: {
          name: businessName,
          address: businessAddress,
          phoneNumber: businessPhoneNumber,
          phoneNumberCountryCode: businessPhoneNumberCountryCode,
        },
      });

      // @ts-ignore
      props.navigation.navigate('SignIn');
    } catch (error) {
      console.warn(error);
      setHasError(true);
    }

    setIsCreating(false);
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Wrapper noPadding>
        <Feature
          title={t('screens.welcome.banners.thankYouForJoining.title')}
          subtitle={t('screens.join.headers.thanksForJoining')}
          image={require('../../assets/startup.svg')}
          style={styles.topBlock}
        />

        {isBusinessPage && (
          <Button
            inline
            size="small"
            icon="arrow-left"
            title={t('common.previous')}
            onPress={handleGoBackToUserPage}
            style={[styles.topBlock, styles.previousButton]}
          />
        )}

        <Block>
          {hasError && (
            <Banner color={Theme.RED} style={styles.error}>
              {t('errors.basicMessage')}
            </Banner>
          )}

          {!isBusinessPage && (
            <>
              <H2>{t('screens.join.headers.yourInformation')}</H2>

              <TextInput
                editable
                type="email"
                label={t('common.email')}
                placeholder="email@mail.com"
                value={email}
                onChangeText={setEmail}
                style={styles.firstInput}
              />

              <TextInput
                editable
                type="password"
                label={t('common.password')}
                placeholder="******"
                value={password}
                onChangeText={setPassword}
                style={styles.input}
              />

              <TextInput
                editable
                label={t('common.lastName')}
                value={lastName}
                onChangeText={setLastName}
                inline
                style={styles.input}
              />

              <TextInput
                editable
                label={t('common.firstName')}
                value={firstName}
                onChangeText={setFirstName}
                inline
                style={styles.input}
              />

              <PhoneNumberInput
                label={t('screens.join.inputs.phoneNumber.label')}
                placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
                phoneNumber={phoneNumber}
                onChangePhoneNumber={setPhoneNumber}
                countryCode={phoneNumberCountryCode}
                onChangeCountryCode={handleChangeUserCountryCode}
                hint={t('screens.join.inputs.phoneNumber.hint')}
                style={styles.input}
              />
            </>
          )}

          {isBusinessPage && (
            <>
              <H2>{t('screens.join.headers.yourBusinessInformation')}</H2>

              <TextInput
                editable
                label={t('screens.join.inputs.businessName.placeholder')}
                value={businessName}
                onChangeText={setBusinessName}
                style={styles.firstInput}
              />

              <TextInput
                editable
                label={t('common.address')}
                value={businessAddress}
                onChangeText={setBusinessAddress}
                hint={t('screens.join.inputs.businessAddress.hint')}
                style={styles.input}
              />

              <PhoneNumberInput
                label={t('screens.join.inputs.businessPhoneNumber.label')}
                placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
                phoneNumber={businessPhoneNumber}
                onChangePhoneNumber={setBusinessPhoneNumber}
                countryCode={businessPhoneNumberCountryCode}
                onChangeCountryCode={setBusinessPhoneNumberCountryCode}
                hint={t('screens.join.inputs.businessPhoneNumber.hint')}
                style={styles.input}
              />
            </>
          )}

          {/*
        NOTE/TODO: Rephrase below based on new marketing approach.

        <View style={[styles.comments, isWideScreen && styles.commentsWide]}>
          <Image
            source={require('../../assets/handshake.svg')}
            resizeMode="contain"
            style={styles.handshake}
          />

          <View style={{ flex: 1 }}>
            <H2 style={{ color: 'white' }}>
              {t('screens.join.banners.covid.title')}
            </H2>

            <H3 style={{ fontWeight: 'normal', marginTop: 5, color: 'white' }}>
              {t('screens.join.banners.covid.text')}
            </H3>
          </View>
        </View> */}
        </Block>

        <Button
          icon={!isBusinessPage ? 'arrow-right' : undefined}
          title={isBusinessPage ? t('navigation.join') : t('common.continue')}
          onPress={isBusinessPage ? handleSignUp : handleGoToBusinessPage}
          busy={isCreating}
          disabled={isBusinessPage ? !canSignUp : !hasCompletedUserInformation}
          style={styles.signUpButton}
        />
      </Wrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  topBlock: {
    marginBottom: 20,
  },
  error: {
    marginBottom: 20,
  },
  firstInput: {
    marginTop: 10,
  },
  input: {
    marginTop: 20,
  },
  signUpButton: {
    marginTop: 20,
  },
  comments: {
    marginBottom: 10,
    marginTop: 20,
    flexDirection: 'column',
    backgroundColor: color(DefaultTheme.colors.primary).lighten(0.2).string(),
    borderRadius: 6,
    padding: 20,
  },
  commentsWide: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  handshake: {
    width: 150,
    height: 150,
    marginLeft: -20,
  },
  previousButton: {
    alignSelf: 'flex-start',
  },
});

export default JoinScreen;
