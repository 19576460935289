import React from 'react';
import { StyleSheet, View, Image, ViewProps } from 'react-native';
import H2 from '../H2/H2';
import { t } from '../../helpers/translation';
import { useIsWideScreen } from '../../hooks/responsive';
import Theme from '../../helpers/theme';
import Link from '../Link/Link';

export type DownloadAppBannerProps = {
  androidBundle: string;
  iOSBundle: string;
  color?: string;
  style?: ViewProps['style'];
};

const DownloadAppBanner = (props: DownloadAppBannerProps) => {
  const isWideScreen = useIsWideScreen();

  return (
    <View
      style={[
        styles.container,
        isWideScreen && styles.containerWide,
        !!props.color && { backgroundColor: props.color },
        props.style,
      ]}
    >
      <H2 dark style={[styles.title, isWideScreen && styles.titleWide]}>
        {t('components.downloadAppBanner.headers.download')}
      </H2>

      <View style={styles.badgesContainer}>
        <Link
          to={`https://apps.apple.com/app/${props.iOSBundle}`}
          target="_blank"
          style={styles.badgeLink}
        >
          <Image
            source={require('../../../assets/download-app-store-en.svg')}
            resizeMode="contain"
            style={styles.badgeImage}
          />
        </Link>

        <Link
          to={`https://play.google.com/store/apps/details?id=${props.androidBundle}`}
          target="_blank"
          style={styles.badgeLink}
        >
          <Image
            source={require('../../../assets/download-google-play.png')}
            resizeMode="contain"
            style={styles.badgeImage}
          />
        </Link>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 6,
    justifyContent: 'center',
    backgroundColor: Theme.PRIMARY,
  },
  containerWide: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    flex: 1,
    marginBottom: 20,
    textAlign: 'center',
  },
  titleWide: {
    marginBottom: 0,
    textAlign: 'left',
  },
  badgesContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  badgeLink: {
    flex: 1,
  },
  badgeImage: {
    display: 'flex',
    height: 50,
  },
});

export default DownloadAppBanner;
