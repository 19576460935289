import React, { useState, useEffect } from 'react';
import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  View,
  StyleSheet,
} from 'react-native';
import TextInput from '../TextInput/TextInput';
import DeleteButton from '../DeleteButton/DeleteButton';
import { useIsWideScreen } from '../../hooks/responsive';
import { t } from '../../helpers/translation';

type Props = {
  name: string;
  description?: string;
  editable?: boolean;
  onDelete?: () => void;
  onEditName?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onEditDescription?: (
    event: NativeSyntheticEvent<TextInputFocusEventData>
  ) => void;
};

const Category = (props: Props) => {
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const isWideScreen = useIsWideScreen();

  useEffect(() => setName(props.name), [props.name]);
  useEffect(() => setDescription(props.description), [props.description]);

  return (
    <View style={styles.container}>
      <View style={styles.inputs}>
        <TextInput
          required
          label={t('components.category.placeholders.name')}
          value={name}
          onChangeText={setName}
          editable={props.editable ?? false}
          onBlur={props.onEditName}
          style={styles.nameInput}
        />

        {(props.description || props.editable) && (
          <TextInput
            value={description}
            onChangeText={setDescription}
            editable={props.editable ?? false}
            onBlur={props.onEditDescription}
            label={
              props.editable &&
              t('components.category.placeholders.description')
            }
            multiline={props.editable}
            numberOfLines={props.editable ? 2 : 1}
          />
        )}
      </View>

      {props.onDelete && (
        <DeleteButton
          title={isWideScreen && t('components.category.buttons.delete')}
          onDelete={props.onDelete}
          style={styles.deleteButton}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputs: {
    flex: 1,
  },
  nameInput: {
    marginBottom: 10,
  },
  deleteButton: {
    marginLeft: 20,
  },
});

export default React.memo(Category);
