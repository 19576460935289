import React from 'react';
import { ViewProps } from 'react-native';
import { OrderStatus, BookingStatus } from '../../../types';
import Picker from '../Picker/Picker';

type StatusSelectorProps = {
  status: OrderStatus | BookingStatus;
  onStatusChange: (status: OrderStatus | BookingStatus) => void;
  options: [string, OrderStatus | BookingStatus][];
  style?: ViewProps['style'];
};

const StatusSelector = (props: StatusSelectorProps) => {
  return (
    <Picker
      selectedValue={props.status}
      onValueChange={props.onStatusChange}
      style={props.style}
    >
      {props.options.map(([label, value]) => (
        <Picker.Item key={value} label={label} value={value} />
      ))}
    </Picker>
  );
};

export default StatusSelector;
