import React, { useState, useCallback, useEffect } from 'react';
import {
  Image,
  ImageSourcePropType,
  TouchableWithoutFeedback,
  StyleSheet,
  View,
  ViewProps,
} from 'react-native';
// @ts-ignore
import color from 'color';
import { LinearGradient } from 'expo-linear-gradient';
import Theme from '../../helpers/theme';
import H2 from '../H2/H2';
import { useIsWideScreen } from '../../hooks/responsive';
import Bubble from '../Bubble/Bubble';
import Text from '../Text/Text';
import { t } from '../../helpers/translation';
import { transition, webOnly } from '../../helpers/style';

export type CarouselProps = {
  items: {
    image: ImageSourcePropType;
    title: string;
  }[];
  color: string;
  onChangeSlide?: (newIndex: number) => void;
  style?: ViewProps['style'];
};

const Positions = {
  imageFromBottom: {
    busy: 180,
    rest: 135,
  },
  textOpacity: {
    busy: 0,
    rest: 1,
  },
  textFromTop: {
    busy: 20,
    rest: 30,
  },
};

const WidePositions = {
  ...Positions,
  imageFromBottom: {
    ...Positions.imageFromBottom,
    rest: 110,
  },
};

const Carousel = (props: CarouselProps) => {
  const isWideScreen = useIsWideScreen();
  const positions = isWideScreen ? WidePositions : Positions;
  const [index, setIndex] = useState(0);
  const [title, setTitle] = useState(props.items[index].title);
  const [image, setImage] = useState(props.items[index].image);
  const [imageFromBottom, setImageFromBottom] = useState(
    positions.imageFromBottom.rest
  );
  const [textOpacity, setTextOpacity] = useState(positions.textOpacity.rest);
  const [textFromTop, setTextFromTop] = useState(positions.textFromTop.rest);

  useEffect(() => {
    Promise.all(
      props.items.map((item) => Image.prefetch(item.image as string))
    );
  }, []);

  useEffect(() => {
    setImageFromBottom(positions.imageFromBottom.busy);
    setTextOpacity(positions.textOpacity.busy);
    setTextFromTop(positions.textFromTop.busy);

    const changeItem = setTimeout(() => {
      setImage(props.items[index].image);
      setTitle(props.items[index].title);
    }, 250);

    const allToRest = setTimeout(() => {
      setImageFromBottom(positions.imageFromBottom.rest);
      setTextOpacity(positions.textOpacity.rest);
      setTextFromTop(positions.textFromTop.rest);
    }, 500);

    return () => {
      clearTimeout(changeItem);
      clearTimeout(allToRest);
    };
  }, [index]);

  const handleNextItem = useCallback(() => {
    setIndex((index) => {
      const newIndex = (index + 1) % props.items.length;

      if (props.onChangeSlide) {
        props.onChangeSlide(newIndex);
      }

      return newIndex;
    });
  }, [props.onChangeSlide]);

  return (
    <TouchableWithoutFeedback onPress={handleNextItem}>
      <LinearGradient
        colors={[props.color, color(props.color).lighten(0.4)]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        style={[styles.container, props.style]}
      >
        <View style={styles.tapIndication}>
          <Text style={styles.tapIndicationText}>
            {t('components.carousel.buttons.tapAnywhere')}
          </Text>
        </View>

        <View
          style={[
            StyleSheet.absoluteFill,
            styles.itemContainer,
            {
              paddingTop: textFromTop,
            },
          ]}
        >
          <View style={[{ flexDirection: 'row' }]}>
            {props.items.map((_, number) => (
              <Bubble
                key={number}
                color={number === index ? Theme.BLACK : 'transparent'}
                style={{
                  opacity: number === index ? 1 : 0.8,
                  margin: 5,
                  ...transition(),
                }}
              >
                {(number + 1).toString()}
              </Bubble>
            ))}
          </View>

          <H2
            light
            style={{
              ...transition(),
              opacity: textOpacity,
              textAlign: 'center',
              width: isWideScreen ? '65%' : '90%',
            }}
          >
            {title}
          </H2>
        </View>

        <View
          style={[
            {
              ...transition(),
              flex: 1,
              opacity: textOpacity,
              // @ts-ignore
              backgroundImage: `url("${image}")`,
              backgroundPosition: `center ${imageFromBottom}px`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            },
          ]}
        />
      </LinearGradient>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: 500,
    borderRadius: 6,
    ...webOnly({
      cursor: 'pointer',
    }),
  },
  tapIndication: {
    borderRadius: 14,
    paddingHorizontal: 10,
    paddingVertical: 4,
    backgroundColor: 'black',
    position: 'absolute',
    left: 10,
    bottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  tapIndicationText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
  itemContainer: {
    flex: 1,
    alignItems: 'center',
    ...transition(),
  },
});

export default Carousel;
