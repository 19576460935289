export default {
  navigation: {
    account: 'Compte',
    bookingDetails: 'Réservation %{reference}',
    contact: 'Contact',
    home: 'Accueil',
    dashboard: 'Tableau de bord',
    help: 'Aide',
    join: 'Inscription',
    myPricing: 'Configurer les produits/services',
    myOrders: 'Mes commandes',
    myBookings: 'Mes réservations',
    myLoyaltyProgram: 'Configurer le programme de fidélité',
    orderDetails: 'Commande %{reference}',
    paymentSettings: 'Configurer les paiements',
    planning: 'Configurer les horaires',
    pricing: 'Commerces',
    productPricing: 'Tarification',
    roomTables: 'Configurer les tables/salles',
    settings: 'Paramètres',
    signIn: 'Connexion',
    signOut: 'Déconnexion',
    welcome: 'Créez votre site internet',
  },
  common: {
    activate: 'Activer',
    activated: 'Activé',
    add: 'Ajouter',
    address: 'Adresse',
    business: 'Commerçant',
    cancel: 'Annuler',
    change: 'Modifier',
    close: 'Fermer',
    comment: 'Commentaire',
    configure: 'Configurer',
    continue: 'Continuer',
    customer: 'Client',
    deactivated: 'désactivé',
    description: 'Description',
    edit: 'Modifier',
    email: 'Adresse email',
    filters: 'Filtres',
    firstName: 'Prénom',
    free: 'Gratuit',
    getStarted: 'Démarrer',
    go: 'Aller',
    lastName: 'Nom',
    learnMore: 'En apprendre plus',
    name: 'Nom',
    new: 'Nouveau',
    next: 'Suivant',
    open: 'Ouvrir',
    optional: 'optionel',
    order: 'Commande',
    password: 'Mot de passe',
    pause: 'Pause',
    payment: 'Paiement',
    previous: 'Précédent',
    seeDetails: 'consulter les détails',
    soon: 'Bientôt',
    status: 'Statut',
    today: "Aujourd'hui",
    update: 'Mettre à jour',
    view: 'Voir',
    walkIn: 'Client de passage',
  },
  errors: {
    basicMessage:
      'Une erreur est survenue. Veuillez réessayer ou nous contacter si le problème persiste.',
  },
  status: {
    cancelled: 'Annulée',
    confirmed: 'Confirmée',
    doing: 'En cours',
    done: 'Prête',
    hold: 'Suspendue',
    paid: 'Payée',
    unpaid: 'Impayée',
    waiting: 'En attente',
  },
  components: {
    bookingDetails: {
      headers: {
        bookingNumber: 'Réservation %{referenceNumber}',
      },
    },
    carousel: {
      buttons: {
        tapAnywhere: "Appuyez n'importe où",
      },
    },
    cart: {
      headers: {
        yourOrder: 'Votre commande',
      },
      buttons: {
        makeOrder: 'Passer commande',
        viewArticles: 'Voir mon panier (%{count})',
      },
    },
    cartItems: {
      misc: {
        payInShop: ' - A régler en magasin',
      },
    },
    category: {
      placeholders: {
        name: 'Nom de la catégorie',
        description: 'Description de la catégorie',
      },
      buttons: {
        create: 'Ajouter catégorie',
        delete: 'Supprimer catégorie',
      },
    },
    categoryContainer: {
      alerts: {
        deleteCategory:
          'Êtes-vous sûr de vouloir supprimer cette catégorie et ses articles ? Vous ne pourrez pas annuler cette opération.',
      },
    },
    countryPicker: {
      placeholders: {
        searchCountry: 'Chercher mon pays',
      },
    },
    downloadAppBanner: {
      headers: {
        download: "Téléchargez l'application gratuitement",
      },
    },
    featuresJob: {
      headers: {
        tailoredFor: 'Adapté',
        everyBusiness: 'à tous les commerces',
        jobs: {
          hairdressers: 'aux coiffeurs',
          restaurants: 'aux restaurants',
        },
      },
    },
    joinBanner: {
      headers: {
        title: 'Vous êtes à deux pas de la réussite',
      },
    },
    item: {
      placeholders: {
        name: "Nom de l'article",
        description: "Description de l'article",
      },
      buttons: {
        addOptions: 'Ajouter une option',
        showOptions: 'Afficher les options (%{count})',
        delete: "Supprimer l'article",
      },
      headers: {
        optionsExtras: 'Options & extras',
      },
    },
    makeBooking: {
      actions: {
        bookTable: 'Réserver une table',
      },
      headers: {
        bookingNumber: 'Réservation #%{reference}',
      },
      paragraphs: {
        bookingConfirmed:
          'Votre réservation a bien été confirmée. Vous recevrez un récapitulatif par email sous peu.',
        bookingWith: 'Réservation avec %{name}',
        noBookings: "Il n'y a plus de créneau disponible pour ce jour-ci.",
      },
      labels: {
        guestsNumber: 'Pour combien de personnes ?',
        onePerson: '1 personne',
        manyPeople: '%{count} personnes',
        pickTime: 'Choisissez une heure',
      },
    },
    planning: {
      alerts: {
        redoPlanning:
          'Êtes-vous sûr de vouloir supprimer et refaire votre planning ? Vous ne pourrez pas annuler cette opération.',
      },
      banners: {
        dayOff: 'Fermé',
        ifClosed:
          'Votre commerce sera considéré comme fermé %{day} si vous n’indiquez pas d’horaires pour cette journée.',
      },
    },
    previewPricingButton: {
      preview: 'Prévisualiser',
    },
    priceRangeInput: {
      placeholders: {
        priceFrom: 'Prix à partir de',
        priceUpTo: "Prix jusqu'à",
      },
    },
    priceInput: {
      placeholders: {
        typeText: 'Votre message',
        price: 'Indiquer le prix si non gratuit',
      },
    },
    priceTypeSelector: {
      options: {
        value: 'Prix : X€',
        range: 'Plage : X€-Y€',
        up: 'A partir de X€',
        other: 'Message personnalisé',
      },
    },
    itemsContainer: {
      buttons: {
        addItem: 'Ajouter article',
        createItem: 'Ajouter article',
        sortItems: 'Réordonner les articles',
        finishSorting: "Enregistrer l'ordre",
      },
    },
    itemContainer: {
      alerts: {
        deleteItem:
          'Êtes-vous sûr de vouloir supprimer cet article ? Vous ne pourrez pas annuler cette opération.',
      },
    },
  },
  forms: {
    addContactInformation: {
      headers: {
        yourInformation: 'Vos coordonnées',
        informationSent: 'Vous voilà prêt %{name}',
      },
      actions: {
        addMyInformation: 'Ajouter mes coordonnées',
      },
      paragraphs: {
        legal:
          "En accord avec le règlement général sur la protection des données, vos coordonnées sont confidentielles et disponibles sur nos services pendant 14 jours. Ni votre commerçant, ni personne d'autre possède un droit de regard sur vos données. Celles-ci ne peuvent être communiquées que dans le cadre des mesures sanitaires locales et après demande légale des autorités compétentes.",
        goodToGo:
          "Vous voilà prêt à rentrer ! Gardez-bien à l'esprit que vos coordonnées sont uniquement gardées pendant 14 jours. Elles sont intégralement encryptées et ne peuvent être partagées qu'avec les services de santé.",
        itIsScary:
          "Nous savons à quel point il peut-être inquiétant d'envoyer vos coordonnées mais vous pouvez nous faire confiance (nous sommes des gens comme vous et avons à cœur le respect de votre vie privée !). Si vous avez des interrogations quant à nos services, n'hésitez-pas à nous contacter via notre page d'accueil.",
      },
    },
    createBooking: {
      buttons: {
        book: 'Réserver',
      },
      inputs: {
        comment: {
          placeholder:
            'Avez-vous certaines précisions particulières pour la réservation ?',
        },
      },
    },
    createItemOption: {
      buttons: {
        addOption: "Ajouter l'option",
      },
      inputs: {
        optionName: "Nom de l'option",
        optionDescription: "Description de l'option",
        isDefault: 'Option par défaut',
      },
    },
    createItemOptionCategory: {
      buttons: {
        createOptions: "Ajouter l'option",
        deleteCategory: "Supprimer l'option",
      },
      inputs: {
        tickCount: {
          leftText: "Nombre maximum d'options qui peuvent être sélectionnées",
          hint:
            "Combien d'options peuvent-être sélectionnées ? Par exemple combien de sauces ? Une taille unique d'un vêtement ?",
        },
      },
    },
    createPricing: {
      slug: {
        title: 'Site internet',
        indications:
          "Afin de créer votre site internet, veuillez choisir un nom de commerce. Ce lien sera unique et permettra à vos clients d'accéder à vos services en ligne.",
        placeholder: 'mon-commerce',
        hint:
          'Votre lien ne peut pas contenir de lettres capitales. Seuls les tirets et lettres en minuscule sont autorisés.',
      },
      currency: {
        title: 'Devise',
        indications:
          "Choisissez la devise utilisée par votre commerce. Les prix seront convertis en fonction de la devise de l'utilisateur.",
      },
    },
    createWalkIn: {
      actions: {
        addCustomerInformation: 'Ajouter des coordonnées clientes (optionel)',
      },
      inputs: {
        guests: {
          label: 'Invités',
        },
        pickTable: {
          label: 'Choisir une table',
          hint:
            'Les tables suivantes sont des recommandations basées sur vos réservations actuelles. Vous pouvez en choisir une qui est supposément prise.',
        },
      },
      paragraphs: {
        noTables:
          'Ajoutez des tables depuis votre tableau de bord pour ajouter des réservations.',
      },
      buttons: {
        tableSeats: '%{name} (%{seats} places)',
      },
    },
    editContactTracingSettings: {
      headers: {
        visits: 'Visites',
      },
      inputs: {
        averageVisitDuration: {
          label: "Temps moyen d'une visite en minutes",
          hint:
            'Le temps moyen passé par un client dans votre commerce en minutes.',
        },
      },
    },
    editPricing: {
      alerts: {
        deletePricing:
          'Êtes-vous sûr de vouloir supprimer votre tarification ? Vous ne pourrez pas annuler cette opération.',
      },
      buttons: {
        addCategory: 'Ajouter catégorie',
        addCategoryHint: `Une catégorie correspond à une sous-partie de votre tarification. Par exemple : "Salades", "Cafés", "Coupes hommes"...sont toutes des catégories correctes. Vous pourrez ensuite y associer des articles/services.`,
        deletePricing: 'Supprimer ma tarification',
      },
      banners: {
        share: {
          title: 'Faites passer le mot',
          subtitle:
            "Partagez votre tarification avec vos clients ! Utilisez ce lien partageable ou imprimez le QR code pour qu'ils puissent le scanner depuis votre commerce.",
          actions: {
            copyLink: 'Copier le lien de mon site',
            getQrCodePDF: 'Obtenir le QR code en PDF',
          },
        },
        configurePayment: {
          canOrder: 'Autoriser les commandes ?',
          systemIndications:
            'Nous utilisons PayPal pour notre système de paiements.',
          createBusinessAccount:
            "Vous aurez besoin d'un compte PayPal commerçant pour commencer (disponible gratuitement).",
          createApplication:
            "Une fois votre compté créé, vous aurez besoin de déclarer une application via PayPal qui vous communiquera ensuite un ID client et un secret qu'il vous suffira de coller ci-dessous afin de recevoir vos paiements.",
          followSteps:
            'Suivez ces 4 étapes pour déclarer une application et obtenir vos identifiants,',
          pasteCredentials:
            ' puis collez vos ID client et secret "LIVE", pas ceux en "SANDBOX".',
          inputs: {
            clientID: {
              label: 'Client ID/ID client',
              placeholder: 'ex. TRKO2HILPsHEo...',
            },
            clientSecret: {
              label: 'Client secret',
              hint:
                'Votre "client secret" est par mesure de sécurité chiffré de notre côté afin de protéger tout vol de données. Il sera uniquement utilisé afin de valider les paiements de vos clients.',
              placeholder: 'ex. TRKO2HILPsHEo...',
            },
          },
          actions: {
            updatePaymentMethod: 'Mettre à jour',
          },
        },
      },
    },
    addProgramStage: {
      banners: {
        deactivateRewardIndications:
          "Vous ne pouvez pas supprimer une récompense car certains de vos clients la possèdent peut-être encore. Néanmoins, vous pouvez la désactiver afin qu'aucun de vos clients ne puissent l'obtenir de nouveau à moins de la réactiver.",
      },
      buttons: {
        addProgramStage: 'Ajouter le palier',
        editProgramStage: 'Editer le palier',
      },
      headers: {
        createProgramStage: 'Créer un palier de points',
        editProgramStage: 'Editer un palier de points',
      },
      inputs: {
        rewardType: {
          label: 'Type de récompense',
          options: {
            item: 'Article/produit',
            discount: 'Réduction',
          },
        },
        rewardName: 'Nom de la récompense',
        rewardDescription: 'Description de la récompense',
        stampsCount: {
          label:
            'Combien de points sont nécessaires pour obtenir cette récompense ?',
          placeholder: 'Points de la récompense',
        },
        isRecurrent: {
          label: 'Est-ce une récompense récurrente ?',
          hint: `Si une récompense à %{points} points est indiquée comme récurrente, un client l'obtiendra au bout de %{points}, %{doublePoints}, %{triplePoints}...points.`,
        },
        isActivated: 'Récompense activée ?',
      },
    },
    room: {
      titles: {
        createRoom: 'Ajouter une salle',
      },
      inputs: {
        name: {
          label: 'Nom de la salle',
          hint: 'Rez-de-chaussée, 1er étage, Jardin...',
        },
      },
      actions: {
        addRoom: 'Ajouter cette salle',
        updateRoom: 'Mettre à jour le nom',
      },
    },
    roomTable: {
      inputs: {
        name: {
          label: 'Nom de table',
        },
        seats: {
          label: 'Nb. places',
        },
        available: {
          label: 'Disponible',
        },
      },
    },
  },
  screens: {
    addContactInformation: {
      title: 'Formulaire de coordonnées',
    },
    businessBookings: {
      paragraphs: {
        noBooking: "Vous n'avez pas encore reçu de réservation.",
        pickBooking: 'Choisissez une réservation pour voir ses détails.',
      },
      inputs: {
        searchBookings: {
          placeholder: 'Recherche de réservation par nom, email, numéro...',
        },
      },
    },
    businessOrders: {
      paragraphs: {
        noOrder: "Vous n'avez pas encore reçu de commande.",
        pickOrder: 'Choisissez une commande pour voir ses détails.',
      },
      inputs: {
        searchOrders: {
          placeholder: 'Recherche de commande par nom, email, numéro...',
        },
      },
    },
    createPlanning: {
      actions: {
        redoPlanning: 'Refaire mon planning',
        confirmPlanning: 'Confirmer mes horaires',
        addSlot: 'Ajouter des horaires',
        deleteSlot: "Supprimer l'horaire",
        goNextDay: 'Passer à la journée suivante',
        sameDayBusinessHours:
          "Mes horaires pour %{day} sont identiques à ceux d'hier",
        dontWorkOnThatDay: 'Je ne travaille pas %{day}',
      },
      banners: {
        allSet:
          "Félicitations, votre planning est maintenant visible par vos clients. Il n'y a rien d'autre à faire, vous êtes prêt !",
        slotFrom: 'Créneau de',
        slotTo: 'à',
        iStartAt: 'je commence à',
      },
      inputs: {
        capacity: {
          label: 'Nb. de clients maximum par créneau',
          placeholder: 'Capacité',
          hint: "Laisser à 0 s'il n'y a pas de limite maximum",
        },
      },
    },
    dashboard: {
      banners: {
        paymentsNotConfigured:
          'Configurez les paiements si vous souhaitez activer les paiments en ligne.',
        seeLiveDashboard:
          'Consultez vos commandes et réservations en TEMPS RÉEL',
        instructionsToSeeLiveDashboard:
          'Activez les commandes et/ou les réservations pour les consulter en TEMPS RÉEL',
      },
      actions: {
        previewMyWebsite: 'Prévisualiser mon site',
        openContactTracingQRCodePDF: 'Obtenir le QR code en PDF',
        copyContactTracingFormLink: 'Copier le lien du formulaire',
        openContactTracingForm: 'Ouvrir le formulaire',
      },
    },
    editContactTracingSettings: {
      title: 'Configurer le carnet de rappel',
    },
    home: {
      title: 'Votre commerce en ligne',
      banners: {
        covid:
          'Accédez à nos services gratuitement pendant un mois en échange de votre retour utilisateur.',
        tellAFriend: {
          title: 'Obtenez nos services gratuitement',
          subtitle:
            "Ne commencez pas l'aventure seul et économisez plus de 20€/mois en invitant un ami sur la plateforme !",
        },
      },
      headers: {
        title:
          'La boîte à outils complète pour mettre vos services en ligne en quelques clics.',
        // dont vous avez besoin pour vos services en ligne
        // title: 'Partagez vos services en ligne en toute simplicité.',
        trustedBy: '1000 professionnels nous font déjà confiance,',
        whyNotYou: 'et vous ?',
      },
      steps: {
        addBusiness: {
          title: 'Ajoutez votre commerce',
          text:
            'Indiquez vos informations (nom, adresse, logo) et vous voilà prêt.',
        },
        createPricing: {
          title: 'Choisissez vos services',
          text:
            'Créez votre commerce en ligne, un programme de fidélité grâce à nos outils simplement intelligents.',
        },
        shareLink: {
          title: 'Partagez votre site',
          text:
            "Vous obtiendrez un lien de votre choix ainsi qu'un QR code pour commercer avec vos clients !",
        },
      },
      features: {
        saveMoney: {
          title: 'Augmentez vos revenus',
          text:
            'Consacrez votre temps et votre argent à ce qui compte vraiment et développez votre clientèle !',
        },
        improveVisibility: {
          title: 'Améliorez votre présence en ligne',
          text:
            'Tous ceux qui ont accès à votre commerce en ligne sont de potentiels clients !',
        },
      },
      products: {
        conciergerie: {
          title: 'Essayez la Conciergerie',
          subtitle:
            'Nous pouvons vous aider à installer tous ces services pour vous.',
          features: {
            simplicity: {
              title: 'Simplicité',
              text:
                'Nous vous rappelerons pour installer ces services pour vous : menu, commandes, réservations et plus.',
            },
            fast: {
              title: 'Rapide',
              text:
                "Cela prend moins de 2 jours pour tout mettre en place. Qu'attendez-vous pour essayer ?",
            },
          },
        },
        pricing: {
          title: 'Site internet',
        },
        orders: {
          title: 'Commandes',
          subtitle:
            'Permettez à vos clients de passer leurs commandes facilement et en toute sécurité.',
        },
        bookings: {
          title: 'Réservations',
          subtitle:
            'Facilitez la prise de rendez-vous, la réservation de cours et gérez efficacement votre planning.',
        },
        contactTracing: {
          title: 'Carnet de rappel',
          subtitle:
            'Un outil simple et confidentiel pour enregistrer les coordonnées de vos clients.',
        },
        memberships: {
          title: 'Cartes de fidélité',
          subtitle:
            'Récompensez la fidélité de vos clients et apprenez à mieux les connaître.',
        },
      },
      contactUs: {
        makeYourLifeEasier:
          "Nous nous efforçons de simplifier votre vie. S'il y a quoi que ce soit que nous puissions améliorer,",
        contactByEmail: 'contactez-nous par email',
      },
    },
    item: {
      buttons: {
        removeFromCart: 'Retirer de la commande',
        addToCart: 'Ajouter à la commande',
      },
      paragraphs: {
        chooseUpTo: "choisissez jusqu'à",
        oneOption: 'une option',
        manyOptions: '%{options} options',
      },
    },
    join: {
      headers: {
        thanksForJoining:
          'Nous sommes très heureux de pouvoir vous accompagner ! Commençons par quelques informations ci-dessous.',
        yourInformation: 'À propos de vous',
        yourBusinessInformation: 'À propos de votre commerce',
      },
      inputs: {
        phoneNumber: {
          placeholder: 'ex : 0601020304',
          label: 'Numéro de téléphone',
          hint:
            "Cette information ne sera pas partagée. Elle ne peut être utilisée qu'à des fins d'authentification.",
        },
        businessName: {
          placeholder: 'Nom du commerce',
        },
        businessAddress: {
          hint:
            "Indiquez l'adresse complète de votre commerce (y compris le pays), par exemple 7 rue de Paris, 75000 Paris, France. Nous chercherons automatiquement votre adresse en utilisant les services de Google.",
        },
        businessPhoneNumber: {
          label: 'Numéro de téléphone du commerce',
          hint:
            'Ce numéro de téléphone doit être valide pour que vos clients puissent vous contacter.',
        },
      },
      banners: {
        covid: {
          title: 'Nous sommes là pour vous aider',
          text:
            "Afin de vous aider au mieux durant cette pandémie, l'intégralité de nos services sont disponibles gratuitement. Nos tarifs reviendront à la normale en Octobre, à partir de 9.99€/mois. L'occasion idéale pour vous d'essayer illicarte dès à présent.",
        },
      },
    },
    liveDashboard: {
      actions: {
        addWalkIn: 'Ajouter client de passage',
      },
      inputs: {
        search: {
          placeholder: 'Recherche par nom, email, numéro...',
        },
      },
    },
    makeOrder: {
      headers: {
        orderSuccess: 'Merci ! Votre commande est passée.',
        orderFollowUp: 'Suivre votre commande',
        referenceNumber: 'N° référence :',
        orderTotal: 'Le total de votre commande est de %{total}.',
        yourContactDetails: 'Vos coordonnées',
      },
      banners: {
        orderSuccess:
          "Vous pouvez désormais récupérer votre commande une fois qu'elle sera prête chez votre commercant. N'hésitez pas à les contacter afin de connaître leurs modalités de récupération de commande.",
        orderFollowUp:
          "Afin d'identifier votre commande, nous avons associé une référence afin que le commerçant puisse également confirmer votre commande. Comme cette information est importante, nous avons aussi pris le soin de vous envoyer une copie par email afin d'accéder à votre commande à tout moment.",
        yourContactDetails:
          'Afin que le commerçant puisse vous contacter et pour identifier votre commande, merci de bien vouloir remplir les informations ci-dessous.',
      },
      inputs: {
        comment: {
          placeholder:
            'Avez-vous certaines précisions particulières sur la commande ?',
        },
        paymentType: {
          label: 'Comment souhaitez-vous régler votre commande ?',
          inShop: 'Sur place',
          online: 'En ligne',
        },
      },
    },
    memberships: {
      banners: {
        message:
          "Votre programme de fidélité peut-être consulté sur notre plateforme Plus One (une application d'illicarte) en utilisant vos identifiants illicarte (même email/mot de passe).",
        noRewards:
          "Vous n'avez pas encore indiqué de paliers pour votre programme. Créez-en un !",
      },
      actions: {
        createProgram: 'Créer le programme',
        downloadIOSApp: 'Télécharger Plus One sur iOS',
        downloadAndroidApp: 'Télécharger Plus One sur Android',
        deleteProgram: 'Supprimer le programme',
      },
      alerts: {
        deleteMembership:
          "Êtes-vous sûr de vouloir supprimer votre programme de fidélité ? Vos clients perderont l'intégralité de leurs récompenses. Vous ne pourrez pas annuler cette opération.",
      },
      paragraphs: {
        everyXPoints: 'Tous les %{points} points',
        XPoints: '%{points} points',
        indications: {
          whatIsAMembership:
            "Un programme de fidélité permet à vos clients de collecter des points dans votre commerce et vous donnera une meilleure vue d'ensemble de votre clientèle en utilisant nos statistiques.",
          onceCreated:
            "Une fois votre programme créé, il sera disponible auprès de vos clients depuis l'application mobile Plus One que vous pouvez télécharger gratuitement. Vos statistiques y seront également disponibles.",
          connectionWithApp:
            "Il vous suffira simplement d'utiliser vos identifiants illicarte sur Plus One (même email/mot de passe). Vous pouvez éditer votre programme soit ici ou via l'application.",
        },
      },
    },
    orderDetails: {
      title: 'Commande %{referenceNumber}',
      errors: {
        orderNotFound:
          "Votre commande n'a pas pu être trouvé. Si vous êtes certain que votre numéro de commande est correct (ici : %{referenceNumber}), veuillez nous contacter pour obtenir une assistance.",
        noComment: 'Aucun commentaire.',
      },
      headers: {
        orderNumber: 'Commande %{referenceNumber}',
      },
      banners: {
        paymentInformation:
          "Les informations liées à votre paiement sont disponibles sur votre compte PayPal ou bien via l'email que vous avez reçu après avoir passé cette commande.",
      },
    },
    paymentSettings: {
      banners: {
        onlyClickCollect:
          "Si vous n'avez pas besoin des paiements en ligne (uniquement les paiements en magasin), vous n'avez pas besoin de configurer les paiements ci-dessous. Vos clients paieront uniquement en magasin.",
      },
      actions: {
        deactivateOnlinePayments: 'Désactiver les paiements en ligne',
      },
    },
    pricing: {
      banners: {
        noSlug:
          'Nous afficherons prochaînement les meilleures tarifications de nos clients pour vous inspirer.',
      },
      actions: {
        getDirections: 'Adresse',
      },
      price: {
        rangeFromTo: 'De %{from} à %{to}',
        up: 'À partir de %{price}',
      },
      links: {
        createdWith: 'Créé avec illicarte',
      },
    },
    products: {
      headers: {
        whatYouGet: 'Inclus dans notre offre',
        youMightAlsoNeed: 'Auriez-vous aussi besoin de...',
      },
      included: {
        onDemand: 'Sur demande',
        onboardingSupport: "Aide à l'installation",
        socialMediaMaterials: 'Contenu marketing pour vos réseaux sociaux',
        customBranding: 'Image de marque personnalisée (logo, nom)',
        securePayments: 'Paiements sécurisés',
        mobileAppAccess: "Accès personnalisé à l'application mobile illicarte",
        customDomainName: 'Nom de domaine personnalisé',
      },
      actions: {
        watchVideo: 'Découvrez notre fonctionnement',
      },
    },
    productBookings: {
      features: {
        fullHouse: {
          title: 'Jouez à guichets fermés',
          subtitle:
            'Simplifiez votre système de réservation grâce à nos services en ligne et augmentez vos revenus.',
        },
        organize: {
          title: 'Organisez-vous encore mieux',
          subtitle:
            'Un bon système de réservation vous permet de mieux planifier vos quantités et disponibilités.',
        },
        wastingTime: {
          title: 'Ne perdez plus votre temps au téléphone',
          subtitle: 'Vos clients réservent intégralement en ligne.',
        },
        scheduleUpToDate: {
          title: 'Vos disponibilités mises à jour',
          subtitle:
            'Vos clients consultent en temps réel les créneaux disponibles.',
        },
        missedBookings: {
          title: 'Dites adieu aux rendez-vous fantômes',
          subtitle: 'Vos clients s’engagent à payer à l’avance.',
        },
      },
      included: {
        personalSpace: 'Espace personnel pour vos services',
        bookingConfirmation: 'Mails de confirmation pour vos réservations',
      },
      faq: {
        setUpBookings: {
          question: "Est-ce compliqué d'ajouter le système de réservation ?",
        },
      },
    },
    productContactTracing: {
      features: {
        dataSafety: {
          title: 'Vos données en sécurité',
          subtitle:
            'Seules les données strictement nécessaires sont collectées, sécurisées puis supprimées après 14 jours.',
        },
        simplifyLife: {
          title: 'Simplifiez-vous la vie',
          subtitle:
            "Nos services s'adaptent aux évolutions complexes des mesures sanitaires pour vous simplifier la vie dans ces moments difficiles.",
        },
        quickSetup: {
          title: 'Mise en place instantanée',
          subtitle:
            'Créez votre carnet de rappel en ligne en quelques minutes.',
        },
        focusOnWhatMatters: {
          title: 'Concentrez-vous sur ce qui compte',
          subtitle:
            'Notre équipe se charge de la transmission de votre carnet avec les autorités.',
        },
        autonomousClients: {
          title: 'Vos clients sont autonomes',
          subtitle:
            "Vos clients restent autonomes dans l'indication de leurs données.",
        },
        confidential: {
          title: 'Vos données sont confidentielles',
          subtitle:
            'Les coordonnées confidentielles de vos clients sont enregistées en toute sécurité.',
        },
      },
      steps: {
        getFormLink: 'Obtenez un lien du formulaire à remplir par vos clients',
        clientsFillForm: "Vos clients remplissent le formulaire et l'envoient",
        weTakeCareOfTheRest: "On s'occupe du reste !",
      },
      faq: {
        safety: {
          question: 'Est-ce vraiment sécurisé ?',
          answer:
            'Nous vivons tout comme vous au quotidien ces changements de vie et tenons à cœur de gardez vos données en toute sécurité. Les coordonnées de vos clients seront gardées pendant 14 jours, cryptées, afin que seules les autorités compétentes puissent y avoir accès dans un cadre légal.',
        },
        timeManagement: {
          question: 'En quoi est-ce un gain de temps ?',
          answer:
            "Lorsqu'il s'agit de tenir un carnet de rappel, la responsibilité retombe sur vous et vos employés. Ce que nous vous proposons et qui plus est gratuitement, c'est de s'occuper de cela pour vous. Il vous suffira simplement d'indiquer à vos clients d'ajouter leurs coordonnées via votre site internet (qui vous sera communiqué une fois inscrit) ou cas exceptionnel de le faire pour eux. Si les autorités venaient à vous contacter, il vous suffit simplement de les rediriger vers nous pour toute demande.",
        },
        setUpTracing: {
          question: 'Est-ce compliqué de créer un carnet de rappel ?',
          answer:
            "Une fois votre compté créé, il vous suffira d'activer en un clic votre carnet de rappel via notre interface. Un lien vers un formulaire vous sera communiqué afin que vos clients puissent indiquer leurs coordonnées avant de rentrer dans votre commerce.",
        },
      },
    },
    productMemberships: {
      features: {
        understandCustomers: {
          title: 'Comprenez votre clientèle',
          subtitle:
            'Apprenez de leurs habitudes grâce à votre tableau de bord et statistiques.',
        },
        simplicity: {
          title: 'La simplicité pour tous',
          subtitle:
            'Passer aux cartes de fidélité digitales ne vous prendra que quelques minutes !',
        },
        adjustedRewards: {
          title: 'Des récompenses adaptées',
          subtitle: 'Une réduction dès 5 points, un café tous les 10 points.',
        },
        useYourPhone: {
          title: 'Utilisez votre téléphone',
          subtitle: 'Scannez les QR codes de vos clients et vous voilà prêt !',
        },
        detailedStatistics: {
          title: 'Statistiques détaillées',
          subtitle: 'Suivez les données et l’évolution de votre clientèle.',
        },
        noPrinting: {
          title: 'Plus besoin d’imprimer de cartes',
          subtitle: 'Réduisez vos coûts et préservez l’environnement.',
        },
      },
      steps: {
        createMembership:
          'Un client se crée une carte de fidélité sur l’app Plus One',
        scanCode: 'Le QR code client est scanné par un employé du commerce',
        getRewards: 'Le client reçoit ses points et récompenses !',
      },
      included: {
        customBranding: 'Image de marque personnalisée (logo, nom, carte)',
        loyaltyProgram: 'Programme de fidélité personnalisable',
        customerStatistics: 'Statistiques clientèle',
        mobileAppAccess: "Accès personnalisé à l'application mobile Plus One",
        businessPromotion: 'Promotion auprès de tous nos utilisateurs',
      },
      faq: {
        anotherService: {
          question: 'Pourquoi notre service ?',
          answer:
            "La plupart des services de cartes de fidélité en ligne savent que vous ne connaissez pas les coûts réels induits et en tirent profit. Nous comprenons qu'il s'agit d'un service essentiel pour votre commerce et avons créé une offre qui répond à vos besoins en respectant notre engagement qualité.",
        },
        setUpMemberships: {
          question: "Est-ce compliqué d'ajouter le système de fidélité ?",
          answer:
            "Tout ce dont vous avez besoin c'est d'un smartphone. Vos clients pourront ensuite se créer une carte de fidélité via notre site internet ou notre application mobile. Il vous suffira ensuite de scanner leur QR code afin d'ajouter des points sur leur carte. Si vous possédez déjà un compte avec nous pour d'autres services, vous n'aurez qu'à éditer votre programme de fidélité ce qui ne vous prendra que quelques minutes.",
        },
      },
    },
    productOrders: {
      features: {
        notifications: {
          title: 'Soyez notifié',
          subtitle:
            "Recevez un email dès qu'un client passe une commande. Vos clients recevront une copie directement dans leur boîte mail.",
        },
        payments: {
          title: 'Paiements sécurisés',
          subtitle:
            'Passer en ligne présente de nombreuses incertitudes. Nos systèmes garantissent la sécurité dont vous avez besoin en ligne.',
        },
        middlemen: {
          title: 'Adieu les intermédiaires',
          subtitle:
            'Terminé les commissions. Payez un coût fixe, qu’importe votre volume d’activité.',
        },
        safety: {
          title: 'Commercez en toute sécurité',
          subtitle:
            'Nos systèmes garantissent la sécurité dont vous avez besoin en ligne (paiements, site).',
        },
        ordersNotifications: {
          title: 'Vos commandes notifiées',
          subtitle: 'Chaque commande vous sera notifiée pour ne rien manquer.',
        },
        complexity: {
          title: 'Votre complexité simplifiée',
          subtitle:
            'Notre plateforme s’adaptera à vos besoins pour vous simplifier la vie.',
        },
      },
      headers: {
        faq: "Les questions qui valent le coup d'être posées",
      },
      steps: {
        yourWebsite: 'Une page pour accéder à vos produits et services',
        clientsBuy: 'Chacun choisit ce qu’il souhaite acheter',
        clientsPutOrder: 'Le client passe commande, vous recevez les détails',
      },
      faq: {
        anotherService: {
          question: 'Pourquoi notre service ?',
          answer:
            "La plupart des services de boutiques en ligne savent que vous ne connaissez pas les coûts réels induits et en tirent profit. Nous comprenons qu'il s'agit d'un service essentiel pour votre commerce et avons créé une offre qui répond à vos besoins en respectant notre engagement qualité. Nous ne récupèrons aucune commission sur vos paiements à l'exception de PayPal qui justifie de 20 années d'expérience en matière de paiements sécurisés.",
        },
        setUpOrders: {
          question: "Est-ce compliqué d'ajouter le système de commandes ?",
          answer:
            'Une fois votre tarification créée (par exemple votre menu, une liste de services), il vous suffira de créer un compte business gratuit avec PayPal. Les étapes suivantes vous seront expliquées et peuvent toutes être réalisées en quelques minutes. Nous sommes à votre disposition si vous rencontrez quelque difficulté.',
        },
        getMoney: {
          question: "Comment je gagne de l'argent ?",
          answer:
            'Après avoir connecté votre compte PayPal business à notre plateforme, nous validerons les paiements clients afin de vérifier si les sommes dûes correspondent aux commandes. Toutes vos recettes seront directement disponibles sur votre compte PayPal.',
        },
        trust: {
          question: 'Puis-je vous faire confiance ?',
          answer:
            "Tout ce qui nous importe c'est de vous aider, par tous les moyens. Cette plateforme est faite pour vous et permet à vos clients de commercer avec vous en toute simplicité. Alors oui, nous sommes une entreprise, mais une entreprise à l'écoute de vos besoins.",
        },
      },
      included: {
        shopSpace: 'Espace personnel pour vos services',
        orderConfirmation: 'Emails de confirmation pour vos commandes',
        customMobileApp: 'Application mobile personnalisée',
      },
    },
    roomTables: {
      actions: {
        createRoom: 'Ajouter une salle et des tables',
      },
      banners: {
        explanations:
          'En ajoutant des salles/tables, vous permettez à vos clients de prendre des réservations qui seront automatiquement associées aux tables les mieux adaptées à leur demande.',
        noRooms:
          "Il n'y a aucune salle et table pour le moment. Ajoutez-en en utilisant le formulaire.",
      },
    },
    settings: {
      inputs: {
        password: {
          hint: 'Si indiqué, cela mettra à jour votre mot de passe actuel.',
        },
      },
      buttons: {
        updateInformation: 'Mettre à jour',
        updateBusinessLogo: 'Modifier votre logo',
      },
    },
    signIn: {
      banners: {
        createYourPricing: 'Vous souhaitez créer votre tarification ?',
        joinUs: 'Créez votre compte',
      },
    },
    welcome: {
      banners: {
        startWebsite: {
          title: 'Commençons par votre site internet',
          subtitle:
            'Afin que vos clients puissent vous trouver en ligne, nous allons créer un site internet pour vous.',
        },
        thankYouForJoining: {
          title: 'Bienvenue parmi nous',
          subtitle:
            "Si vous vous sentez perdu ou avez besoin d'assistance, n'hésitez surtout pas à nous envoyer un message. Nous avons aussi préparé plusieurs tutoriels ci-dessous pour ne pas vous perdre, jetez-y un coup d'œil !",
        },
        website: {
          title: 'Produits et services',
          subtitle:
            'Vous souhaitez mettre votre menu en ligne ou une liste de produits/services.',
        },
        orders: {
          title: 'Commandes',
          subtitle:
            'Vous souhaitez permettre à vos clients de commander en ligne.',
        },
        bookings: {
          title: 'Réservations',
          subtitle:
            'Vous souhaitez permettre à vos clients de réserver en ligne.',
        },
        loyaltyProgram: {
          title: 'Programme de fidélité',
          subtitle:
            'Vous souhaitez créer un programme de fidélité pour vos clients.',
        },
        contactTracing: {
          title: 'Carnet de rappel',
          subtitle: 'Vous souhaitez mettre en place un carnet de rappel.',
        },
      },
      headers: {
        whereToStart: 'Par où souhaitez-vous commencer ?',
        learnAboutOurServices:
          'Apprenez-en plus sur nos services au travers de ces courts tutoriels.',
      },
      actions: {
        skip: 'Continuer, je comprends comment le site fonctionne',
      },
    },
  },
};
