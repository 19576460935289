import React from 'react';
import { StyleSheet, TextProps } from 'react-native';
import Text from '../Text/Text';
import Theme from '../../helpers/theme';

export type LabelProps = {
  children: any;
  required?: boolean;
  style?: TextProps['style'];
};

const Label = (props: LabelProps) => {
  return (
    <Text style={[styles.container, props.style]}>
      {props.children}
      {props.required && <Text style={styles.required}>*</Text>}
    </Text>
  );
};

const styles = StyleSheet.create({
  container: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  required: {
    color: Theme.RED,
  },
});

export default Label;
