import React, { useState, useCallback, useRef } from 'react';
import {
  TextInput as RNTextInput,
  ViewProps,
  View,
  StyleSheet,
} from 'react-native';
import TextInput from '../TextInput/TextInput';
import Hint from '../Hint/Hint';
import CountryPicker from '../CountryPicker/CountryPicker';
import CountryIcon from '../CountryPicker/CountryIcon';
import Label from '../Label/Label';

export type PhoneNumberInputProps = {
  label?: string;
  placeholder: string;
  countryCode: string;
  phoneNumber: string;
  onChangeCountryCode: (countryCode: string) => void;
  onChangePhoneNumber: (phoneNumber: string) => void;
  hint?: string;
  required?: boolean;
  style?: ViewProps['style'];
};

const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  const inputRef = useRef<RNTextInput>();
  const [visible, setVisible] = useState(false);

  const handleToggleModal = useCallback(
    () => setVisible((visible) => !visible),
    []
  );

  const handleChangeCountry = useCallback((countryCode: string) => {
    props.onChangeCountryCode(countryCode);
    setVisible(false);
    inputRef.current?.focus();
  }, []);

  return (
    <View style={[styles.container, props.style]}>
      <Label required={props.required}>{props.label}</Label>

      <View style={styles.inputs}>
        <CountryIcon
          countryCode={props.countryCode}
          onPress={handleToggleModal}
        />

        <TextInput
          ref={inputRef}
          editable
          type="phone"
          placeholder={props.placeholder}
          value={props.phoneNumber}
          onChangeText={props.onChangePhoneNumber}
          style={styles.phoneNumberInput}
        />
      </View>

      {visible && (
        <CountryPicker
          height={150}
          onChangeCountry={handleChangeCountry}
          style={styles.countryPicker}
        />
      )}

      {props.hint && <Hint>{props.hint}</Hint>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  inputs: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  phoneNumberInput: {
    flex: 1,
    marginLeft: 10,
  },
  countryPicker: {
    marginVertical: 10,
  },
});

export default React.memo(PhoneNumberInput);
