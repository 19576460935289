import React, { useEffect, useCallback, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { useQuery, queryCache } from 'react-query';
import { UserStackParamList } from '../navigators/UserNavigator';
import { t } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import Block from '../components/Block/Block';
import P from '../components/P/P';
import Banner from '../components/Banner/Banner';
import Theme from '../helpers/theme';
import API from '../helpers/api';
import {
  businessIdState,
  membershipStagesState,
  membershipsProgramIdState,
} from '../store/state';
import H3 from '../components/H3/H3';
import Icon from '../components/Icon/Icon';
import Button from '../components/Button/Button';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';
import AddProgramStageForm from '../forms/AddProgramStageForm';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import { useIsWideScreen } from '../hooks/responsive';

type MembershipsScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'Memberships'>;
};

const MembershipsScreen = (props: MembershipsScreenProps) => {
  useDocumentTitle(t('navigation.myLoyaltyProgram'));

  const [isBusy, setIsBusy] = useState(false);
  const businessId = useRecoilValue(businessIdState);
  const [membershipsProgramId, setMembershipsProgramId] = useRecoilState(
    membershipsProgramIdState
  );
  const resetMembershipsProgramId = useResetRecoilState(
    membershipsProgramIdState
  );
  const [membershipStages, setMembershipStages] = useRecoilState(
    membershipStagesState
  );
  const resetMembershipStages = useResetRecoilState(membershipStagesState);
  const isWideScreen = useIsWideScreen();

  const { data: program, status, refetch: refetchProgram } = useQuery(
    ['loyalty-program', businessId],
    (_key: string, businessId: string) => {
      return API.fetchBusinessProgramRewards(businessId);
    }
  );

  useEffect(() => {
    if (program) {
      setMembershipsProgramId(program.id.toString());
      setMembershipStages([
        ...program.loyalty_stages.sort((a, b) => a.stampsCount - b.stampsCount),
      ]);
    }
  }, [program]);

  const handleDeleteProgram = useCallback(async () => {
    if (!membershipsProgramId) {
      return;
    }

    if (!confirm(t('screens.memberships.alerts.deleteMembership'))) {
      return;
    }

    setIsBusy(true);

    try {
      await API.deleteProgramById(membershipsProgramId);

      resetMembershipsProgramId();
      resetMembershipStages();

      queryCache.invalidateQueries(['loyalty-program', businessId], {
        refetchActive: false,
      });
    } catch (error) {
      console.warn(error);
    }

    setIsBusy(false);
  }, [membershipsProgramId]);

  const handleCreateProgram = useCallback(async () => {
    try {
      await API.createProgram();
      refetchProgram();
    } catch (error) {
      console.warn(error);
    }
  }, []);

  if (status !== 'success') {
    return <LoaderContainer />;
  }

  return (
    <ScrollView>
      <View style={styles.wrapper}>
        <Banner
          color={Theme.RED}
          align="left"
          rounded
          textStyle={styles.plusOneBannerText}
          action={t('common.open')}
          onPressAction={() => {
            window.open('https://getplusone.app', '_blank');
          }}
        >
          {t('screens.memberships.banners.message')}
        </Banner>

        {membershipsProgramId ? (
          <>
            <Block verticalMargins list>
              {membershipStages.length === 0 ? (
                <P>{t('screens.memberships.banners.noRewards')}</P>
              ) : (
                membershipStages.map((stage, index) => (
                  <React.Fragment key={stage.id}>
                    <View style={styles.stageContainer}>
                      <P style={styles.stageStampsCount}>
                        {stage.isRecurrent
                          ? t('screens.memberships.paragraphs.everyXPoints', {
                              points: stage.stampsCount,
                            })
                          : t('screens.memberships.paragraphs.XPoints', {
                              points: stage.stampsCount,
                            })}
                      </P>

                      <View style={styles.stageInformation}>
                        <Icon
                          name={
                            stage.rewardType === 'discount' ? 'percent' : 'gift'
                          }
                          style={styles.stageIcon}
                        />

                        <View style={styles.stageText}>
                          <H3>
                            {stage.rewardName}

                            {stage.isActivated
                              ? ''
                              : ` (${t('common.deactivated')})`}
                          </H3>

                          <P>{stage.rewardDescription}</P>
                        </View>

                        <Button
                          size="small"
                          title={isWideScreen && t('common.edit')}
                          icon="edit-3"
                          onPress={() =>
                            props.navigation.navigate('EditReward', {
                              rewardId: stage.id,
                            })
                          }
                          inline
                          style={styles.editStageButton}
                        />
                      </View>
                    </View>

                    {index < membershipStages.length - 1 && (
                      <ItemSeparator style={styles.stageSeparator} />
                    )}
                  </React.Fragment>
                ))
              )}
            </Block>

            <Block>
              <AddProgramStageForm />
            </Block>

            <Button
              icon="trash-2"
              type="danger"
              title={t('screens.memberships.actions.deleteProgram')}
              onPress={handleDeleteProgram}
              busy={isBusy}
              style={styles.deleteProgramButton}
            />
          </>
        ) : (
          <Block style={styles.createProgramContainer}>
            <P style={styles.indications}>
              {t(
                'screens.memberships.paragraphs.indications.whatIsAMembership'
              )}
            </P>

            <P style={styles.indications}>
              {t('screens.memberships.paragraphs.indications.onceCreated')}
            </P>

            <P style={styles.indications}>
              {t(
                'screens.memberships.paragraphs.indications.connectionWithApp'
              )}
            </P>

            <Button
              title={t('screens.memberships.actions.createProgram')}
              onPress={handleCreateProgram}
              busy={isBusy}
            />
          </Block>
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    padding: 20,
  },
  plusOneBannerText: {
    color: Theme.BLACK,
  },
  createProgramContainer: {
    marginTop: 20,
  },
  stageContainer: {},
  stageInformation: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
  },
  stageStampsCount: {
    color: Theme.RED,
    fontWeight: 'bold',
  },
  stageIcon: {
    marginRight: 10,
  },
  stageText: {
    flex: 1,
  },
  editStageButton: {
    marginLeft: 10,
  },
  stageSeparator: {
    marginLeft: 10,
  },
  deleteProgramButton: {
    marginTop: 20,
  },
  indications: {
    marginBottom: 10,
  },
});

export default MembershipsScreen;
