import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useRecoilValue } from 'recoil';
import { AppStackParamList } from '../navigators/AppNavigator';
import { useIsWideScreen } from '../hooks/responsive';
import { useDocumentTitle } from '../hooks/document';
import Link from '../components/Link/Link';
import Feature from '../components/Feature/Feature';
import P from '../components/P/P';
import { t } from '../helpers/translation';
import Wrapper from '../components/Wrapper/Wrapper';
import Features from '../components/Features/Features';
import BrandBar from '../components/BrandBar/BrandBar';
import JoinBanner from '../components/JoinBanner/JoinBanner';
import Theme from '../helpers/theme';
import { isLoggedInState } from '../store/state';
import { usePrevious } from '../hooks/misc';
import Icons from '../constants/icons';
import FeaturesJob from '../components/FeaturesJob/FeaturesJob';
import Title from '../components/Title/Title';
import ServiceBlock from '../modules/home/ServiceBlock';
import H1 from '../components/H1/H1';

const VIDEOS = [
  'https://firebasestorage.googleapis.com/v0/b/illicarte.appspot.com/o/menu.mp4?alt=media&token=4a2729ed-618d-4db3-b7e9-f42bd1e1e55e',
  'https://firebasestorage.googleapis.com/v0/b/illicarte.appspot.com/o/orders.mp4?alt=media&token=c556d932-6dab-4b55-91fb-0a1fa99c0f88',
  'https://firebasestorage.googleapis.com/v0/b/illicarte.appspot.com/o/bookings.mp4?alt=media&token=18200366-661b-4e8d-97a1-ee2345080215',
  'https://firebasestorage.googleapis.com/v0/b/illicarte.appspot.com/o/tracing.mp4?alt=media&token=dd8c60e0-7ece-4f37-9ec5-3eeb07c799b4',
  'https://firebasestorage.googleapis.com/v0/b/illicarte.appspot.com/o/loyalty.mp4?alt=media&token=9ac8da81-b758-47c0-bddb-cc23a7ea0f29',
];

type Props = {
  navigation: DrawerNavigationProp<AppStackParamList, 'Home'>;
};

const HomeScreen = (props: Props) => {
  const [videoIndex, setVideoIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isWideScreen = useIsWideScreen();
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const prevIsLoggedIn = usePrevious(isLoggedIn);

  useDocumentTitle(t('screens.home.title'));

  useEffect(() => {
    if (!prevIsLoggedIn && isLoggedIn) {
      props.navigation.navigate('SignedIn');
    }
  }, [prevIsLoggedIn, isLoggedIn]);

  useEffect(() => {
    videoRef.current?.play().catch(() => null);
  }, [videoIndex, videoRef]);

  const handleVideoEnd = useCallback(() => {
    // 5 = number of services videos
    // TODO: use a constant for videos and do VIDEOS.length instead of 5
    setVideoIndex((videoIndex) => (videoIndex + 1) % 5);
  }, []);

  const handlePickVideo = useCallback((index: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }

    setVideoIndex(index);
  }, []);

  const handleFirstVideo = useCallback(() => handlePickVideo(0), []);
  const handleSecondVideo = useCallback(() => handlePickVideo(1), []);
  const handleThirdVideo = useCallback(() => handlePickVideo(2), []);
  const handleFourthVideo = useCallback(() => handlePickVideo(3), []);
  const handleFifthVideo = useCallback(() => handlePickVideo(4), []);

  return (
    <ScrollView contentContainerStyle={styles.wrapper}>
      <BrandBar />

      <Wrapper style={[styles.container, isWideScreen && styles.containerWide]}>
        <View
          style={[
            {
              marginTop: -20,
              marginBottom: 40,
              justifyContent: 'center',
            },
            isWideScreen && {
              flexDirection: 'row',
              marginHorizontal: 40,
            },
          ]}
        >
          {!isWideScreen && (
            <H1 align="center" style={{ marginVertical: 40 }}>
              {t('screens.home.headers.title')}
            </H1>
          )}

          <video
            ref={videoRef}
            src={VIDEOS[videoIndex]}
            width={200}
            height={433}
            autoPlay
            muted
            onEnded={handleVideoEnd}
            controls={!isWideScreen}
            controlsList="nofullscreen"
            style={{
              borderRadius: 6,
              alignSelf: isWideScreen ? 'flex-start' : 'center',
              marginRight: isWideScreen ? 40 : 0,
              marginBottom: isWideScreen ? 0 : 20,
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 5px 13px',
            }}
          />

          <View style={{ flex: 1, justifyContent: 'center' }}>
            {isWideScreen && (
              <Title align="justify" style={{ marginBottom: 20 }}>
                {t('screens.home.headers.title')}
              </Title>
            )}

            <View
              style={[
                {
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'center',
                },
                isWideScreen && {
                  width: '80%',
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <ServiceBlock
                title={t('screens.welcome.banners.website.title')}
                icon={Icons.PRICING}
                selected={videoIndex === 0}
                onPress={handleFirstVideo}
                style={{ marginRight: 20 }}
              />

              <ServiceBlock
                title={t('screens.welcome.banners.orders.title')}
                color={Theme.ORANGE}
                icon={Icons.ORDERS}
                selected={videoIndex === 1}
                onPress={handleSecondVideo}
                style={{ marginRight: 20 }}
              />

              <ServiceBlock
                title={t('screens.welcome.banners.bookings.title')}
                color={Theme.GREEN}
                icon={Icons.BOOKINGS}
                selected={videoIndex === 2}
                onPress={handleThirdVideo}
                style={{ marginRight: 20 }}
              />

              <ServiceBlock
                title={t('screens.welcome.banners.contactTracing.title')}
                color={Theme.INDIGO}
                icon={Icons.CONTACT_TRACING}
                selected={videoIndex === 3}
                onPress={handleFourthVideo}
                style={{ marginRight: 20 }}
              />

              <ServiceBlock
                title={t('screens.welcome.banners.loyaltyProgram.title')}
                color={Theme.RED}
                icon={Icons.LOYALTY}
                selected={videoIndex === 4}
                onPress={handleFifthVideo}
              />
            </View>
          </View>
        </View>

        <Features noMargins>
          <Feature
            title={t('screens.home.features.saveMoney.title')}
            subtitle={t('screens.home.features.saveMoney.text')}
            image={require('../../assets/piggy_bank.svg')}
          />

          <Feature
            title={t('screens.home.features.improveVisibility.title')}
            subtitle={t('screens.home.features.improveVisibility.text')}
            image={require('../../assets/marketing.svg')}
          />
        </Features>

        <View style={{ marginVertical: 20 }}>
          <Feature
            backgroundColor="rgb(190, 190, 190)"
            image={require('../../assets/talk_friend.svg')}
            textColor={Theme.BLACK}
            title={t('screens.home.banners.tellAFriend.title')}
            subtitle={t('screens.home.banners.tellAFriend.subtitle')}
          />
        </View>

        <FeaturesJob
          href="/join"
          image={require('../../assets/conciergerie.jpg')}
          title={t('screens.home.products.conciergerie.title')}
          subtitle={t('screens.home.products.conciergerie.subtitle')}
          features={[
            {
              icon: 'smile',
              title: t(
                'screens.home.products.conciergerie.features.simplicity.title'
              ),
              text: t(
                'screens.home.products.conciergerie.features.simplicity.text'
              ),
            },
            {
              icon: 'zap',
              title: t(
                'screens.home.products.conciergerie.features.fast.title'
              ),
              text: t('screens.home.products.conciergerie.features.fast.text'),
            },
          ]}
          style={{ marginBottom: 20 }}
        />

        <Features noMargins style={{ marginBottom: 20 }}>
          <Feature
            textColor={Theme.BLACK}
            backgroundColor="rgb(190, 190, 190)"
            image={require('../../assets/order_confirmed.svg')}
            title={t('screens.home.products.orders.title')}
            subtitle={t('screens.home.products.orders.subtitle')}
            href="/products/orders"
          />

          <Feature
            textColor={Theme.BLACK}
            backgroundColor="rgb(190, 190, 190)"
            image={require('../../assets/credit_card.svg')}
            title={t('screens.home.products.memberships.title')}
            subtitle={t('screens.home.products.memberships.subtitle')}
            href="/products/memberships"
          />
        </Features>

        <Features noMargins style={{ marginBottom: 20 }}>
          <Feature
            textColor={Theme.BLACK}
            backgroundColor="rgb(190, 190, 190)"
            image={require('../../assets/restaurant.svg')}
            title={t('screens.home.products.bookings.title')}
            subtitle={t('screens.home.products.bookings.subtitle')}
            href="/products/bookings"
          />

          <Feature
            textColor={Theme.BLACK}
            backgroundColor="rgb(190, 190, 190)"
            image={require('../../assets/contact-tracking.svg')}
            title={t('screens.home.products.contactTracing.title')}
            subtitle={t('screens.home.products.contactTracing.subtitle')}
            href="/products/contact-tracing"
          />
        </Features>

        <JoinBanner
          forceWhite
          backgroundColor={Theme.PRIMARY}
          style={{ marginBottom: 20 }}
        />

        <P>
          {t('screens.home.contactUs.makeYourLifeEasier') + ' '}
          <Link to="mailto:contact@illicarte.com" target="_blank">
            {t('screens.home.contactUs.contactByEmail')}
          </Link>
          .
        </P>
      </Wrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: 20,
  },
  container: {
    marginTop: 20,
  },
  containerWide: {
    marginTop: 0,
  },
  myPricingButton: {
    marginRight: 20,
  },
  stepsContainer: {
    paddingBottom: 20,
    paddingTop: 30,
  },
  steps: {
    flexDirection: 'column',
  },
  stepsWide: {
    flexDirection: 'row',
  },
  stepsWideLast: {
    marginTop: 30,
  },
  step: {
    marginBottom: 20,
  },
  productContainer: {
    flexDirection: 'column-reverse',
    marginBottom: 20,
  },
  productContainerWide: {
    flexDirection: 'row',
  },
  productContainerReversedWide: {
    flexDirection: 'row-reverse',
  },
  productIcon: {
    marginRight: 5,
    backgroundColor: Theme.PRIMARY,
    borderRadius: 13,
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  productImage: {
    height: 300,
    width: '100%',
    margin: 'auto',
  },
  productImageWide: {
    width: '50%',
  },
  productText: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  productTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  productSubtitle: {
    textAlign: 'center',
    marginVertical: 10,
    fontWeight: 'normal',
  },
  productReadMore: {
    fontSize: 18,
  },
});

export default HomeScreen;
