import React, { useState } from 'react';
import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  View,
  FlatList,
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { UserStackParamList } from '../navigators/UserNavigator';
import { t } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import API from '../helpers/api';
import { businessIdState } from '../store/state';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import P from '../components/P/P';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';
import TextInput from '../components/TextInput/TextInput';
import BookingRowItem from '../components/BookingRowItem/BookingRowItem';
import BookingDetails from '../components/BookingDetails/BookingDetails';
import BookingStatusSelectors from '../components/BookingStatusSelectors/BookingStatusSelectors';
import { webOnly } from '../helpers/style';
import { useLiveBookings } from '../hooks/queries';
import { useIsWideScreen } from '../hooks/responsive';

type BusinessOrdersScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'BusinessBookings'>;
};

const BusinessBookingsScreen = (props: BusinessOrdersScreenProps) => {
  const [referenceNumber, setReferenceNumber] = useState<string>();
  const [search, setSearch] = useState('');
  const businessId = useRecoilValue(businessIdState);
  const { data: bookings, status } = useLiveBookings(businessId);
  const scheme = useColorScheme();
  const isWideScreen = useIsWideScreen();

  useDocumentTitle(t('navigation.myBookings'));

  if (status !== 'success' || !bookings) {
    return <LoaderContainer />;
  }

  const currentBooking = bookings.find(
    (booking) => booking.referenceNumber === referenceNumber
  );

  const filteredBookings = bookings.filter((booking) => {
    if (search) {
      const lowerSearch = search.toLowerCase();

      return (
        booking.referenceNumber.toLowerCase().includes(lowerSearch) ||
        booking.contactEmail.toLowerCase().includes(lowerSearch) ||
        booking.contactFirstName.toLowerCase().includes(lowerSearch) ||
        booking.contactLastName.toLowerCase().includes(lowerSearch) ||
        booking.contactPhone.toLowerCase().includes(lowerSearch)
      );
    }

    return true;
  });

  return (
    <>
      <FlatList
        data={filteredBookings}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => {
              if (isWideScreen) {
                setReferenceNumber(item.referenceNumber);
              } else {
                props.navigation.navigate('BusinessBookingDetails', {
                  booking: item,
                });
              }
            }}
          >
            <BookingRowItem
              booking={item}
              selected={item.referenceNumber === referenceNumber}
            />
          </TouchableOpacity>
        )}
        ListEmptyComponent={() => (
          <P style={styles.noBookingMessage}>
            {t('screens.businessBookings.paragraphs.noBooking')}
          </P>
        )}
        ItemSeparatorComponent={() => <ItemSeparator noMargins />}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={
          <TextInput
            editable
            type="search"
            value={search}
            onChangeText={setSearch}
            placeholder={t(
              'screens.businessBookings.inputs.searchBookings.placeholder'
            )}
            inputStyle={styles.searchInput}
            style={[styles.search, scheme === 'dark' && styles.searchDark]}
          />
        }
        keyExtractor={(item) => item.referenceNumber}
        style={[
          styles.bookings,
          scheme === 'dark' && styles.bookingsDark,
          isWideScreen && styles.bookingsWide,
          scheme === 'dark' && isWideScreen && styles.bookingsWideDark,
        ]}
      />

      {isWideScreen && (
        <View style={styles.currentBooking}>
          {!currentBooking && (
            <View style={styles.noCurrentBooking}>
              <P>{t('screens.businessBookings.paragraphs.pickBooking')}</P>
            </View>
          )}

          {currentBooking && (
            <>
              <View
                style={[
                  styles.statusSelectors,
                  scheme === 'dark' && styles.statusSelectorsDark,
                ]}
              >
                <BookingStatusSelectors
                  statusOwner={currentBooking.statusOwner}
                  statusUser={currentBooking.statusUser}
                  referenceNumber={currentBooking.referenceNumber}
                  referenceToken={currentBooking.referenceToken}
                />
              </View>

              <ScrollView
                contentContainerStyle={styles.currentBookingScrollView}
              >
                <BookingDetails booking={currentBooking} />
              </ScrollView>
            </>
          )}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  searchInput: {
    borderRadius: 0,
    ...webOnly({
      outline: 'none',
    }),
  },
  search: {
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  searchDark: {
    borderBottomColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  noBookingMessage: {
    textAlign: 'center',
    margin: 20,
  },
  bookings: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: DefaultTheme.colors.card,
  },
  bookingsDark: {
    backgroundColor: DarkTheme.colors.card,
  },
  bookingsWide: {
    width: '35%',
    right: 'auto',
    borderRightColor: DefaultTheme.colors.border,
    borderRightWidth: 1,
  },
  bookingsWideDark: {
    borderRightColor: DarkTheme.colors.border,
  },
  currentBooking: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    width: '65%',
  },
  noCurrentBooking: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusSelectors: {
    alignItems: 'flex-end',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  statusSelectorsDark: {
    borderBottomColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  currentBookingScrollView: {
    padding: 20,
  },
});

export default BusinessBookingsScreen;
