import React, { useEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { StackNavigationProp } from '@react-navigation/stack';
import { UserStackParamList } from '../navigators/UserNavigator';
import {
  businessIdState,
  categoriesOrderState,
  pricingBaseCurrencyState,
  rawCategoriesState,
} from '../store/state';
import API from '../helpers/api';
import EditPricingForm from '../forms/EditPricingForm';
import { useDocumentTitle } from '../hooks/document';
import { t } from '../helpers/translation';

type Props = {
  navigation: StackNavigationProp<UserStackParamList, 'Edit'>;
};

const EditScreen = (props: Props) => {
  const setPricingBaseCurrency = useSetRecoilState(pricingBaseCurrencyState);
  const setCategoriesOrder = useSetRecoilState(categoriesOrderState);
  const setRawCategories = useSetRecoilState(rawCategoriesState);
  const businessId = useRecoilValue(businessIdState);

  useDocumentTitle(t('navigation.myPricing'));

  const { data: pricing } = useQuery(['pricing', businessId], () =>
    API.fetchPricingByBusinessId(businessId)
  );

  useEffect(() => {
    if (!pricing) {
      return;
    }

    setPricingBaseCurrency(pricing.card.baseCurrency);

    const categoryIds = pricing.card.categories.map(
      (category: { id: string }) => category.id
    );

    setCategoriesOrder(categoryIds);
    setRawCategories(pricing.card.categories);
  }, [pricing]);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <EditPricingForm />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  inlineInput: {
    flexDirection: 'row',
  },
});

export default React.memo(EditScreen);
