import React from 'react';
import { useQuery } from 'react-query';
import { RouteProp } from '@react-navigation/native';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { AppStackParamList } from '../navigators/AppNavigator';
import API from '../helpers/api';
import Text from '../components/Text/Text';
import { useDocumentTitle } from '../hooks/document';
import H1 from '../components/H1/H1';
import H2 from '../components/H2/H2';
import P from '../components/P/P';
import Link from '../components/Link/Link';
import H3 from '../components/H3/H3';
import { formatPrice } from '../helpers/format';
import Block from '../components/Block/Block';
import { t } from '../helpers/translation';
import Status from '../components/Status/Status';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import OrderDetails from '../components/OrderDetails/OrderDetails';

type Props = {
  route: RouteProp<AppStackParamList, 'OrderDetails'>;
};

const fetchOrderDetails = (
  _key: string,
  referenceNumber: string,
  referenceToken: string,
  asOwner: boolean
) => {
  return API.fetchOrderByReference(referenceNumber, referenceToken, asOwner);
};

const OrderDetailsScreen = (props: Props) => {
  const { referenceNumber, referenceToken } = props.route.params || {};

  const { data: orderDetails, status } = useQuery(
    // @ts-ignore
    ['order-details', referenceNumber, referenceToken, API.isOwner()],
    fetchOrderDetails,
    { enabled: referenceNumber && referenceToken, retry: false }
  );

  useDocumentTitle(t('screens.orderDetails.title', { referenceNumber }));

  if (status === 'loading') {
    return <LoaderContainer />;
  }

  if (status === 'error' || !orderDetails) {
    return (
      <Text>
        {t('screens.orderDetails.errors.orderNotFound', { referenceNumber })}
      </Text>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <OrderDetails order={orderDetails} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
});

export default OrderDetailsScreen;
