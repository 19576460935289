import React, { useEffect, useCallback } from 'react';
import { Clipboard, StyleSheet, ScrollView, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { DefaultTheme, Link } from '@react-navigation/native';
import { UserStackParamList } from '../navigators/UserNavigator';
import AccountServiceBlock from '../components/AccountServiceBlock/AccountServiceBlock';
import Theme from '../helpers/theme';
import Banner from '../components/Banner/Banner';
import { getFormattedLocale, t } from '../helpers/translation';
import Wrapper from '../components/Wrapper/Wrapper';
import { useDocumentTitle } from '../hooks/document';
import { useIsWideScreen } from '../hooks/responsive';
import API from '../helpers/api';
import {
  pricingSlugState,
  pricingIdState,
  pricingCanOrderState,
  pricingCanBookState,
  pricingPaymentMethodState,
  businessIdState,
  contactTracingEnabledState,
} from '../store/state';
import Icons from '../constants/icons';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import Button from '../components/Button/Button';

type DashboardScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'Dashboard'>;
};

const DashboardScreen = (props: DashboardScreenProps) => {
  const [pricingId, setPricingId] = useRecoilState(pricingIdState);
  const setPricingSlug = useSetRecoilState(pricingSlugState);
  const [contactTracingEnabled, setContactTracingEnabled] = useRecoilState(
    contactTracingEnabledState
  );
  const [pricingCanOrder, setPricingCanOrder] = useRecoilState(
    pricingCanOrderState
  );
  const [pricingCanBook, setPricingCanBook] = useRecoilState(
    pricingCanBookState
  );
  const [pricingPaymentMethod, setPricingPaymentMethod] = useRecoilState(
    pricingPaymentMethodState
  );
  const pricingSlug = useRecoilValue(pricingSlugState);
  const businessId = useRecoilValue(businessIdState);
  const isWideScreen = useIsWideScreen();
  const canUseOrders = pricingCanOrder && !pricingPaymentMethod.clientId;

  useDocumentTitle(t('navigation.dashboard'));

  const { data: accountStatus } = useQuery(['account-status', businessId], () =>
    API.fetchAccountStatus()
  );

  if (accountStatus && !accountStatus.services.website.enabled) {
    props.navigation.navigate('Welcome');
  }

  useEffect(() => {
    if (!accountStatus) {
      return;
    }

    const status = accountStatus.services;

    if (status.website.slug) {
      setPricingSlug(status.website.slug);
    }

    if (status.website.id) {
      setPricingId(status.website.id);
    }

    setPricingCanBook(status.bookings.enabled);
    setPricingCanOrder(status.orders.enabled);
    setContactTracingEnabled(status.contact_tracing.enabled);

    if (status.payment_method.enabled) {
      setPricingPaymentMethod((paymentMethod) => ({
        ...paymentMethod,
        type: status.payment_method.type!,
        clientId: status.payment_method.client_id!,
      }));
    }
  }, [accountStatus]);

  useEffect(() => {
    if (!pricingSlug) {
      return;
    }

    props.navigation.setOptions({
      headerRight: () => (
        <Link to={`/${pricingSlug}`} target="_blank" style={styles.websiteIcon}>
          <Button
            inline
            size="small"
            icon="external-link"
            title={t('screens.home.products.pricing.title')}
          />
        </Link>
      ),
    });
  }, [pricingSlug]);

  const handleToggleCanOrder = useCallback(
    async (canOrder) => {
      setPricingCanOrder(canOrder);

      try {
        API.updatePricingById(pricingId, { canOrder });
      } catch (error) {
        console.warn(error);
      }
    },
    [pricingId]
  );

  const handleToggleCanBook = useCallback(
    async (canBook) => {
      setPricingCanBook(canBook);

      try {
        API.updatePricingById(pricingId, { canBook });
      } catch (error) {
        console.warn(error);
      }
    },
    [pricingId]
  );

  const handleToggleContactTracing = useCallback(
    (isEnabled) => {
      setContactTracingEnabled(isEnabled);

      if (!contactTracingEnabled) {
        try {
          API.createContactTracingSettings(businessId);
        } catch (error) {
          console.warn(error);
        }
      }
    },
    [businessId]
  );

  if (!accountStatus) {
    return <LoaderContainer />;
  }

  const canSeeLiveDashboard = pricingCanBook || pricingCanOrder;

  return (
    <ScrollView>
      <Wrapper noPadding>
        {/* <Button
          title="Test welcome screen"
          onPress={() => props.navigation.navigate('Welcome')}
        /> */}

        <Banner
          rounded
          align="left"
          icon="radio"
          iconColor={Theme.PRIMARY}
          color={DefaultTheme.colors.card}
          textColor={Theme.BLACK}
          action={canSeeLiveDashboard ? t('common.go') : undefined}
          onPressAction={
            canSeeLiveDashboard
              ? () => props.navigation.navigate('LiveDashboard')
              : undefined
          }
          style={[
            {
              margin: 20,
              marginBottom: 0,
              borderWidth: 2,
              borderColor: Theme.PRIMARY,
            },
            isWideScreen && {
              marginHorizontal: 30,
            },
          ]}
        >
          {canSeeLiveDashboard
            ? t('screens.dashboard.banners.seeLiveDashboard')
            : t('screens.dashboard.banners.instructionsToSeeLiveDashboard')}
        </Banner>

        <View style={[styles.container, isWideScreen && styles.containerWide]}>
          <AccountServiceBlock
            icon={Icons.PRICING}
            color={Theme.PRIMARY}
            name={t('screens.home.products.pricing.title')}
            helpVideoKey={
              getFormattedLocale() === 'fr' ? '45j8rtgxqUQ' : undefined
            }
            actions={[
              {
                label: t('navigation.myPricing'),
                onPress() {
                  props.navigation.navigate('Edit');
                },
                disabled: !pricingSlug,
              },
              {
                label: t('forms.editPricing.banners.share.actions.copyLink'),
                onPress() {
                  Clipboard.setString(`https://illicarte.com/${pricingSlug}`);
                },
                disabled: !pricingSlug,
              },
              {
                label: t(
                  'forms.editPricing.banners.share.actions.getQrCodePDF'
                ),
                onPress() {
                  window.open(
                    API.getPricingQRCodeLink(pricingSlug, getFormattedLocale()),
                    '_blank'
                  );
                },
                disabled: !pricingSlug,
              },
              {
                label: t('screens.dashboard.actions.previewMyWebsite'),
                onPress() {
                  window.open(`/${pricingSlug}`, '_blank');
                },
                disabled: !pricingSlug,
              },
            ]}
            activated
            style={[styles.block, isWideScreen && styles.blockWide]}
          />

          <AccountServiceBlock
            icon={Icons.ORDERS}
            color={Theme.ORANGE}
            name={t('screens.home.products.orders.title')}
            helpVideoKey={
              getFormattedLocale() === 'fr' ? '-lvr1W80pZ8' : undefined
            }
            message={
              canUseOrders
                ? t('screens.dashboard.banners.paymentsNotConfigured')
                : undefined
            }
            actions={[
              {
                label: t('navigation.paymentSettings'),
                onPress() {
                  props.navigation.navigate('PaymentSettings');
                },
                disabled: !pricingSlug || !pricingCanOrder,
                symbol: canUseOrders ? 'warning' : undefined,
              },
              {
                label: t('navigation.myOrders'),
                onPress() {
                  props.navigation.navigate('BusinessOrders');
                },
                disabled: !pricingSlug,
              },
            ]}
            activated={pricingCanOrder}
            onToggleActivate={pricingSlug ? handleToggleCanOrder : undefined}
            style={[styles.block, isWideScreen && styles.blockWide]}
          />

          <AccountServiceBlock
            icon={Icons.BOOKINGS}
            color={Theme.GREEN}
            name={t('screens.home.products.bookings.title')}
            helpVideoKey={
              getFormattedLocale() === 'fr' ? 'ft1PoLVToSw' : undefined
            }
            actions={[
              {
                label: t('navigation.planning'),
                onPress() {
                  props.navigation.navigate('CreatePlanning');
                },
                disabled: !pricingSlug,
              },
              {
                label: t('navigation.roomTables'),
                onPress() {
                  props.navigation.navigate('RoomTables');
                },
                disabled: !pricingSlug,
              },
              {
                label: t('navigation.myBookings'),
                onPress() {
                  props.navigation.navigate('BusinessBookings');
                },
                disabled: !pricingSlug,
              },
            ]}
            activated={pricingCanBook}
            onToggleActivate={pricingSlug ? handleToggleCanBook : undefined}
            style={[styles.block, isWideScreen && styles.blockWide]}
          />

          <AccountServiceBlock
            icon={Icons.LOYALTY}
            color={Theme.RED}
            name={t('screens.home.products.memberships.title')}
            actions={[
              {
                label: t('navigation.myLoyaltyProgram'),
                onPress() {
                  props.navigation.navigate('Memberships');
                },
              },
              {
                label: t('screens.memberships.actions.downloadIOSApp'),
                onPress() {
                  window.open(
                    'https://apps.apple.com/app/id1527586992',
                    '_blank'
                  );
                },
              },
              {
                label: t('screens.memberships.actions.downloadAndroidApp'),
                onPress() {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.illicarte.plusone',
                    '_blank'
                  );
                },
              },
            ]}
            activated={false}
            style={[styles.block, isWideScreen && styles.blockWide]}
          />

          <AccountServiceBlock
            icon={Icons.CONTACT_TRACING}
            color={Theme.INDIGO}
            name={t('screens.home.products.contactTracing.title')}
            helpVideoKey={
              getFormattedLocale() === 'fr' ? 'tNm7w4R4lU0' : undefined
            }
            actions={[
              {
                label: t('screens.editContactTracingSettings.title'),
                onPress() {
                  props.navigation.navigate('EditContactTracingSettings');
                },
                disabled: !pricingSlug || !contactTracingEnabled,
              },
              // {
              //   label: 'See recent statistics',
              //   onPress() {},
              //   disabled: !pricingSlug || !contactTracingEnabled,
              // },
              {
                label: t(
                  'screens.dashboard.actions.openContactTracingQRCodePDF'
                ),
                onPress() {
                  window.open(
                    API.getContactTracingQRCodeLink(
                      pricingSlug,
                      getFormattedLocale()
                    ),
                    '_blank'
                  );
                },
                disabled: !pricingSlug,
              },
              {
                label: t(
                  'screens.dashboard.actions.copyContactTracingFormLink'
                ),
                onPress() {
                  Clipboard.setString(
                    `https://illicarte.com/${pricingSlug}/tracing`
                  );
                },
                disabled: !pricingSlug || !contactTracingEnabled,
              },
              {
                label: t('screens.dashboard.actions.openContactTracingForm'),
                onPress() {
                  window.open(`/${pricingSlug}/tracing`, '_blank');
                },
                disabled: !pricingSlug || !contactTracingEnabled,
              },
            ]}
            activated={contactTracingEnabled}
            onToggleActivate={
              pricingSlug ? handleToggleContactTracing : undefined
            }
            style={[styles.block, isWideScreen && styles.blockWide]}
          />

          <AccountServiceBlock
            icon="user"
            color="gray"
            name={t('navigation.account')}
            activated
            actions={[
              {
                label: t('navigation.settings'),
                onPress() {
                  // @ts-ignore
                  props.navigation.navigate('Settings');
                },
              },
              {
                label: t('navigation.signOut'),
                onPress() {
                  // @ts-ignore
                  props.navigation.navigate('SignOut');
                },
              },
            ]}
            style={[styles.block, isWideScreen && styles.blockWide]}
          />

          <AccountServiceBlock
            icon="help-circle"
            color={Theme.DEEP_ORANGE}
            name={t('navigation.help')}
            activated
            actions={[
              {
                label: t('navigation.contact'),
                onPress() {
                  window.open(
                    `mailto:contact@illicarte.com?subject=%5BSUPPORT%5D`
                  );
                },
              },
            ]}
            style={[styles.block, isWideScreen && styles.blockWide]}
          />
        </View>
      </Wrapper>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
  containerWide: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  block: {
    margin: 10,
  },
  blockWide: {
    width: 300,
  },
  websiteIcon: {
    marginRight: 20,
  },
});

export default DashboardScreen;
