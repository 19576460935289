import { DefaultTheme } from '@react-navigation/native';

const Theme = {
  PRIMARY: DefaultTheme.colors.primary, // #007AFF
  BLACK: '#000',
  ORANGE: '#FBC02D',
  DEEP_ORANGE: '#ff8a65',
  GREEN: '#00e676',
  // OLD: GREEN: '#98EE99',
  RED: '#FF7961',
  INDIGO: '#5c6bc0',
};

export default Theme;
