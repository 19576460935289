import React, { useState, useCallback } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { useRecoilValue } from 'recoil';
import TextInput from '../components/TextInput/TextInput';
import Button from '../components/Button/Button';
import API from '../helpers/api';
import { businessIdState } from '../store/state';
import { RoomTable } from '../../types';
import DeleteButton from '../components/DeleteButton/DeleteButton';
import H2 from '../components/H2/H2';
import P from '../components/P/P';
import QueryCache from '../helpers/query-cache';
import RoomTableForm from './RoomTableForm';
import { t } from '../helpers/translation';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';

type RoomFormProps = {
  type: 'create' | 'update';
  id?: string;
  name?: string;
  tables?: RoomTable[];
  style?: ViewProps['style'];
};

const RoomForm = (props: RoomFormProps) => {
  const [name, setName] = useState(props.name ?? '');
  const [isBusy, setIsBusy] = useState(false);
  const businessId = useRecoilValue(businessIdState);

  const canSend =
    props.type === 'create' ? name.length > 0 : name !== props.name;

  const handleSend = useCallback(async () => {
    setIsBusy(true);

    try {
      if (props.type === 'create') {
        const roomId = await API.createRoomForBusiness(businessId, { name });

        QueryCache.createRoom(businessId, {
          id: roomId,
          name,
          room_tables: [],
        });

        setName('');
      } else {
        await API.updateRoomById(businessId, props.id!, { name });
        QueryCache.updateRoom(businessId, props.id!, { name });
      }
    } catch (error) {
      console.warn(error);
    }

    setIsBusy(false);
  }, [businessId, props.type, props.id, name]);

  const handleDelete = useCallback(async () => {
    setIsBusy(true);

    try {
      await API.deleteRoomById(businessId, props.id!);
      QueryCache.deleteRoom(businessId, props.id!);
    } catch (error) {
      console.warn(error);
    }

    setIsBusy(false);
  }, [businessId, props.id]);

  return (
    <View style={props.style}>
      {props.type === 'create' && (
        <H2 style={{ marginBottom: 10 }}>
          {t('forms.room.titles.createRoom')}
        </H2>
      )}

      <View
        style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center' }}
      >
        <TextInput
          editable
          required
          type="text"
          value={name}
          onChangeText={setName}
          label={t('forms.room.inputs.name.label')}
          hint={t('forms.room.inputs.name.hint')}
          style={{ flex: 1 }}
        />

        {props.type === 'update' && (
          <DeleteButton onDelete={handleDelete} style={{ marginLeft: 20 }} />
        )}
      </View>

      <Button
        title={
          props.type === 'create'
            ? t('forms.room.actions.addRoom')
            : t('forms.room.actions.updateRoom')
        }
        onPress={handleSend}
        icon={props.type === 'create' ? 'plus' : 'edit-3'}
        busy={isBusy}
        disabled={!canSend}
      />

      {props.type === 'update' && typeof props.tables !== 'undefined' && (
        <View style={{ marginTop: 20 }}>
          <RoomTableForm type="create" roomId={props.id!} />

          {props.tables.map((table) => (
            <React.Fragment key={table.id}>
              <ItemSeparator />

              <RoomTableForm
                type="update"
                roomId={props.id!}
                id={table.id}
                name={table.name}
                seats={table.seats}
                minimumSeats={table.minimumSeats}
                available={table.available}
              />
            </React.Fragment>
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({});

export default RoomForm;
