import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import moment from 'moment';
// @ts-ignore
import color from 'color';
import { Booking } from '../../../types';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import Theme from '../../helpers/theme';
import { t } from '../../helpers/translation';

type BookingRowItemProps = {
  booking: Booking;
  selected?: boolean;
};

const BookingRowItem = ({ booking, selected }: BookingRowItemProps) => {
  const scheme = useColorScheme();

  return (
    <View style={[styles.container, selected && styles.containerSelected]}>
      <View style={styles.dateContainer}>
        <Text style={[styles.text, styles.date]}>
          {moment.utc(booking.startAt).format('DD/MM')}
        </Text>

        <Text style={[styles.text, styles.time]}>
          {moment.utc(booking.startAt).format('HH:mm')}
        </Text>
      </View>

      <View style={styles.identification}>
        <Text style={[styles.text, styles.referenceNumber]}>
          #{booking.referenceNumber}
        </Text>

        <Text ellipsize style={styles.text}>
          {booking.walkIn
            ? t('common.walkIn')
            : `${booking.contactLastName} ${booking.contactFirstName}`}
        </Text>
      </View>

      <View style={styles.metadata}>
        <View style={styles.quantityContainer}>
          <View
            style={[
              styles.tableNameContainer,
              scheme === 'dark' && styles.tableNameContainerDark,
            ]}
          >
            <Text style={[styles.text, styles.tableName]}>
              {booking.room_table.name}
            </Text>
          </View>

          <Icon
            name="smile"
            size={12}
            style={[styles.icon, styles.quantityIcon]}
          />

          <Text style={[styles.text, styles.quantity]}>{booking.quantity}</Text>
        </View>

        <View style={styles.statuses}>
          {booking.comment && (
            <View style={[styles.status, styles.statusComment]}>
              <Icon name="message-circle" color="white" size={12} />
            </View>
          )}

          <View
            style={[
              styles.status,
              {
                backgroundColor:
                  booking.statusOwner === 'confirmed' ? Theme.GREEN : Theme.RED,
              },
            ]}
          >
            <Icon
              name={booking.statusOwner === 'confirmed' ? 'check' : 'x'}
              color="black"
              size={12}
            />
          </View>

          <View
            style={[
              styles.status,
              {
                backgroundColor:
                  booking.statusUser === 'paid' ? Theme.GREEN : Theme.ORANGE,
              },
            ]}
          >
            <Icon name="dollar-sign" color="black" size={12} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerSelected: {
    backgroundColor: color(DefaultTheme.colors.border).lighten(0.1),
  },
  text: {
    fontSize: 12,
  },
  dateContainer: {
    flex: 2,
  },
  date: {
    fontWeight: 'bold',
  },
  referenceNumber: {
    fontWeight: 'bold',
  },
  identification: {
    paddingRight: 20,
    flex: 4,
  },
  metadata: {
    flex: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 10,
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 3,
  },
  quantityIcon: {
    marginLeft: 8,
  },
  quantity: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  time: {
    fontWeight: 'bold',
  },
  tableNameContainer: {
    marginLeft: 8,
    borderWidth: 1,
    borderColor: DefaultTheme.colors.border,
    borderRadius: 4,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  tableNameContainerDark: {
    borderColor: DarkTheme.colors.border,
  },
  tableName: {
    fontWeight: 'bold',
  },
  statuses: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  status: {
    marginLeft: 5,
    width: 18,
    height: 18,
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusComment: {
    backgroundColor: Theme.PRIMARY,
  },
});

export default React.memo(BookingRowItem, (prevProps, nextProps) => {
  return (
    prevProps.selected === nextProps.selected &&
    prevProps.booking.contactFirstName === nextProps.booking.contactFirstName &&
    prevProps.booking.contactLastName === nextProps.booking.contactLastName &&
    prevProps.booking.startAt === nextProps.booking.startAt &&
    prevProps.booking.quantity === nextProps.booking.quantity &&
    prevProps.booking.walkIn === nextProps.booking.walkIn &&
    prevProps.booking.statusOwner === nextProps.booking.statusOwner &&
    prevProps.booking.statusUser === nextProps.booking.statusUser &&
    prevProps.booking.referenceNumber === nextProps.booking.referenceNumber &&
    prevProps.booking.room_table.name === nextProps.booking.room_table.name &&
    prevProps.booking.comment === nextProps.booking.comment
  );
});
