import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  Suspense,
} from 'react';
import { StyleSheet, Platform } from 'react-native';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { RecoilRoot } from 'recoil';
import { AppearanceProvider, useColorScheme } from 'react-native-appearance';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  NavigationContainerRef,
} from '@react-navigation/native';
import { ReactQueryConfigProvider } from 'react-query';
import ReactGA from 'react-ga';
// @ts-ignore
import { hotjar } from 'react-hotjar';
import 'react-native-gesture-handler';
// @ts-ignore
import Portal from '@burstware/react-native-portal';
import AppNavigator from './src/navigators/AppNavigator';
import { initTranslations } from './src/helpers/translation';
import ActivityIndicator from './src/components/ActivityIndicator/ActivityIndicator';
import { isPricingPage } from './src/helpers/misc';

if (Platform.OS === 'web') {
  hotjar.initialize(1927078, 6);
  ReactGA.initialize('UA-84366865-5');
}

const linking = {
  prefixes: ['https://illicarte.com', 'illicarte://'],
  config: {
    PlusOnePrivacyPolicyScreen: 'plus-one/privacy',
    PlusOneTermsConditionsScreen: 'plus-one/terms-conditions',

    Home: '/',
    OrderDetails: 'order/:referenceNumber/:referenceToken',
    Join: {
      path: 'join',
      screens: {
        Join: '',
      },
    },
    ProductBookings: 'products/bookings/:job?',
    ProductContactTracing: 'products/contact-tracing/:job?',
    ProductMemberships: 'products/memberships/:job?',
    ProductOrders: 'products/orders/:job?',
    Settings: {
      path: 'settings',
      screens: {
        Settings: '',
      },
    },
    SignIn: {
      path: 'signin',
      screens: {
        SignIn: '',
      },
    },
    SignOut: 'signout',

    SignedIn: '',
    BusinessOrders: 'account/orders',
    BusinessBookings: 'account/bookings',
    CreatePlanning: 'account/planning',
    Dashboard: 'account/dashboard',
    Edit: 'account/pricing',
    EditContactTracingSettings: 'account/contact-tracing',
    EditReward: 'account/membership/reward/:rewardId',
    LiveDashboard: 'account/live',
    Memberships: 'account/membership',
    PaymentSettings: 'account/payments',
    RoomTables: 'account/tables',
    Welcome: 'account/welcome',
    Pricing: {
      screens: {
        MakeOrder: ':pricingSlug/order',
        ContactTracing: ':pricingSlug/tracing',
        Item: ':pricingSlug/item/:itemId',
        Pricing: ':pricingSlug',
      },
    },
  },
};

const queryConfig = { queries: { refetchOnWindowFocus: false } };

export default function App() {
  const [hasRendered, setHasRendered] = useState(false);
  const scheme = useColorScheme();
  const routeNameRef = useRef<string>();
  const navigationRef = useRef<NavigationContainerRef>(null);

  useEffect(() => {
    initTranslations().then(() => {
      setHasRendered(true);
    });
  }, []);

  const handleReady = useCallback(() => {
    if (navigationRef.current) {
      const route = navigationRef.current.getCurrentRoute();

      if (route) {
        routeNameRef.current = route.name;
      }
    }
  }, [navigationRef.current]);

  const handleStateChange = useCallback(() => {
    if (!navigationRef.current) {
      return;
    }

    const currentRoute = navigationRef.current.getCurrentRoute();

    if (!currentRoute) {
      return;
    }

    let currentRouteName = currentRoute.name;

    if (currentRoute.params) {
      currentRouteName +=
        '?' +
        Object.entries(currentRoute.params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
    }

    const previousRouteName = routeNameRef.current;

    if (previousRouteName !== currentRouteName) {
      ReactGA.set({ page: currentRouteName });
      ReactGA.pageview(currentRouteName);
    }

    routeNameRef.current = currentRouteName;
  }, [navigationRef.current, routeNameRef.current]);

  if (!hasRendered) {
    return <ActivityIndicator style={styles.loader} />;
  }

  return (
    <Suspense fallback={<ActivityIndicator style={styles.loader} />}>
      <ReactQueryConfigProvider config={queryConfig}>
        <AppearanceProvider>
          <RecoilRoot>
            {!isPricingPage() && (
              <Helmet>
                <script type="text/javascript">
                  window.$crisp=[];
                  window.CRISP_WEBSITE_ID="3b2cd04e-07cc-4499-9da7-d6545402bc61";
                </script>

                <script
                  type="text/javascript"
                  src="https://client.crisp.chat/l.js"
                  async
                ></script>

                <style type="text/css">{`body,#root{overflow-x: hidden;}`}</style>
              </Helmet>
            )}

            <Portal.Host>
              <NavigationContainer
                ref={navigationRef}
                theme={scheme === 'dark' ? DarkTheme : DefaultTheme}
                // @ts-ignore
                linking={linking}
                onReady={handleReady}
                onStateChange={handleStateChange}
              >
                <AppNavigator />
              </NavigationContainer>
            </Portal.Host>
          </RecoilRoot>
        </AppearanceProvider>
      </ReactQueryConfigProvider>
    </Suspense>
  );
}

const styles = StyleSheet.create({
  loader: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
