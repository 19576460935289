import React from 'react';
import {
  View,
  StyleSheet,
  ViewProps,
  TextProps,
  GestureResponderEvent,
} from 'react-native';
import Text from '../Text/Text';
import Theme from '../../helpers/theme';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

export type BannerProps = {
  color?: string;
  icon?: string;
  iconColor?: string;
  textColor?: string;
  children: any;
  rounded?: boolean;
  align?: 'left' | 'right';
  action?: string;
  onPressAction?: (event: GestureResponderEvent) => void;
  style?: ViewProps['style'];
  textStyle?: TextProps['style'];
};

const Banner = (props: BannerProps) => {
  return (
    <View
      style={[
        styles.container,
        !!props.color && { backgroundColor: props.color },
        props.rounded && styles.containerRounded,
        props.style,
      ]}
    >
      {props.icon && (
        <Icon
          name={props.icon}
          size={20}
          color={props.iconColor}
          style={styles.icon}
        />
      )}

      <Text
        style={[
          styles.message,
          props.align === 'left' && styles.messageLeft,
          props.align === 'right' && styles.messageRight,
          !!props.textColor && { color: props.textColor },
          props.textStyle,
        ]}
      >
        {props.children}
      </Text>

      {props.action && props.onPressAction && (
        <Button
          inline
          size="small"
          // @ts-ignore
          textStyle={props.textStyle}
          title={props.action}
          onPress={props.onPressAction}
          style={styles.actionButton}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: Theme.PRIMARY,
  },
  containerRounded: {
    borderRadius: 6,
  },
  icon: {
    marginRight: 10,
  },
  message: {
    flex: 1,
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  messageLeft: {
    textAlign: 'left',
  },
  messageRight: {
    textAlign: 'right',
  },
  actionButton: {
    marginLeft: 10,
  },
});

export default Banner;
