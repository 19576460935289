import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import Button from '../../components/Button/Button';
import H2 from '../../components/H2/H2';
import LoaderContainer from '../../components/LoaderContainer/LoaderContainer';
import TextInput from '../../components/TextInput/TextInput';
import API from '../../helpers/api';
import { t } from '../../helpers/translation';
import { businessIdState } from '../../store/state';

type EditContactTracingSettingsFormProps = {};

const EditContactTracingSettingsForm = (
  props: EditContactTracingSettingsFormProps
) => {
  const [averageVisitDuration, setAverageVisitDuration] = useState(60);
  const [isUpdating, setIsUpdating] = useState(false);
  const businessId = useRecoilValue(businessIdState);

  const { data: contactTracingSettings, status } = useQuery(
    ['contact-tracing-settings', businessId],
    () => API.fetchContactTracingSettings(businessId)
  );

  useEffect(() => {
    if (!contactTracingSettings) {
      return;
    }

    setAverageVisitDuration(contactTracingSettings.averageVisitDuration);
  }, [contactTracingSettings]);

  const handleUpdateSettings = useCallback(async () => {
    setIsUpdating(true);

    try {
      await API.updateContactTracingSettings(businessId, {
        averageVisitDuration,
      });
    } catch (error) {
      console.warn(error);
    }

    setIsUpdating(false);
  }, [businessId, averageVisitDuration]);

  if (status !== 'success') {
    return <LoaderContainer />;
  }

  return (
    <>
      <H2>{t('forms.editContactTracingSettings.headers.visits')}</H2>

      <TextInput
        editable
        type="number"
        label={t(
          'forms.editContactTracingSettings.inputs.averageVisitDuration.label'
        )}
        placeholder="60"
        hint={t(
          'forms.editContactTracingSettings.inputs.averageVisitDuration.hint'
        )}
        value={averageVisitDuration.toString()}
        onChangeText={(value: string) =>
          setAverageVisitDuration(parseInt(value, 10))
        }
        style={styles.input}
      />

      <Button
        icon="edit-3"
        title={t('common.update')}
        busy={isUpdating}
        onPress={handleUpdateSettings}
        style={styles.button}
      />
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    marginVertical: 10,
  },
  button: {
    marginTop: 10,
  },
});

export default EditContactTracingSettingsForm;
