import { useState, useEffect } from 'react';
import { Dimensions, ScaledSize } from 'react-native';

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(() => {
    const currentDimensions = Dimensions.get('window');
    return { width: currentDimensions.width, height: currentDimensions.height };
  });

  useEffect(() => {
    const updateDimensions = ({ window }: { window: ScaledSize }) => {
      setDimensions({ width: window.width, height: window.height });
    };

    Dimensions.addEventListener('change', updateDimensions);

    return () => Dimensions.removeEventListener('change', updateDimensions);
  }, []);

  return dimensions;
};
