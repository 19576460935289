import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, ScrollView, View, Image } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useQuery } from 'react-query';
// @ts-ignore
import nodePhone from 'node-phone';
// @ts-ignore
import phone from 'phone';
import { useDocumentTitle } from '../hooks/document';
import Block from '../components/Block/Block';
import H2 from '../components/H2/H2';
import Button from '../components/Button/Button';
import API from '../helpers/api';
import ActivityIndicator from '../components/ActivityIndicator/ActivityIndicator';
import Text from '../components/Text/Text';
import TextInput from '../components/TextInput/TextInput';
import { t } from '../helpers/translation';
import FileInput from '../components/FileInput/FileInput';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import { AppStackParamList } from '../navigators/AppNavigator';
import PhoneNumberInput from '../components/PhoneNumberInput/PhoneNumberInput';

type Props = {
  navigation: StackNavigationProp<AppStackParamList, 'Settings'>;
};

const SettingsScreen = (props: Props) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [isUpdatingBusiness, setIsUpdatingBusiness] = useState(false);
  const [businessLogo, setBusinessLogo] = useState('');
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [
    businessPhoneNumberCountryCode,
    setBusinessPhoneNumberCountryCode,
  ] = useState('');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');

  const { data: profile, status: profileStatus } = useQuery('user', () =>
    API.fetchUserProfile()
  );

  const { data: business, status: businessStatus } = useQuery(
    'user-business',
    // @ts-ignore
    () => API.fetchBusinessById(profile?.businesses[0].id),
    { enabled: profile && profile.businesses.length >= 1 }
  );

  useEffect(() => {
    if (
      profile &&
      profileStatus === 'success' &&
      business &&
      businessStatus === 'success'
    ) {
      setPhoneNumber(nodePhone.normalizePhone(profile.phoneNumber).phone);
      setPhoneNumberCountryCode(
        phone(profile.phoneNumber, '', true)[1].substr(0, 2)
      );

      setEmail(profile.email);
      setFirstName(profile.firstName);
      setLastName(profile.lastName);
      setBusinessName(business.name);
      setBusinessAddress(business.address);
      setBusinessLogo(business.image);

      if (business.phoneNumber) {
        setBusinessPhoneNumber(
          nodePhone.normalizePhone(business.phoneNumber).phone
        );

        setBusinessPhoneNumberCountryCode(
          phone(business.phoneNumber, '', true)[1].substr(0, 2)
        );
      }
    }
  }, [businessStatus]);

  useDocumentTitle(t('navigation.settings'));

  const handleUpdateUserInformation = useCallback(async () => {
    if (!profile) {
      return;
    }

    const toUpdate: any = {};
    let shouldQuery = false;

    if (email !== profile.email) {
      shouldQuery = true;
      toUpdate.email = email;
    }

    if (firstName !== profile.firstName) {
      shouldQuery = true;
      toUpdate.firstName = firstName;
    }

    if (lastName !== profile.lastName) {
      shouldQuery = true;
      toUpdate.lastName = lastName;
    }

    const computedPhoneNumber = phone(
      phoneNumber,
      phoneNumberCountryCode,
      true
    );

    if (computedPhoneNumber && computedPhoneNumber !== profile.phoneNumber) {
      shouldQuery = true;
      toUpdate.phoneNumber = computedPhoneNumber[0];
    }

    setIsUpdatingUser(true);

    try {
      if (shouldQuery) {
        await API.updateUserProfile(toUpdate);
      }
    } catch (error) {
      console.warn(error);
    }

    setIsUpdatingUser(false);
  }, [
    profile,
    email,
    firstName,
    lastName,
    phoneNumber,
    phoneNumberCountryCode,
  ]);

  const handleUpdateBusinessInformation = useCallback(async () => {
    if (!business || !profile) {
      return;
    }

    const toUpdate: any = {};
    let shouldQuery = false;

    if (businessName !== business.name) {
      shouldQuery = true;
      toUpdate.name = businessName;
    }

    if (businessAddress !== business.address) {
      shouldQuery = true;
      toUpdate.address = businessAddress;
    }

    const computedBusinessPhoneNumber = phone(
      businessPhoneNumber,
      businessPhoneNumberCountryCode,
      true
    );

    if (
      computedBusinessPhoneNumber &&
      computedBusinessPhoneNumber !== business.phoneNumber
    ) {
      shouldQuery = true;
      toUpdate.phoneNumber = computedBusinessPhoneNumber[0];
    }

    setIsUpdatingBusiness(true);

    try {
      if (shouldQuery) {
        await API.updateBusinessById(profile.businesses[0].id, toUpdate);
      }
    } catch (error) {
      console.warn(error);
    }

    setIsUpdatingBusiness(false);
  }, [
    business,
    profile,
    businessName,
    businessAddress,
    businessPhoneNumberCountryCode,
    businessPhoneNumber,
  ]);

  const handleChangeBusinessLogo = useCallback(
    async (file: File) => {
      if (!profile) {
        return;
      }

      setIsUploadingLogo(true);

      try {
        const imageURL = await API.uploadBusinessImageForId(
          profile.businesses[0].id,
          file
        );

        setBusinessLogo(imageURL);
      } catch (error) {
        console.warn(error);
      }

      setIsUploadingLogo(false);
    },
    [profile]
  );

  if (profileStatus === 'loading') {
    return <LoaderContainer />;
  }

  if (profileStatus === 'error') {
    return <Text>There was an error. Please retry later or contact us.</Text>;
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Block>
        <H2>{t('screens.join.headers.yourInformation')}</H2>

        <TextInput
          editable
          type="email"
          label={t('common.email')}
          placeholder="email@mail.com"
          value={email}
          onChangeText={setEmail}
          style={styles.input}
        />

        <TextInput
          editable
          label={t('common.password')}
          placeholder="******"
          value={password}
          onChangeText={setPassword}
          hint={t('screens.settings.inputs.password.hint')}
          style={styles.input}
        />

        <TextInput
          editable
          label={t('common.lastName')}
          value={lastName}
          onChangeText={setLastName}
          inline
          style={styles.input}
        />

        <TextInput
          editable
          label={t('common.firstName')}
          value={firstName}
          onChangeText={setFirstName}
          inline
          style={styles.input}
        />

        <PhoneNumberInput
          label={t('screens.join.inputs.phoneNumber.label')}
          placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
          phoneNumber={phoneNumber}
          onChangePhoneNumber={setPhoneNumber}
          countryCode={phoneNumberCountryCode}
          onChangeCountryCode={setPhoneNumberCountryCode}
          style={styles.input}
        />

        <Button
          icon="edit-3"
          title={t('screens.settings.buttons.updateInformation')}
          busy={isUpdatingUser}
          onPress={handleUpdateUserInformation}
          style={styles.button}
        />
      </Block>

      <Block verticalMargins>
        <H2>{t('screens.join.headers.yourBusinessInformation')}</H2>

        <TextInput
          editable
          label={t('screens.join.inputs.businessName.placeholder')}
          value={businessName}
          onChangeText={setBusinessName}
          style={styles.input}
        />

        <TextInput
          editable
          label={t('common.address')}
          value={businessAddress}
          onChangeText={setBusinessAddress}
          hint={t('screens.join.inputs.businessAddress.hint')}
          style={styles.input}
        />

        <PhoneNumberInput
          label={t('screens.join.inputs.businessPhoneNumber.label')}
          placeholder={t('screens.join.inputs.phoneNumber.placeholder')}
          phoneNumber={businessPhoneNumber}
          onChangePhoneNumber={setBusinessPhoneNumber}
          countryCode={businessPhoneNumberCountryCode}
          onChangeCountryCode={setBusinessPhoneNumberCountryCode}
          style={styles.input}
        />

        <View style={styles.input}>
          <FileInput
            title={t('screens.settings.buttons.updateBusinessLogo')}
            onChangeFile={handleChangeBusinessLogo}
          />

          {businessLogo && (
            <View style={[styles.input, { position: 'relative' }]}>
              {isUploadingLogo && (
                <View
                  style={[
                    StyleSheet.absoluteFill,
                    {
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      zIndex: 1,
                    },
                  ]}
                >
                  <ActivityIndicator />
                </View>
              )}

              <Image
                source={{ uri: businessLogo }}
                resizeMode="contain"
                style={{ width: '100%', height: 100 }}
              />
            </View>
          )}
        </View>

        <Button
          icon="edit-3"
          title={t('screens.settings.buttons.updateInformation')}
          busy={isUpdatingBusiness}
          onPress={handleUpdateBusinessInformation}
          style={styles.button}
        />
      </Block>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingBottom: 0,
  },
  row: {
    flexDirection: 'row',
  },
  input: {
    marginVertical: 10,
  },
  button: {
    marginTop: 10,
  },
});

export default SettingsScreen;
