import React from 'react';
import { StyleSheet } from 'react-native';
import { useRecoilValue } from 'recoil';
import { Link } from '@react-navigation/native';
import { pricingSlugState } from '../../store/state';
import { t } from '../../helpers/translation';
import Button from '../Button/Button';

type Props = {};

const PreviewPricingButton = (props: Props) => {
  const pricingSlug = useRecoilValue(pricingSlugState);

  if (!pricingSlug) {
    return null;
  }

  return (
    <Link to={`/${pricingSlug}`} target="_blank" style={styles.wrapper}>
      <Button
        title={t('components.previewPricingButton.preview')}
        icon="eye"
        inline
        size="small"
      />
    </Link>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginRight: 20,
  },
});

export default PreviewPricingButton;
