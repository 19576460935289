import React from 'react';
import {
  ScrollView,
  StyleSheet,
  View,
  ViewProps,
  GestureResponderEvent,
} from 'react-native';
// @ts-ignore
import Portal from '@burstware/react-native-portal';
import Pill from '../Pill/Pill';
import { t } from '../../helpers/translation';
import { webOnly } from '../../helpers/style';

type ModalProps = {
  children: any;
  visible?: boolean;
  onClose?: (event: GestureResponderEvent) => void;
  style?: ViewProps['style'];
};

const Modal = (props: ModalProps) => {
  if (!props.visible) {
    return null;
  }

  return (
    <Portal>
      <View style={[styles.container, props.style]}>
        {props.onClose && (
          <Pill
            type="black"
            icon="x"
            title={t('common.close')}
            onPress={props.onClose}
            style={styles.closeButton}
          />
        )}

        <ScrollView contentContainerStyle={styles.scrollView}>
          {props.children}
        </ScrollView>
      </View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    ...webOnly({
      position: 'fixed',
    }),
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: 20,
    zIndex: 1,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    marginBottom: 20,
  },
  scrollView: {
    flex: 1,
  },
});

export default Modal;
