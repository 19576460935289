import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import { DarkTheme, DefaultTheme } from '@react-navigation/native';
import Text from '../Text/Text';

export type StepperProps = {
  minValue?: number;
  maxValue?: number;
  value: number;
  onChangeValue?: (number: number) => void;
  disabled?: boolean;
  style?: ViewProps['style'];
};

const Stepper = (props: StepperProps) => {
  const scheme = useColorScheme();

  function handleDecrement() {
    if (
      typeof props.minValue !== 'undefined' &&
      props.value === props.minValue
    ) {
      return;
    }

    if (props.onChangeValue) {
      props.onChangeValue(props.value - 1);
    }
  }

  function handleIncrement() {
    if (
      typeof props.maxValue !== 'undefined' &&
      props.value === props.maxValue
    ) {
      return;
    }

    if (props.onChangeValue) {
      props.onChangeValue(props.value + 1);
    }
  }

  return (
    <View style={[styles.container, props.style]}>
      <TouchableOpacity
        onPress={handleDecrement}
        disabled={props.disabled}
        activeOpacity={0.5}
        style={[
          styles.button,
          styles.decrement,
          scheme === 'dark' && styles.decrementDark,
        ]}
      >
        <Text style={styles.textCenter}>-</Text>
      </TouchableOpacity>

      <View
        style={[
          styles.currentValue,
          scheme === 'dark' && styles.currentValueDark,
        ]}
      >
        <Text style={styles.textCenter}>{props.value}</Text>
      </View>

      <TouchableOpacity
        onPress={handleIncrement}
        disabled={props.disabled}
        activeOpacity={0.5}
        style={[
          styles.button,
          styles.increment,
          scheme === 'dark' && styles.incrementDark,
        ]}
      >
        <Text style={styles.textCenter}>+</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  currentValue: {
    flex: 2,
    padding: 10,
    borderColor: DefaultTheme.colors.border,
    borderWidth: 1,
  },
  currentValueDark: {
    borderColor: DarkTheme.colors.border,
  },
  button: {
    flex: 1,
    padding: 10,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: 'rgb(250, 250, 250)',
  },
  decrement: {
    borderRightWidth: 0,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  decrementDark: {
    backgroundColor: DarkTheme.colors.card,
    borderColor: DarkTheme.colors.border,
  },
  increment: {
    borderLeftWidth: 0,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  incrementDark: {
    backgroundColor: DarkTheme.colors.card,
    borderColor: DarkTheme.colors.border,
  },
  textCenter: {
    textAlign: 'center',
  },
});

export default Stepper;
