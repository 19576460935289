import React, { useState, useEffect, useCallback } from 'react';
import {
  FlatList,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useColorScheme } from 'react-native-appearance';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { UserStackParamList } from '../navigators/UserNavigator';
import { t } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import {
  businessIdState,
  pricingCanBookState,
  pricingCanOrderState,
} from '../store/state';
import P from '../components/P/P';
import TextInput from '../components/TextInput/TextInput';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';
import OrderRowItem from '../components/OrderRowItem/OrderRowItem';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import OrderDetails from '../components/OrderDetails/OrderDetails';
import OrderStatusSelectors from '../components/OrderStatusSelectors/OrderStatusSelectors';
import { webOnly } from '../helpers/style';
import { useLiveBookings, useLiveOrders } from '../hooks/queries';
import BookingRowItem from '../components/BookingRowItem/BookingRowItem';
import { Order, Booking } from '../../types';
import BookingDetails from '../components/BookingDetails/BookingDetails';
import BookingStatusSelectors from '../components/BookingStatusSelectors/BookingStatusSelectors';
import { usePrevious } from '../hooks/misc';
import Icon from '../components/Icon/Icon';
import Icons from '../constants/icons';
import Theme from '../helpers/theme';
import { useIsWideScreen } from '../hooks/responsive';
import BookingsTimeline from '../components/BookingsTimeline/BookingsTimeline';
import { useQuery } from 'react-query';
import API from '../helpers/api';
import DateTimePicker from '../components/DateTimePicker/DateTimePicker';
import Button from '../components/Button/Button';
import Modal from '../components/Modal/Modal';
import CreateWalkInBookingForm from '../forms/CreateWalkInBookingForm';
import Block from '../components/Block/Block';

type LiveDashboardScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'LiveDashboard'>;
};

const LiveDashboardScreen = (props: LiveDashboardScreenProps) => {
  const [referenceNumber, setReferenceNumber] = useState<string>();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<'order' | 'booking'>();
  const [timelineDate, setTimelineDate] = useState(new Date());
  const [showAddBookingFrom, setShowAddBookingForm] = useState(false);
  const [creatingBooking, setCreatingBooking] = useState(false);
  const businessId = useRecoilValue(businessIdState);
  const scheme = useColorScheme();
  const pricingCanBook = useRecoilValue(pricingCanBookState);
  const pricingCanOrder = useRecoilValue(pricingCanOrderState);
  const isWideScreen = useIsWideScreen();

  const {
    data: orders,
    status: ordersStatus,
    isFirstRequest: isFirstOrdersRequest,
  } = useLiveOrders(businessId, {
    enabled: pricingCanOrder,
  });

  const {
    data: bookings,
    status: bookingsStatus,
    isFirstRequest: isFirstBookingsRequest,
  } = useLiveBookings(businessId, { enabled: pricingCanBook });

  const { data: rooms, status: roomsStatus } = useQuery(
    ['rooms', businessId],
    (_key: string, businessId: string) => API.fetchBusinessRooms(businessId)
  );

  const allOrders = orders ?? [];
  const allBookings = bookings ?? [];
  const data = [...allOrders, ...allBookings];
  const previousDataLength = usePrevious(data.length);

  useDocumentTitle(t('navigation.myOrders'));

  useEffect(() => {
    (async () => {
      if (
        previousDataLength !== data.length &&
        ((pricingCanBook && !isFirstBookingsRequest) ||
          (pricingCanOrder && !isFirstBookingsRequest))
      ) {
        try {
          const audio = new Audio(require('../../assets/notification.mp3'));
          await audio.play();
        } catch (error) {
          console.warn(error);
        }
      }
    })();
  }, [data, previousDataLength, isFirstOrdersRequest, isFirstBookingsRequest]);

  const toggleFilter = useCallback(
    (newFilter: 'booking' | 'order') => {
      if (!filter || newFilter !== filter) {
        setFilter(newFilter);
      } else {
        setFilter(undefined);
      }
    },
    [filter]
  );

  const filteredData = data
    .filter((element) => {
      // @ts-ignore
      if (filter && element.type !== filter) {
        return false;
      }

      if (search) {
        const lowerSearch = search.toLowerCase();

        return (
          element.referenceNumber.toLowerCase().includes(lowerSearch) ||
          (element.contactEmail &&
            element.contactEmail.toLowerCase().includes(lowerSearch)) ||
          element.contactFirstName.toLowerCase().includes(lowerSearch) ||
          element.contactLastName.toLowerCase().includes(lowerSearch) ||
          (element.contactPhone &&
            element.contactPhone.toLowerCase().includes(lowerSearch))
        );
      }

      return true;
    })
    // @ts-ignore
    .sort((a, b) => b.date - a.date);

  const renderItem = useCallback(
    ({ item, index }) => {
      // @ts-ignore
      const itemDate = moment.utc(item.startAt || item.createdAt);

      // @ts-ignore
      const date = itemDate.format('LL');

      const nextItem = filteredData[index + 1];
      const nextItemDate =
        // @ts-ignore
        nextItem && moment.utc(nextItem.startAt || nextItem.createdAt);
      const nextDate = nextItem && nextItemDate.format('LL');

      return (
        <>
          {index === 0 && (
            <View
              style={[
                styles.sectionContainer,
                styles.topSectionContainer,
                itemDate.isSame(new Date(), 'day') &&
                  styles.sectionContainerToday,
              ]}
            >
              <P style={styles.sectionText}>{date}</P>

              {itemDate.isSame(new Date(), 'day') && (
                <P style={styles.todayText}>{t('common.today')}</P>
              )}
            </View>
          )}

          <TouchableOpacity
            onPress={() => {
              if (isWideScreen) {
                setReferenceNumber(item.referenceNumber);
              } else {
                // @ts-ignore
                if (item.type === 'order') {
                  props.navigation.navigate('BusinessOrderDetails', {
                    order: item as Order,
                  });
                }

                // @ts-ignore
                if (item.type === 'booking') {
                  props.navigation.navigate('BusinessBookingDetails', {
                    booking: item as Booking,
                  });
                }
              }
            }}
          >
            {
              // @ts-ignore
              item.type === 'order' && (
                <OrderRowItem
                  order={item as Order}
                  selected={item.referenceNumber === referenceNumber}
                />
              )
            }

            {
              // @ts-ignore
              item.type === 'booking' && (
                <BookingRowItem
                  booking={item as Booking}
                  selected={item.referenceNumber === referenceNumber}
                />
              )
            }
          </TouchableOpacity>

          {nextDate !== date && index < filteredData.length - 1 && (
            <View
              style={[
                styles.sectionContainer,
                styles.bottomSectionContainer,
                nextItemDate.isSame(new Date(), 'day') &&
                  styles.sectionContainerToday,
              ]}
            >
              <P style={styles.sectionText}>{nextDate}</P>

              {nextItemDate.isSame(new Date(), 'day') && (
                <P style={styles.todayText}>{t('common.today')}</P>
              )}
            </View>
          )}
        </>
      );
    },
    [filteredData.length, referenceNumber, isWideScreen]
  );

  if (
    (pricingCanOrder && ordersStatus !== 'success') ||
    (pricingCanBook && bookingsStatus !== 'success') ||
    (pricingCanOrder && typeof orders === 'undefined') ||
    (pricingCanBook && typeof bookings === 'undefined')
  ) {
    return <LoaderContainer />;
  }

  const currentElement = data.find(
    (element) => element.referenceNumber === referenceNumber
  );

  return (
    <>
      {isWideScreen && (
        <Modal
          visible={showAddBookingFrom}
          onClose={() => setShowAddBookingForm(false)}
        >
          <Block>
            <CreateWalkInBookingForm
              sending={creatingBooking}
              onSubmit={async (values) => {
                try {
                  setCreatingBooking(true);

                  await API.createWalkInForBusiness(businessId, values);

                  setCreatingBooking(false);
                  setShowAddBookingForm(false);
                } catch (error) {
                  console.warn(error);
                }
              }}
            />
          </Block>
        </Modal>
      )}

      <FlatList
        data={filteredData}
        removeClippedSubviews
        renderItem={renderItem}
        ListEmptyComponent={() => (
          <P style={styles.noOrderMessage}>
            {t('screens.businessOrders.paragraphs.noOrder')}
          </P>
        )}
        ItemSeparatorComponent={() => <ItemSeparator noMargins />}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={
          <View style={[styles.header, scheme === 'dark' && styles.headerDark]}>
            <Icon
              name="arrow-left"
              onPress={() => props.navigation.goBack()}
              size={16}
              style={[
                styles.headerBackIcon,
                scheme === 'dark' && styles.headerBackIconDark,
              ]}
            />

            <TextInput
              editable
              type="search"
              value={search}
              onChangeText={setSearch}
              placeholder={t('screens.liveDashboard.inputs.search.placeholder')}
              inputStyle={styles.searchInput}
              style={styles.search}
            />

            <Icon
              name={Icons.ORDERS}
              onPress={() => toggleFilter('order')}
              size={16}
              color={filter === 'order' ? Theme.PRIMARY : undefined}
              style={[
                styles.headerIcon,
                scheme === 'dark' && styles.headerIconDark,
              ]}
            />

            <Icon
              name={Icons.BOOKINGS}
              onPress={() => toggleFilter('booking')}
              size={16}
              color={filter === 'booking' ? Theme.PRIMARY : undefined}
              style={[
                styles.headerIcon,
                scheme === 'dark' && styles.headerIconDark,
              ]}
            />
          </View>
        }
        keyExtractor={(item) => item.referenceNumber}
        style={[
          styles.items,
          scheme === 'dark' && styles.itemsDark,
          isWideScreen && styles.itemsWide,
          scheme === 'dark' && isWideScreen && styles.itemsWideDark,
        ]}
      />

      {isWideScreen && (
        <ScrollView
          style={styles.currentOrderContainer}
          contentContainerStyle={styles.currentOrder}
        >
          <View
            style={[
              styles.statusSelectors,
              scheme === 'dark' && styles.statusSelectorsDark,
              { justifyContent: 'space-between', zIndex: 3 },
            ]}
          >
            <Button
              title={t('screens.liveDashboard.actions.addWalkIn')}
              size="small"
              inline
              onPress={() => setShowAddBookingForm(true)}
              icon="plus"
            />

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <DateTimePicker
                mode="date"
                value={timelineDate}
                onChange={setTimelineDate}
                style={{ width: 300 }}
              />

              <Button
                title={t('common.today')}
                style={{ marginLeft: 10 }}
                size="small"
                inline
                onPress={() => setTimelineDate(new Date())}
                selected={moment.utc(timelineDate).isSame(moment.utc(), 'day')}
              />
            </View>
          </View>

          {roomsStatus === 'success' && typeof rooms !== 'undefined' && (
            <BookingsTimeline
              rooms={rooms}
              bookings={allBookings.filter((booking) =>
                moment.utc(booking.startAt).isSame(timelineDate, 'day')
              )}
              onPressBooking={(booking) =>
                setReferenceNumber(booking.referenceNumber)
              }
            />
          )}

          {/* TEMP */}
          {false && !currentElement && (
            <View style={styles.noCurrentOrder}>
              <P>{t('screens.businessOrders.paragraphs.pickOrder')}</P>
            </View>
          )}

          {currentElement && (
            <View
              style={{
                zIndex: 4,
                borderRightWidth: 1,
                borderRightColor: DefaultTheme.colors.border,
                backgroundColor: DefaultTheme.colors.background,
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '50%',
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowOpacity: 0.27,
                shadowRadius: 4.65,
              }}
            >
              <View
                style={[
                  styles.statusSelectors,
                  scheme === 'dark' && styles.statusSelectorsDark,
                ]}
              >
                {currentElement.type === 'order' && (
                  <OrderStatusSelectors
                    statusOwner={currentElement.statusOwner}
                    statusUser={currentElement.statusUser}
                    referenceNumber={currentElement.referenceNumber}
                    referenceToken={currentElement.referenceToken}
                  />
                )}

                {currentElement.type === 'booking' && (
                  <BookingStatusSelectors
                    statusOwner={currentElement.statusOwner}
                    statusUser={currentElement.statusUser}
                    referenceNumber={currentElement.referenceNumber}
                    referenceToken={currentElement.referenceToken}
                  />
                )}

                <Icon
                  name="x"
                  onPress={() => setReferenceNumber(undefined)}
                  style={{ marginLeft: 10 }}
                />
              </View>

              <ScrollView contentContainerStyle={styles.currentOrderScrollView}>
                {currentElement.type === 'order' && (
                  <OrderDetails order={currentElement as Order} />
                )}

                {currentElement.type === 'booking' && (
                  <BookingDetails booking={currentElement as Booking} />
                )}
              </ScrollView>
            </View>
          )}
        </ScrollView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  headerDark: {
    borderBottomColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  headerBackIcon: {
    alignSelf: 'center',
    borderRightWidth: 1,
    borderRightColor: DefaultTheme.colors.border,
    padding: 10,
  },
  headerBackIconDark: {
    borderRightColor: DarkTheme.colors.border,
  },
  headerIcon: {
    alignSelf: 'center',
    borderLeftWidth: 1,
    borderLeftColor: DefaultTheme.colors.border,
    padding: 10,
  },
  headerIconDark: {
    borderLeftColor: DarkTheme.colors.border,
  },
  searchInput: {
    borderRadius: 0,
    ...webOnly({
      outline: 'none',
    }),
  },
  search: {
    flex: 1,
  },
  noOrderMessage: {
    textAlign: 'center',
    margin: 20,
  },
  items: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: DefaultTheme.colors.card,
  },
  itemsDark: {
    backgroundColor: DarkTheme.colors.card,
  },
  itemsWide: {
    width: '35%',
    right: 'auto',
    borderRightColor: DefaultTheme.colors.border,
    borderRightWidth: 1,
  },
  itemsWideDark: {
    borderRightColor: DarkTheme.colors.border,
  },
  currentOrderContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    width: '65%',
  },
  currentOrder: {
    flex: 1,
  },
  noCurrentOrder: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusSelectors: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  statusSelectorsDark: {
    borderBottomColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  currentOrderScrollView: {
    padding: 20,
  },
  sectionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: DefaultTheme.colors.background,
  },
  sectionContainerToday: {
    borderRightWidth: 2,
    borderRightColor: Theme.PRIMARY,
  },
  topSectionContainer: {
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
  },
  bottomSectionContainer: {
    borderTopWidth: 1,
    borderTopColor: DefaultTheme.colors.border,
  },
  sectionText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  todayText: {
    flex: 1,
    fontSize: 12,
    textAlign: 'right',
    color: Theme.PRIMARY,
    fontWeight: 'bold',
  },
});

export default LiveDashboardScreen;
