import React from 'react';
import { ViewProps, Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import exifr from 'exifr';
import { convertBase64ImageToFile } from '../../helpers/format';
import Pill from '../Pill/Pill';

export type FileInputProps = {
  title?: string;
  onChangeFile: (file: File) => void;
  style?: ViewProps['style'];
};

const FileInput = (props: FileInputProps) => {
  const openFilePicker = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsMultipleSelection: false,
      });

      if (result.cancelled || !result.uri) {
        return;
      }

      const file = await convertBase64ImageToFile(result.uri);

      // exifr does not handle png files
      if (result.uri.indexOf('image/png') !== -1) {
        props.onChangeFile(file);
        return;
      }

      // @ts-ignore
      const exifOrientation = await exifr.rotation(file);

      if (exifOrientation.dimensionSwapped) {
        // NOTE(eveningkid): For some reason, "manipulating" the image
        // without applying any transforms gets rid of the EXIF
        // orientation ¯\_(ツ)_/¯
        const manipResult = await ImageManipulator.manipulateAsync(
          result.uri,
          [],
          { compress: 1, format: ImageManipulator.SaveFormat.PNG }
        );

        const rotatedFile = await convertBase64ImageToFile(manipResult.uri);
        props.onChangeFile(rotatedFile);
      } else {
        props.onChangeFile(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Pill
      type="primary"
      icon="image"
      title={props.title}
      onPress={openFilePicker}
      style={props.style}
    />
  );
};

export default React.memo(FileInput);
