import React, { useEffect, useCallback, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
  ViewProps,
} from 'react-native';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  rawCategoryItemsState,
  itemsState,
  pricingIdState,
  categoryItemsState,
} from '../store/state';
import API from '../helpers/api';
import Item from '../components/Item/Item';
import { t } from '../helpers/translation';
import { PriceType } from '../../types';

type Props = {
  id: string;
  categoryId: string;
  editable?: boolean;
  style?: ViewProps['style'];
};

const ItemContainer = (props: Props) => {
  const [item, setItem] = useRecoilState(itemsState(props.id));
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const pricingId = useRecoilValue(pricingIdState);
  const rawCategoryItems = useRecoilValue(
    rawCategoryItemsState(props.categoryId)
  );
  const setCategoryItems = useSetRecoilState(
    categoryItemsState(props.categoryId)
  );

  useEffect(() => {
    if (rawCategoryItems.length > 0) {
      const item = rawCategoryItems.find((item) => item.id === props.id);

      if (item) {
        setItem(item);
      }
    }
  }, [rawCategoryItems]);

  const handleEditName = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      const value = (event.target as any).value;

      if (value.trim().length > 0 && value !== item.name) {
        setItem((item) => ({ ...item, name: value }));

        try {
          API.updatePricingItemById(pricingId, props.categoryId, props.id, {
            name: value,
          });
        } catch (e) {}
      }
    },
    [item.name, pricingId, props.categoryId, props.id]
  );

  const handleEditDescription = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      const value = (event.target as any).value;

      if (value.trim().length > 0 && value !== item.description) {
        setItem((item) => ({ ...item, description: value }));

        try {
          API.updatePricingItemById(pricingId, props.categoryId, props.id, {
            description: value,
          });
        } catch (e) {}
      }
    },
    [item.name, pricingId, props.categoryId, props.id]
  );

  const handleEditPrice = useCallback(
    (price: string) => {
      setItem((item) => ({ ...item, price }));

      try {
        API.updatePricingItemById(pricingId, props.categoryId, props.id, {
          priceType: item.priceType ?? 'value',
          price,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [pricingId, props.categoryId, props.id]
  );

  const handleEditPriceType = useCallback(
    (priceType: PriceType) => {
      setItem((item) => ({ ...item, priceType }));

      try {
        API.updatePricingItemById(pricingId, props.categoryId, props.id, {
          priceType,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [pricingId, props.categoryId, props.id]
  );

  const handleUpdateImage = useCallback(
    async (file: File) => {
      setIsUploadingImage(true);

      try {
        const imageURL = await API.uploadPricingItemImage(
          pricingId,
          props.categoryId,
          props.id,
          file
        );

        setItem((item) => ({ ...item, image: imageURL }));
      } catch (error) {
        console.log(error);
      }

      setIsUploadingImage(false);
    },
    [pricingId, props.categoryId, props.id]
  );

  const handleDelete = useCallback(async () => {
    if (!confirm(t('components.itemContainer.alerts.deleteItem'))) {
      return;
    }

    setCategoryItems((categoryItems) =>
      categoryItems.filter((itemId) => itemId !== props.id)
    );

    try {
      API.deletePricingItem(pricingId, props.categoryId, props.id);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const handleDeleteItemOption = useCallback(
    async (categoryId: string, itemId: string) => {
      try {
        setItem((item) => ({
          ...item,
          optionCategories: item.optionCategories
            ? item.optionCategories.map((category) => {
                if (category.id !== categoryId) {
                  return category;
                }

                return {
                  ...category,
                  options: category.options.filter((optionItem) => {
                    return optionItem.id !== itemId;
                  }),
                };
              })
            : null,
        }));

        await API.deletePricingItemOption(
          pricingId,
          props.id,
          categoryId,
          itemId
        );
      } catch (error) {
        console.warn(error);
      }
    },
    []
  );

  const handleEditItemOptionCategory = useCallback(
    async (categoryId: string, key: string, value: any) => {
      try {
        setItem((item) => ({
          ...item,
          optionCategories: item.optionCategories!.map((category) => {
            if (category.id !== categoryId) {
              return category;
            }

            return {
              ...category,
              [key]: value,
            };
          }),
        }));

        await API.updatePricingItemOptionCategoryById(
          pricingId,
          props.id,
          categoryId,
          { [key]: value }
        );
      } catch (error) {
        console.warn(error);
      }
    },
    []
  );

  const handleDeleteItemOptionCategory = useCallback(
    async (categoryId: string) => {
      try {
        setItem((item) => ({
          ...item,
          optionCategories: item.optionCategories!.filter((category) => {
            return category.id !== categoryId;
          }),
        }));

        await API.deletePricingItemOptionCategory(
          pricingId,
          props.id,
          categoryId
        );
      } catch (error) {
        console.warn(error);
      }
    },
    []
  );

  const handleEditItemOption = useCallback(
    async (categoryId: string, optionId: string, key: string, value: any) => {
      try {
        setItem((item) => ({
          ...item,
          optionCategories: item.optionCategories!.map((category) => {
            if (category.id === categoryId) {
              return {
                ...category,
                options: category.options.map((option) => {
                  if (option.id === optionId) {
                    return {
                      ...option,
                      [key]: value,
                    };
                  } else {
                    return option;
                  }
                }),
              };
            } else {
              return category;
            }
          }),
        }));

        await API.updatePricingItemOptionById(
          pricingId,
          props.id,
          categoryId,
          optionId,
          { [key]: value }
        );
      } catch (error) {
        console.warn(error);
      }
    },
    []
  );

  const handleCreateItemOption = useCallback(
    async (
      categoryId: string,
      values: {
        name: string;
        description: string;
        price: number;
        isDefault: boolean;
      }
    ) => {
      try {
        await API.createPricingItemOption(
          pricingId,
          props.id,
          // @ts-ignore
          categoryId,
          values
        );
      } catch (error) {
        console.warn(error);
      }
    },
    []
  );

  return (
    <Item
      {...item}
      id={props.id}
      editable={props.editable}
      onEditName={handleEditName}
      onEditDescription={handleEditDescription}
      onEditPrice={handleEditPrice}
      onEditPriceType={handleEditPriceType}
      onUpdateImage={handleUpdateImage}
      onCreateItemOption={handleCreateItemOption}
      onEditItemOption={handleEditItemOption}
      onDeleteItemOption={handleDeleteItemOption}
      onEditItemOptionCategory={handleEditItemOptionCategory}
      onDeleteItemOptionCategory={handleDeleteItemOptionCategory}
      onDelete={handleDelete}
      isUploadingImage={isUploadingImage}
      style={props.style}
    />
  );
};

export default ItemContainer;
