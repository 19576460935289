import React, { useState, useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import Icon from '../Icon/Icon';
import H3 from '../H3/H3';
import Switch from '../Switch/Switch';
import Text from '../Text/Text';
import { useColorScheme } from 'react-native-appearance';
import Theme from '../../helpers/theme';
import Modal from '../Modal/Modal';
import YoutubePlayer from '../YoutubePlayer/YoutubePlayer';
import { t } from '../../helpers/translation';

export type AccountServiceBlockProps = {
  icon: string;
  color: string;
  name: string;
  activated: boolean;
  message?: string;
  actions?: {
    label: string;
    onPress: () => void;
    disabled?: boolean;
    symbol?: 'warning';
  }[];
  onToggleActivate?: (status: boolean) => void;
  helpVideoKey?: string;
  style?: ViewProps['style'];
};

const AccountServiceBlock = (props: AccountServiceBlockProps) => {
  const scheme = useColorScheme();
  const [watchHelpVideo, setWatchHelpVideo] = useState(false);

  const toggleWatchHelpVideo = useCallback(() => {
    setWatchHelpVideo((watchVideo) => !watchVideo);
  }, []);

  return (
    <>
      {props.helpVideoKey && (
        <Modal visible={watchHelpVideo} onClose={toggleWatchHelpVideo}>
          <YoutubePlayer videoKey={props.helpVideoKey} />
        </Modal>
      )}

      <View
        style={[
          styles.container,
          scheme === 'dark' && styles.containerDark,
          props.style,
        ]}
      >
        <View style={[styles.header, scheme === 'dark' && styles.headerDark]}>
          <View style={styles.headerMain}>
            <View style={styles.headerName}>
              <Icon name={props.icon} color={props.color} style={styles.icon} />

              <H3>{props.name}</H3>
            </View>

            {props.helpVideoKey && (
              <Icon
                name="help-circle"
                color={props.color}
                size={18}
                onPress={toggleWatchHelpVideo}
              />
            )}

            {props.onToggleActivate && (
              <Switch
                titleOn={t('common.activated')}
                titleOff={t('common.activate')}
                value={props.activated}
                onValueChange={props.onToggleActivate}
                color={props.color}
                style={styles.activationSwitch}
              />
            )}
          </View>

          {props.message && <Text style={styles.message}>{props.message}</Text>}
        </View>

        {props.actions &&
          props.actions.map((action, index) => (
            <View
              key={action.label}
              style={[
                styles.actionSeparator,
                scheme === 'dark' && styles.actionSeparatorDark,
                index === 0 && styles.actionSeparatorFirst,
              ]}
            >
              <TouchableOpacity
                disabled={action.disabled}
                onPress={action.onPress}
                style={[
                  styles.action,
                  action.disabled && styles.actionDisabled,
                ]}
              >
                <Text style={styles.actionLabel}>{action.label}</Text>

                {action.symbol === 'warning' && (
                  <Icon
                    name="alert-circle"
                    color={Theme.RED}
                    size={14}
                    style={styles.icon}
                  />
                )}

                <Icon name="arrow-right" color={props.color} size={14} />
              </TouchableOpacity>
            </View>
          ))}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  containerDark: {
    borderColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  header: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
  },
  headerDark: {
    borderBottomColor: DarkTheme.colors.border,
  },
  headerMain: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerName: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  message: {
    marginTop: 10,
  },
  actionSeparator: {
    borderTopWidth: 1,
    borderTopColor: DefaultTheme.colors.border,
  },
  actionSeparatorDark: {
    borderTopColor: DarkTheme.colors.border,
  },
  actionSeparatorFirst: {
    borderTopWidth: 0,
  },
  action: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionDisabled: {
    opacity: 0.6,
  },
  actionLabel: {
    flex: 1,
  },
  activationSwitch: {
    marginLeft: 10,
  },
});

export default AccountServiceBlock;
