import React, { useState } from 'react';
import { ScrollView, StyleSheet, Dimensions, View, Image } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppStackParamList } from '../navigators/AppNavigator';
import { useDocumentTitle } from '../hooks/document';
import Wrapper from '../components/Wrapper/Wrapper';
import Hero from '../components/Hero/Hero';
import { t } from '../helpers/translation';
import Features from '../components/Features/Features';
import Feature from '../components/Feature/Feature';
import Theme from '../helpers/theme';
import BrandBar from '../components/BrandBar/BrandBar';
import Step from '../components/Step/Step';
import H1 from '../components/H1/H1';
import JoinBanner from '../components/JoinBanner/JoinBanner';
import Carousel from '../components/Carousel/Carousel';
import BulletFeatures from '../components/BulletFeatures/BulletFeatures';
import FeaturesJob from '../components/FeaturesJob/FeaturesJob';
import { RouteProp } from '@react-navigation/native';
import JobFeatures, { JobType } from '../constants/job-features';
import FeaturesTable from '../components/FeaturesTable/FeaturesTable';
import DownloadAppBanner from '../components/DownloadAppBanner/DownloadAppBanner';
import YoutubePlayer from '../components/YoutubePlayer/YoutubePlayer';
import Modal from '../components/Modal/Modal';

type Props = {
  navigation: StackNavigationProp<AppStackParamList, 'ProductMemberships'>;
  route: RouteProp<AppStackParamList, 'ProductMemberships'>;
};

const ProductMembershipsScreen = (props: Props) => {
  const [watchVideo, setWatchVideo] = useState(false);

  useDocumentTitle(t('screens.home.products.memberships.title'));

  const job: JobType =
    props.route.params && props.route.params.job
      ? props.route.params.job
      : 'all';

  return (
    <>
      <Modal visible={watchVideo} onClose={() => setWatchVideo(false)}>
        <YoutubePlayer videoKey="l3UZIl1_6zQ" />
      </Modal>

      <ScrollView contentContainerStyle={styles.container}>
        <BrandBar />

        <Wrapper>
          <Hero
            image={require('../../assets/credit_card.svg')}
            title={t('screens.home.products.memberships.title')}
            subtitle={t('screens.home.products.memberships.subtitle')}
            action={t('screens.products.actions.watchVideo')}
            actionIcon="play-circle"
            onPressAction={(event) => {
              event.preventDefault();
              setWatchVideo(true);
            }}
          />

          <Features>
            <Feature
              title={t(
                'screens.productMemberships.features.understandCustomers.title'
              )}
              subtitle={t(
                'screens.productMemberships.features.understandCustomers.subtitle'
              )}
              image={require('../../assets/data-analysis.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />

            <Feature
              title={t('screens.productMemberships.features.simplicity.title')}
              subtitle={t(
                'screens.productMemberships.features.simplicity.subtitle'
              )}
              image={require('../../assets/simple.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />
          </Features>

          <Carousel
            color={Theme.RED}
            items={[
              {
                image: require('../../assets/memberships-shot-1.png'),
                title: t('screens.productMemberships.steps.createMembership'),
              },
              {
                image: require('../../assets/memberships-shot-2.png'),
                title: t('screens.productMemberships.steps.scanCode'),
              },
              {
                image: require('../../assets/memberships-shot-3.png'),
                title: t('screens.productMemberships.steps.getRewards'),
              },
            ]}
            style={styles.carousel}
          />

          <BulletFeatures
            color={Theme.RED}
            features={[
              {
                icon: 'trending-up',
                title: t(
                  'screens.productMemberships.features.adjustedRewards.title'
                ),
                text: t(
                  'screens.productMemberships.features.adjustedRewards.subtitle'
                ),
              },
              {
                icon: 'smartphone',
                title: t(
                  'screens.productMemberships.features.useYourPhone.title'
                ),
                text: t(
                  'screens.productMemberships.features.useYourPhone.subtitle'
                ),
              },
              {
                icon: 'pie-chart',
                title: t(
                  'screens.productMemberships.features.detailedStatistics.title'
                ),
                text: t(
                  'screens.productMemberships.features.detailedStatistics.subtitle'
                ),
              },
              {
                icon: 'feather',
                title: t(
                  'screens.productMemberships.features.noPrinting.title'
                ),
                text: t(
                  'screens.productMemberships.features.noPrinting.subtitle'
                ),
              },
            ]}
            style={styles.bulletFeatures}
          />

          <DownloadAppBanner
            iOSBundle="id1527586992"
            androidBundle="com.illicarte.plusone"
            color={Theme.RED}
            style={styles.downloadApp}
          />

          {/* <FeaturesJob
            job={job}
            image={require(`../../assets/job-${job}.jpg`)}
            features={JobFeatures.memberships[job]}
            style={{
              marginBottom: 70,
            }}
          /> */}

          <H1>{t('screens.productOrders.headers.faq')}</H1>

          <Step
            number={'Q'}
            title={t('screens.productMemberships.faq.anotherService.question')}
            indications={t(
              'screens.productMemberships.faq.anotherService.answer'
            )}
            numberColor={Theme.RED}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t(
              'screens.productMemberships.faq.setUpMemberships.question'
            )}
            indications={t(
              'screens.productMemberships.faq.setUpMemberships.answer'
            )}
            numberColor={Theme.RED}
            style={styles.step}
          />

          <Step
            number={'Q'}
            title={t('screens.productOrders.faq.trust.question')}
            indications={t('screens.productOrders.faq.trust.answer')}
            numberColor={Theme.RED}
            style={styles.step}
          />

          <H1 style={styles.whatYouGet}>
            {t('screens.products.headers.whatYouGet')}
          </H1>

          <FeaturesTable
            color={Theme.RED}
            features={[
              {
                icon: 'layout',
                title: t('screens.productMemberships.included.customBranding'),
              },
              {
                icon: 'trending-up',
                title: t('screens.productMemberships.included.loyaltyProgram'),
              },
              {
                icon: 'pie-chart',
                title: t(
                  'screens.productMemberships.included.customerStatistics'
                ),
              },
              {
                icon: 'help-circle',
                title: t('screens.products.included.onboardingSupport'),
              },
              {
                icon: 'instagram',
                title: t('screens.products.included.socialMediaMaterials'),
              },
              {
                icon: 'list',
                title: t('screens.productMemberships.included.mobileAppAccess'),
              },
              {
                icon: 'star',
                title: t(
                  'screens.productMemberships.included.businessPromotion'
                ),
              },
            ]}
            style={styles.featuresTable}
          />

          <JoinBanner backgroundColor={Theme.RED} style={styles.join} />

          <H1>{t('screens.products.headers.youMightAlsoNeed')}</H1>

          <Features>
            <Feature
              href="/products/orders"
              title={t('screens.home.products.orders.title')}
              subtitle={t('screens.home.products.orders.subtitle')}
              image={require('../../assets/order_confirmed.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />

            <Feature
              href="/products/bookings"
              title={t('screens.home.products.bookings.title')}
              subtitle={t('screens.home.products.bookings.subtitle')}
              image={require('../../assets/restaurant.svg')}
              backgroundColor="white"
              textColor={Theme.BLACK}
            />
          </Features>
        </Wrapper>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  carousel: {
    marginBottom: 70,
  },
  bulletFeatures: {
    marginBottom: 30,
  },
  downloadApp: {
    marginBottom: 30,
  },
  step: {
    marginTop: 30,
    marginBottom: 10,
  },
  featuresTable: {
    marginBottom: 70,
  },
  whatYouGet: {
    marginTop: 60,
    marginBottom: 20,
  },
  join: {
    marginBottom: 70,
  },
});

export default ProductMembershipsScreen;
