// @ts-ignore
import phone from 'phone';
import { t, getLocale } from './translation';
import { PriceType } from '../../types';

export const isValidPhoneNumber = (
  countryCode: string,
  phoneNumber: string
): boolean => {
  return phone(phoneNumber, countryCode, true).length > 0;
};

export const convertBase64ImageToFile = async (
  image: string
): Promise<File> => {
  const res = await fetch(image);
  const blob = await res.blob();
  const mimeType = image.substr(5, image.indexOf(';') - 5);
  const extension = mimeType.substr(mimeType.indexOf('/') + 1);
  const fileName = `${Date.now()}.${extension}`;
  return new File([blob], fileName, { type: mimeType });
};

export const formatLocale = (locale: string) => {
  // e.g. en-GB => ['en', 'GB'], fr => ['fr']
  const splittedLocale = locale.split('-');

  if (splittedLocale.length >= 1) {
    return splittedLocale[0].toLowerCase();
  }

  return locale;
};

const formatCurrency = (language: string, price: string, currency = 'EUR') => {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  })
    .format(Number(price))
    .replace(/\D00(?=\D*$)/, '');
};

export const formatPrice = (
  baseCurrency: string,
  priceType: PriceType,
  price: string | number
) => {
  if (typeof price === 'number') {
    price = price.toString();
  }

  switch (priceType) {
    case 'value':
      return formatCurrency(getLocale(), price, baseCurrency);

    case 'range':
      const [rangeStart, rangeEnd] = price
        .split('-')
        .map((value) => value.trim());

      return t('screens.pricing.price.rangeFromTo', {
        from: formatCurrency(getLocale(), rangeStart, baseCurrency),
        to: formatCurrency(getLocale(), rangeEnd, baseCurrency),
      });

    case 'up':
      return t('screens.pricing.price.up', {
        price: formatCurrency(getLocale(), price, baseCurrency),
      });

    case 'other':
    default:
      return price;
  }
};

export const formatTime = (time: number) => {
  const [integer, decimals] = time.toFixed(2).split('.');

  if (decimals === '00') {
    return `${integer}:00`;
  }

  return `${integer}:${60 * (Number(decimals) / 100)}`;
};

export const generateRange = (from: number, to: number, step: number) => {
  const range = [from];

  let acc = from;
  while (acc < to) {
    acc += step;
    range.push(acc);
  }

  return range;
};

/** Float to minutes integer.
 *
 *     0.8 => 0.8 * 60
 */
export const percentageToMinutes = (percentage: number) => {
  return 60 * percentage;
};

/** Minutes integer to float.
 *
 *     30 => 0.5
 */
export const minutesToPercentage = (minutes: number) => {
  return minutes / 60;
};

/** Duration is in minutes. */
export const formatDuration = (duration: number) => {
  let formatted = '';

  const hours = Math.floor(duration / 60);

  if (hours !== 0) {
    formatted += `${hours}h`;
    duration -= 60 * hours;
  }

  if (duration === 0) {
    return formatted;
  }

  const minutesIndicator = hours !== 0 ? '' : 'min';

  if (String(duration).length === 1) {
    return `${formatted}0${duration}${minutesIndicator}`;
  }

  return `${formatted}${duration}${minutesIndicator}`;
};
