import React from 'react';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import PricingScreen from '../screens/PricingScreen';
import ItemScreen from '../screens/ItemScreen';
import MakeOrderScreen from '../screens/MakeOrderScreen';
import { t } from '../helpers/translation';
import { PricingItem } from '../../types';
import { AppStackParamList } from './AppNavigator';
import AddContactInformationScreen from '../modules/add-contact-tracing/AddContactTracingScreen';

type Props = {
  navigation: StackNavigationProp<AppStackParamList, 'Pricing'>;
};

export type PricingStackParamList = {
  Pricing: { pricingSlug: string };
  Item: {
    pricingSlug: string;
    itemId: string;
    item: PricingItem;
  };
  MakeOrder: {
    pricingSlug: string;
  };
  ContactTracing: {
    pricingSlug: string;
  };
};

const Stack = createStackNavigator<PricingStackParamList>();

const PricingNavigator = (props: Props) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Pricing"
        component={PricingScreen}
        options={{
          title: t('navigation.pricing'),
        }}
        initialParams={{ pricingSlug: '' }}
      />

      <Stack.Screen
        name="Item"
        component={ItemScreen}
        options={({ route }) => ({ title: route.params.item.name })}
      />

      <Stack.Screen
        name="MakeOrder"
        component={MakeOrderScreen}
        options={{
          title: t('components.cart.buttons.makeOrder'),
        }}
      />

      <Stack.Screen
        name="ContactTracing"
        component={AddContactInformationScreen}
        options={{
          title: t('screens.addContactInformation.title'),
        }}
      />
    </Stack.Navigator>
  );
};

export default PricingNavigator;
