import React, { useState, useEffect, useCallback } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { useQuery, queryCache } from 'react-query';
import { useDocumentTitle } from '../hooks/document';
import { t } from '../helpers/translation';
import Text from '../components/Text/Text';
import Button from '../components/Button/Button';
import { formatTime, generateRange } from '../helpers/format';
import { Planning as PlanningType } from '../../types';
import API from '../helpers/api';
import { businessIdState } from '../store/state';
import { UserStackParamList } from '../navigators/UserNavigator';
import Picker from '../components/Picker/Picker';
import ItemSeparator from '../components/ItemSeparator/ItemSeparator';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import Block from '../components/Block/Block';
import Label from '../components/Label/Label';
import P from '../components/P/P';
import Theme from '../helpers/theme';
import DeleteButton from '../components/DeleteButton/DeleteButton';

type Props = {
  navigation: StackNavigationProp<UserStackParamList, 'CreatePlanning'>;
};

const CreatePlanningScreen = (props: Props) => {
  const [planning, setPlanning] = useState<PlanningType>({});
  const [isCreating, setIsCreating] = useState(false);
  const businessId = useRecoilValue(businessIdState);

  const { data: fetchedPlanning, status: fetchPlanningStatus } = useQuery(
    ['planning', businessId],
    () => API.fetchPlanningByBusinessId(businessId),
    {
      retry: false,
    }
  );

  const isEditing = !fetchedPlanning;

  useDocumentTitle(t('navigation.planning'));

  useEffect(() => {
    if (fetchedPlanning) {
      setPlanning(fetchedPlanning);
    } else {
      setPlanning({
        '0': [],
        '1': [],
        '2': [],
        '3': [],
        '4': [],
        '5': [],
        '6': [],
      });
    }
  }, [fetchedPlanning]);

  const handleRedoPlanning = useCallback(async () => {
    if (!confirm(t('components.planning.alerts.redoPlanning'))) {
      return;
    }

    setIsCreating(true);

    try {
      await API.deletePlanningByBusinessId(businessId);
      queryCache.removeQueries(['planning', businessId]);
    } catch (error) {
      console.warn(error);
    }

    setIsCreating(false);
  }, []);

  const handleCreatePlanning = useCallback(async () => {
    setIsCreating(true);

    try {
      await API.createPlanningForBusiness(businessId, planning);
      queryCache.invalidateQueries(['planning', businessId]);
    } catch (error) {
      console.warn(error);
    }

    setIsCreating(false);
  }, [businessId, planning]);

  if (fetchPlanningStatus === 'loading' || Object.keys(planning).length === 0) {
    return <LoaderContainer />;
  }

  const weekdays = moment.weekdays();

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {!isEditing && (
        <Button
          type="danger"
          title={t('screens.createPlanning.actions.redoPlanning')}
          icon="calendar"
          onPress={handleRedoPlanning}
          busy={isCreating}
          style={styles.redoPlanningButton}
        />
      )}

      <Block list>
        {[...weekdays.slice(1), weekdays[0]].map((weekday, dayIndex) => (
          <React.Fragment key={weekday}>
            <View style={styles.weekdayContainer}>
              <Label style={styles.weekdayLabel}>{weekday}</Label>

              {!isEditing && planning[dayIndex].length === 0 && (
                <P style={{ color: Theme.RED, fontWeight: 'bold' }}>
                  {t('components.planning.banners.dayOff')}
                </P>
              )}

              {isEditing && planning[dayIndex].length === 0 && (
                <P style={{ marginBottom: 10 }}>
                  {t('components.planning.banners.ifClosed', { day: weekday })}
                </P>
              )}

              {planning[String(dayIndex)].map((slot, slotIndex, slots) => (
                <View
                  key={slotIndex}
                  style={[
                    styles.inputsRow,
                    (slotIndex < slots.length - 1 || isEditing) && {
                      marginBottom: 10,
                    },
                  ]}
                >
                  <Text>{t('screens.createPlanning.banners.slotFrom')}</Text>

                  <Picker
                    enabled={isEditing}
                    selectedValue={slot.from}
                    onValueChange={(slotFromTime) => {
                      const parsedFromTime = Number(slotFromTime);

                      setPlanning((planning) => {
                        const dayIndexKey = String(dayIndex);

                        return {
                          ...planning,
                          [dayIndexKey]: planning[dayIndexKey].map(
                            (daySlot, daySlotIndex) => {
                              if (daySlotIndex === slotIndex) {
                                return {
                                  ...daySlot,
                                  from: parsedFromTime,
                                };
                              }

                              return daySlot;
                            }
                          ),
                        };
                      });
                    }}
                    style={styles.slotFromTimePicker}
                  >
                    {generateRange(0, 23.5, 0.5).map((timeSlot) => (
                      <Picker.Item
                        key={timeSlot}
                        label={formatTime(timeSlot)}
                        value={timeSlot}
                      />
                    ))}
                  </Picker>

                  <Text>{t('screens.createPlanning.banners.slotTo')}</Text>

                  <Picker
                    enabled={isEditing}
                    selectedValue={slot.to}
                    onValueChange={(slotToTime) => {
                      const parsedToTime = Number(slotToTime);

                      setPlanning((planning) => {
                        const dayIndexKey = String(dayIndex);

                        return {
                          ...planning,
                          [dayIndexKey]: planning[dayIndexKey].map(
                            (daySlot, daySlotIndex) => {
                              if (daySlotIndex === slotIndex) {
                                return {
                                  ...daySlot,
                                  to: parsedToTime,
                                };
                              }

                              return daySlot;
                            }
                          ),
                        };
                      });
                    }}
                    style={styles.slotToTimePicker}
                  >
                    {generateRange(0, 23.5, 0.5).map((timeSlot) => (
                      <Picker.Item
                        key={timeSlot}
                        label={formatTime(timeSlot)}
                        value={timeSlot}
                      />
                    ))}
                  </Picker>

                  {isEditing && (
                    <DeleteButton
                      title={t('screens.createPlanning.actions.deleteSlot')}
                      onDelete={() => {
                        setPlanning((planning) => {
                          const dayIndexKey = String(dayIndex);

                          return {
                            ...planning,
                            [dayIndexKey]: planning[dayIndexKey].filter(
                              (_slot, index) => slotIndex !== index
                            ),
                          };
                        });
                      }}
                      style={styles.deleteSlotButton}
                    />
                  )}
                </View>
              ))}

              {isEditing && (
                <Button
                  size="small"
                  inline
                  icon="plus"
                  title={t('screens.createPlanning.actions.addSlot')}
                  onPress={() => {
                    setPlanning((planning) => {
                      const dayIndexKey = String(dayIndex);

                      const fromTime =
                        planning[dayIndexKey].length > 0
                          ? planning[dayIndexKey].slice(-1)[0].to
                          : 10;

                      const toTime =
                        planning[dayIndexKey].length > 0
                          ? planning[dayIndexKey].slice(-1)[0].to +
                            (planning[dayIndexKey].slice(-1)[0].to -
                              planning[dayIndexKey].slice(-1)[0].from)
                          : 10.5;

                      return {
                        ...planning,
                        [dayIndexKey]: [
                          ...planning[dayIndexKey],
                          {
                            from: fromTime,
                            to: toTime,
                            capacity: 0,
                          },
                        ],
                      };
                    });
                  }}
                />
              )}
            </View>

            {dayIndex < weekdays.length - 1 && <ItemSeparator />}
          </React.Fragment>
        ))}

        {isEditing && (
          <Button
            type="success"
            title={t('screens.createPlanning.actions.confirmPlanning')}
            onPress={handleCreatePlanning}
            busy={isCreating}
            icon="check"
            style={styles.confirmPlanningButton}
          />
        )}
      </Block>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  weekdayContainer: {
    paddingRight: 20,
  },
  weekdayLabel: {
    textTransform: 'capitalize',
  },
  slotFromTimePicker: {
    marginHorizontal: 10,
  },
  slotToTimePicker: {
    marginLeft: 10,
  },
  deleteSlotButton: {
    marginLeft: 10,
  },
  redoPlanningButton: {
    marginBottom: 20,
  },
  confirmPlanningButton: {
    marginTop: 20,
    marginRight: 20,
  },
  inputsRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default CreatePlanningScreen;
