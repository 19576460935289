import { atom, atomFamily } from 'recoil';
import { Cart, PricingItem, PaymentMethod, LoyaltyStage } from '../../types';

type PricingCategory = {
  id?: string;
  name: string;
  description?: string;
};

// -- Auth

export const isLoggedInState = atom({
  key: 'isLoggedIn',
  default: false,
});

// -- Business

export const businessIdState = atom({
  key: 'businessId',
  default: '',
});

export const businessNameState = atom({
  key: 'businessName',
  default: '',
});

// -- Pricing

export const pricingIdState = atom({
  key: 'pricingId',
  default: '',
});

export const pricingSlugState = atom({
  key: 'pricingSlug',
  default: '',
});

export const pricingBaseCurrencyState = atom({
  key: 'pricingBaseCurrency',
  default: '',
});

export const pricingCanOrderState = atom({
  key: 'pricingCanOrder',
  default: false,
});

export const pricingCanBookState = atom({
  key: 'pricingCanBook',
  default: false,
});

export const pricingPaymentMethodState = atom<PaymentMethod>({
  key: 'pricingPaymentMethod',
  default: {
    type: 'paypal',
    clientId: '',
    clientSecret: '',
  },
});

// -- Pricing editor

export const categoriesOrderState = atom<string[]>({
  key: 'categoriesOrder',
  default: [],
});

// This only serves as a temporary place to go from
// `rawCategories` to `categories`
export const rawCategoriesState = atom<PricingCategory[]>({
  key: 'rawCategories',
  default: [],
});

// categoryId => [PricingItem, ...]
export const rawCategoryItemsState = atomFamily<PricingItem[], string>({
  key: 'rawCategoryItems',
  default: [],
});

export const categoryItemsState = atomFamily<string[], string>({
  key: 'categoryItems',
  default: [],
});

export const categoriesState = atomFamily<PricingCategory, string>({
  key: 'categories',
  default: {
    name: '',
  },
});

export const itemsState = atomFamily<PricingItem, string>({
  key: 'items',
  default: {
    name: '',
    description: '',
    order: 1,
  },
});

export const categoryScrollTopPositionState = atomFamily<number, string>({
  key: 'categoryScrollTopPosition',
  default: 0,
});

// -- Pricing viewer

export type RawPricingItems = { [itemId: string]: PricingItem };

export const pricingItemsState = atom<RawPricingItems>({
  key: 'pricingItems',
  default: {},
});

export const cartState = atom<Cart>({
  key: 'cart',
  default: [],
});

export const pricingBusinessNameState = atom({
  key: 'pricingBusinessName',
  default: '',
});

export const pricingBusinessIdState = atom({
  key: 'pricingBusinessId',
  default: '',
});

// -- Memberships editor

export const membershipsProgramIdState = atom({
  key: 'membershipsProgramId',
  default: '',
});

export const membershipStagesState = atom<LoyaltyStage[]>({
  key: 'membershipStages',
  default: [],
});

// -- Contact tracing

export const contactTracingEnabledState = atom({
  key: 'contactTracingEnabled',
  default: false,
});
