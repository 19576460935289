import React from 'react';
import {
  GestureResponderEvent,
  Image,
  ImageSourcePropType,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
// @ts-ignore
import color from 'color';
import H2 from '../H2/H2';
import H3 from '../H3/H3';
import Text from '../Text/Text';
import { useIsWideScreen } from '../../hooks/responsive';
import Link from '../Link/Link';
import Theme from '../../helpers/theme';
import Icon from '../Icon/Icon';

export type FeatureProps = {
  title: string;
  subtitle: string;
  image?: ImageSourcePropType;
  href?: string;
  backgroundColor?: string;
  textColor?: string;
  titleColor?: string;
  actions?: { label: string; onPress: () => void }[];
  onPress?: (event: GestureResponderEvent) => void;
  style?: ViewProps['style'];
};

const Feature = (props: FeatureProps) => {
  const isWideScreen = useIsWideScreen();
  const Wrapper = props.onPress ? TouchableOpacity : View;

  const extraProps: any = {};
  if (props.onPress) {
    extraProps.onPress = props.onPress;
  }

  const LinkWrapper = props.href
    ? (otherProps: any) => (
        <Link
          to={props.href}
          underline={false}
          style={styles.linkContainer}
          {...otherProps}
        />
      )
    : (otherProps: any) => <React.Fragment {...otherProps} />;

  return (
    <LinkWrapper>
      <Wrapper
        {...extraProps}
        style={[
          {
            backgroundColor: color(props.backgroundColor ?? Theme.PRIMARY)
              .lighten(0.2)
              .string(),
          },
          styles.container,
          props.style,
        ]}
      >
        <View style={styles.text}>
          <H2 style={{ color: props.titleColor ?? props.textColor ?? 'white' }}>
            {props.title}

            {(props.onPress || props.href) && (
              <Icon
                name="arrow-right"
                color={Theme.PRIMARY}
                size={16}
                style={styles.titleLinkArrow}
              />
            )}
          </H2>

          <H3
            style={[
              styles.subtitle,
              {
                color: props.textColor ?? 'white',
              },
            ]}
          >
            {props.subtitle}
          </H3>

          {props.actions && (
            <View style={[styles.actions, isWideScreen && styles.actionsWide]}>
              {props.actions.map((action) => (
                <TouchableOpacity
                  key={action.label}
                  onPress={action.onPress}
                  style={[styles.action, isWideScreen && styles.actionWide]}
                >
                  <Text
                    style={[
                      styles.actionLabel,
                      {
                        color: props.textColor ?? 'white',
                      },
                    ]}
                  >
                    {action.label}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>

        {props.image && (
          <View style={styles.image}>
            <Image
              source={props.image}
              style={StyleSheet.absoluteFill}
              resizeMode="contain"
            />
          </View>
        )}
      </Wrapper>
    </LinkWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 6,
    padding: 20,
    paddingRight: 0,
    minHeight: 200,
  },
  linkContainer: {
    flex: 1,
    display: 'flex',
  },
  text: {
    flex: 1,
    marginRight: 20,
    justifyContent: 'center',
  },
  titleLinkArrow: {
    marginLeft: 5,
  },
  subtitle: {
    fontWeight: 'normal',
    marginTop: 5,
  },
  actions: {
    flexDirection: 'column',
  },
  actionsWide: {
    flexDirection: 'row',
    marginTop: 10,
  },
  action: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 8,
    borderRadius: 6,
    marginRight: 10,
    marginTop: 10,
  },
  actionWide: {
    marginTop: 0,
  },
  actionLabel: {
    fontWeight: 'bold',
  },
  image: {
    flex: 1,
  },
});

export default Feature;
