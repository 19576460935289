import React, { useState } from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';
import { DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useColorScheme } from 'react-native-appearance';
import CountriesList from './CountriesList';
import TextInput from '../TextInput/TextInput';
import { t } from '../../helpers/translation';

type Props = {
  height: number;
  onChangeCountry: (countryCode: string) => void;
  style?: ViewProps['style'];
};

const CountryPicker = (props: Props) => {
  const [query, setQuery] = useState('');
  const scheme = useColorScheme();

  return (
    <View
      style={[
        styles.container,
        scheme === 'dark' && styles.containerDark,
        props.style,
      ]}
    >
      <View
        style={[
          styles.searchInputContainer,
          scheme === 'dark' && styles.searchInputContainerDark,
        ]}
      >
        <TextInput
          editable
          autoFocus
          type="text"
          placeholder={t('components.countryPicker.placeholders.searchCountry')}
          value={query}
          onChangeText={setQuery}
          style={styles.searchInput}
        />
      </View>

      <CountriesList
        query={query}
        onChangeCountry={props.onChangeCountry}
        height={props.height}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: DefaultTheme.colors.border,
    backgroundColor: DefaultTheme.colors.card,
  },
  containerDark: {
    borderColor: DarkTheme.colors.border,
    backgroundColor: DarkTheme.colors.card,
  },
  searchInput: {
    margin: 10,
  },
  searchInputContainer: {
    borderBottomWidth: 1,
    borderBottomColor: DefaultTheme.colors.border,
  },
  searchInputContainerDark: {
    borderBottomColor: DarkTheme.colors.border,
  },
});

export default CountryPicker;
