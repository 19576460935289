import React, { useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';
// @ts-ignore
import color from 'color';
import { useDocumentTitle } from '../hooks/document';
import { t } from '../helpers/translation';
import API from '../helpers/api';
import { businessIdState } from '../store/state';
import { UserStackParamList } from '../navigators/UserNavigator';
import LoaderContainer from '../components/LoaderContainer/LoaderContainer';
import Block from '../components/Block/Block';
import P from '../components/P/P';
import Button from '../components/Button/Button';
import RoomForm from '../forms/RoomForm';
import Banner from '../components/Banner/Banner';
import Theme from '../helpers/theme';

type Props = {
  navigation: StackNavigationProp<UserStackParamList, 'RoomTables'>;
};

const RoomTablesScreen = (props: Props) => {
  const businessId = useRecoilValue(businessIdState);
  const [showCreateForm, setShowCreateForm] = useState(false);

  const { data: rooms, status: status } = useQuery(['rooms', businessId], () =>
    API.fetchBusinessRooms(businessId)
  );

  useDocumentTitle(t('navigation.roomTables'));

  if (status === 'loading' || !rooms) {
    return <LoaderContainer />;
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Banner
        rounded
        align="left"
        color={color(Theme.GREEN).alpha(0.12)}
        textColor={Theme.BLACK}
        style={styles.explanations}
      >
        {t('screens.roomTables.banners.explanations')}
      </Banner>

      {showCreateForm ? (
        <Block>
          <RoomForm type="create" />
        </Block>
      ) : (
        <Button
          title={t('screens.roomTables.actions.createRoom')}
          onPress={() => setShowCreateForm(true)}
          icon="plus"
        />
      )}

      {rooms.length === 0 && (
        <Block verticalMargins>
          <P>{t('screens.roomTables.banners.noRooms')}</P>
        </Block>
      )}

      {rooms.map((room) => (
        <Block key={room.id} style={{ marginTop: 20 }} list>
          <RoomForm
            type="update"
            id={room.id}
            name={room.name}
            tables={room.room_tables}
            style={styles.roomTableForm}
          />
        </Block>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  explanations: {
    marginBottom: 20,
  },
  roomTableForm: {
    paddingRight: 20,
  },
});

export default RoomTablesScreen;
