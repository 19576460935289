import React, { useCallback, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { UserStackParamList } from '../navigators/UserNavigator';
import { t } from '../helpers/translation';
import { useDocumentTitle } from '../hooks/document';
import Block from '../components/Block/Block';
import P from '../components/P/P';
import TextInput from '../components/TextInput/TextInput';
import Button from '../components/Button/Button';
import Text from '../components/Text/Text';
import Link from '../components/Link/Link';
import { pricingPaymentMethodState, pricingIdState } from '../store/state';
import API from '../helpers/api';
import Banner from '../components/Banner/Banner';
import Theme from '../helpers/theme';

type DashboardScreenProps = {
  navigation: StackNavigationProp<UserStackParamList, 'PaymentSettings'>;
};

const PaymentSettingsScreen = (props: DashboardScreenProps) => {
  const pricingId = useRecoilValue(pricingIdState);
  const [pricingPaymentMethod, setPricingPaymentMethod] = useRecoilState(
    pricingPaymentMethodState
  );
  const resetPaymentMethod = useResetRecoilState(pricingPaymentMethodState);
  const [isBusyUpdating, setIsBusyUpdating] = useState(false);
  const [isBusyDeleting, setIsBusyDeleting] = useState(false);

  const handleDeletePaymentMethod = useCallback(async () => {
    setIsBusyDeleting(true);

    try {
      await API.deletePricingPaymentMethod(pricingId);
      resetPaymentMethod();
    } catch (error) {
      console.warn(error);
    }

    setIsBusyDeleting(false);
  }, []);

  const handleUpdatePaymentMethod = useCallback(async () => {
    setIsBusyUpdating(true);

    const toUpdate: any = {
      paymentType: pricingPaymentMethod.type,
      paymentClientId: pricingPaymentMethod.clientId,
    };

    if (pricingPaymentMethod.clientSecret) {
      toUpdate.paymentClientSecret = pricingPaymentMethod.clientSecret;
    }

    try {
      await API.updatePricingById(pricingId, toUpdate);
    } catch (error) {
      console.warn(error);
    }

    setIsBusyUpdating(false);
  }, [
    pricingId,
    pricingPaymentMethod.type,
    pricingPaymentMethod.clientId,
    pricingPaymentMethod.clientSecret,
  ]);

  useDocumentTitle(t('navigation.paymentSettings'));

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Banner
        rounded
        align="left"
        color={Theme.ORANGE}
        textStyle={styles.payInStoreBannerText}
        style={styles.payInStoreBanner}
      >
        {t('screens.paymentSettings.banners.onlyClickCollect')}
      </Banner>

      <P style={styles.instructions}>
        {t('forms.editPricing.banners.configurePayment.systemIndications') +
          ' '}

        <Link
          to="https://www.paypal.com/merchantsignup/applicationChecklist"
          target="_blank"
        >
          {t(
            'forms.editPricing.banners.configurePayment.createBusinessAccount'
          )}
        </Link>

        {' ' +
          t('forms.editPricing.banners.configurePayment.createApplication') +
          ' '}

        <Link
          to="https://developer.paypal.com/docs/business/get-started"
          target="_blank"
        >
          {t('forms.editPricing.banners.configurePayment.followSteps')}
        </Link>

        <Text style={{ fontWeight: 'bold' }}>
          {t('forms.editPricing.banners.configurePayment.pasteCredentials')}
        </Text>
      </P>

      <Block>
        <TextInput
          editable
          label={t(
            'forms.editPricing.banners.configurePayment.inputs.clientID.label'
          )}
          value={pricingPaymentMethod.clientId}
          onChangeText={(clientId) => {
            setPricingPaymentMethod((paymentMethod) => ({
              ...paymentMethod,
              clientId,
            }));
          }}
          placeholder={t(
            'forms.editPricing.banners.configurePayment.inputs.clientID.placeholder'
          )}
          style={styles.input}
        />

        <TextInput
          editable
          label={t(
            'forms.editPricing.banners.configurePayment.inputs.clientSecret.label'
          )}
          value={pricingPaymentMethod.clientSecret}
          onChangeText={(clientSecret) => {
            setPricingPaymentMethod((paymentMethod) => ({
              ...paymentMethod,
              clientSecret,
            }));
          }}
          hint={t(
            'forms.editPricing.banners.configurePayment.inputs.clientSecret.hint'
          )}
          placeholder={t(
            'forms.editPricing.banners.configurePayment.inputs.clientSecret.placeholder'
          )}
          style={styles.input}
        />

        <Button
          icon="edit-3"
          title={t(
            'forms.editPricing.banners.configurePayment.actions.updatePaymentMethod'
          )}
          onPress={handleUpdatePaymentMethod}
          busy={isBusyUpdating}
        />
      </Block>

      {pricingPaymentMethod.clientId && (
        <Button
          icon="x"
          type="danger"
          title={t('screens.paymentSettings.actions.deactivateOnlinePayments')}
          onPress={handleDeletePaymentMethod}
          busy={isBusyDeleting}
          style={styles.deletePaymentMethodButton}
        />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  instructions: {
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
  },
  payInStoreBanner: {
    marginBottom: 20,
  },
  payInStoreBannerText: {
    color: Theme.BLACK,
  },
  deletePaymentMethodButton: {
    marginTop: 20,
  },
});

export default PaymentSettingsScreen;
