import React from 'react';
import { StyleSheet } from 'react-native';
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import Logo from '../Logo/Logo';
import { useIsWideScreen } from '../../hooks/responsive';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

type Props = DrawerContentComponentProps;

const NavigationBar = (props: Props) => {
  const isWideScreen = useIsWideScreen();

  const filteredProps = {
    ...props,
    state: {
      ...props.state,
      routes: props.state.routes.filter(
        (route) =>
          route.name !== 'OrderDetails' &&
          route.name !== 'ProductBookings' &&
          route.name !== 'ProductContactTracing' &&
          route.name !== 'ProductMemberships' &&
          route.name !== 'ProductOrders' &&
          route.name !== 'CreatePlanning' &&
          route.name !== 'Pricing' &&
          route.name !== 'PlusOnePrivacyPolicyScreen' &&
          route.name !== 'PlusOneTermsConditionsScreen' &&
          // TEMP
          route.name !== 'ProductPricing'
      ),
    },
  };

  return (
    <>
      <DrawerContentScrollView
        {...filteredProps}
        style={[props.style, styles.scrollView]}
      >
        {isWideScreen && <Logo style={styles.logo} />}
        <DrawerItemList {...filteredProps} />
      </DrawerContentScrollView>

      <LanguageSelector />
    </>
  );
};

const styles = StyleSheet.create({
  logo: {
    marginVertical: 10,
  },
  scrollView: {
    flex: 1,
  },
});

export default NavigationBar;
