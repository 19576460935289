import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Order } from '../../../types';
import H1 from '../H1/H1';
import { t } from '../../helpers/translation';
import Status from '../Status/Status';
import Block from '../Block/Block';
import H2 from '../H2/H2';
import P from '../P/P';
import H3 from '../H3/H3';
import { formatPrice } from '../../helpers/format';
import Link from '../Link/Link';
import API from '../../helpers/api';

type OrderDetailsProps = {
  order: Order;
};

const OrderDetails = ({ order }: OrderDetailsProps) => {
  const role = API.getUserRole();

  return (
    <>
      <H1>
        {t('screens.orderDetails.headers.orderNumber', {
          referenceNumber: order.referenceNumber,
        })}
      </H1>

      <View style={styles.statuses}>
        <Status status={order.statusOwner} style={styles.status} />
        <Status status={order.statusUser} />
      </View>

      <Block verticalMargins style={styles.informationContainer}>
        <View style={styles.column}>
          <H3>{t('common.customer')}</H3>

          <P>
            {order.contactFirstName} {order.contactLastName}
          </P>

          {order.contactPhone && (
            <P>
              {order.contactPhone.includes('*') ? (
                order.contactPhone
              ) : (
                <Link to={`tel:${order.contactPhone}`} target="_blank">
                  {order.contactPhone}
                </Link>
              )}
            </P>
          )}

          {order.contactEmail && (
            <P>
              {order.contactEmail.includes('*') ? (
                order.contactEmail
              ) : (
                <Link to={`mailto:${order.contactEmail}`} target="_blank">
                  {order.contactEmail}
                </Link>
              )}
            </P>
          )}
        </View>

        {order.business && (
          <View style={styles.column}>
            <H3>{t('common.business')}</H3>

            <P>{order.business.name}</P>

            <P>{order.business.address}</P>

            {order.business.phoneNumber && (
              <P>
                <Link to={`tel:${order.business.phoneNumber}`}>
                  {order.business.phoneNumber}
                </Link>
              </P>
            )}
          </View>
        )}
      </Block>

      <Block>
        {order.order_items.map((item) =>
          !item.pricing_item ? null : (
            <View key={item.id} style={styles.itemContainer}>
              {item.pricing_item.image && (
                <Image
                  source={{ uri: item.pricing_item.image }}
                  resizeMode="cover"
                  style={styles.itemImage}
                />
              )}

              <View style={styles.column}>
                <H3>
                  {item.pricing_item.name}
                  {item.order_item_options && item.order_item_options.length > 0
                    ? ` (${item.order_item_options
                        .map(
                          (orderItemOption) =>
                            orderItemOption.pricing_item_option.name
                        )
                        .join(', ')})`
                    : ''}
                </H3>

                {item.comment && <P>"{item.comment}"</P>}

                <P>
                  {item.pricing_item.priceType && item.pricing_item.price
                    ? formatPrice(
                        // TODO
                        'EUR',
                        item.pricing_item.priceType,
                        item.pricing_item.priceType === 'value'
                          ? String(
                              (Number(item.pricing_item.price!) +
                                (item.order_item_options
                                  ? item.order_item_options!.reduce(
                                      (acc, orderItemOption) =>
                                        acc +
                                        (orderItemOption.pricing_item_option
                                          .price || 0),
                                      0
                                    )
                                  : 0)) *
                                item.quantity
                            )
                          : item.pricing_item.price
                      ) + ' '
                    : ''}
                  x {item.quantity}
                </P>
              </View>
            </View>
          )
        )}

        <H3>{t('common.comment')}</H3>

        <P>
          {order.comment
            ? `"${order.comment}"`
            : t('screens.orderDetails.errors.noComment')}
        </P>
      </Block>

      {!API.isOwner() && (
        <P style={styles.paymentText}>
          {t('screens.orderDetails.banners.paymentInformation')}
        </P>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  statuses: {
    flexDirection: 'row',
    marginTop: 10,
  },
  status: {
    marginRight: 8,
  },
  informationContainer: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    marginBottom: 10,
  },
  itemImage: {
    width: 60,
    height: 60,
    borderRadius: 6,
    marginRight: 10,
  },
  paymentText: {
    marginTop: 20,
    fontStyle: 'italic',
  },
});

export default OrderDetails;
